import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
import { servicePath } from "../app/variables";

function ProfileFriendsCard({item, getFriendList, setMessage, setShowMessage, token}) {

    const handleFollowFriend = (friendId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "following_id": friendId
            },
            "action": "follow"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/follow.user`, requestOptions)
        .then(response => response.json())
        .then(result => {
            // console.log(result)
            if(result.success == "Following"){
                setMessage("Followed successfully")
                setShowMessage(true)

                getFriendList()
                
                setTimeout(() =>{
                        setShowMessage(false)
                }, 2000)
            }
        })
        .catch(error => console.log('error', error));
    };

    const handleRemoveFriend = (friendId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "friend_user_id": friendId
            }
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${servicePath}/friend.unfriend`, requestOptions)
        .then(response => response.json())
        .then(result => {
        // console.log(result)
        if(result.success == "unfriend successfully"){
            setMessage("Unfriend successful")
            setShowMessage(true)

            getFriendList('')
            
            setTimeout(() =>{
                    setShowMessage(false)
            }, 2000)
        }
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div className="iq-friendlist-block custom_friends p-1 p-sm-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center friend_wrap me-2">
                    <Link to={`/dashboard/app/friend-profile/${item.id}`}>
                        <img
                            src={item && item.photo ? item.photo : dummyUserImage}
                            alt="profile-img"
                            className="img-fluid me-2"
                            onError={(e) => {
                                e.target.src = dummyUserImage
                            }}
                        />
                    </Link>
                    <div className="friend-info">
                        <h5 className="fw-bold mb-0">{item.name}</h5>
                        {/* <p className="mb-0 fw-500">{item.username}</p> */}
                        {/* <p className="mb-0">15 friends</p> */}
                    </div>
                </div>
                <div className="card-header-toolbar d-flex align-items-center">
                    <Dropdown className="primary_drop_btn">
                        <Dropdown.Toggle className="btn btn-primary m-0 px-2 py-1">
                            <i className="ri-check-line me-1"></i>
                            Friend
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                            {/* <Dropdown.Item href="#">
                                Get Notification
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                Close Friend
                            </Dropdown.Item> */}
                            {/* <Dropdown.Item href="#" onClick={() => {handleFollowFriend(item.id)}}>
                                Follow
                            </Dropdown.Item> */}
                            <Dropdown.Item href="#" onClick={() => {handleRemoveFriend(item.id)}}>
                                <i className="ri-user-unfollow-line me-1"></i>
                                Unfriend
                            </Dropdown.Item>
                            {/* <Dropdown.Item href="#">
                                Block
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default ProfileFriendsCard;