import React, { useEffect, useState } from "react";

//router
import { Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";

//layoutpages
import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import WithoutLeftSidebar from "../layouts/dashboard/without-leftsidebar";
import WithoutRightSidebar from "../layouts/dashboard/without-rightsidebar";
import SelectUniverse from "../views/dashboard/app/SelectUniverse";
import { baseURL } from "../views/dashboard/app/variables";
import HelmetMetaData from "../views/dashboard/components/HelmetMetaData";

import { Toaster } from "react-hot-toast";
import { ProfileProvider } from "../context/ProfileContext";
import Playground from "../views/playground/Playground";

const userDetail = JSON.parse(localStorage.getItem("users"));

const IndexRouters = () => {
  const history = useHistory();

  useEffect(() => {
    if (userDetail !== null) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
      const isNotPublicPost = history.location.pathname.includes(
        "/dashboard/app/post-detail"
      );

      var initialPath = history.location.pathname.substring(0, 10);

      if (
        !isNotPublicPost &&
        (initialPath === "/dashboard" || initialPath === "/")
      ) {
        history.push("/auth/sign-up");
      }
    }
  }, [userDetail]);

  const [loginStatus, setLoginStatus] = useState(false);

  return (
    <ProfileProvider>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 4000,
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <HelmetMetaData
        title="Universimm"
        description="Share your passions and interests with the people you love."
        url={baseURL}
      ></HelmetMetaData>

      <Switch>
        <Route
          path="/without-leftsidebar"
          component={WithoutLeftSidebar}
        ></Route>
        <Route
          path="/without-rightsidebar"
          component={WithoutRightSidebar}
        ></Route>
        <Route path="/playground" component={Playground} />

        <Route
          path="/dashboard/app/selectuniverse"
          component={SelectUniverse}
        />
        <Route path="/errors" component={Simple}></Route>
        <Route path="/user" component={Simple}></Route>
        <Route path="/auth" component={Simple}></Route>

        {!loginStatus ? (
          <>
            <Route path="/" component={Simple}></Route>
          </>
        ) : (
          <>
            <Route path="/" component={Default}></Route>
          </>
        )}
      </Switch>
    </ProfileProvider>
  );
};

export default IndexRouters;
