import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { BiBlock } from "react-icons/bi";
import { FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import InputEmoji from "react-input-emoji";
import ReactPlayer from "react-player";
import NumericLabel from "react-pretty-numbers";
import { Link, NavLink, useHistory } from "react-router-dom";
import dummyUserImage from "../../../assets/images/no-profile-picture.jpg";
import CustomToggle from "../../../components/dropdowns";
import LikeCommentListModal from "../../../components/likesCommentsListModal/LikeCommentListModal";
import useModal from "../../../hooks/useModal";
import { baseURL, servicePath } from "../app/variables";
import EditPostModal from "./EditPostModal";
import { Linkify } from "./Functions";
import LinkPreview from "./LinkPreview";
import PostCommentsC from "./PostCommentsC";
import SingleLightbox from "./SingleLightbox2";
import SocialSharePopup from "./SocialSharePopup";

function ProfilePostItem({
  item,
  componentOf,
  setLoading,
  token,
  setShowMessage,
  setMessage,
  userPost,
  getAllPosts,
  currentPage,
  setSubsUniverseModal,
  setUniverId,
}) {
  const userData = JSON.parse(localStorage.getItem("users"));

  const {
    show: showLikeCommentModal,
    handleClose: handleCloseLikeCommentModal,
    handleShow: handleShowLikeCommentModal,
  } = useModal();

  const [showLikeCommentModalTypeInfo, setShowLikeCommentModalTypeInfo] =
    useState({
      type: "",
      postId: "",
    });

  const [deletePostConfirm, setDeletePostConfirm] = useState(false);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentList, setCommentList] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [sharingModal, setSharingModal] = useState(false);
  const [blockConfirm, setBlockConfirm] = useState(false);
  const [reportMsg, setReportMsg] = useState("");
  const [postLike, setPostLike] = useState({
    isLiked: false,
    likeCount: 0,
  });

  const [commentCount, setCommentCount] = useState({
    cCount: 0,
  });

  useEffect(() => {
    if (item.id !== "") {
      setPostLike({
        isLiked: item.isLikedByMe,
        likeCount: item.total_emotions,
      });

      setCommentCount({
        cCount: item.total_comments,
      });
    }
  }, [item]);

  const history = useHistory();

  const friendProfile = () => {
    history.push(`/dashboard/app/friend-profile/${item.user_id}`);
  };

  const taggedUserProfile = (taggedID) => {
    if (taggedID !== userData.id) {
      history.push(`/dashboard/app/friend-profile/${taggedID}`);
    } else {
      history.push(`/dashboard/app/profile`);
    }
  };

  const userProfile = () => {
    history.push(`/dashboard/app/profile`);
  };

  const showPostDetails = (postId) => {
    history.push(`/dashboard/app/post-detail/${postId}`);
  };

  const handleDeletePost = (postId) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      data: {
        confirm: "Y",
      },
      query: {
        id: postId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.delete`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "delete result")
        if (result.success === "Post deleted") {
          setDeletePostConfirm(false);
          setMessage("Post deleted successfully");
          setShowMessage(true);

          if (componentOf === "index") {
            getAllPosts(currentPage);
          } else {
            userPost(currentPage);
          }

          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleHidePost = (postId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      query: {
        post_id: postId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.hide`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "post hide successfully ") {
          setMessage("Post hide successfully");

          if (componentOf === "index") {
            getAllPosts(currentPage);
          } else {
            userPost(currentPage);
          }

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleSavePost = (postId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        post_id: postId,
        status: "save",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.save`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "post saved successfully ") {
          setMessage("Post saved successfully");
          setShowMessage(true);

          if (componentOf === "index") {
            getAllPosts(currentPage);
          } else {
            userPost(currentPage);
          }

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleRemoveSavePost = (postId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        post_id: postId,
        status: "unsave",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.save`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "post unsaved successfully ") {
          setMessage("Post removed from saved");
          setShowMessage(true);

          if (componentOf === "index") {
            getAllPosts(currentPage);
          } else {
            userPost(currentPage);
          }

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlePostLike = (postId, status) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var statusVal = 0;

    if (status === false) {
      statusVal = 1;
      // console.log(statusVal, status)
    } else {
      statusVal = 0;
      // console.log(statusVal, status)
    }

    var raw = JSON.stringify({
      origin: "web",
      data: {
        post_id: postId,
        status: statusVal,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.like`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)

        if (status) {
          setPostLike({
            isLiked: false,
            likeCount: postLike.likeCount - 1,
          });
        } else {
          setPostLike({
            isLiked: true,
            likeCount: postLike.likeCount + 1,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const openEditPostModal = (item) => {
    // console.log(item, "new postId")

    if (item.id) {
      setCreatePostModal(true);
    }
  };

  const handleReportPost = (item) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        post_id: item.id,
        message: reportMsg,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.report`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result.data === "post reported successfully ") {
          setMessage("Reported successfully");
          setShowMessage(true);

          if (componentOf === "index") {
            getAllPosts(currentPage);
          }

          handleClose();
          setReportMsg("");

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getPostComments = (postId, commentPage, limit) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      where: {
        post_id: postId,
        parent_id: "0",
      },
      limit: limit,
      order: "desc",
      page: commentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/comment.browse`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          // console.log(result, "comments list")

          setCommentList(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCommentShow = (universeID, isUniversedMatched) => {
    if (componentOf === "index" || componentOf === "profile") {
      setShowComment(true);
      getPostComments(item.id, 1, 5);
    } else if (componentOf === "friend-profile") {
      if (isUniversedMatched) {
        setShowComment(true);
        getPostComments(item.id, 1, 5);
      } else {
        setUniverId(universeID);
        setSubsUniverseModal(true);
      }
    }
  };

  const handlePostComment = (postDetail) => {
    // console.log(postDetail, "postDetail postDetail")

    if (commentText !== "") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var bodyFormData = new FormData();

      bodyFormData = {
        action: "save",
        data: {
          parent_id: "0",
          user_id: postDetail.user_id,
          post_id: postDetail.id,
          type: "text",
          comment: commentText,
          media: "",
          tagged_users: [],
          hash_tags: [],
        },
        query: {
          id: "",
        },
      };

      axios
        .post(`${servicePath}/comment.post`, bodyFormData, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res) {
            // console.log("comment post API response", res.data);

            setLoading(false);

            // if(componentOf === "index") {
            //     getAllPosts(currentPage);
            // } else {
            //     userPost(currentPage);
            // }

            getPostComments(postDetail.id, 1, 5);

            setCommentCount({
              cCount: commentCount.cCount + 1,
            });
          }
        })
        .catch((err) => {
          console.log(err, "false");

          setLoading(false);
          setMessage("There is some error, Please try after sometime");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        });
    }
  };

  const LoadNextComments = (commentId, currentLimit, limit) => {
    getPostComments(commentId, currentLimit + 1, limit);
  };

  const LoadPrevComments = (commentId, currentLimit, limit) => {
    getPostComments(commentId, currentLimit - 1, limit);
  };

  // const handleCommentModal = (commentId, page, limit) => {

  //     console.log(commentId, "commentId");

  //     getPostComments(commentId, page, limit);

  //     setCommentModal(true)
  // };

  // const handlePostCommentText = (e) => {
  //     e.preventDefault()

  //     setCommentText(e.target.value)

  //     console.log(e.target.value, commentText, "comment text input");
  // };

  const handleClose = () => {
    setDeletePostConfirm(false);
    setBlockConfirm(false);
  };

  const handleLikeCommentPostModal = (id, type) => {
    handleShowLikeCommentModal();
    setShowLikeCommentModalTypeInfo({
      type: type,
      postId: id,
    });
  };

  return (
    <>
      <div className="post-item post_cards">
        <div className="user-post-data pb-2">
          <div className="d-flex justify-content-between">
            {userData && item.user_id === userData.id ? (
              <NavLink
                to="#"
                location={{}}
                onClick={() => userProfile()}
                className="me-2"
              >
                <img
                  className="rounded-circle avatar-50"
                  src={
                    item.details && item.details.photo
                      ? item.details.photo
                      : dummyUserImage
                  }
                  alt=""
                  onError={(e) => {
                    e.target.src = dummyUserImage;
                  }}
                />
              </NavLink>
            ) : (
              <NavLink
                to="#"
                location={{}}
                onClick={() => friendProfile()}
                className="me-2"
              >
                <img
                  className="rounded-circle avatar-50"
                  src={
                    item.details && item.details.photo
                      ? item.details.photo
                      : dummyUserImage
                  }
                  alt=""
                  onError={(e) => {
                    e.target.src = dummyUserImage;
                  }}
                />
              </NavLink>
            )}

            <div className="w-100">
              <div className="d-flex justify-content-between align-items-start flex-nowrap h-100">
                <div className="d-flex justify-content-center h-100 flex-column">
                  {item.tagged_users === null || item.tagged_users === "0" ? (
                    <div className="h6 fw-bold mb-1">
                      {item.details && item.details.name}
                    </div>
                  ) : (
                    <div
                      className={`${
                        item.tagged_users.length > 0
                          ? "h6 mb-1 lh-1"
                          : "h6 mb-1 lh-1"
                      }`}
                    >
                      <strong className="text-dark">
                        {item.details && item.details.name + " "}{" "}
                      </strong>

                      {item.tagged_users.length > 0 ? (
                        <>
                          {" "}
                          is with{" "}
                          <span>
                            <NavLink
                              className="text-dark"
                              to="#"
                              location={{}}
                              onClick={() =>
                                taggedUserProfile(item.tagged_users[0].id)
                              }
                            >
                              <strong>{item.tagged_users[0].name}</strong>
                            </NavLink>
                          </span>{" "}
                        </>
                      ) : (
                        ""
                      )}
                      {item.tagged_users.length > 1 ? (
                        <>
                          and
                          <span className="d-inline-block ms-1 tagged_user_drop">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id="post-option"
                              >
                                <strong className="text-dark">
                                  {item.tagged_users.length - 1 > 1
                                    ? `${item.tagged_users.length - 1} others`
                                    : "1 other"}
                                </strong>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {item.tagged_users.map((item, index) => {
                                  return (
                                    <Dropdown.Item
                                      className={
                                        index === 0 ? "d-none" : "d-block px-2"
                                      }
                                      key={item.id}
                                      to="#"
                                      location={{}}
                                      onClick={() => taggedUserProfile(item.id)}
                                    >
                                      <div className="d-flex align-items-center">
                                        <img
                                          className="rounded-circle avatar-30"
                                          src={
                                            item.photo
                                              ? item.photo
                                              : dummyUserImage
                                          }
                                          alt=""
                                          onError={(e) => {
                                            e.target.src = dummyUserImage;
                                          }}
                                        />
                                        <h6 className="mb-0 ms-1 fw-500">
                                          {item.name}
                                        </h6>
                                      </div>
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <p className="mb-2 lh-1" style={{ fontSize: "12px" }}>
                    <strong>
                      {item.universe_id && item.universe_id.universeName}
                    </strong>
                    {item.sub_universe_id ? (
                      <strong>
                        {", "} {item.sub_universe_id.universeName}
                      </strong>
                    ) : (
                      ""
                    )}{" "}
                    <span className="text-muted">
                      {item.createdAt ? `(${item.createdAt})` : ""}
                    </span>
                  </p>

                  <div className="d-flex align-items-center flex-wrap badge_btns">
                    {item && item.privacy && (
                      <span className="btn btn-light custom_badge me-2">
                        {item.privacy === "Public" ? (
                          <i className="ri-global-line h4"></i>
                        ) : (
                          <>
                            {item.privacy === "Multiverse" ? (
                              <i className="ri-settings-2-line h4"></i>
                            ) : (
                              <>
                                {item.privacy === "Universe" ? (
                                  <i className="ri-star-line h4"></i>
                                ) : (
                                  <>
                                    {item.privacy === "Friends" ? (
                                      <i className="ri-user-follow-line h4"></i>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {item.privacy}
                      </span>
                    )}

                    {item && item.multiverse_id && (
                      <span className="btn btn-light custom_badge me-2">
                        <i className="ri-settings-2-line h4"></i>{" "}
                        {item.multiverse_id.multiverseName}
                      </span>
                    )}

                    {item && item.sub_multiverse_id && (
                      <span className="btn btn-light custom_badge">
                        <i className="ri-flag-line h4"></i>{" "}
                        {item.sub_multiverse_id.multiverseName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-post-toolbar">
                  <Dropdown>
                    <Dropdown.Toggle className="bg-transparent border-0 p-0 lh-1">
                      <i className="ri-more-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="m-0 p-0">
                      <Dropdown.Item
                        className=" p-3"
                        to="#"
                        onClick={() => {
                          showPostDetails(item.id);
                        }}
                      >
                        <div className="d-flex align-items-top">
                          <i className="ri-profile-line h4"></i>
                          <div className="data ms-2">
                            <h6>View details</h6>
                            <p className="mb-0">View post details</p>
                          </div>
                        </div>
                      </Dropdown.Item>

                      {userData && item.isSaved === false && (
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            handleSavePost(item.id);
                          }}
                        >
                          <div className="d-flex align-items-top">
                            <i className="ri-save-line h4"></i>
                            <div className="data ms-2">
                              <h6>Save Post</h6>
                              <p className="mb-0">
                                Add this to your saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      {userData && item.isSaved === true && (
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            handleRemoveSavePost(item.id);
                          }}
                        >
                          <div className="d-flex align-items-top">
                            <i className="ri-save-line h4"></i>
                            <div className="data ms-2">
                              <h6>Unsave post</h6>
                              <p className="mb-0">
                                Remove this from your saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      {userData && item.user_id === userData.id && (
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            openEditPostModal(item);
                          }}
                        >
                          <div className="d-flex align-items-top">
                            <i className="ri-pencil-line h4"></i>
                            <div className="data ms-2">
                              <h6>Edit Post</h6>
                              <p className="mb-0">
                                Update your post and saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      {userData && item.user_id != userData.id && (
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            setBlockConfirm(true);
                          }}
                        >
                          <div className="d-flex align-items-top">
                            <BiBlock className="me-1 h4" />
                            <div className="data ms-2">
                              <h6>Report Post</h6>
                              <p className="mb-0">Report this post</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      {/* {userData && item.user_id !== userData.id && 
                                                <Dropdown.Item className=" p-3" to="#">
                                                    <div className="d-flex align-items-top">
                                                        <i className="ri-user-unfollow-line h4"></i>
                                                        <div className="data ms-2">
                                                            <h6>Unfollow User</h6>
                                                            <p className="mb-0">
                                                                Stop seeing posts but stay friends.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            } */}

                      {componentOf === "index" && (
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            handleHidePost(item.id);
                          }}
                        >
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Hide From Timeline</h6>
                              <p className="mb-0">Hide this post from feeds</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      {userData && item.user_id === userData.id && (
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            setDeletePostConfirm(true);
                          }}
                        >
                          <div className="d-flex align-items-top">
                            <i className="ri-delete-bin-7-line h4"></i>
                            <div className="data ms-2">
                              <h6>Delete</h6>
                              <p className="mb-0">Delete this post</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      {/* <Dropdown.Item
                                                className=" p-3"
                                                to="#"
                                            >
                                                <div className="d-flex align-items-top">
                                                <i className="ri-notification-line h4"></i>
                                                <div className="data ms-2">
                                                    <h6>Notifications</h6>
                                                    <p className="mb-0">
                                                        Turn on notifications for this post
                                                    </p>
                                                </div>
                                                </div>
                                            </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="text-black mb-3">
            {item.post_body && <Linkify>{item.post_body}</Linkify>}
          </div>

          {item.post_body &&
            (item.post_body.match(/\bhttps?::\/\/\S+/gi) ||
              item.post_body.match(/\bhttps?:\/\/\S+/gi)) && (
              <LinkPreview data={item.post_body} />
            )}
        </div>
        {item.post_type !== "text" && (
          <div className="user-post mt-2">
            {item.post_type === "image" && item.media_url !== null && (
              <SingleLightbox
                thumb={item.media_url}
                large={item.media_url}
                className="img-fluid w-100"
              />
            )}

            {item.post_type === "video" && item.media_url !== null && (
              <div className="video_players">
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={item.media_url}
                    width="100%"
                    height="100%"
                    controls={true}
                    playsinline
                  />
                </div>
              </div>
            )}

            {item.post_type === "audio" && item.media_url !== null && (
              <div className="d-flex">
                <ReactAudioPlayer
                  src={item.media_url}
                  controls
                  className="w-100"
                />
              </div>
            )}
          </div>
        )}

        <div className="comment-area mt-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="position-relative d-flex align-items-center justify-content-between w-100">
              <div className="like-block not_hover d-flex align-items-center">
                {/* <div className="like-data">
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        <img
                                            src={icon1}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="py-2">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Like</Tooltip>}
                                            className="ms-2 me-2"
                                        >
                                            <img
                                            src={icon1}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Love</Tooltip>}
                                            className="me-2"
                                        >
                                            <img
                                            src={icon2}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Happy</Tooltip>}
                                            className="me-2"
                                        >
                                            <img
                                            src={icon3}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>HaHa</Tooltip>}
                                            className="me-2"
                                        >
                                            <img
                                            src={icon4}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Think</Tooltip>}
                                            className="me-2"
                                        >
                                            <img
                                            src={icon5}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Sade</Tooltip>}
                                            className="me-2"
                                        >
                                            <img
                                            src={icon6}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                            <Tooltip>Lovely</Tooltip>
                                            }
                                            className="me-2"
                                        >
                                            <img
                                            src={icon7}
                                            className="img-fluid"
                                            alt=""
                                            />
                                        </OverlayTrigger>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                <div className="total-like-block me-3 lh-1">
                  <button
                    className="reset-btn text-secondary like-comment-btn"
                    onClick={() => handleLikeCommentPostModal(item.id, "like")}
                    disabled={Number(postLike.likeCount ?? 0) < 1}
                  >
                    <strong className="me-1">
                      <NumericLabel
                        params={{ justification: "L", shortFormat: true }}
                      >
                        {postLike.likeCount || "0"}
                      </NumericLabel>
                    </strong>
                    {postLike.likeCount === 1 ? "Like" : "Likes"}
                  </button>
                  {/* <Dropdown>
                                        <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="post-option"
                                        >
                                            <span>
                                                <strong className='me-1'>
                                                    <NumericLabel
                                                        params={{ justification: 'L', shortFormat: true }}
                                                        >
                                                        {postLike.likeCount || '0'}
                                                    </NumericLabel>
                                                </strong>
                                                {postLike.likeCount === 1 ? "Like" : "Likes"}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item to="#">
                                                Max Emum
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                                Bill Yerds
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                                Hap E. Birthday
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                                Tara Misu
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                                Midge Itz
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                                Sal Vidge
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                                Other
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="total-comment-block lh-1">
                  <button
                    className="reset-btn text-secondary like-comment-btn"
                    onClick={() =>
                      handleLikeCommentPostModal(item.id, "comment")
                    }
                    disabled={Number(commentCount.cCount ?? 0) < 1}
                  >
                    <span>
                      <strong className="me-1">
                        <NumericLabel
                          params={{ justification: "L", shortFormat: true }}
                        >
                          {commentCount.cCount || "0"}
                        </NumericLabel>
                      </strong>
                      {commentCount.cCount === 1 ? "Comment" : "Comments"}
                    </span>
                  </button>

                  {/* <Dropdown>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="post-option"
                                        >
                                            
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item to="#">
                                            Max Emum
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                            Bill Yerds
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                            Hap E. Birthday
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                            Tara Misu
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                            Midge Itz
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                            Sal Vidge
                                            </Dropdown.Item>
                                            <Dropdown.Item to="#">
                                            Other
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                </div>
                {/* <div className="like-block total-comment-block lh-1">
                                    <span>
                                        <strong className='me-1'>
                                            <NumericLabel
                                                params={{ justification: 'L', shortFormat: true }}
                                                >
                                                140000
                                            </NumericLabel>
                                        </strong>
                                        share
                                    </span>
                                </div> */}
              </div>
            </div>
          </div>

          <hr className="mb-1 mt-3" />
          <div className="post_actions feather-icon d-flex justify-content-between px-2 px-sm-4 px-lg-5">
            <Link
              to="#"
              className="me-3 d-flex align-items-center"
              onClick={() => {
                handlePostLike(item.id, postLike.isLiked);
              }}
            >
              {postLike.isLiked === true ? (
                <FaThumbsUp size={18} />
              ) : (
                <FaRegThumbsUp size={18} />
              )}
              <span className="ms-1 fw-bold lh-1">
                {postLike.isLiked === true ? "Liked" : "Like"}
              </span>
            </Link>
            <Link
              to="#"
              className="me-3"
              onClick={() => {
                handleCommentShow(item.universe_id.ID, item.isUniverseMatch);
              }}
            >
              <i className="ri-chat-4-line fs-4"></i>
              <span className="ms-1 fw-bold">Comment</span>
            </Link>
            {/* <ShareOffcanvas /> */}
            <div className="d-flex align-items-center feather-icon">
              <Link to="#" onClick={() => setSharingModal(!sharingModal)}>
                <i className="ri-share-forward-line fs-4"></i>
                <span className="ms-1 fw-bold">Share</span>
              </Link>
            </div>
          </div>
          <hr className="mt-1" />

          <SocialSharePopup
            openModal={sharingModal}
            toggleModal={setSharingModal}
            title={item.post_body ? item.post_body : ""}
            shareUrl={`${baseURL}/dashboard/app/post-detail/${item.id}`}
            mediaUrl={item.media_url ? item.media_url : ""}
            description=""
          />

          {showComment && (
            <div className="">
              {commentList && commentList.total > 0 ? (
                <>
                  <ul className="post-comments comment_lists p-0 m-0">
                    {commentList.data.map((commentItem) => {
                      return (
                        <PostCommentsC
                          token={token}
                          key={commentItem.id}
                          item={commentItem}
                          postID={item.id}
                          userData={userData}
                          setCommentCount={setCommentCount}
                          commentCount={commentCount}
                          getPostComments={getPostComments}
                        />
                      );
                    })}
                  </ul>

                  {/* {commentList.total > 5 && (
                                        <span className="btn-link clickable" onClick={() => LoadNextComments(item.id, commentList.current_page)}>
                                            Show more comments
                                        </span>
                                    )} */}

                  {commentList.total > 5 && (
                    <>
                      <div className="w-100 d-flex justify-content-between ps-sm-5">
                        {commentList.current_page > 1 ? (
                          <Link
                            to="#"
                            className="me-2 fw-500"
                            onClick={() => {
                              LoadPrevComments(
                                item.id,
                                commentList.current_page,
                                5
                              );
                            }}
                          >
                            Latest comments
                          </Link>
                        ) : (
                          <span></span>
                        )}

                        {commentList.total !== commentList.to ? (
                          <Link
                            to="#"
                            className="fw-500"
                            onClick={() => {
                              LoadNextComments(
                                item.id,
                                commentList.current_page,
                                5
                              );
                            }}
                          >
                            Old comments
                          </Link>
                        ) : (
                          <span></span>
                        )}
                      </div>

                      {/* <span className="btn-link clickable" onClick={() => handleCommentModal(item.id, commentList.current_page, 5)}>
                                                View all comments
                                            </span> */}

                      {/* <Modal
                                                size="md"
                                                className="fade"
                                                id="post-modal"
                                                dialogClassName="modal-dialog-centered"
                                                onHide={handleClose}
                                                show={commentModal}
                                                >
                                                <div className="text-center p-2 post_cards">
                                                    <Modal.Body>
                                                    <ul className="post-comments p-0 m-0">
                                                        {commentList.data.map((item) => {
                                                            return(
                                                                <PostCommentsC token={token} key={item.id} item={item} userData={userData} setCommentCount={setCommentCount} commentCount={commentCount} />
                                                            )
                                                        })}
                                                    </ul>
                                                    </Modal.Body>
                                                    <Modal.Footer className='px-0 pt-2 pb-3 d-flex justify-content-center border-0'>
                                                        <Button variant="primary" onClick={() => {LoadPrevComments(item.id, commentList.current_page, 5)}} className="me-2">
                                                            Prev
                                                        </Button>
                                                        <Button variant="outline-danger" onClick={() => {LoadNextComments(item.id, commentList.current_page, 5)}}>
                                                            Next
                                                        </Button>
                                                    </Modal.Footer>
                                                </div>
                                            </Modal> */}
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              <form
                className="comment-text d-flex align-items-center mt-3 mb-4"
                action=""
                method="POST"
              >
                <InputEmoji
                  onChange={setCommentText}
                  cleanOnEnter
                  placeholder="Enter Your Comment"
                  onEnter={() => {
                    handlePostComment(item);
                  }}
                />

                {/* <div className="comment_attachment d-flex">
                                    <Link to="#">
                                        <i className="ri-link me-2"></i>
                                    </Link>
                                    <Link to="#">
                                        <i className="ri-user-smile-line me-2"></i>
                                    </Link>
                                    <button type="submit" className='d-none'>
                                        <i className="ri-camera-line me-0"></i>
                                    </button>
                                </div> */}
              </form>
            </div>
          )}
        </div>
      </div>

      <Modal
        size="md"
        className="fade"
        id="post-modal"
        dialogClassName="modal-dialog-centered"
        onHide={handleClose}
        show={blockConfirm}
      >
        <div className="p-3">
          <Modal.Body className="p-0">
            <h5 className="mb-2 fw-500">Why you want to report this post?</h5>

            <Form>
              <Form.Group className="form-group col-sm-12">
                <Form.Label className="form-label d-flex justify-content-between">
                  <span>Message</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="form-control"
                  maxLength={101}
                  rows={3}
                  style={{ lineHeight: "22px" }}
                  placeholder=""
                  name="reportMsg"
                  onChange={(e) => setReportMsg(e.target.value)}
                  value={reportMsg || ""}
                ></Form.Control>
                <div className="text-danger">
                  {reportMsg &&
                    reportMsg.length === 101 &&
                    "Max 101 characters allowed!"}
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="px-0 pt-0 pb-0 d-flex border-0">
            <Button
              variant="primary"
              onClick={handleClose}
              className="me-2 px-3"
            >
              Cancel
            </Button>
            <Button
              variant="outline-danger"
              className="px-4"
              onClick={() => {
                handleReportPost(item);
              }}
            >
              Report
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        size="sm"
        className="fade"
        id="post-modal"
        dialogClassName="modal-dialog-centered"
        onHide={handleClose}
        show={deletePostConfirm}
      >
        <div className="text-center p-2">
          <Modal.Body>
            <h5>Are you sure you want to delete this post?</h5>
          </Modal.Body>
          <Modal.Footer className="px-0 pt-2 pb-3 d-flex justify-content-center border-0">
            <Button variant="primary" onClick={handleClose} className="me-2">
              Cancel
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                handleDeletePost(item.id);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <EditPostModal
        postItem={item}
        createPostModal={createPostModal}
        setCreatePostModal={setCreatePostModal}
        token={token}
        getAllPosts={getAllPosts}
        currentPage={currentPage}
        userPost={userPost}
        componentOf={componentOf}
        setLoading={setLoading}
        setShowMessage={setShowMessage}
        setMessage={setMessage}
      />
      {showLikeCommentModal && (
        <LikeCommentListModal
          show={showLikeCommentModal}
          handleClose={handleCloseLikeCommentModal}
          showModalTypeInfo={showLikeCommentModalTypeInfo}
        />
      )}
    </>
  );
}

export default ProfilePostItem;
