import React, { useEffect, useState } from "react";
import ReactInstaStories from "react-insta-stories";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import useUniverseTypeStories from "../../../hooks/services/useUniverseTypeStories";
import "./StoryViews.css";
import StoryWrapper from "./StoryWrapper";

const StoryViews = () => {
  const {
    stories = [],
    hasStories,
    isLoading,
    hasPrevious,
    hasNext,
    handlePrevPageClick,
    handleNextPageClick,
  } = useUniverseTypeStories();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (stories.length > 0) {
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 5);
    }
  }, [stories.length]);

  return (
    <div className="view-story-tracker" style={{ position: "relative" }}>
      <div
        style={{ position: "absolute", top: 10, right: 10 }}
        className="text-light"
      >
        <Link to="/" title="Close">
          <i className="ri-close-fill ri-xl text-light"></i>
        </Link>
      </div>
      <StoryWrapper>
        <div className="story-paginator">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="story-viewer-pagination">
              {/* {error ? (
                <ErrorParser error={error} />
              ) : (
                
              )} */}
              <div className="d-flex align-items-end">
                {hasStories && (
                  <button
                    className="btn btn-primary"
                    disabled={hasPrevious}
                    onClick={handlePrevPageClick}
                    title="Prev Page"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 16 16"
                      className="me-1"
                      height={16}
                      width={16}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>
                  </button>
                )}
                {hasStories ? (
                  <div style={{ zIndex: 0 }}>
                    {show && (
                      <ReactInstaStories
                        stories={stories}
                        defaultInterval={3000}
                        height={"80vh"}
                        style={{
                          background: "red",
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <h5 className="text-white">No Stories Available</h5>
                )}

                {hasStories && (
                  <button
                    className="btn btn-primary"
                    disabled={hasNext}
                    onClick={handleNextPageClick}
                    title="Next Page"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 16 16"
                      className="ms-1"
                      height={16}
                      width={16}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </StoryWrapper>
    </div>
  );
};

export default StoryViews;
