import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Container, Image, Alert } from 'react-bootstrap'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import OTPInput from "otp-input-react";
import "./sign-up.css"

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';
import img1  from "../../../assets/images/LoginPage/Beer.jpg"
import img2  from "../../../assets/images/LoginPage/Fashion.jpg"
import img3  from "../../../assets/images/LoginPage/Food.jpg"
import img4  from "../../../assets/images/LoginPage/Sports.jpg"
import img5  from "../../../assets/images/LoginPage/Travel.jpg"
import img6  from "../../../assets/images/LoginPage/Wine.jpg"
import logoWeb from "../../../assets/images/universimm-logo-4.png"
import { servicePath } from '../app/variables';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const ResetPassword = () => {

  const history = useHistory()

  const [otp, setOtp] = useState('')
  const [recoveryEmail, setRecoveryEmail] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState('')
  const [showMessage, setShowMessage] = useState(true)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errors, setErrors] = useState({})

  const [succesMessage, setSuccesMessage] = useState("")

  const swiperImages = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    {
      image: img6,
    },
  ];


  const changePassword = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZXYudW5pdmVyc2ltbS5jb21cL2luZGV4LnBocFwvYXBpXC9hY3Rpb25cL2F1dGgubG9naW4iLCJpYXQiOjE2NTM5MTY4NTgsImV4cCI6MTY4NTQ1Mjg1OCwibmJmIjoxNjUzOTE2ODU4LCJqdGkiOiI3ZnhmNWFxZm8ycGdYV3ZWIiwic3ViIjo3LCJwcnYiOiJjMzJiNDU5OGZjNGNmNjdmYzZlZTM2MjY3ZmQ1ODg5NzhhZGM2ZmQ1In0.m_2AqcLRkQVJA56KpQRL3dFzf20tWFXVWLp0BmqrbTA"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      origin: "web",
      data: {
        email: recoveryEmail,
        code: otp,
        new_password: password,
        confirm_password: confirmPassword,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/auth.change_account_password`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.status === true && result.success === "Password changed"){
        history.push("/auth/sign-up" , {state:{successMessage : result.success}})
        }else if(result.status === false && result.success === "Invalid Code"){
        setSuccesMessage(result.success)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const validate = () => {
    let error = {}
    let isValid = true;
    if(password !== confirmPassword){
      error.confirmPassword = "Password did not match"
    }
    else{
      error.confirmPassword = ""
    }
    setErrors(error)
    return isValid
  }

  const resetValid = () => {
    let error = {}
    let isValid = true
    if (!email || email === "") {
      error.email = "Email is required"
    }
    else {

    }
    setErrors(error)
    return isValid
  }

  const resetPassword = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      origin: "web",
      data: {
        email: email,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/auth.request_password_change`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result && result.status === true && result.success === "Account recovery code sent") {

          setRecoveryEmail(email)

          setMessage("Account recovery code sent")
          setShowMessage(true)

          setTimeout(() => {
            setMessage("")
            setShowMessage(false)
          }, 2000);
        } else {
          if(result.error === "The email field is required.") {
            setMessage("Email is required to reset password!")
          } else {
            setMessage(result.error)
          }

          setShowMessage(true)

          setTimeout(() => {
            setMessage("")
            setShowMessage(false)
          }, 2000);
        }

      })
      .catch((error) => console.log("error", error))
      .finally((error) => console.log(error))
  };

  return (
    <>

      {showMessage && message ? <div className="alert-message">{message}</div> : <></>}

      <div className="header-main flex-wrap align-items-center justify-content-between py-2">
        <Link to="#" className='header_logo py-1' onClick={history.goBack}>
          <img src={logoWeb} className="w-100" />
        </Link>
      </div>
      <section>
        <div id="container-inside">
        </div>
        <Container>
          <Row className="verify-container auth_page">
            <Col md="6">
              <div className="sign-in-detail text-white">
                <div className="sign-slider overflow-hidden">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0"
                  >
                    {swiperImages.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <Image
                            src={item.image}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                            <h4 className="text-black">
                              Share your passion and interest with the people you
                              love.
                            </h4>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col md="6" className="recoverpw-container">
              <div className="sign-in-from pb-3 pb-lg-5">
                <h1 className="mb-0">Reset Password</h1>

                {recoveryEmail !== '' ? (
                  <>
                    <p>
                      An OTP has been send to <strong>{recoveryEmail}</strong>. Please check for an email from Universimm.{" "}
                      <NavLink
                        to="#"
                        className="btn-link fw-bold"
                        onClick={() => {setRecoveryEmail(''); setEmail('')}}
                      >
                        Change email
                      </NavLink>
                    </p>

                    <Form className="mt-4">
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          className="mb-3"
                          id="exampleInputEmail1"
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          className="mb-0"
                          id="exampleInputEmail1"
                          placeholder="Confirm Password"
                          onChange={(e) => { setConfirmPassword(e.target.value) }}
                          value={confirmPassword}
                        />
                      </Form.Group>
                      <div className='mb-3 mt-1 text-danger'>{errors.confirmPassword}</div>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>OTP</Form.Label>
                      </Form.Group>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        secure
                      />
                      {/* <div className='mt-1'>{minutes} : {second}</div> */}
                      <div className='text-danger'>{succesMessage}</div>

                      <div className="d-inline-block w-100">
                        <Button
                          variant="primary"
                          type="button"
                          className="float-right mt-4 mb-2"
                          onClick={() => { validate() ? changePassword() : <></> }}
                        >
                          Reset Password
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <Form className="mt-4">
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        className="mb-3"
                        id="email"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </Form.Group>

                    <div className="d-inline-block w-100">
                      <Button
                        variant="primary"
                        type="button"
                        className="float-right my-2"
                        onClick={() => { resetValid() ? resetPassword() : <></> }}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ResetPassword
