import React,{useState , useEffect} from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import "../app/SelectUniverse.css"
import { AiOutlinePlusCircle, AiFillCheckCircle } from 'react-icons/ai';
import { Link, useHistory } from "react-router-dom";
import { servicePath } from "./variables";


const SelectUniverse = () => {

  const history = useHistory()

  const [universes, setUniverses] = useState([])
  const [universeCount, setUniverseCount] = useState(0)
  const [univerId, setUniverId] = useState([])
  const [selectUniverseError, setSelectUniverseError] = useState(false)
  
  const userData = localStorage.getItem("access_token")
  const token = "Bearer" +" "+ localStorage.getItem("access_token")

useEffect(() => {
  document.body.classList.add('edit-universe-page');

  if(userData !== null){
    getUniverse()
    userState()
  }

  return () => {
    document.body.classList.remove('edit-universe-page');
  };
}, []);

const handleClose = () => {setSelectUniverseError(false)};

const userState = () => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    token
  );
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    `${servicePath}/user.state?`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
        // console.log(result, "user state data");

        function getUniverIds(item) {
          // console.log(item.ID);
          var ids = item.ID
          if(univerId.includes(ids)){
            var index = univerId.findIndex(id => id === ids);
  
            const newUniverId = univerId.filter((_, i) => i !== index);
  
            setUniverId(newUniverId);

            // console.log(univerId, "print ids")

            document.getElementById(`universe_card${ids}`).classList.add('isActive');
          } else {
            // console.log("item does not exists")
            univerId.push(ids);

            // console.log(univerId, "print ids not exists")
            document.getElementById(`universe_card${ids}`).classList.add('isActive');
          }
        }

        result.data.universes.forEach(getUniverIds)

        setUniverseCount(univerId.length)
    })
    .catch((error) => console.log("error", error));
};

  const getUniverse = () =>{
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };
    
    fetch(`${servicePath}/public.universes`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setUniverses(result.data.universes)
      })
      .catch(error => console.log('error', error));
  }

  const subscribeUniverse = (id) =>{
    // alert(id);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        universes: id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${servicePath}/user.subscribe_to_universes`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if(result.data.universe_count > 1){
          {history.push("/dashboard/app/profile",{signin : true})}
        }
      })
      .catch(error => console.log('error', error));
  }

  // const unSubscribeUniverse = (id) =>{
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", token);
  //   myHeaders.append("Content-Type", "application/json");
  //   var raw = JSON.stringify({
  //       universes: id
  //   });

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };

  //   fetch(`${servicePath}/user.unsubscribe_to_universes`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //     })
  //     .catch(error => console.log('error', error));
  // }

  const addUniverses = (ids, e) =>{
    //([...univerId , ids])
    if (e.currentTarget.classList.contains('isActive')) {
        e.currentTarget.classList.toggle('isActive');

        if(univerId.includes(ids)){

          var index = univerId.findIndex(id => id === ids);

          const newUniverId = univerId.filter((_, i) => i !== index);

          setUniverId(newUniverId);

          // console.log(univerId);
        } else {
          // console.log("item does not exists")
        }
        // unSubscribeUniverse(ids);

        setUniverseCount(universeCount - 1)
    } else {
        e.currentTarget.classList.toggle('isActive');
        univerId.push(ids);
        setUniverId(univerId);
        // console.log(univerId);

        setUniverseCount(universeCount + 1)
    }
  }

  const handleUniverseSubmit = () => {
    // console.log(univerId)

    if(univerId.length > 2) {
      subscribeUniverse(univerId)
    } else {
      setSelectUniverseError(true);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col xxl="7" xl="9" lg="10" md="12" sm="12" className="universeContainer mx-auto text-center">
          <div className="mt-2">
            <h3 className="text-center text-dark fw-bold">Edit your universe</h3>
            <h5 className="text-muted mb-3">Select 3 or more Universes</h5>
            <h6> <strong>{universeCount}</strong> Selected</h6>
            <div className="d-flex justify-content-center flex-wrap select_univer_wrap">
              {universes.map((item, index) => {
                return (
                  <div className="px-1 py-1 position-relative universe_card" id={`universe_card${item.ID}`} key={index} onClick = {(e) => addUniverses(item.ID, e)}>
                    <div className="position-absolute iconDiv">
                      <AiOutlinePlusCircle size={30} className="text-white add"/>
                      <AiFillCheckCircle size={30} className="text-white added"/>
                    </div>
                    <img src={item.universeIcon} alt="universe" className = "universeImage" />
                    <div className="position-absolute  textDiv ">
                      <p className="mb-0">{item.universeName}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {universes && universes.length > 0 && <div className="d-flex justify-content-center mt-2">
            <Button className="btn-primary" onClick={handleUniverseSubmit}>Update universe</Button>
          </div>}
        </Col>

        <Modal
            size="sm"
            className="fade"
            id="post-modal"
            dialogClassName="modal-dialog-centered"
            onHide={handleClose}
            show={selectUniverseError}
        >
            <div className="text-center p-3">
                <div className="d-flex justify-content-between">
                    <span></span>
                    <Link to="#" className="lh-1" onClick={() => {setSelectUniverseError(false)}}>
                        <i className="ri-close-fill ri-xl"></i>
                    </Link>
                </div>
                <Modal.Body>
                    <h5>Please select at least 3 universe</h5>
                </Modal.Body>
            </div>
        </Modal>
      </Row>
    </Container>
  );
};

export default SelectUniverse;
