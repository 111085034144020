import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import logoWeb from "../../../assets/images/universimm-logo-4.png"
import Footer from '../../../components/partials/dashboard/footerStyle/footer'

const PrivacyPolicy = () => {
    const history = useHistory()

    const userDetail = JSON.parse(localStorage.getItem("users"))

    return (
        <>  
            <div className="header-main flex-wrap align-items-center justify-content-between py-2 mb-4">
                {userDetail === null ? (
                    <Link to="/auth/sign-up" className='header_logo py-1'>
                        <img src={logoWeb} className="w-100" alt="logo" />
                    </Link>
                ) : (
                    <Link to="#" className='header_logo py-1' onClick={history.goBack}>
                        <img src={logoWeb} className="w-100" alt="logo" />
                    </Link>
                )}

                {userDetail === null && (
                    <Link to="/auth/sign-up" className="btn btn-primary iq-sign-btn" role="button">Sign In
                        <i className="ri-login-box-line ms-2"></i>
                    </Link>
                )}
            </div>

            <Container>
                <Row className="docs-paras pt-4 pb-5 extra_page row">
                    <Col lg="12">
                        <h4 className="mb-2 fw-bold text-center">Thank you for using Universimm!</h4>
                        <p>
                            Universimm is a free website and mobile app. If you choose to use our service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving our service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions. Our goal is for you to share your passions and interests with the people you love. We wrote this policy to help you understand what information we collect, how we use it and what choices you have about it. Users agree to terms (EULA) and these terms make it clear that there is no tolerance for objectionable content or abusive users. We have a method for filtering objectionable content, a method for users to report objectionable content and a mechanism for users to block abusive users. We have taken steps to moderate content and prevent abusive behavior. We act on objectionable content reports within 24 hours by removing the content and ejecting the user who provided the offending content. Users who register will be able to access their content from other iOS devices. 
                        </p>

                        {/* <h4 className="mb-2 fw-bold text-center">Account-specific functionality</h4>
                        <p>
                            Universimm is a Vertical Social Network based on interests. To provide account-specific functionality and ensure access to relevant features, we require users to provide their name, email address, password, gender, and date of birth. This helps us ensure that certain features remain available to users aged 18 and above. Our app's core functionality is customized to each individual sign up, providing access to relevant features with bespoke customization. Users who register will be able to access their content from other iOS devices.                         
                        </p> */}

                        <h4 className="mb-2 fw-bold text-center">Here are some of the types of information we collect</h4>
                        <p>
                            This Privacy Policy document contains types of information that is collected and recorded by Universimm and how we use it. If you create an account, you must provide us with some information, so that we can provide our services to you. Your profile information, in addition to login details, will feature a display name, username, profile picture, biography, and account and privacy settings. The data we gather from user interaction covers a range of metrics, including the number of friends and friend requests, reactions, and comments made by both yourself and other users. When you use Universimm, our servers record information and data, including information your browser or mobile sends whenever you visit a website. This log data includes your Internet Protocol address, activity, how you use Universimm, cookie data and device data. We use “cookies” (small text files sent by your computer each time you visit our website) to capture log data. We use cookies until you or your browsers delete them. We will only obtain your offsite behavior and interests with permission. We do this for existing users and for new users, and it helps us customize the new user experience. For more detailed information about how we use cookies, read further below or review our Cookies Policy. In addition to log data, we collect information about the device you’re using Universimm on, including type of device, operating system, settings, unique device identifiers and crash data that helps us understand when something crashes, depending on whether you’re using a Mac or a PC, or an iPhone or Android phone. 
                        </p>

                        <h4 className="mb-2 fw-bold text-center">What do we do with the information that we collect</h4>
                        <p>
                            With the aim of delivering the finest service to you, the information we gather is channeled towards presenting you with content that is highly specific, relevant, interesting, and personalized to your needs. By evaluating your activities, Universimm suggests exciting interests and topics that align with your preferences. We strive towards enhancing the experience you have on Universimm by including more universes also known as interests that are suitable to you. It is within the power of users to add or remove interests at their discretion. Universimm also facilitates the task of finding like-minded individuals with similar likes, preferences, and interests, by offering helpful suggestions. Through Universimm's configurations, such as your settings, friends, family and work colleagues with similar interests can locate you effortlessly. To enhance the user experience, we will leverage analytics to scrutiny key metrics, KPIs, and other relevant statistics. If you opt-in, we may occasionally send you industry updates via email, but you can always unsubscribe at your convenience. The Universim platform is designed to deliver ads that align with your interests exclusively. For instance, if your interest is food, you will only receive ads related to food. This model applies to design, architecture and other categories. Any data shared by our partner advertisers or third parties is done so with the user's explicit permission, and within the confines of data protection laws.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Transferring your information</h4>
                        <p>
                            Universimm is a worldwide service. By using our services, you authorize us to transfer and store your information outside your home country, including in the United States & Europe. The privacy protections and the rights of authorities to access your personal information in such countries may not be equivalent to those of your home country. Universimm complies with the EU GDPR regulations and CalOPPA with customers from California.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Choices you have about your information</h4>
                        <p>
                            You have full access to your profile page. You can edit at any time, decide whether your profile is available to search engines, or choose whether others can find you. You have full privacy control under Settings on who sees your content. You can close your account at any time. Your data will then be removed. Other users can copy your content (i.e take a screenshot) and share it through other services, so we recommend only sharing content you want people to see. Your browser will also let you control "cookies" or other types of local data storage. Your mobile device will also have personal settings that you can tweak. Like minded people can follow you depending on your settings. We encourage users to follow like minded people seeing this creates great interaction, synergy and communities of interest that are very relevant to you. 
                        </p>
                        
                        <p>
                            Universimm is an interest based social network, so it's the perfect platform to find things that you really like. Users are also able to make posts via countries, which makes the content even more relevant. Children under 13 are not allowed to use Universimm. If you are based in the EEA you may only use Universimm if you are over the age at which you can provide consent to data processing under the laws of your country or with appropriate parental consent. Universimm is a new social network, so changes will be made from time to time. If you continue to use Universimm after those changes are in effect, you agree to the new policy. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Log Data</h4>
                        
                        <p>
                            We want to inform you that whenever you use our Service, in the case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Universimm follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. 
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Cookies</h4>
                        <p>
                            The Website uses "cookies" to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. We may use cookies to collect, store, and track information for statistical purposes to operate our Website and Services. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. To learn more about cookies and how to manage them, visit <a target="_blank" rel="noreferrer" href="https://www.internetcookies.com/">internetcookies.org</a>
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Google DoubleClick DART Cookie</h4>

                        <p>
                            Google is one of the third-party vendors on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a target="_blank" rel="noreferrer" href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Our Advertising Partners</h4>

                        <p>
                            Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
                        </p>

                        <ul>
                            <li>Google</li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                            </li>
                        </ul>
                        

                        <h4 className="mb-2 fw-bold text-center">Do Not Track signals</h4>

                        <p>
                            Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. Our Website does not track its visitors over time and across third party websites. However, some third party sites may keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Service Providers</h4>

                        <p>
                            We may employ third-party companies and individuals due to the following reasons:
                        </p>

                        <ul>
                            <li>To facilitate our Service</li>
                            <li>To provide the Service on our behalf</li>
                            <li>To perform Service-related services</li>
                            <li>To assist us in analyzing how our Service is used</li>
                        </ul>

                        <p>
                            We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Security</h4>

                        <p>
                            We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Links to Other Sites</h4>

                        <p>
                            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </p>

                        <p>
                            Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Universimm, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit. Note that Universimm has no access to or control over these cookies that are used by third-party advertisers.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Third Party Privacy Policies</h4>

                        <p>
                            Universimm's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.
                        </p>

                        <p>
                            You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?
                        </p>
                        
                        <h4 className="mb-2 fw-bold text-center">Children's Privacy</h4>

                        <p>
                            These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so we can take necessary actions. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Online Privacy Policy Only</h4>

                        <p>
                            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in Universimm. This policy is not applicable to any information collected offline or via channels other than this website.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Changes to This Privacy Policy</h4>

                        <p>
                            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Consent</h4>

                        <p>
                            By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions and agree to terms (EULA) and these terms make it clear that there is no tolerance for objectionable content or abusive users.
                        </p>

                        <h4 className="mb-2 fw-bold text-center">Contact Us</h4>

                        <p>
                            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:admin@universimm.com">admin@universimm.com</a>
                        </p>
                    </Col>
                </Row>
            </Container>

            <Footer />   
        </>
    )
}

export default PrivacyPolicy
