import React, { useEffect, useState } from 'react'
import {Row,Col, Container, Image} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'

import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
import { servicePath } from './variables'

const SentRequests = () => {
   const userData = JSON.parse(localStorage.getItem("users"))
   const token = "Bearer" +" "+ localStorage.getItem("access_token")

   useEffect(() => {
      if(userData !== null){
         getFriendRequests()
      }
   }, [])

   const [message, setMessage] = useState("")
   const [showMessage, setShowMessage] = useState(false)
   const [requestList, setRequestList] = useState([])

   const getFriendRequests = () => {
      var myHeaders = new Headers();

      myHeaders.append(
            "Authorization",
            token
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "data": {
            "mode": "sent"
         }
      });

      var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
      };

      fetch(
            `${servicePath}/friend.requests`,
            requestOptions
      )
      .then((response) => response.json())
      .then((result) => {
         // console.log(result, "sent request lists")
         if(result && result.data){
            setRequestList(result.data)
         }
      })
      .catch((error) => console.log("error", error));
   };

   const handleCancelRequest = (requestId) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "data": {
            "request_id": requestId
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(`${servicePath}/friend.delete`, requestOptions)
      .then(response => response.json())
      .then(result => {
         // console.log(result)
         if(result.success == "Request was deleted"){
            setMessage("Request cancelled successfully")
            setShowMessage(true)

            getFriendRequests()
            
            setTimeout(() =>{
                  setShowMessage(false)
            }, 2000)
         }
      })
      .catch(error => console.log('error', error));
   };

   return(
      <>
         <Container className='position-relative'>
            {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
            <Row>
               <Col sm="12">
                  <Card className="friend_list_card mb-4">
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Sent Requests</h4>
                        </div>
                        <span className='badge bg-soft-primary d-flex align-items-center fs-5'>
                           {requestList && requestList.counters && requestList.counters.pending || 0}
                        </span>
                     </Card.Header>
                     <Card.Body className="card-body px-2 px-sm-3 pb-0">
                        <ul className="request-list list-inline m-0 p-0">
                           {requestList && requestList.requests && requestList.requests.length > 0 ? (
                              <>
                                 {requestList.requests.map((item) => {
                                    return(
                                       <li key={item.id} className="d-flex align-items-center justify-content-between flex-wrap">
                                          <Link to={`/dashboard/app/friend-profile/${item.params.id}`} className="w-100 d-flex align-items-center user_rqst_details sent_rqst_list">
                                                <div className='img-fluid'>
                                                   <Image 
                                                      className="avatar-50 rounded-circle" 
                                                      src={item.params.photo || dummyUserImage} 
                                                      alt=""
                                                      onError={(e) => {
                                                         e.target.src = dummyUserImage
                                                      }}
                                                   />
                                                </div>
                                                
                                                <div className="ms-2 w-100 user_detail">
                                                   <h6 className="mb-0 text-ellipsis-1 fw-500">{item.params.name}</h6>
                                                   {/* <p className="mb-0">40 friends</p> */}
                                                   {/* <p className="mb-0 text-muted text-ellipsis-1">{item.params.username}</p> */}
                                                </div>
                                          </Link>
                                          <div className="d-flex align-items-center">
                                             <div className="confirm-click-btn">
                                                <Link to="#" className="btn btn-secondary rounded btn-sm confirm-btn" onClick={() => {handleCancelRequest(item.id)}}>Cancel</Link>
                                             </div>                                  
                                          </div>
                                       </li>
                                    )
                                 })}
                              </>
                           ) : (
                              <h5 className='text-center mb-3'>No friend request</h5>
                           )}
                           
                        </ul>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </Container>
      </>
   )
}

export default SentRequests