import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
import { servicePath } from "../app/variables";

function ProfileFollowersCard({item, getFriendList, setMessage, setShowMessage, token}) {

    const handleUnfollowFriend = (friendId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "following_id": friendId
            },
            "action": "unfollow"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/follow.user`, requestOptions)
        .then(response => response.json())
        .then(result => {
            // console.log(result)
            if(result.success == "Following"){
                setMessage("Followed successfully")
                setShowMessage(true)

                getFriendList('')
                
                setTimeout(() =>{
                        setShowMessage(false)
                }, 2000)
            }
        })
        .catch(error => console.log('error', error));
    };

    const handleRemoveFriend = (friendId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "friend_user_id": friendId
            }
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${servicePath}/friend.unfriend`, requestOptions)
        .then(response => response.json())
        .then(result => {
        // console.log(result)
        if(result.success == "unfriend successfully"){
            setMessage("Unfriend successful")
            setShowMessage(true)

            getFriendList('')
            
            setTimeout(() =>{
                    setShowMessage(false)
            }, 2000)
        }
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div className="iq-friendlist-block custom_friends p-1 p-sm-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center friend_wrap full_w me-2">
                    <Link to={`/dashboard/app/friend-profile/${item.followerId}`}>
                        <img
                            src={item && item.photo ? item.photo : dummyUserImage}
                            alt="profile-img"
                            className="img-fluid me-2"
                            onError={(e) => {
                                e.target.src = dummyUserImage
                            }}
                        />
                    </Link>
                    <div className="friend-info">
                        <h5 className="fw-bold">{item.followerName}</h5>
                        <p className="mb-0 fw-500">{item.createdAt}</p>
                    </div>
                </div>
                {/* <div className="card-header-toolbar d-flex align-items-center primary_drop_btn">
                    <Button className="btn btn-soft-primary m-0 px-2 py-1" onClick={() => {handleUnfollowFriend(item.followerId)}}>
                        Unfollow
                    </Button>
                </div> */}
            </div>
        </div>
    );
}

export default ProfileFollowersCard;