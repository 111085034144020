import React,{useState , useEffect} from 'react'
import {Row,Col,Container,Image,Button} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import OTPInput, { ResendOTP } from "otp-input-react";
import "./sign-up.css"

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';
import img1  from "../../../assets/images/LoginPage/Beer.jpg"
import img2  from "../../../assets/images/LoginPage/Fashion.jpg"
import img3  from "../../../assets/images/LoginPage/Food.jpg"
import img4  from "../../../assets/images/LoginPage/Sports.jpg"
import img5  from "../../../assets/images/LoginPage/Travel.jpg"
import img6  from "../../../assets/images/LoginPage/Wine.jpg"
import logoWeb from "../../../assets/images/universimm-logo-4.png"
import loader from "../../../assets/images/page-img/page-load-loader.gif"

import { servicePath } from '../app/variables';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);


const Verify = () => {

  const history = useHistory()

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("")
  const [otpMessage, setOtpMessage] = useState("")
  const [valid, setValid] = useState({})
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState(true)
  const [resendLoader, setResendLoader] = useState(false)

  const location = useLocation();
  // console.log(location.state , "113");
  const credentials = location?.state?.userEmail;

  const validate = () =>{
    let message = {}
    let isValid = true
    if(!otp || otp === ""){
      message.otp = "Please enter OTP"
    }
    else{
        
    }
    setValid(message)
    return isValid

  }
  
  const resendOtpApi = () => {
    setResendLoader(true)
    var myHeaders = new Headers();
    
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      origin: "web",
      data: {
        email: credentials,
      },
    });
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(
      `${servicePath}/auth.resend_verification_code`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if(result.status === true && result.success === "Verification code sent again"){
          setOtpMessage(result.success)
          setError("")
        }
        else{
          setOtpMessage(result.error)
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setResendLoader(false))
  };

  const swiperImages = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    {
      image: img6,
    },
  ]

const verifyAccount = () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    origin: "web",
    data: {
      email: credentials,
      phone: "",
      code: otp,
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${servicePath}/auth.verify_account`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if(result.status === true && result.success === "Verified"){

        sessionStorage.setItem("message" , result.success)
        setMessage("Account verified! Please login")
        setShowMessage(true)

        setTimeout(() =>{
            setShowMessage(false)
            history.push("/auth/sign-up")
        }, 2000)

      } else if(result.error === "Invalid Request"){
        setError("Invalid Otp")

        setMessage("Otp incorrect!")
        setShowMessage(true)

        setTimeout(() =>{
            setShowMessage(false)
        }, 2000)
      }
      else{
        setError(result.error)

        setMessage(result.error)
        setShowMessage(true)

        setTimeout(() =>{
            setShowMessage(false)
        }, 2000)
      }
    })
    .catch((error) => console.log("error", error));
};

// console.log(otpMessage);
// console.log(showMessage , "timer");

  return (
    <>
      <div className="header-main flex-wrap align-items-center justify-content-between py-2">
        <Link to="/auth/sign-up" className='header_logo py-1'>
          <img src={logoWeb} className="w-100" />
        </Link>
      </div>

      <section>
        <Container className='position-relative'>
        {showMessage && message ? <div className="alert-message">{message}</div> : <></>}

          <Row className="verify-container auth_page">
            <Col md="6" className="text-center">
              {/* <div className='text-danger text-center'>{error}</div> */}
              <div className="sign-in-detail text-white">

                <div className="sign-slider overflow-hidden ">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0 "
                  >
                    {swiperImages.map((item , index) => {
                      return(
                        <SwiperSlide key={index}>
                          <Image
                            src={item.image}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="text-black">
                            Share your passion and interest with the people you
                            love.
                          </h4>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="sign-in-from pb-3 pb-lg-5">
            
                <h1 className="mt-3 mb-0">Success !</h1>
                <p>
                  An OTP has been send to <strong>{credentials}</strong>. Please check
                  for an email from company.
                </p>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
                {otp.length > 0 ? "" : <div className='text-danger mt-1'>{valid.otp}</div>} 
                {otpMessage ? <div className='text-danger'>{otpMessage}</div> : <></>}
                {error ? <div className='text-danger'>{error}</div> : <></>}

                <div className='mt-2 resend-otp' onClick={() =>resendOtpApi()}>Resend OTP</div>
      
                <div className="d-inline-block w-100">
                  <button
                    type="button"
                    onClick={() =>{validate() ? verifyAccount() : <></>}}
                
                    className="mt-3 submit-button"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Verify