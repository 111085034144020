import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SubscribeUniverseModal = ({ handleClose, subsUniverseModal, setSubsUniverseModal, subscribeUniverse, univerId }) => {

  return (
    <>
        <Modal
            size="md"
            className="fade"
            id="post-modal"
            dialogClassName="modal-dialog-centered"
            onHide={handleClose}
            show={subsUniverseModal}
        >
            <div className="text-center p-3">
                <div className="d-flex justify-content-between">
                    <span></span>
                    <Link to="#" className="lh-1" onClick={() => {setSubsUniverseModal(false)}}>
                        <i className="ri-close-fill ri-xl"></i>
                    </Link>
                </div>
                <Modal.Body>
                    <h5 className="mb-3">The selected universe is not subscribed.</h5>

                    <h5 className="text-muted">Please add this universe in your profile in order to create post or comment on posts related to this universe.</h5>
                </Modal.Body>

                <Modal.Footer className='px-0 pt-2 pb-1 d-flex justify-content-center border-0'>
                    {/* <Button variant="outline-danger" onClick={handleClose} className="me-2">
                        Cancel
                    </Button> */}
                    <Button variant="primary" onClick={() => {subscribeUniverse(univerId)}}>
                        Add universe
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
        </>
    );
};
export default SubscribeUniverseModal;
