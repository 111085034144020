import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Form, Image, Modal } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import "./sign-up.css"
import { isMobile } from 'react-device-detect';
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import moment from 'moment/moment'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';
// Import firebase
import database from '../../../firebase';
import { ref, update} from "firebase/database";
// import { ref, onValue, onChildAdded, onChildChanged, onChildRemoved, set, update, push, child, remove } from "firebase/database";

import img1 from "../../../assets/images/LoginPage/Beer.jpg"
import img2 from "../../../assets/images/LoginPage/Fashion.jpg"
import img3 from "../../../assets/images/LoginPage/Food.jpg"
import img4 from "../../../assets/images/LoginPage/Sports.jpg"
import img5 from "../../../assets/images/LoginPage/Travel.jpg"
import img6 from "../../../assets/images/LoginPage/Wine.jpg"
import logoWeb from "../../../assets/images/logo_web.png"
import loader from "../../../assets/images/page-img/page-load-loader.gif"
import googleStoreIcon from "../../../assets/images/icon/google-play.svg"
import appleStoreIcon from "../../../assets/images/icon/app-store.svg"
import { servicePath } from '../app/variables'
// import { useNavigate } from "react-router-dom";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [gender, setGender] = useState("")
  const [email, setEmail] = useState("")
  const [reEnterEmail, setReEnterEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState({})
  const [emailError, setEmailError] = useState(false)
  const [registrationError, setRegistrationError] = useState("")
  const [dob, setDob] = useState("")
  const [loginEmail, setLoginEmail] = useState("")
  const [loginPassowrd, setLoginPassowrd] = useState("")
  const [status, setStatus] = useState("")
  const [signUpError, setSignUpError] = useState("")
  const [loginErrors, setloginErrors] = useState({})
  const [signInLoading, setSignInLoading] = useState(false)
  const [signUpLoading, setSignUpLoading] = useState(false)
  // const [resLoading, setResLoading] = useState(false)
  // const [resetMessge, setresetMessge] = useState("")
  const [resetErrors, setResetErrors] = useState({})
  const [showMessage, setShowMessage] = useState(true)
  const [signUpModal, setSignUpModal] = useState(false)
  const [downloadApp, setDownloadApp] = useState(false)

  sessionStorage.setItem("mail", email)
  sessionStorage.setItem("recoveryMail", loginEmail)

  useEffect(() => {
    if (isMobile && window.screen.width < 580) {
      console.log("mobile");
      setDownloadApp(true)
    } else {
      console.log("web")
      setDownloadApp(false)
    }
  }, [isMobile])

  const validation = () => {
    let error = {}
    let isValid = true;
    let emailReg = "^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"
    if (!firstName || firstName === "") {
      error.firstName = "Please enter your first name"
      isValid = false
    }
    if (!lastName || lastName === "") {
      error.lastName = "Please enter your last name"
      isValid = false
    }
    if (!password || password === "") {
      error.password = "Password is required"
      isValid = false
    }
    if (password.length < 8) {
      error.password = "Password length should not be less than 8"
    }
    if (!email || email === "" || email.match(emailReg)) {
      error.email = "Enter valid email"
      isValid = false
    }
    if (email !== reEnterEmail) {
      error.reEnterEmail = "Email did not match"
      isValid = false
    }
    setErrors(error)
    return isValid
  }

  const handleEmail=(e)=>{
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if(regEmail.test(e.target.value)){
        console.log("email", "Valid");
        setEmailError(false)
    } else {
        console.log("email", "invalid");

        setEmailError(true)
    }
}

  const signUpApi = () => {
    setSignUpLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      origin: "web",
      data: [
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          userType: "normal",
          dob: dob,
          gender: gender,
        },
      ],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/auth.signup`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "result.error")

        if (result.status === false && result.error === "Please verify you account") {
          history.push({
            pathname: "/auth/verify",
            state: { userEmail : email }
          })
        } else if (result.status === true && result.success === "Signup was successful. We sent you an email with verification link.") {
          history.push({
            pathname: "/auth/confirm-mail",
            state: { userEmail : email }
          })
        } else if (result.error !== "") {
          setRegistrationError(result.error)
          setShowMessage(true)
          window.scrollTo(0, 0);
          setTimeout(() => {
            setShowMessage(false)
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setSignUpLoading(false))
  };

  const swiperImages = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    {
      image: img6,
    },
  ];

  const genders = [
    {
      g: "Male",
      o: "M",
    },
    {
      g: "Female",
      o: "F",
    },
    {
      g: "Non-binary",
      o: "O",
    }
  ]

  const loginValidaton = () => {
    let loginerror = {}
    let isLoginValid = true
    if (!loginEmail || loginEmail === "") {
      loginerror.loginEmail = "Please enter your email"
    }
    if (!loginPassowrd || loginPassowrd === "") {
      loginerror.loginPassowrd = "Please enter valid password"
    }
    else {
    }
    setloginErrors(loginerror)
    return isLoginValid
  }

  const userState = (token) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      token
    );
    myHeaders.append("Content-Type", "application/json");
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
  
    fetch(
      `${servicePath}/user.state?`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "user state data");

        if(result && result.data){
          setLoading(false)
          if(result.data.universes.length > 0){
            window.location.href = '/'
          } else {
            history.push("/dashboard/app/selectuniverse")
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  const login = () => {
    setSignInLoading(true)

    setLoading(true)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      origin: "web",
      data: {
        username: loginEmail,
        password: loginPassowrd,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/auth.login`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "login status");

        if (result && result.status === true && result.success === "Your now logged into Universimm.com") {
          
          const userLocal = {
            id: result.data.user.id,
            email: result.data.user.email,
            name: result.data.user.name,
            firstName: result.data.user.username,
            lastName: result.data.user.username,
            photo: result.data.user.photo,
            firebase_id: result.data.user.firebase_id,
          }

          console.log(userLocal, "userLocal")

          localStorage.setItem("users", JSON.stringify(userLocal))
          
          localStorage.setItem("access_token", result.data.access_token)

          const userNodeRef = ref(database, 'users/' + result.data.user.firebase_id);
          const updates = {};
          updates['userId'] = result.data.user.id;
          updates['userName'] = result.data.user.username;
          updates['name'] = result.data.user.name;
          updates['email'] = result.data.user.email;
          updates['userType'] = result.data.user.userType;
          updates['photo'] = result.data.user.photo;
          
          const mState = {};
          mState['dateTime'] = ""+moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');
          mState['state'] = "Online";
          const updateState = {};
          updates['userState'] = mState;

          console.log(updates, "updateFirebase");

          update(userNodeRef, updates)

          setTimeout(() => {
            const token = "Bearer" +" "+ localStorage.getItem("access_token")
            userState(token);
          }, 100);

        } else if (result.status === false && result.error === "Please verify your account" && result.is_verfied === false) {
          history.push({
            pathname: "/auth/verify",
            state: { userEmail : loginEmail }
          })
        } else if (result.status === false) {
          setRegistrationError(result.error)
          setShowMessage(true)
          setSignUpModal(false)

          setLoading(false)
          setTimeout(() => {
            setShowMessage(false)
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setSignInLoading(false))
  };

  const resetValid = () => {
    let resetErr = {}
    let resVali = true
    if (!loginEmail || loginEmail === "") {
      resetErr.loginEmail = "Email is required"
    }
    else {

    }
    setResetErrors(resetErr)
    return resVali
  }

  // const resetPassword = () => {
  //   setResLoading(true)
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     origin: "web",
  //     data: {
  //       email: loginEmail,
  //     },
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${servicePath}/auth.request_password_change`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result && result.status === true && result.success === "Account recovery code sent") {
  //         history.push("/auth/reset-password", { message: result.success })
  //       } else {
  //         if(result.error === "The email field is required.") {
  //           setRegistrationError("Email is required to reset password!")
  //         } else {
  //           setRegistrationError(result.error)
  //         }

  //         setShowMessage(true)

  //         setTimeout(() => {
  //           setRegistrationError("")
  //           setShowMessage(false)
  //         }, 2000);
  //       }

  //     })
  //     .catch((error) => console.log("error", error))
  //     .finally((error) => setResLoading(true))
  // };

  const handleClose = () => { setSignUpModal(false) };

  return (
    <>

      {loading && (<div className="main_loader">
          <img src={loader} alt="loader" style={{ height: "100px" }} />
      </div>)}

      <Form className="header-main flex-wrap align-items-center justify-content-between py-2">
        <div className='header_logo py-1'>
          <img src={logoWeb} className="w-100" alt=""/>
        </div>
        <div className="input-container-header login_inputs d-none d-md-flex">
          <div>
            <input type="text" placeholder="Email" onChange={(e) => setLoginEmail(e.target.value)} value={loginEmail} />
            <div className='text-danger'>{loginErrors.loginEmail ? loginErrors.loginEmail : resetErrors.loginEmail}</div>
          </div>
          <div>
            <input type="password" placeholder="Password" onChange={(e) => setLoginPassowrd(e.target.value)} value={loginPassowrd} />
            <div className='text-danger'>{loginErrors.loginPassowrd}</div>
          </div>
          <div className='d-flex'>
            {signInLoading ? <img src={loader} alt="loader" style={{ height: "35.19px", float: "right" }} /> :
              <button className="login-btn br-8 px-3 px-lg-4" onClick={() => loginValidaton() ? login() : <></>}>Log in</button>}
          </div>
        </div>
        <div className='d-inline-flex d-md-none'>
          <p className="mb-0 text-white"> <span className='have_account'>Already have account?</span> <span onClick={() => { setSignUpModal(true) }} className="text-underline fw-bold clickable">Login</span></p>
        </div>
      </Form>

      <div className="actions px-2 d-none d-md-flex my-1 justify-content-end">
        <div className="d-flex align-items-center">
          <input type="checkbox" id="keepLogged" />
          <Form.Label htmlFor="keepLogged" className='mb-0 text-black'>Keep me logged in</Form.Label>
        </div>

        <div className="text-black ms-3">
          <NavLink to="/auth/reset-password" className="text-black">
              Forgot your password?
          </NavLink>
        </div>
      </div>

      {showMessage && registrationError ? <div className="alert-message">{registrationError}</div> : <></>}

      <section>
        <Container className='signup_page'>
          <Row className="no-gutters d-flex justify-content-center align-items-center auth_page">
            <Col md="10" lg="7" className="text-center">
              <div className="sign-in-detail text-white pb-0 pb-lg-1 my-3">
                <div className="sign-slider overflow-hidden">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0 "
                  >
                    {swiperImages.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <Image
                            src={item.image}
                            className="img-fluid  mb-4"
                            alt="logo"
                          />
                          <h4 className="text-black">
                            Share your passions and interests with the people you
                            love.
                          </h4>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col md="10" lg="5" className="form-container pt-0 pt-lg-3 mb-4">
              <div className="text-center text-danger">{signUpError}</div>
              <div className="sign-in-from">

                <div className="d-block d-sm-none mb-2">
                  <h4 className='text-black mb-0'>Download on app stores</h4>
                  <div className="d-flex justify-content-start">
                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.universimm.mobile&hl=en">
                      <div className="apps-icon me-2">
                        <img src={googleStoreIcon} alt=""/>
                      </div>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/uy/app/universimm/id1501969168">
                      <div className="apps-icon me-2">
                        <img src={appleStoreIcon} alt=""/>
                      </div>
                    </a>
                  </div>
                </div>
                <h3 className="mb-0 create-universe">
                  Create your Universe!
                </h3>
                <h5>Join this new generation now</h5>
                <div className="text-center text-danger">{status}</div>
                <Form className="mt-2">
                  <div className="name-inputs row">
                    <Form.Group className="col-sm-6">
                      <Form.Control
                        type="email"
                        className="input"
                        placeholder="First name"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      <div className="text-danger mb-2">{errors.firstName}</div>
                    </Form.Group>

                    <Form.Group className="col-sm-6">
                      <Form.Control
                        type="email"
                        className="mb-0 input"
                        placeholder="Last name"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                      <div className="text-danger mb-2">{errors.lastName}</div>
                    </Form.Group>
                  </div>

                  <Form.Group className="form-group mb-2">
                    <Form.Control
                      type="email"
                      className="mb-0"
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmail}
                      value={email}
                    />

                    {(errors.email || emailError) && (
                      <div className="text-danger">Please enter valid email</div>
                    )}
                  </Form.Group>

                    <Form.Group className="form-group mb-0">
                      <Form.Control
                        type="email"
                        className="mb-0"
                        placeholder="Re-Enter email"
                        onChange={(e) => setReEnterEmail(e.target.value)}
                        value={reEnterEmail}
                      />
                    </Form.Group>
                  <div className="text-danger mb-2">{errors.reEnterEmail}</div>
                    <Form.Group className="form-group mb-0">
                      <Form.Control
                        type="password"
                        className="mb-0"
                        id="newPassword"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </Form.Group>
                  <div className="text-danger mb-2">{errors.password}</div>

                  {/* <div className="row">
                    <Form.Group controlId="dob" className="col-sm-6">
                      <Form.Control
                        type="date"
                        name="dob"
                        placeholder="Your Birthday"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                      <div className="text-danger mb-2">{errors.dob}</div>
                    </Form.Group>

                    <Form.Group className="col-sm-6">
                      <Form.Select
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                      >
                        <option> Choose gender</option>
                        {genders.map((item, index) => {
                          return <option key={index} value={item.o}>{item.g}</option>;
                        })}
                      </Form.Select>
                      <div className="text-danger mb-2">{errors.gender}</div>
                    </Form.Group>
                  </div> */}

                  <div className="Signup-container w-100">
                    <p className="text-center signup-agreement">
                      By clicking SignUp you agree to our{" "}
                      <Link to="/user/terms-conditions" className="signup-links">
                        Terms & conditions
                      </Link>{" "}

                      and that you have read our{" "}

                      <Link to="/user/privacy-policy" className="signup-links">Privacy Policy {" "}</Link>

                      including our{" "} <span><Link to="/user/cookie-policy" className="signup-links">Cookie Use</Link></span>
                    </p>
                    {signUpLoading ? <img src={loader} alt="loader" style={{ height: "50px", width: "40px", margin: "auto" }} /> :
                      <button
                        type="button"
                        className="sign-up"
                        onClick={() => {validation() ? signUpApi() : <></>;}}
                      >
                        Sign Up
                      </button>}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>

          <Modal
            size="md"
            className="fade"
            id="post-modal"
            dialogClassName="modal-dialog-centered"
            onHide={handleClose}
            show={signUpModal}
          >
            <div className="text-center p-3">
              <div className="d-flex justify-content-between mb-3">
                <h4 className='fw-bold'>Login</h4>
                <Link to="#" className="lh-1 text-muted" onClick={() => { setSignUpModal(false) }}>
                  <i className="ri-close-fill ri-xl"></i>
                </Link>
              </div>
              <Modal.Body className='p-0'>
                <Form className="">
                  <div className='w-100 mb-3'>
                    <input type="text" className='form-control' placeholder="Email" onChange={(e) => setLoginEmail(e.target.value)} value={loginEmail} />
                    <div className='text-danger d-flex'>{loginErrors.loginEmail ? loginErrors.loginEmail : resetErrors.loginEmail}</div>
                  </div>
                  <div className='w-100 mb-3'>
                    <input type="password" className='form-control' placeholder="Password" onChange={(e) => setLoginPassowrd(e.target.value)} value={loginPassowrd} />
                    <div className='text-danger d-flex'>{loginErrors.loginPassowrd}</div>
                  </div>

                  <div className="actions mb-4 d-flex my-1 justify-content-between flex-wrap">
                    <div className="d-flex align-items-center me-2">
                      <input type="checkbox" id="keepLogged2" />
                      <Form.Label htmlFor="keepLogged2" className='mb-0 text-nowrap text-black'>Keep me logged in</Form.Label>
                    </div>
                    <div className="d-flex text-nowrap text-black">
                      <NavLink to="/auth/reset-password" className="text-black">
                          Forgot your password?
                      </NavLink>
                    </div>
                  </div>

                  <div className='d-flex justify-content-center w-100'>
                    {signInLoading ? <button className="btn btn-primary px-4" disabled>Log in</button> :
                      <button className="btn btn-primary px-4" onClick={() => loginValidaton() ? login() : <></>}>Log in</button>}
                  </div>
                </Form>
              </Modal.Body>
            </div>
          </Modal>

          <Modal
            size="sm"
            className="fade"
            id="post-modal"
            dialogClassName="modal-dialog-centered"
            onHide={handleClose}
            show={downloadApp}
          >
            <div className="p-3">
              <div className="d-flex justify-content-between mb-3">
                <span></span>
                <Link to="#" className="lh-1 text-muted" onClick={() => { setDownloadApp(false) }}>
                  <i className="ri-close-fill ri-xl"></i>
                </Link>
              </div>
              <Modal.Body className='text-center p-0'>
                <h5 className='text-muted'>for better experience!</h5>
                <h4 className='text-black'>Download on app stores</h4>

                <div className="d-flex justify-content-center mt-3">
                  <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.universimm.mobile&hl=en">
                    <div className="apps-icon me-2">
                      <img src={googleStoreIcon} alt=""/>
                    </div>
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://apps.apple.com/uy/app/universimm/id1501969168">
                    <div className="apps-icon me-2">
                      <img src={appleStoreIcon} alt=""/>
                    </div>
                  </a>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </Container>
      </section>
    </>
  );
}

export default SignUp