export default function calculateTimeAgo(dateTime) {
  const currentDate = new Date();
  const inputDate = new Date(dateTime);
  const timeDifference = currentDate - inputDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds < 60) {
    return `Posted ${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  } else if (minutes < 60) {
    return `Posted ${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else if (hours < 24) {
    return `Posted ${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else {
    const days = Math.floor(hours / 24);
    return `Posted ${days} ${days === 1 ? "day" : "days"} ago`;
  }
}
