import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../helper/axiosInstance";

export const USER_STATES = "USER_STATES";

const useUserState = () => {
  return useQuery([USER_STATES], async () => {
    const response = await axiosInstance({
      url: "/api/action/user.state",
      method: "post",
    });

    return response?.data?.data ?? {};
  });
};

export default useUserState;
