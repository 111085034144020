import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import BrokenImg from '../../../assets/images/broken_img_1.jpg';

const SingleLightbox2 = ({ thumb, className, large }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavLink to="#" location={{}} onClick={() => setIsOpen(true)}>
        <img 
            src={thumb} 
            alt="thumbnail" 
            className={className}
            onError={(e) => {
              e.target.src = BrokenImg
            }}
        />
      </NavLink>

      {isOpen && (
        <Lightbox mainSrc={large} onCloseRequest={() => setIsOpen(false)} />
      )}
    </>
  );
};
export default SingleLightbox2;
