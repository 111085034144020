//router
import IndexRouters from "./router/index"
// import 'react-image-lightbox/style.css';
import '../node_modules/video.js/dist/video-js.css';
import { HelmetProvider } from 'react-helmet-async';

//scss
import "./assets/scss/main.scss"
import "./assets/scss/custom.scss";
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from "react";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <IndexRouters />
      </HelmetProvider>
    </div>
  );
}

export default App;
