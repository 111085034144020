import React from "react";
import NealsLinkPreview from "./NealsLinkPreview";

function LinkPreview({ data }) {
  const urlMatches = data.match(/\bhttps?:\/\/\S+/gi);

  if (urlMatches && urlMatches.length > 0) {
    const url = urlMatches[0];
    return <NealsLinkPreview url={url} />;
  }

  return null;
}

export default LinkPreview;
