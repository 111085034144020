import React, { useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Card from "../../../components/Card";

// images
import {
  BiArrowBack,
  BiBlock,
  BiCake,
  BiGlobe,
  BiHeart,
  BiHome,
  BiMap,
  BiUser,
} from "react-icons/bi";
import {
  FaBriefcase,
  FaFacebookF,
  FaGraduationCap,
  FaInstagram,
  FaLanguage,
  FaPhoneAlt,
  FaRegEnvelope,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import Moment from "react-moment";
import dummyBanner from "../../../assets/images/banner.jpg";
import noprofile from "../../../assets/images/no-profile-picture.jpg";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import FriendProfileHeader from "../components/FriendProfileHeader";
import ProfilePostItem from "../components/ProfilePostItem";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Carousel from "react-multi-carousel";
import NumericLabel from "react-pretty-numbers";
import audioThumb from "../../../assets/images/audio_thumb.png";
import videoThumb from "../../../assets/images/video_thumb.png";
import { subscribeUniverseSingle } from "../components/Functions";
import SingleLightbox from "../components/SingleLightbox";
import SubscribeUniverseModal from "../components/SubscribeUniverseModal";
import VideoPlayerPreview from "../components/VideoPlayerPreview";
import { responsiveUniversesProfile, servicePath } from "./variables";

const FriendProfile = () => {
  const { id } = useParams();

  const userData = JSON.parse(localStorage.getItem("users"));
  const token = "Bearer" + " " + localStorage.getItem("access_token");
  // const [id, setid] = useState('')

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [educationData, setEducationData] = useState([]);
  const [experiencesData, setExperiencesData] = useState([]);
  const [userAccountD, setUserAccountD] = useState([]);
  const [showFriends, setShowFriends] = useState([]);
  const [userPrivacy, setUserPrivacy] = useState("");
  const [showUni, setShowUni] = useState([]);
  const [userSocialLinks, setUserSocialLinks] = useState([]);
  const [languagesKnown, setLanguagesKnown] = useState([]);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  const [userPosts, setUserPosts] = useState({
    current_page: "",
    data: [],
    first_page_url: "",
    form: "",
    last_page: "",
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  });

  const [subsUniverseModal, setSubsUniverseModal] = useState(false);
  const [univerId, setUniverId] = useState("");

  const [mediaLimit, setMediaLimit] = useState(20);

  const handleClose = () => {
    setSubsUniverseModal(false);
  };

  const [userStat, setUserStat] = useState({
    followers: "",
    following: "",
    friends: "",
    posts: "",
  });

  const [profileImg, setProfileImg] = useState("");
  const [bannerImg, setBannerImg] = useState("");

  const [isDeactivated, setIsDeactivated] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [hasBlock, setHasBlock] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [hasRequest, setHasRequest] = useState(false);
  const [hasDenied, setHasDenied] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [aboutActive, setAboutActive] = useState(false);
  const [friendsActive, setFriendsActive] = useState(false);
  const [photosActive, setPhotosActive] = useState(false);

  const [media, setMedia] = useState([]);
  const [videoAudioPreview, setVideoAudioPreview] = useState(false);
  const [previewType, setPreviewType] = useState("");
  const [previewURL, setPreviewURL] = useState("");

  useEffect(() => {
    setLoading(true);

    if (userData !== null) {
      // console.log(id)
      setLoadingPage(true);

      friendState(id);

      var loadLimit = 10;
      userPost(loadLimit, id);
    }
  }, [id]);

  const handleRemoveFriend = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        friend_user_id: id,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.unfriend`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "unfriend successfully") {
          setMessage("Unfriend successful");
          setShowMessage(true);

          friendState(userAccountD.id);

          setTimeout(() => {
            setShowMessage(false);

            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleBlockUser = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    console.log(id, "block id");

    var raw = JSON.stringify({
      data: {
        status: "blocked",
        blocked_user_id: id,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.block`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result.success === "User blocked successfully") {
          setMessage("Blocked successfully");
          setShowMessage(true);

          setHasBlock(true);
          // friendState(userAccountD.id)

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        } else {
          setMessage(result.success);
          setShowMessage(true);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleConfirmRequest = (requestId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        request_id: requestId,
        status: "accepted",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "Request was accepted") {
          setMessage("Request confirmed");
          setShowMessage(true);

          friendState(userAccountD.id);

          setTimeout(() => {
            setShowMessage(false);

            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCancelRequest = (requestId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        request_id: requestId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.delete`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "Request was deleted") {
          setMessage("Request cancelled successfully");
          setShowMessage(true);

          friendState(userAccountD.id);

          setTimeout(() => {
            setShowMessage(false);

            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const friendState = (id) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        user_id: id,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.profile_details`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "friend user state data");

        setUserAccountD(result.data.account);
        setProfileImg(result.data.account.photo);
        setBannerImg(result.data.account.banner);
        setUserSocialLinks(result.data.social);
        setIsFriend(result.data.is_friend);
        setIsDeactivated(result.data.is_deactivated);
        setHasBlock(result.data.has_block);
        setIsBlocked(result.data.is_blocked);
        setIsFollowing(result.data.following);
        setHasRequest(result.data.has_request);
        setHasDenied(result.data.has_denied);
        setRequestSent(result.data.request_sent);

        setEducationData(result.data.education);
        setExperiencesData(result.data.experiences);

        setShowFriends(result.data.friendships_details);
        setMedia(result.data.media_data);
        setShowUni(result.data.universes);

        if (
          !result.data.is_blocked &&
          !result.data.has_block &&
          !result.data.is_deactivated
        ) {
          verifyUserUni(result.data.universes);
        }

        setUserStat((preVal) => ({
          ...preVal,
          friends: result.data.friendships_details.length,
        }));

        var userLanguagesString;
        if (result.data.account.language !== null) {
          userLanguagesString = result.data.account.language
            .map((value) => value)
            .join(", ");
          // console.log(userLanguagesString, "userLanguagesString");
        }

        setLanguagesKnown(userLanguagesString);

        // for privacy settings
        setUserPrivacy(result.data.privacy);

        setLoading(false);
        setLoadingPage(false);

        // console.log(userPrivacy, "userPrivacy details");
      })
      .catch((error) => console.log("error", error));
  };

  const userPost = (postLimit, id) => {
    // console.log(id, "friend post")

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    // console.log(id, "id")

    var raw = JSON.stringify({
      data: {
        post: {
          user_id: id,
        },
      },
      limit: postLimit,
      page: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.user`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          // console.log(result, "post result friend")

          if (result && result.data && result.data.length > 0) {
            setUserPosts((preVal) => ({
              ...preVal,
              current_page: result.current_page,
              data: result.data,
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links: result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page: result.per_page,
              prev_page_url: result.prev_page_url,
              to: result.to,
              total: result.total,
            }));
          } else {
            setUserPosts((preVal) => ({
              ...preVal,
              current_page: result.current_page,
              data: [],
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links: result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page: result.per_page,
              prev_page_url: result.prev_page_url,
              to: result.to,
              total: result.total,
            }));
          }

          setUserStat((preVal) => ({ ...preVal, posts: result.total }));
        }

        setTimeout(() => {
          setPostLoading(false);
          setLoading(false);
        }, 1000);
      })
      .catch((error) => console.log("error", error));
  };

  const LoadMorePost = (currentLimit) => {
    // console.log(currentLimit, "new postLimit");
    userPost(currentLimit + 10, id);
  };

  const handleMoreMedia = () => {
    setLoadingMedia(true);

    setTimeout(() => {
      setMediaLimit(mediaLimit + 20);
      setLoadingMedia(false);
    }, 1000);
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group create_post_btn">
        <div className="icon" onClick={() => previous()}>
          <IoIosArrowBack size={18} />
        </div>
        <div className="icon" onClick={() => next()}>
          <IoIosArrowForward size={18} />
        </div>
      </div>
    );
  };

  const verifyUserUni = (friendUni) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.state`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const userUniDataIds = [];

        function userUniIds(item) {
          userUniDataIds.push(item.ID);
        }

        if (result.data.universes) {
          result.data.universes.forEach(userUniIds);
        }

        function getUniverIds(item) {
          var ids = item.ID;

          if (userUniDataIds.includes(ids)) {
            document
              .getElementById(`universe_card${ids}`)
              .classList.add("isSubscribed");
          } else {
            document
              .getElementById(`universe_card${ids}`)
              .classList.remove("isSubscribed");
            document
              .getElementById(`universe_card${ids}`)
              .classList.add("clickable");
          }
        }

        if (friendUni) {
          friendUni.forEach(getUniverIds);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCheckUniverse = (item, e) => {
    // console.log(item.ID, "handleCheckUniverse")
    if (e.currentTarget.classList.contains("isSubscribed")) {
      // console.log("already sub univ")
      setSubsUniverseModal(false);
    } else {
      // console.log(univerId, "not sub univ")
      setUniverId(item.ID);
      setSubsUniverseModal(true);
    }
  };

  const subscribeUniverse = () => {
    subscribeUniverseSingle(univerId, token, setShowMessage, setMessage);

    setTimeout(() => {
      friendState(id);
      setSubsUniverseModal(false);
    }, 1000);
  };

  return (
    <>
      {!isBlocked && !hasBlock && !isDeactivated ? (
        <Container>
          {loadingPage && (
            <div className="main_loader">
              <img src={loader} alt="loader" style={{ height: "100px" }} />
            </div>
          )}
          <Row>
            <Col sm={12}>
              {showMessage && message ? (
                <div className="alert-message">{message}</div>
              ) : (
                <></>
              )}
              <FriendProfileHeader
                userState={friendState}
                setBannerImg={setBannerImg}
                setProfileImg={setProfileImg}
                profileImg={profileImg}
                bannerImg={bannerImg}
                userAccountD={userAccountD}
                setLoading={setLoading}
                token={token}
                setMessage={setMessage}
                setShowMessage={setShowMessage}
                userStat={userStat}
                userPost={userPost}
                userData={userData}
                isFriend={isFriend}
                hasBlock={hasBlock}
                isBlocked={isBlocked}
                isFollowing={isFollowing}
                hasRequest={hasRequest}
                hasDenied={hasDenied}
                requestSent={requestSent}
                handleRemoveFriend={handleRemoveFriend}
                handleBlockUser={handleBlockUser}
                handleConfirmRequest={handleConfirmRequest}
                handleCancelRequest={handleCancelRequest}
              />
            </Col>
            <Col sm={12}>
              <Row>
                <Col md={4}>
                  <Row className="profile_left">
                    <Col sm={6} md={12}>
                      <Card>
                        <div className="card-header d-flex justify-content-between">
                          <div className="header-title">
                            <h4 className="card-title">About</h4>
                          </div>
                        </div>
                        <Card.Body>
                          <ul className="list-inline p-0 m-0">
                            {userAccountD && userAccountD.aboutMe && (
                              <li className="mb-2 d-flex">
                                <i className="ri-git-repository-line me-2 font-size-20"></i>
                                <p className="mb-0">{userAccountD.aboutMe}</p>
                              </li>
                            )}

                            {userAccountD && userAccountD.currentCity && (
                              <li className="mb-2 d-flex align-items-center">
                                <i className="ri-map-pin-line me-2 font-size-20"></i>
                                <p className="mb-0">
                                  {userAccountD.currentCity}
                                  {userAccountD.country
                                    ? `, ${userAccountD.country}`
                                    : ""}
                                </p>
                              </li>
                            )}

                            {userAccountD && userAccountD.marital_status && (
                              <li className="mb-2 d-flex align-items-center">
                                <i className="ri-heart-line me-2 font-size-20"></i>
                                <p className="mb-0">
                                  {userAccountD.marital_status}
                                </p>
                              </li>
                            )}

                            {userAccountD &&
                              !userAccountD.currentCity &&
                              userPrivacy &&
                              (userPrivacy.see_birthday == "public" ||
                                (userPrivacy.see_birthday == "friends" &&
                                  isFriend)) &&
                              userAccountD.dob && (
                                <li className="mb-2 d-flex align-items-center">
                                  <i className="ri-cake-2-line me-2 font-size-20"></i>
                                  <p className="mb-0">
                                    <Moment format="DD MMM YYYY">
                                      {userAccountD.dob}
                                    </Moment>
                                  </p>
                                </li>
                              )}

                            {userAccountD &&
                              !userAccountD.marital_status &&
                              userAccountD.gender && (
                                <li className="mb-2 d-flex align-items-center">
                                  <i className="ri-user-line me-2 font-size-20"></i>
                                  <p className="mb-0">
                                    {userAccountD.gender && (
                                      <>
                                        {userAccountD.gender === "M"
                                          ? "Male"
                                          : userAccountD.gender === "F"
                                          ? "Female"
                                          : userAccountD.gender === "O"
                                          ? "Non-binary"
                                          : userAccountD.gender}
                                      </>
                                    )}
                                  </p>
                                </li>
                              )}

                            {userAccountD && userAccountD.website && (
                              <li className="mb-2 d-flex align-items-center">
                                <i className="ri-user-line me-2 font-size-20"></i>
                                <a
                                  className="text-link"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={userAccountD.website}
                                >
                                  {userAccountD.website}
                                </a>
                              </li>
                            )}
                          </ul>

                          <div className="text-center w-100 fw-bold">
                            <Link
                              to="#"
                              onClick={() => {
                                setAboutActive(!aboutActive);
                                setFriendsActive(false);
                                setPhotosActive(false);
                              }}
                            >
                              {aboutActive ? "" : "View all details"}
                            </Link>
                          </div>
                        </Card.Body>
                      </Card>

                      {userPrivacy &&
                        (userPrivacy.u_activity == "public" ||
                          (userPrivacy.u_activity == "friends" &&
                            isFriend)) && (
                          <Card>
                            <div className="card-header d-flex justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title">Photos</h4>
                              </div>
                            </div>
                            <Card.Body>
                              {media && media.length > 0 ? (
                                <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                  {media.slice(0, 6).map((item, index) => {
                                    return (
                                      <li key={index}>
                                        {item.media_type === "image" && (
                                          <SingleLightbox
                                            thumb={item.media_url}
                                            large={item.media_url}
                                            className="img-fluid"
                                          />
                                        )}

                                        {item.media_type === "video" && (
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setPreviewType(item.media_type);
                                              setPreviewURL(item.media_url);
                                              setVideoAudioPreview(true);
                                            }}
                                          >
                                            <img
                                              src={videoThumb}
                                              alt="gallary"
                                              className="img-fluid"
                                            />
                                          </Link>
                                        )}

                                        {item.media_type === "audio" && (
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setPreviewType(item.media_type);
                                              setPreviewURL(item.media_url);
                                              setVideoAudioPreview(true);
                                            }}
                                          >
                                            <img
                                              src={audioThumb}
                                              alt="gallary"
                                              className="img-fluid"
                                            />
                                          </Link>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <h5 className="text-center">No photos</h5>
                              )}

                              {media && media.length > 0 && (
                                <Link
                                  className="d-block text-center w-100 fw-bold mt-1"
                                  to="#"
                                  onClick={() => {
                                    setPhotosActive(!photosActive);
                                    setAboutActive(false);
                                    setFriendsActive(false);
                                  }}
                                >
                                  {photosActive ? "" : "View all media"}
                                </Link>
                              )}
                            </Card.Body>
                          </Card>
                        )}
                    </Col>

                    <VideoPlayerPreview
                      previewType={previewType}
                      previewURL={previewURL}
                      videoAudioPreview={videoAudioPreview}
                      setVideoAudioPreview={setVideoAudioPreview}
                    />

                    <Col sm={6} md={12}>
                      <Card>
                        <div className="card-header d-flex justify-content-between">
                          <div className="header-title">
                            <h4 className="card-title">Friends</h4>
                          </div>
                        </div>
                        <Card.Body>
                          {userPrivacy &&
                          (userPrivacy.friends_listing == "public" ||
                            (userPrivacy.friends_listing == "friends" &&
                              isFriend)) ? (
                            <>
                              {showFriends && showFriends.length > 0 ? (
                                <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                  {showFriends
                                    .slice(0, 6)
                                    .map((item, index) => {
                                      return (
                                        <li key={index} className="">
                                          <Link
                                            className=""
                                            to={
                                              userData &&
                                              item.id === userData.id
                                                ? "/dashboard/app/profile"
                                                : `/dashboard/app/friend-profile/${item.id}`
                                            }
                                          >
                                            <img
                                              src={
                                                item.photo
                                                  ? item.photo
                                                  : noprofile
                                              }
                                              onError={(e) => {
                                                e.target.src = noprofile;
                                              }}
                                              alt="gallary"
                                              className="img-fluid"
                                            />
                                          </Link>
                                          <h6 className="text-center my-1 fw-500 text-ellipsis-2">
                                            {item.name}
                                          </h6>
                                        </li>
                                      );
                                    })}
                                </ul>
                              ) : (
                                <h5 className="text-center">No friends</h5>
                              )}

                              {showFriends && showFriends.length > 0 && (
                                <Link
                                  className="d-block text-center w-100 fw-bold mt-1"
                                  to="#"
                                  onClick={() => {
                                    setFriendsActive(!friendsActive);
                                    setPhotosActive(false);
                                    setAboutActive(false);
                                  }}
                                >
                                  {friendsActive ? "" : "View all friends"}
                                </Link>
                              )}
                            </>
                          ) : (
                            <h5 className="text-center">
                              Friend visibility locked
                            </h5>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      {showUni && showUni.length > 0 && (
                        <div className="card card-block card-stretch position-relative d-flex justify-content-between create_post">
                          <div className="d-flex justify-content-between">
                            <h5 className="px-1 mb-1 text-muted">Universe</h5>
                          </div>
                          <Carousel
                            arrows={false}
                            customButtonGroup={<ButtonGroup />}
                            infinite={false}
                            responsive={responsiveUniversesProfile}
                            autoPlay={false}
                            containerClass="carousel-container mb-0"
                            renderButtonGroupOutside={true}
                            itemClass="carousel-item-padding-40px"
                            sliderClass="react-multi-carousel-track"
                          >
                            {showUni.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="text-center universe_sub_check image_broken uni_image_wrap"
                                  id={`universe_card${item.ID}`}
                                  onClick={(e) => {
                                    handleCheckUniverse(item, e);
                                  }}
                                >
                                  <img
                                    src={item.universeIcon}
                                    alt="universe"
                                    className="rounded-circle story-img"
                                  />
                                  <div className="universes-text">
                                    {item.universeName}
                                  </div>
                                </div>
                              );
                            })}
                          </Carousel>
                        </div>
                      )}
                    </Col>

                    <SubscribeUniverseModal
                      handleClose={handleClose}
                      subsUniverseModal={subsUniverseModal}
                      setSubsUniverseModal={setSubsUniverseModal}
                      subscribeUniverse={subscribeUniverse}
                      univerId={univerId}
                    />

                    {!aboutActive && !photosActive && !friendsActive && (
                      <Col>
                        <div className="mx-auto" style={{ maxWidth: "550px" }}>
                          {userPrivacy &&
                          (userPrivacy.u_activity == "public" ||
                            (userPrivacy.u_activity == "friends" &&
                              isFriend)) ? (
                            <>
                              {userPosts && userPosts.total > 0 ? (
                                <Card>
                                  <div className="d-flex justify-content-between align-items-center py-2 border-bottom card-body lh-1">
                                    <h5 className="fw-bold">Posts</h5>

                                    <p className="mb-0">
                                      Showing:{" "}
                                      <NumericLabel
                                        params={{
                                          justification: "L",
                                          shortFormat: true,
                                        }}
                                      >
                                        {userPosts.to || "0"}
                                      </NumericLabel>
                                      {" of "}
                                      <NumericLabel
                                        params={{
                                          justification: "L",
                                          shortFormat: true,
                                        }}
                                      >
                                        {userPosts.total || "0"}
                                      </NumericLabel>
                                    </p>
                                  </div>

                                  <Card.Body>
                                    <Row>
                                      {userPosts.data.map((item, index) => {
                                        return (
                                          <Col
                                            xs={12}
                                            key={index}
                                            className="mb-4"
                                          >
                                            <ProfilePostItem
                                              setLoading={setLoading}
                                              token={token}
                                              userPost={userPost}
                                              setMessage={setMessage}
                                              setShowMessage={setShowMessage}
                                              item={item}
                                              componentOf="friend-profile"
                                              setSubsUniverseModal={
                                                setSubsUniverseModal
                                              }
                                              setUniverId={setUniverId}
                                            />
                                          </Col>
                                        );
                                      })}
                                    </Row>

                                    {userPosts.total !== userPosts.to ? (
                                      <div className="d-flex justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() =>
                                            LoadMorePost(userPosts.per_page)
                                          }
                                        >
                                          Show more post
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="text-center">
                                        <h4 className="fw-semibold mb-0">
                                          No more posts
                                        </h4>
                                      </div>
                                    )}
                                  </Card.Body>
                                </Card>
                              ) : (
                                <>
                                  {postLoading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                      <img
                                        src={loader}
                                        alt="loader post"
                                        style={{
                                          height: "70px",
                                          width: "70px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <Card className="text-center">
                                      <div className="d-flex justify-content-between align-items-center py-2 border-bottom card-body lh-1">
                                        <h5 className="fw-bold">Posts</h5>

                                        <p className="mb-0">
                                          Showing:{" "}
                                          <NumericLabel
                                            params={{
                                              justification: "L",
                                              shortFormat: true,
                                            }}
                                          >
                                            {userPosts.to || "0"}
                                          </NumericLabel>
                                          {" of "}
                                          <NumericLabel
                                            params={{
                                              justification: "L",
                                              shortFormat: true,
                                            }}
                                          >
                                            {userPosts.total || "0"}
                                          </NumericLabel>
                                        </p>
                                      </div>
                                      <Card.Body>
                                        <h4>No posts found!</h4>
                                      </Card.Body>
                                    </Card>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Card>
                                <div className="d-flex justify-content-between align-items-center py-2 border-bottom card-body lh-1">
                                  <h5 className="fw-bold">Posts</h5>

                                  <p className="mb-0 badge rounded bg-soft-secondary fw-500 fs-6">
                                    <NumericLabel
                                      params={{
                                        justification: "L",
                                        shortFormat: true,
                                      }}
                                    >
                                      {userStat.posts || "0"}
                                    </NumericLabel>
                                  </p>
                                </div>

                                <Card.Body>
                                  <div className="d-flex justify-content-center">
                                    <div className="avatar-60 bg-soft-primary d-flex justify-content-center align-items-center rounded-circle mb-2">
                                      <FiLock
                                        size={30}
                                        color="#3954ff"
                                        className="mb-0"
                                      />
                                    </div>
                                  </div>
                                  <h5 className="text-center">
                                    No posts to share because of privacy
                                    settings!
                                  </h5>
                                </Card.Body>
                              </Card>
                            </>
                          )}
                        </div>
                      </Col>
                    )}

                    {aboutActive && (
                      <Col md={12}>
                        <Card>
                          <div className="d-flex py-2 border-bottom card-body lh-1">
                            <Link
                              to="#"
                              className="d-flex align-items-center"
                              onClick={() => setAboutActive(!aboutActive)}
                            >
                              <span>
                                <BiArrowBack size={20} />
                              </span>
                              <h5 className="fw-bold ms-2">About</h5>
                            </Link>
                          </div>

                          <Card.Body className="friend_about">
                            {(userAccountD.dob ||
                              userAccountD.gender ||
                              languagesKnown) && (
                              <div className="about_item_wrap border-bottom">
                                <h6 className="about_title">Basic info</h6>
                                {userAccountD.gender && (
                                  <div className="about_items">
                                    <div className="icon bg-soft-info border-0">
                                      <BiUser size={20} color="#777d74" />
                                    </div>
                                    <div className="fcc">
                                      <h6>
                                        {userAccountD.gender && (
                                          <>
                                            {userAccountD.gender === "M"
                                              ? "Male"
                                              : userAccountD.gender === "F"
                                              ? "Female"
                                              : userAccountD.gender === "O"
                                              ? "Non-binary"
                                              : userAccountD.gender}
                                          </>
                                        )}
                                      </h6>
                                      <span className="text-muted">Gender</span>
                                    </div>
                                  </div>
                                )}

                                {userAccountD.marital_status && (
                                  <div className="about_items">
                                    <div className="icon bg-soft-info border-0">
                                      <BiHeart size={20} color="#777d74" />
                                    </div>
                                    <div className="fcc">
                                      <h6>{userAccountD.marital_status}</h6>
                                      <span className="text-muted">
                                        Marital status
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {userPrivacy &&
                                  (userPrivacy.see_birthday == "public" ||
                                    (userPrivacy.see_birthday == "friends" &&
                                      isFriend)) &&
                                  userAccountD.dob && (
                                    <div className="about_items">
                                      <div className="icon bg-soft-info border-0">
                                        <BiCake size={20} color="#777d74" />
                                      </div>
                                      <div className="fcc">
                                        <h6>
                                          <Moment format="DD MMM YYYY">
                                            {userAccountD.dob}
                                          </Moment>
                                        </h6>
                                        <span className="text-muted">
                                          Birthday
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {userPrivacy &&
                                  (userPrivacy.see_email == "public" ||
                                    (userPrivacy.see_email == "friends" &&
                                      isFriend)) &&
                                  userAccountD.email && (
                                    <div className="about_items">
                                      <div className="icon bg-soft-info border-0">
                                        <FaRegEnvelope
                                          size={20}
                                          color="#777d74"
                                        />
                                      </div>
                                      <div className="fcc">
                                        <h6>{userAccountD.email}</h6>
                                        <span className="text-muted">
                                          Email
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {userPrivacy &&
                                  (userPrivacy.see_email == "public" ||
                                    (userPrivacy.see_email == "friends" &&
                                      isFriend)) &&
                                  userAccountD.phone && (
                                    <div className="about_items">
                                      <div className="icon bg-soft-info border-0">
                                        <FaPhoneAlt size={20} color="#777d74" />
                                      </div>
                                      <div className="fcc">
                                        <h6>{userAccountD.phone}</h6>
                                        <span className="text-muted">
                                          Contact number
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {userAccountD.website && (
                                  <div className="about_items">
                                    <div className="icon bg-soft-info border-0">
                                      <BiGlobe size={20} color="#777d74" />
                                    </div>
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={userAccountD.website}
                                    >
                                      <div className="fcc">
                                        <h6 className="text-link">
                                          {userAccountD.website}
                                        </h6>
                                        <span className="text-muted">
                                          Website
                                        </span>
                                      </div>
                                    </a>
                                  </div>
                                )}

                                {languagesKnown && (
                                  <div className="about_items">
                                    <div className="icon bg-soft-info border-0">
                                      <FaLanguage size={20} color="#777d74" />
                                    </div>
                                    <div className="fcc">
                                      <h6>{languagesKnown}</h6>
                                      <span className="text-muted">
                                        Language
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            {(userAccountD.homeCity ||
                              userAccountD.currentCity) && (
                              <div className="about_item_wrap border-bottom">
                                <h6 className="about_title">Places lived</h6>

                                {userAccountD.currentCity && (
                                  <div className="about_items">
                                    <div className="icon bg-soft-info border-0">
                                      <BiMap size={20} color="#777d74" />
                                    </div>
                                    <div className="fcc">
                                      <h6>
                                        {userAccountD.currentCity}
                                        {userAccountD.country
                                          ? `, ${userAccountD.country}`
                                          : ""}
                                      </h6>
                                      <span className="text-muted">
                                        Current city
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {userAccountD.homeCity && (
                                  <div className="about_items">
                                    <div className="icon bg-soft-info border-0">
                                      <BiHome size={20} color="#777d74" />
                                    </div>
                                    <div className="fcc">
                                      <h6>
                                        {userAccountD.homeCity}
                                        {userAccountD.country
                                          ? `, ${userAccountD.country}`
                                          : ""}
                                      </h6>
                                      <span className="text-muted">
                                        Hometown
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            {userPrivacy &&
                              (userPrivacy.aboutme == "public" ||
                                (userPrivacy.aboutme == "friends" &&
                                  isFriend)) && (
                                <>
                                  {experiencesData &&
                                    experiencesData.length > 0 && (
                                      <div className="about_item_wrap border-bottom">
                                        <h6 className="about_title">Work</h6>

                                        {experiencesData.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="about_items"
                                            >
                                              <div className="icon bg-soft-info border-0">
                                                <FaBriefcase
                                                  size={20}
                                                  color="#777d74"
                                                />
                                              </div>
                                              <div className="fcc">
                                                <h6>{item.title}</h6>
                                                <span className="text-muted">
                                                  {item.company}
                                                  {", "} {item.jobLocation}
                                                </span>

                                                <span className="text-muted">
                                                  <Moment format="DD MMM YYYY">
                                                    {item.startingDate}
                                                  </Moment>

                                                  {" - "}

                                                  {item.isPresent === "N" ? (
                                                    <Moment format="DD MMM YYYY">
                                                      {item.endingDate}
                                                    </Moment>
                                                  ) : (
                                                    <span className="text-muted">
                                                      Present
                                                    </span>
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}

                                  {educationData &&
                                    educationData.length > 0 && (
                                      <div className="about_item_wrap border-bottom">
                                        <h6 className="about_title">
                                          Education
                                        </h6>

                                        {educationData.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="about_items"
                                            >
                                              <div className="icon bg-soft-info border-0">
                                                <FaGraduationCap
                                                  size={20}
                                                  color="#777d74"
                                                />
                                              </div>
                                              <div className="fcc">
                                                <h6>{item.degree}</h6>
                                                <span className="text-muted">
                                                  {item.institute}
                                                  {", "} {item.eduLocation}
                                                </span>
                                                {item.isPresent === "y" ? (
                                                  <span className="text-muted">
                                                    Pursuing
                                                  </span>
                                                ) : (
                                                  <span className="text-muted">
                                                    Passed on{" "}
                                                    <Moment format="DD MMM YYYY">
                                                      {item.passingDate}
                                                    </Moment>
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                </>
                              )}

                            {userPrivacy &&
                              (userPrivacy.social_profiles == "public" ||
                                (userPrivacy.social_profiles == "friends" &&
                                  isFriend)) && (
                                <>
                                  {userSocialLinks.facebook &&
                                    userSocialLinks.twitter && (
                                      <div className="about_item_wrap border-bottom">
                                        <h6 className="about_title">
                                          Social links
                                        </h6>

                                        <div className="d-flex">
                                          {userSocialLinks.facebook && (
                                            <div className="about_items">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>Facebook</Tooltip>
                                                }
                                              >
                                                <a
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  href={
                                                    userSocialLinks.facebook
                                                  }
                                                >
                                                  <div className="icon bg-soft-info border-0 me-2">
                                                    <FaFacebookF
                                                      size={20}
                                                      color="#777d74"
                                                    />
                                                  </div>
                                                </a>
                                              </OverlayTrigger>
                                            </div>
                                          )}

                                          {userSocialLinks.twitter && (
                                            <div className="about_items">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>Twitter</Tooltip>
                                                }
                                              >
                                                <a
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  href={userSocialLinks.twitter}
                                                >
                                                  <div className="icon bg-soft-info border-0 me-2">
                                                    <FaTwitter
                                                      size={20}
                                                      color="#777d74"
                                                    />
                                                  </div>
                                                </a>
                                              </OverlayTrigger>
                                            </div>
                                          )}

                                          {userSocialLinks.instagram && (
                                            <div className="about_items">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>Instagram</Tooltip>
                                                }
                                              >
                                                <a
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  href={
                                                    userSocialLinks.instagram
                                                  }
                                                >
                                                  <div className="icon bg-soft-info border-0 me-2">
                                                    <FaInstagram
                                                      size={20}
                                                      color="#777d74"
                                                    />
                                                  </div>
                                                </a>
                                              </OverlayTrigger>
                                            </div>
                                          )}

                                          {userSocialLinks.youtube && (
                                            <div className="about_items">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>Youtube</Tooltip>
                                                }
                                              >
                                                <a
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  href={userSocialLinks.youtube}
                                                >
                                                  <div className="icon bg-soft-info border-0 me-2">
                                                    <FaYoutube
                                                      size={20}
                                                      color="#777d74"
                                                    />
                                                  </div>
                                                </a>
                                              </OverlayTrigger>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {photosActive && (
                      <Col md={12}>
                        <Card>
                          <div className="d-flex py-2 border-bottom card-body lh-1">
                            <Link
                              to="#"
                              className="d-flex align-items-center"
                              onClick={() => setPhotosActive(!photosActive)}
                            >
                              <span>
                                <BiArrowBack size={20} />
                              </span>
                              <h5 className="fw-bold ms-2">Photos</h5>
                            </Link>
                          </div>

                          <Card.Body>
                            {/* <div className="d-grid gap-2 d-grid-template-1fr-13"> */}
                            <div className="d-flex gap-1 photo_video_card_wrap">
                              {media && media.length > 0 ? (
                                <>
                                  {media
                                    .slice(0, mediaLimit)
                                    .map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="photo_video_card"
                                        >
                                          {item.media_type === "image" && (
                                            <div className="user-images position-relative overflow-hidden">
                                              <SingleLightbox
                                                thumb={item.media_url}
                                                large={item.media_url}
                                                className="img-fluid rounded"
                                                onError={(e) => {
                                                  e.target.src = dummyBanner;
                                                }}
                                              />
                                            </div>
                                          )}

                                          {item.media_type === "video" && (
                                            <Link
                                              className="user-images position-relative overflow-hidden h-100"
                                              to="#"
                                              onClick={() => {
                                                setPreviewType(item.media_type);
                                                setPreviewURL(item.media_url);
                                                setVideoAudioPreview(true);
                                              }}
                                            >
                                              <img
                                                src={videoThumb}
                                                alt="gallary"
                                                className="img-fluid rounded h-100"
                                              />
                                            </Link>
                                          )}

                                          {item.media_type === "audio" && (
                                            <Link
                                              className="user-images position-relative overflow-hidden audio_thumb_box"
                                              to="#"
                                              onClick={() => {
                                                setPreviewType(item.media_type);
                                                setPreviewURL(item.media_url);
                                                setVideoAudioPreview(true);
                                              }}
                                            >
                                              <img
                                                src={audioThumb}
                                                alt="gallary"
                                                className="img-fluid rounded"
                                              />
                                            </Link>
                                          )}
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <h5 className="text-center">No photos</h5>
                              )}
                            </div>

                            {loadingMedia && (
                              <div className="d-flex justify-content-center align-items-center">
                                <img
                                  src={loader}
                                  alt="loader"
                                  style={{ height: "100px" }}
                                />
                              </div>
                            )}

                            {media.length > mediaLimit && (
                              <div className="mt-3 text-center">
                                <span
                                  className="text-primary fw-500 clickable"
                                  onClick={handleMoreMedia}
                                >
                                  Show more
                                </span>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {friendsActive && (
                      <Col md={12}>
                        <Card>
                          <div className="d-flex py-2 border-bottom card-body lh-1">
                            <Link
                              to="#"
                              className="d-flex align-items-center"
                              onClick={() => setFriendsActive(!friendsActive)}
                            >
                              <span>
                                <BiArrowBack size={20} />
                              </span>
                              <h5 className="fw-bold ms-2">Friends</h5>
                            </Link>
                          </div>

                          <Card.Body>
                            <Row>
                              {showFriends && showFriends.length > 0 ? (
                                <>
                                  {showFriends.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-sm-6 col-lg-6 mb-3"
                                      >
                                        <div className="iq-friendlist-block custom_friends p-1 p-sm-2 rounded">
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center friend_wrap w-100 me-2">
                                              <Link
                                                to={
                                                  userData &&
                                                  item.id === userData.id
                                                    ? "/dashboard/app/profile"
                                                    : `/dashboard/app/friend-profile/${item.id}`
                                                }
                                              >
                                                <img
                                                  src={
                                                    item && item.photo
                                                      ? item.photo
                                                      : noprofile
                                                  }
                                                  alt="profile-img"
                                                  className="img-fluid me-2"
                                                  onError={(e) => {
                                                    e.target.src = noprofile;
                                                  }}
                                                />
                                              </Link>
                                              <div className="friend-info">
                                                <h5 className="fw-bold">
                                                  {item.name}
                                                </h5>
                                                {/* <p className="mb-0 fw-500">{item.username}</p> */}
                                                {/* <p className="mb-0">15 friends</p> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <h5 className="text-center">No friends</h5>
                              )}
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="my-5">
          <div className="d-flex justify-content-center">
            <div className="avatar-60 bg-soft-danger d-flex justify-content-center align-items-center rounded-circle mb-2">
              <BiBlock size={30} className="mb-0 text-danger" />
            </div>
          </div>
          <h5 className="text-center">The page you requested was not found!</h5>
        </div>
      )}
    </>
  );
};

export default FriendProfile;
