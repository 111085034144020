import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import dummyUserImage from "../../../assets/images/no-profile-picture.jpg";
import CustomToggle from "../../../components/dropdowns";
import { Linkify } from "./Functions";
import LinkPreview from "./LinkPreview";
import SingleLightbox from "./SingleLightbox2";

function PublicProfilePostItemDetail({ item }) {
  return (
    <>
      <div className="post-item post_cards">
        <div className="user-post-data pb-2">
          <div className="d-flex justify-content-between">
            <NavLink to="/auth/sign-up" location={{}} className="me-2">
              <img
                className="rounded-circle avatar-50"
                src={
                  item.details && item.details.photo
                    ? item.details.photo
                    : dummyUserImage
                }
                alt=""
                onError={(e) => {
                  e.target.src = dummyUserImage;
                }}
              />
            </NavLink>

            <div className="w-100">
              <div className="d-flex justify-content-between align-items-start flex-nowrap h-100">
                <div className="d-flex justify-content-center h-100 flex-column">
                  {item.tagged_users === null || item.tagged_users === "0" ? (
                    <div className="h5 fw-bold mb-1">
                      {item.details && item.details.name}
                    </div>
                  ) : (
                    <div
                      className={`${
                        item.tagged_users.length > 0
                          ? "h5 mb-1 lh-1"
                          : "h5 mb-1 lh-1"
                      }`}
                    >
                      <strong>
                        {item.details && item.details.name + " "}{" "}
                      </strong>

                      {item.tagged_users.length > 0 ? (
                        <>
                          {" "}
                          is with{" "}
                          <span>
                            <NavLink
                              className="text-dark"
                              to="/auth/sign-up"
                              location={{}}
                            >
                              <strong>{item.tagged_users[0].name}</strong>
                            </NavLink>
                          </span>{" "}
                        </>
                      ) : (
                        ""
                      )}

                      {item.tagged_users.length > 1 ? (
                        <>
                          and
                          <span className="d-inline-block ms-1 tagged_user_drop">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id="post-option"
                              >
                                <strong className="text-dark">
                                  {item.tagged_users.length - 1 > 1
                                    ? `${item.tagged_users.length - 1} others`
                                    : "1 other"}
                                </strong>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {item.tagged_users.map((item, index) => {
                                  return (
                                    <Dropdown.Item
                                      className={
                                        index === 0 ? "d-none" : "d-block px-2"
                                      }
                                      key={item.id}
                                      to="/auth/sign-up"
                                      location={{}}
                                    >
                                      <div className="d-flex align-items-center">
                                        <img
                                          className="rounded-circle avatar-30"
                                          src={
                                            item.photo
                                              ? item.photo
                                              : dummyUserImage
                                          }
                                          alt=""
                                          onError={(e) => {
                                            e.target.src = dummyUserImage;
                                          }}
                                        />
                                        <h6 className="mb-0 ms-1 fw-500">
                                          {item.name}
                                        </h6>
                                      </div>
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  <p className="mb-2 lh-1">
                    <strong>
                      {item.universe_id && item.universe_id.universeName}
                    </strong>
                    {item.sub_universe_id ? (
                      <strong>
                        {", "} {item.sub_universe_id.universeName}
                      </strong>
                    ) : (
                      ""
                    )}{" "}
                    <span className="text-muted">
                      {item.createdAt ? `(${item.createdAt})` : ""}
                    </span>
                  </p>

                  <div className="d-flex align-items-center flex-wrap badge_btns">
                    {item && item.privacy && (
                      <span className="btn btn-light custom_badge me-2">
                        {item.privacy === "Public" ? (
                          <i className="ri-global-line h4"></i>
                        ) : (
                          <>
                            {item.privacy === "Multiverse" ? (
                              <i className="ri-settings-2-line h4"></i>
                            ) : (
                              <>
                                {item.privacy === "Universe" ? (
                                  <i className="ri-star-line h4"></i>
                                ) : (
                                  <>
                                    {item.privacy === "Friends" ? (
                                      <i className="ri-user-follow-line h4"></i>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {item.privacy}
                      </span>
                    )}

                    {item && item.multiverse_id && (
                      <span className="btn btn-light custom_badge me-2">
                        <i className="ri-settings-2-line h4"></i>{" "}
                        {item.multiverse_id.multiverseName}
                      </span>
                    )}

                    {item && item.sub_multiverse_id && (
                      <span className="btn btn-light custom_badge">
                        <i className="ri-flag-line h4"></i>{" "}
                        {item.sub_multiverse_id.multiverseName}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="text-black mb-3">
            {item.post_body && <Linkify>{item.post_body}</Linkify>}
          </div>

          {item.post_body &&
            (item.post_body.match(/\bhttps?::\/\/\S+/gi) ||
              item.post_body.match(/\bhttps?:\/\/\S+/gi)) && (
              <LinkPreview data={item.post_body} />
            )}
        </div>

        {item.post_type !== "text" && (
          <div className="user-post mt-2">
            {item.post_type === "image" && item.media_url !== null && (
              <SingleLightbox
                thumb={item.media_url}
                large={item.media_url}
                className="img-fluid w-100"
              />
            )}

            {item.post_type === "video" && item.media_url !== null && (
              <div className="video_players">
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={item.media_url}
                    width="100%"
                    height="100%"
                    controls={true}
                    playsinline
                  />
                </div>
              </div>
            )}

            {item.post_type === "audio" && item.media_url !== null && (
              <div className="d-flex">
                <ReactAudioPlayer
                  src={item.media_url}
                  controls
                  className="w-100"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default PublicProfilePostItemDetail;
