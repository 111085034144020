import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

const VideoPlayerPreview = ({ previewType, previewURL, videoAudioPreview, setVideoAudioPreview }) => {

  return (
    <>
      <Modal
        // size={`${(selectMultiverseModal || tagFriendsModal) ? "md" : "md"}`}
        className="fade"
        id="post-modal"
        show={videoAudioPreview}
        dialogClassName="modal-dialog-centered"
      >
        <div>
          <div className='d-flex justify-content-between pt-2 pe-3'>
            <span></span>
            <Link
                to="#"
                className="lh-1 close_btn bg-light"
                onClick={() => setVideoAudioPreview(false)}
              >
                <i className="ri-close-fill Wri-xl"></i>
            </Link>
          </div>
          <Modal.Body>
            {previewType === "video" && previewURL !== null && (
              <div className="video_players">
                  <div className='player-wrapper'>
                      <ReactPlayer
                          className='react-player'
                          url={previewURL}
                          width='100%'
                          height='100%'
                          controls={true}
                          playsinline
                      />
                  </div>
              </div>
            )}

            {previewType === "audio" && previewURL !== null && (
                <div className="d-flex">
                    <ReactAudioPlayer
                        src={previewURL}
                        controls
                        className="w-100"
                    />
                </div>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default VideoPlayerPreview;
