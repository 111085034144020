import { ErrorMessage, useFormikContext } from "formik";
import React, { useRef, useState } from "react";
const FormikFilePreview = ({ name, handleToggle }) => {
  const [media, setMedia] = useState(null);
  const fileInputRef = useRef(null);
  const { setFieldValue } = useFormikContext();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setMedia(reader.result);
    };

    if (file) {
      setFieldValue(name, file);
      if (file.type.startsWith("image")) {
        reader.readAsDataURL(file);
      }
      //    else if (file.type.startsWith("video")) {
      //     reader.readAsArrayBuffer(file);
      //   }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setMedia(reader.result);
    };

    if (file) {
      setFieldValue(name, file);
      if (file.type.startsWith("image")) {
        reader.readAsDataURL(file);
      }

      //   else if (file.type.startsWith("video")) {
      //     reader.readAsArrayBuffer(file);
      //   }
    }
  };

  const resetMedia = () => {
    setMedia(null);
    setFieldValue(name, null);
    handleToggle();
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div style={{ textAlign: "center", padding: "5px", marginBottom: "5px" }}>
        <div
          style={{
            borderRadius: "10px",
            cursor: "pointer",
            position: "relative",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            background: "#f7f8fa",
          }}
          onDrop={handleDrop}
          onDragOver={preventDefault}
        >
          {media && (
            <div
              onClick={resetMedia}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                zIndex: 99,
              }}
            >
              <div className="lh-1 close_btn border right_10 close_sm bg-light border">
                <i className="ri-close-fill ri-xl"></i>
              </div>
            </div>
          )}
          {media &&
          typeof media === "string" &&
          media.startsWith("data:image") ? (
            <img
              src={media}
              alt="Uploaded"
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          ) : media && media instanceof ArrayBuffer ? (
            <video
              key={media}
              controls
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            >
              <source
                src={URL.createObjectURL(
                  new Blob([media], { type: "video/mp4" })
                )}
                type="video/mp4"
              />
            </video>
          ) : (
            <div
              onClick={() => fileInputRef.current.click()}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="upload-story-icon border"
                  style={{ background: "#ddd" }}
                >
                  <i className="fas fa-image"></i>
                </div>
                <h5 className="fw-bold">Add Photo</h5>
                {/* <h5 className="mt-2 fw-bold">Add Photo or Video</h5> */}
                <span className="p-0 m-0">or drag and drop</span>
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          //   accept="image/*,video/*"
          style={{ display: "none" }}
          onChange={handleFileUpload}
          ref={fileInputRef}
        />
      </div>
      <ErrorMessage
        name={name}
        component="div"
        className="text-danger"
        style={{ marginTop: "-8px" }}
      />
    </div>
  );
};

const FormikFile = ({ name }) => {
  const [toggleComponent, setToggleComponent] = useState(true);
  const handleToggle = () => {
    setToggleComponent(false);
    setTimeout(() => {
      setToggleComponent(true);
    }, 5);
  };
  return (
    <>
      {toggleComponent ? (
        <FormikFilePreview name={name} handleToggle={handleToggle} />
      ) : (
        <div
          style={{ textAlign: "center", padding: "5px", marginBottom: "5px" }}
        >
          <div
            style={{
              borderRadius: "10px",
              cursor: "pointer",
              position: "relative",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              background: "#f7f8fa",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="upload-story-icon border"
                  style={{ background: "#ddd" }}
                >
                  <i className="fas fa-image"></i>
                </div>
                <h5 className="fw-bold">Add Photo</h5>
                {/* <h5 className="mt-2 fw-bold">Add Photo or Video</h5> */}
                <span className="p-0 m-0">or drag and drop</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormikFile;
