import React, { useEffect, useState } from 'react'
import {Row,Col, Container, Image} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import loader from '../../../assets/images/page-img/loader_sq.gif'

import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
//Sweet alert
import Swal from 'sweetalert2'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { servicePath } from './variables'

const FriendRequests = () => {
   const userData = JSON.parse(localStorage.getItem("users"))
   
   const token = "Bearer" + " " + localStorage.getItem("access_token")

   useEffect(() => {
      if(userData !== null){
         getFriendRequests()
         getPeopleList()
         setLoadSearchPeople(true)
      }
   }, [])

   const [message, setMessage] = useState("")
   const [searchString, setSearchString] = useState('')
   const [searchCount, setSearchCount] = useState('')
   const [showSearch, setShowSearch] = useState('')
   const [showMessage, setShowMessage] = useState(false)
   const [requestList, setRequestList] = useState([])
   const [loadPeople, setLoadPeople] = useState(false)
   const [loadSearchPeople, setLoadSearchPeople] = useState(false)
   const [peopleList, setPeopleList] = useState({
      current_page: '', 
      data : [],
      first_page_url: '',
      form: '',
      last_page: '',
      last_page_url: '',
      links : [],
      next_page_url: '',
      path: '',
      per_page : '', 
      prev_page_url : '', 
      to : '',
      total : '',
   })

   const getFriendRequests = () => {
      var myHeaders = new Headers();

      myHeaders.append(
            "Authorization",
            token
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "data": {
            "mode": "received"
         }
      });

      var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
      };

      fetch(
         `${servicePath}/friend.requests`,
         requestOptions
      )
         .then((response) => response.json())
         .then((result) => {

            // console.log(result, "friend request lists")
            if (result && result.data) {
               setRequestList(result.data)
            }
         })
         .catch((error) => console.log("error", error));
   };

   const getPeopleList = (peopleLimit) => {
      var myHeaders = new Headers();
      myHeaders.append(
            "Authorization",
            token
      );
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
         "limit": "20",
         "page": peopleLimit
      });
      
      var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
      };

      fetch(
         `${servicePath}/minded.pepole`,
         requestOptions
      )
      .then((response) => response.json())
      .then((result) => {
            // console.log(result, "minded pepole list")
            if (result) {

               if(result && result.data && result.data.length > 0) {
                  setPeopleList(preVal => ({
                     ...preVal,
                     current_page: result.current_page,
                     data: result.data,
                     first_page_url: result.first_page_url,
                     form: result.from,
                     last_page: result.last_page,
                     last_page_url: result.last_page_url,
                     links: result.links,
                     next_page_url: result.next_page_url,
                     path: result.path,
                     per_page: result.per_page,
                     prev_page_url: result.prev_page_url,
                     to: result.to,
                     total: result.total,
                  }))
               } else {
                  setPeopleList(preVal => ({
                     ...preVal,
                     current_page: result.current_page,
                     data: [],
                     first_page_url: result.first_page_url,
                     form: result.from,
                     last_page: result.last_page,
                     last_page_url: result.last_page_url,
                     links: result.links,
                     next_page_url: result.next_page_url,
                     path: result.path,
                     per_page: result.per_page,
                     prev_page_url: result.prev_page_url,
                     to: result.to,
                     total: result.total,
                  }))
               }

            setSearchCount(result.total);
            setShowSearch(result.total);

            setLoadPeople(false);
            setLoadSearchPeople(false)
         }
      })
      .catch((error) => console.log("error", error));
   };

   const getPeopleSearch = (peopleLimit, searchData) => {
      var myHeaders = new Headers();

      myHeaders.append(
            "Authorization",
            token
      );
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
         "limit": "10",
         "page": peopleLimit,
         "search": searchData
      });
      
      var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
      };

      fetch(
         `${servicePath}/minded.pepole`,
         requestOptions
      )
      .then((response) => response.json())
      .then((result) => {
            // console.log(result, "pepole search list")

               if(result && result.data && result.data.length > 0) {
                  setPeopleList(preVal => ({
                     ...preVal,
                     current_page: result.current_page,
                     data: result.data,
                     first_page_url: result.first_page_url,
                     form: result.from,
                     last_page: result.last_page,
                     last_page_url: result.last_page_url,
                     links: result.links,
                     next_page_url: result.next_page_url,
                     path: result.path,
                     per_page: result.per_page,
                     prev_page_url: result.prev_page_url,
                     to: result.to,
                     total: result.total,
                  }))
               } else {
                  setPeopleList(preVal => ({
                     ...preVal,
                     current_page: result.current_page,
                     data: [],
                     first_page_url: result.first_page_url,
                     form: result.from,
                     last_page: result.last_page,
                     last_page_url: result.last_page_url,
                     links: result.links,
                     next_page_url: result.next_page_url,
                     path: result.path,
                     per_page: result.per_page,
                     prev_page_url: result.prev_page_url,
                     to: result.to,
                     total: result.total,
                  }))
               }

            setSearchCount(result.total);

            setLoadSearchPeople(false);
            setLoadPeople(false);
      })
      .catch((error) => console.log("error", error));
   };

   const handleConfirmRequest = (requestId) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "data": {
            "request_id": requestId,
            "status": "accepted"
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(`${servicePath}/friend.update`, requestOptions)
         .then(response => response.json())
         .then(result => {
            // console.log(result)

         if(result.success === "Request was accepted"){
            setMessage("Request confirmed")
            setShowMessage(true)

            getFriendRequests()
            
            setTimeout(() =>{
                  setShowMessage(false)
            }, 2000)
         }
      })
      .catch(error => console.log('error', error));
   };

   const handleRejectRequest = (requestId) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "data": {
            "request_id": requestId,
            "status": "denied"
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(`${servicePath}/friend.update`, requestOptions)
         .then(response => response.json())
         .then(result => {
            // console.log(result)
            if (result.success === "Request was denied") {
               setMessage("Request rejected")
               setShowMessage(true)

            getFriendRequests()
            
            setTimeout(() =>{
                  setShowMessage(false)
            }, 2000)
         }
      })
      .catch(error => console.log('error', error));
   };

   const handleAddFriend = (friendId) => {

      setLoadPeople(true);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "data": {
            "friend_user_id": friendId
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(`${servicePath}/friend.send`, requestOptions)
         .then(response => response.json())
         .then(result => {
            // console.log(result)
            if (result.success === "Request sent") {
               setMessage("Request sent successfully")
               setShowMessage(true)
               
               // console.log(searchString, "searchString")
               if(searchString != '') {
                  getPeopleSearch("1", searchString);
               } else {
                  getPeopleList()
               }

               setLoadPeople(true);

               setTimeout(() => {
                  setShowMessage(false)
            }, 2000)
         }
      })
      .catch(error => console.log('error', error));
   };

   const questionAlert = (requestId) => {
      const swalWithBootstrapButtons = Swal.mixin({
         customClass: {
            cancelButton: 'btn btn-outline-primary btn-lg ms-2',
            confirmButton: 'btn btn-primary btn-lg',
         },
         buttonsStyling: false
         })
         
         swalWithBootstrapButtons.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         cancelButtonText: 'cancel',
         confirmButtonText: 'Yes, delete it!',
         
         reverseButtons: false,
         showClass: {
            popup: 'animate__animated animate__zoomIn'
      },
      hideClass: {
            popup: 'animate__animated animate__zoomOut'
      }
      
      }).then((result) => {
      if (result.isConfirmed) {
         handleRejectRequest(requestId)

         swalWithBootstrapButtons.fire({
            title: 'Deleted!',
            text: 'Your Request has been deleted.',
            icon: 'success',
            showClass: {
            popup: 'animate__animated animate__zoomIn'
            },
            hideClass: {
            popup: 'animate__animated animate__zoomOut'
            }
         })
      }})
   }

   const [searchText, setSearchText] = useState("");
   // state for keeping the timeout
   const [searchTextTimeout, setSearchTextTimeout] = useState(null);

   const handleUserSearch = (searchValue) => {
      setLoadSearchPeople(true);

      if(searchValue !== '') {
         var currentLimit;
         getPeopleSearch(currentLimit, searchValue);
         setSearchString(searchValue);

         // console.log(searchValue, "search");
      } else {
         getPeopleList()
         setSearchString('');
      }
   };

   const handleOnChange = (e) => {
      if (searchTextTimeout) {
         clearTimeout(searchTextTimeout);
      }

      setSearchText(e.target.value);

      setSearchTextTimeout(
         setTimeout(() => {
            handleUserSearch(e.target.value);
         }, 500),
      );
   };

   // making sure that we clear the timeout if/when the component unmount
   useEffect(() => {
      return () => clearTimeout(searchTextTimeout);
   }, [searchTextTimeout]);

   const LoadMorePeople = (currentLimit) => {
      if(searchString !== ''){
         // console.log(searchString, "searchString")
         getPeopleSearch(currentLimit + 1, searchString)
      } else {
         getPeopleList(currentLimit + 1);
      }
      setLoadPeople(true);
   };

   const LoadLessPeople = (currentLimit) => {
      if(searchString !== ''){
         // console.log(searchString, "searchString")
         getPeopleSearch(currentLimit - 1, searchString)
      } else {
         getPeopleList(currentLimit - 1);
      }
      setLoadPeople(true);
   };

   return(
      <>
         <Container className='position-relative'>
            {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
            <Row>
               <Col sm="12">
                  <Card className="friend_list_card mb-4">
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Friend Request</h4>
                        </div>
                        <span className='badge bg-soft-primary d-flex align-items-center fs-5'>
                           {requestList && requestList.counters && requestList.counters.pending || 0}
                        </span>
                     </Card.Header>
                     <Card.Body className="card-body px-2 px-sm-3 pb-0">
                        <ul className="request-list list-inline m-0 p-0">
                           {requestList && requestList.requests && requestList.requests.length > 0 ? (
                              <>
                                 {requestList.requests.map((item) => {
                                    return(
                                       <li key={item.id} className="d-flex align-items-center  justify-content-between flex-wrap">
                                          <Link to={`/dashboard/app/friend-profile/${item.params.id}`} className="w-100 d-flex align-items-center user_rqst_details rqst_list">
                                             <div className='img-fluid'>
                                                <Image
                                                   className="avatar-50 rounded-circle"
                                                   src={item.params.photo || dummyUserImage}
                                                   alt=""
                                                   onError={(e) => {
                                                      e.target.src = dummyUserImage
                                                   }}
                                                />
                                             </div>

                                             <div className="ms-2 w-100 user_detail">
                                                <h6 className="mb-0 text-ellipsis-1 fw-500">{item.params.name}</h6>
                                                {/* <p className="mb-0">40 friends</p> */}
                                                {/* <p className="mb-0 text-muted text-ellipsis-1">{item.params.username}</p> */}
                                             </div>
                                          </Link>
                                          <div className="d-flex align-items-center">
                                             <div className="confirm-click-btn">
                                                <Link to="#" className="me-2 btn btn-primary btn-sm rounded confirm-btn" onClick={() => { handleConfirmRequest(item.id) }}>Confirm</Link>
                                                <Link to="#" className="btn btn-primary rounded btn-sm request-btn" style={{ display: "none" }}>View All</Link>
                                             </div>
                                             <Link to="#" onClick={(e) => {questionAlert(item.id)}} className="btn btn-outline-danger btn-icon" data-extra-toggle="delete" data-closest-elem=".item">
                                                <i className="ri-delete-bin-line"></i>
                                             </Link>                                    
                                          </div>
                                       </li>
                                    )
                                 })}
                              </>
                           ) : (
                              <h5 className='text-center mb-3'>No friend request</h5>
                           )}
                           
                        </ul>
                     </Card.Body>
                  </Card>
                  
                  <Card className="friend_list_card">
                     <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div className="header-title">
                           <h4 className="card-title">People You May Know</h4>
                        </div>

                        <Link to="/dashboard/app/sent-request" className="btn-link h5 text-nowrap">
                           Sent Requests
                        </Link>
                     </Card.Header>
                     <Card.Body className="card-body px-2 px-sm-3 pb-0">
                        
                        {showSearch && showSearch > 10 && <div className='d-flex justify-content-end mb-2'>
                           <input type="text" className="form-control max-w-250" onChange={handleOnChange} placeholder="Search people"/>
                        </div>}
                        
                        {loadSearchPeople ? (
                           <div className='d-flex justify-content-center my-3 w-100'>
                              <img src={loader} alt="loader" style={{ height: "40px" }} />
                           </div>
                        ) : (
                           <ul className="request-list list-inline m-0 p-0">
                              {peopleList && peopleList.total > 0 ? (
                                 <>
                                    {loadPeople && (
                                       <div className="main_loader">
                                          <img src={loader} alt="loader" style={{ height: "40px" }} />
                                       </div>
                                    )}

                                    {peopleList.data.map((item) => {
                                       return(
                                          <li key={item.id} className="d-flex align-items-center  justify-content-between flex-wrap">
                                             <Link to={`/dashboard/app/friend-profile/${item.id}`} className="w-100 d-flex align-items-center user_rqst_details people_list">
                                                   <div className='img-fluid'>
                                                      <Image 
                                                         className="avatar-50 rounded-circle" 
                                                         src={item.photo || dummyUserImage} 
                                                         alt=""
                                                         onError={(e) => {
                                                            e.target.src = dummyUserImage
                                                         }}
                                                      />
                                                   </div>
                                                   
                                                   <div className="ms-2 w-100 user_detail">
                                                      <h6 className="mb-0 text-ellipsis-1 fw-500">{item.name}</h6>
                                                      {/* <p className="mb-0">40 friends</p> */}
                                                      {/* <p className="mb-0 text-muted text-ellipsis-1">{item.username}</p> */}
                                                   </div>
                                             </Link>

                                             <div className="d-flex align-items-center">
                                                <Link to="#" className="btn btn-primary rounded btn-sm" onClick={() => { handleAddFriend(item.id) }}>
                                                   <i className="ri-user-add-line me-1"></i>
                                                   Add Friend
                                                </Link>                                  
                                             </div>
                                          </li>
                                       )
                                    })}
                                 </>
                              ) : (
                                 <h5 className='text-center mb-3'> {searchCount === 0 && searchString !== '' ? 'No result found!' : 'No users to show'}</h5>
                              )}
                           </ul>
                        )}
                     </Card.Body>
                  </Card>

                  {peopleList.total > 20 && ( 
                     <div className="w-100 d-flex justify-content-between mb-4">
                        {peopleList.current_page > 1 ? (
                           <button type="button" className="btn btn-primary" onClick={() => LoadLessPeople(peopleList.current_page)}>
                              <BsArrowLeft size={16} className="me-1" /> Show prev people
                           </button>
                        ) : (
                              <div></div>
                        )}

                        {peopleList.total !== peopleList.to && (
                           <button type="button" className="btn btn-primary" onClick={() => LoadMorePeople(peopleList.current_page)}>
                              Show next people <BsArrowRight size={16} className="ms-1" />
                           </button>
                        )}
                     </div>
                  )}
               </Col>
            </Row>
         </Container>
      </>
   )
}

export default FriendRequests