import React, { useEffect, useState } from 'react'
import {Row,Col,Container} from 'react-bootstrap'
import Card from '../../../components/Card'
import SavedPostItem from '../components/SavedPostItem'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { servicePath } from './variables'

const SavedPosts =()=>{

  const userData = JSON.parse(localStorage.getItem("users"))
  const token = "Bearer" +" "+ localStorage.getItem("access_token")

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [savedPosts, setSavedPosts] = useState({
    current_page: '', 
    data : [],
    first_page_url: '',
    form: '',
    last_page: '',
    last_page_url: '',
    links : [],
    next_page_url: '',
    path: '',
    per_page : '', 
    prev_page_url : '', 
    to : '',
    total : '',
  })

  const LoadMorePost = (currentLimit) => {
    getSavedPost(currentLimit + 10);
  };

    useEffect(() => {
      if(userData !== null){
        getSavedPost();
      }
    }, [])

    const getSavedPost = (postLimit) => {
      setLoading(true)

      var myHeaders = new Headers();
      myHeaders.append(
          "Authorization",
          token
      );
      
      var raw = JSON.stringify({
          "limit": "10",
          "page": postLimit
      });

      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
      };

      fetch(`${servicePath}/post.save_list?page=${postLimit}`,
      requestOptions
      )
      .then((response) => response.json())
      .then((result) =>{

        // console.log(result, "saved post result")

        if(result && result.data && result.data.length > 0) {
          setSavedPosts(preVal => ({
              current_page: result.current_page, 
              data : result.data,
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links : result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page : result.per_page, 
              prev_page_url : result.prev_page_url, 
              to : result.to,
              total : result.total,
          }))
        } else {
          setSavedPosts(preVal => ({
            ...preVal,
              current_page: result.current_page, 
              data : [],
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links : result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page : result.per_page, 
              prev_page_url : result.prev_page_url, 
              to : result.to,
              total : result.total,
          }))
        }

        if(result) {
            setLoading(false)
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false))
  };

  const LoadNextPost = (currentLimit) => {
    getSavedPost(currentLimit + 1);

    setCurrentPage(currentLimit + 1);
  };

  const LoadPrevPost = (currentLimit) => {
    getSavedPost(currentLimit - 1);

    setCurrentPage(currentLimit + 1);
  };

    return(
        <>
            <Container className='position-relative'>
              {loading && (<div className="main_loader">
                <img src={loader} alt="loader" style={{ height: "100px" }} />
              </div>)}

              {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
              
                {savedPosts && savedPosts.total > 0 ? (
                  <Row>
                    <Col md={12}>
                      <h4 className='mb-3'>Saved posts</h4>
                    </Col>
                    <Col md={6}>
                        {savedPosts.data.map((item, index) => {
                            return(
                              <div key={item.id}>
                                {item && index%2 === 0 ? (
                                  <div className="mb-3">
                                    <Card className="card-block card-stretch card-height post_cards h-auto">
                                        <Card.Body>
                                        <SavedPostItem token={token} setLoading={setLoading} setMessage={setMessage} setShowMessage={setShowMessage} item={item} componentOf="saved" getSavedPost={getSavedPost} currentPage={currentPage} />
                                        </Card.Body>
                                    </Card>
                                  </div>
                                ) : ''}
                              </div>
                            )
                        })}
                    </Col>
                    
                    <Col md={6}>
                      {savedPosts.data.map((item, index) => {
                          return(
                            <div key={item.id}>
                              {item && index%2 != 0 ? (
                                <div className="mb-3">
                                  <Card className="card-block card-stretch card-height post_cards h-auto">
                                      <Card.Body>
                                        <SavedPostItem token={token} setLoading={setLoading} setMessage={setMessage} setShowMessage={setShowMessage} item={item} componentOf="saved" getSavedPost={getSavedPost} currentPage={currentPage} />
                                      </Card.Body>
                                  </Card>
                                </div>
                              ) : ''}
                            </div>
                          )
                      })}
                    </Col>

                    {/* <Col xs={12}>
                      {savedPosts.total != savedPosts.to ? (  
                        <div className="d-flex justify-content-center mb-4">
                          <button type="button" className="btn btn-primary" onClick={() => LoadMorePost(savedPosts.per_page)}>
                            Show more
                          </button>
                        </div>
                      ):('')}
                    </Col> */}
                  </Row>
                ) : (
                  <div className='d-flex justify-content-center align-items-center h-100 no_content'>
                    <h4 className='text-muted'>No saved posts</h4>
                  </div>
                )}

                {savedPosts.total > 10 && (
                  <div className="d-flex justify-content-between mb-4">
                      {savedPosts.current_page > 1 ? (
                          <button type="button" className="btn btn-primary" onClick={() => LoadPrevPost(savedPosts.current_page)}>
                              <BsArrowLeft size={16} className="me-1" /> Prev post
                          </button>
                      ) : (
                          <div></div>
                      )}
                      {savedPosts.total !== savedPosts.to && (
                          <button type="button" className="btn btn-primary" onClick={() => LoadNextPost(savedPosts.current_page)}>
                              Next post <BsArrowRight size={16} className="ms-1" />
                          </button>
                      )}
                  </div>
                )}
            </Container>
        </>
    )
}

export default SavedPosts