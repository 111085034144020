import React from "react";
import Header from "../../components/partials/dashboard/headerStyle/header";
import SuggestedFriend from "../dashboard/app/suggestedFriend";

const Playground = () => {
  return (
    <div>
      {/* <ImageUploadComponent /> */}
      <Header />
      <SuggestedFriend />
    </div>
  );
};

export default Playground;
