import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { DEFAULT_AVATAR } from "../../constant/variables";
import axiosInstance from "../../helper/axiosInstance";
import calculateTimeAgo from "../../helper/calculateTimeAgo";
import useLocalStorage from "../useLocalStorage";
import useQueryParams from "../useQueryParams";
export const INVALIDATE_STORY_TYPES = "UNIVERSE_TYPE_STORIES";

const useUniverseTypeStories = () => {
  const { universeId, universeType } = useQueryParams();
  const [userInfo] = useLocalStorage("users", {});
  const [pageNumber, setPageNumber] = useState(1);
  const universe_id = universeId;
  const { data, isLoading, error } = useQuery(
    [
      INVALIDATE_STORY_TYPES,
      pageNumber,
      universe_id,
      userInfo.id,
      universeType,
    ],
    async ({ queryKey }) => {
      const [, pageNumber, universe_id, user_id, type] = queryKey;
      const { data } = await axiosInstance({
        url: "/app/api/v1/action/stories/list",
        method: "post",
        data: {
          story_type: type,
          user_id,
          universe_id: universe_id ?? "",
          // limit: 1,
        },
        params: {
          page: pageNumber,
        },
      });
      return data?.data ?? {};
    },
    {
      enabled: !!universeType,
      retry: false,
    }
  );

  const userStory = data?.data.reduce((output, item) => {
    item?.story_images?.forEach((story) => {
      output.push({
        type: story?.media_type,
        url: story?.media_url,
        header: {
          heading: item?.users?.name,
          profileImage: item?.users?.photo ?? DEFAULT_AVATAR,
          subheading: calculateTimeAgo(item?.created_at),
        },
      });
    });
    return output;
  }, []);

  const stories = userStory ? userStory?.filter((i) => i.url) : [];

  const hasStories = stories.length > 0;
  const handlePrevPageClick = () => {
    if (data?.prev_page_url) {
      setPageNumber((prev) => prev - 1);
    }
  };

  const handleNextPageClick = () => {
    if (data?.next_page_url) {
      setPageNumber((prev) => prev + 1);
    }
  };
  const hasPrevious = !data?.prev_page_url;
  const hasNext = !data?.next_page_url;
  return {
    data,
    stories,
    hasStories,
    isLoading,
    hasPrevious,
    hasNext,
    handlePrevPageClick,
    handleNextPageClick,
    error,
  };
};

export default useUniverseTypeStories;
