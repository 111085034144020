import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dummyUserImage from "../../../assets/images/no-profile-picture.jpg";
import loader from "../../../assets/images/page-img/loader_sq.gif";

//Sweet alert
import { Image } from "react-bootstrap";
import { servicePath } from "./variables";

const SuggestedFriend = () => {
  const userData = JSON.parse(localStorage.getItem("users"));

  const token = "Bearer" + " " + localStorage.getItem("access_token");

  useEffect(() => {
    if (userData !== null) {
      getFriendRequests();
      getPeopleList();
      setLoadSearchPeople(true);
    }
  }, []);

  const [message, setMessage] = useState("");
  const [searchString, setSearchString] = useState("");
  const [searchCount, setSearchCount] = useState("");
  const [showSearch, setShowSearch] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [loadPeople, setLoadPeople] = useState(false);
  const [loadSearchPeople, setLoadSearchPeople] = useState(false);
  const [peopleList, setPeopleList] = useState({
    current_page: "",
    data: [],
    first_page_url: "",
    form: "",
    last_page: "",
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  });

  const getFriendRequests = () => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        mode: "received",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.requests`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "friend request lists")
        if (result && result.data) {
          setRequestList(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getPeopleList = (peopleLimit) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      limit: "20",
      page: peopleLimit,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/minded.pepole`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "minded pepole list")
        if (result) {
          if (result && result.data && result.data.length > 0) {
            setPeopleList((preVal) => ({
              ...preVal,
              current_page: result.current_page,
              data: result.data,
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links: result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page: result.per_page,
              prev_page_url: result.prev_page_url,
              to: result.to,
              total: result.total,
            }));
          } else {
            setPeopleList((preVal) => ({
              ...preVal,
              current_page: result.current_page,
              data: [],
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links: result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page: result.per_page,
              prev_page_url: result.prev_page_url,
              to: result.to,
              total: result.total,
            }));
          }

          setSearchCount(result.total);
          setShowSearch(result.total);

          setLoadPeople(false);
          setLoadSearchPeople(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getPeopleSearch = (peopleLimit, searchData) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      limit: "10",
      page: peopleLimit,
      search: searchData,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/minded.pepole`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "pepole search list")

        if (result && result.data && result.data.length > 0) {
          setPeopleList((preVal) => ({
            ...preVal,
            current_page: result.current_page,
            data: result.data,
            first_page_url: result.first_page_url,
            form: result.from,
            last_page: result.last_page,
            last_page_url: result.last_page_url,
            links: result.links,
            next_page_url: result.next_page_url,
            path: result.path,
            per_page: result.per_page,
            prev_page_url: result.prev_page_url,
            to: result.to,
            total: result.total,
          }));
        } else {
          setPeopleList((preVal) => ({
            ...preVal,
            current_page: result.current_page,
            data: [],
            first_page_url: result.first_page_url,
            form: result.from,
            last_page: result.last_page,
            last_page_url: result.last_page_url,
            links: result.links,
            next_page_url: result.next_page_url,
            path: result.path,
            per_page: result.per_page,
            prev_page_url: result.prev_page_url,
            to: result.to,
            total: result.total,
          }));
        }

        setSearchCount(result.total);

        setLoadSearchPeople(false);
        setLoadPeople(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleAddFriend = (friendId) => {
    setLoadPeople(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        friend_user_id: friendId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.send`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "Request sent") {
          setMessage("Request sent successfully");
          setShowMessage(true);

          // console.log(searchString, "searchString")
          if (searchString != "") {
            getPeopleSearch("1", searchString);
          } else {
            getPeopleList();
          }

          setLoadPeople(true);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {showMessage && message ? (
        <div className="alert-message">{message}</div>
      ) : (
        <></>
      )}

      <div className="friend_list_card px-3 py-2">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="header-title">
            <h6 className="card-title fw-500">Suggested for you</h6>
          </div>

          <Link
            to="/dashboard/app/friend-request"
            className="btn-link h6 text-nowrap"
          >
            See all
          </Link>
        </div>
        <div>
          {loadSearchPeople ? (
            <div className="d-flex justify-content-center my-3 w-100">
              <img src={loader} alt="loader" style={{ height: "40px" }} />
            </div>
          ) : (
            <ul className="request-list list-inline m-0 p-0 pt-2">
              {peopleList && peopleList.total > 0 ? (
                <>
                  {loadPeople && (
                    <div className="main_loader">
                      <img
                        src={loader}
                        alt="loader"
                        style={{ height: "40px" }}
                      />
                    </div>
                  )}

                  {peopleList.data.map((item) => {
                    return (
                      <li key={item.id}>
                        <div>
                          <div className="d-flex align-items-center">
                            <Link
                              to={`/dashboard/app/friend-profile/${item.id}`}
                            >
                              <div className="img-fluid">
                                <Image
                                  className="avatar-50 rounded-circle"
                                  src={item.photo || dummyUserImage}
                                  alt=""
                                  onError={(e) => {
                                    e.target.src = dummyUserImage;
                                  }}
                                />
                              </div>
                            </Link>
                            <div className="ms-2">
                              <Link
                                to={`/dashboard/app/friend-profile/${item.id}`}
                              >
                                <h6 className="mb-0  fw-500">{item.name}</h6>
                              </Link>
                              <Link
                                style={{ fontSize: "12px" }}
                                to={"#"}
                                className="fw-500"
                                onClick={() => {
                                  handleAddFriend(item.id);
                                }}
                              >
                                <i className="ri-user-add-line me-1"></i>
                                Add Friend
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* <Link
                          to={`/dashboard/app/friend-profile/${item.id}`}
                          className="w-100 d-flex align-items-center user_rqst_details people_list"
                        >
                          <div className="img-fluid">
                            <Image
                              className="avatar-50 rounded-circle"
                              src={item.photo || dummyUserImage}
                              alt=""
                              onError={(e) => {
                                e.target.src = dummyUserImage;
                              }}
                            />
                          </div>

                          <div className="ms-2 w-100 user_detail">
                            <h6 className="mb-0  fw-500">{item.name}</h6>
                          </div>
                        </Link>

                        <div className="d-flex align-items-center">
                          <Link
                            to="#"
                            className="btn btn-primary rounded btn-sm"
                            onClick={() => {
                              handleAddFriend(item.id);
                            }}
                          >
                            <i className="ri-user-add-line me-1"></i>
                            Add Friend
                          </Link>
                        </div> */}
                      </li>
                    );
                  })}
                </>
              ) : (
                <h5 className="text-center mb-3">
                  {" "}
                  {searchCount === 0 && searchString !== ""
                    ? "No result found!"
                    : "No users to show"}
                </h5>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default SuggestedFriend;
