import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip,
} from "react-bootstrap";
import { BsArrowLeft, BsArrowRight, BsPen, BsTrash } from "react-icons/bs";
import {
  FaBriefcase,
  FaFacebookF,
  FaGraduationCap,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import "../../../views/index.css";
import AddAndEditWorkPlaceModal from "../components/AddAndEditWorkPlaceModal";
import "./user-profile-edit.css";
// images
import Moment from "react-moment";
import NumericLabel from "react-pretty-numbers";
import audioThumb from "../../../assets/images/audio_thumb.png";
import noprofile from "../../../assets/images/no-profile-picture.jpg";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import user01 from "../../../assets/images/user/01.jpg";
import user02 from "../../../assets/images/user/02.jpg";
import user03 from "../../../assets/images/user/03.jpg";
import videoThumb from "../../../assets/images/video_thumb.png";
import AddAndEditEducationModal from "../components/AddEditEductionModal";
import ProfileFollowersCard from "../components/ProfileFollowersCard";
import ProfileFollowingCard from "../components/ProfileFollowingCard";
import ProfileFriendsCard from "../components/ProfileFriendsCard";
import ProfileHeader from "../components/ProfileHeader";
import ProfilePostItem from "../components/ProfilePostItem";
import SingleLightbox from "../components/SingleLightbox";
import VideoPlayerPreview from "../components/VideoPlayerPreview";
import { responsiveUniversesProfile, servicePath } from "./variables";

const userData = JSON.parse(localStorage.getItem("users"));
const token = "Bearer" + " " + localStorage.getItem("access_token");

const UserProfile = () => {
  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    if (userData !== null) {
      console.log(userData, "not null data");
      userState();
      getUserMedia();
      userPost();
      getFollowersList();
      getFollowingList();
      getFriendList("");
    } else {
      console.log(userData, "null data");
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [mediaLimit, setMediaLimit] = useState(20);

  const [profileTab, setProfileTab] = useState("first");
  const [postLoading, setPostLoading] = useState(false);
  const [friendsLoading, setFriendsLoading] = useState(false);
  const [userAccountD, setUserAccountD] = useState([]);
  const [userSocialLinks, setUserSocialLinks] = useState([]);
  const [showUni, setShowUni] = useState([]);
  const [message, setMessage] = useState("");
  const [showAddAndEditWorkPlace, setShowAddAndEditWorkPlace] = useState(false);
  const [editWorkData, setEditWorkData] = useState("");
  const [showAddAndEditEducation, setShowAddAndEditEducation] = useState(false);
  const [editEducationData, setEditEducationData] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  const [eduId, setEduId] = useState();
  const [showAddEditMessages, setShowAddEditMessages] = useState("");
  const [workplace, setWorkplace] = useState([]);
  const [videoAudioPreview, setVideoAudioPreview] = useState(false);
  const [previewType, setPreviewType] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [userPosts, setUserPosts] = useState({
    current_page: "",
    data: [],
    first_page_url: "",
    form: "",
    last_page: "",
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  });

  const [userStat, setUserStat] = useState({
    followers: "",
    following: "",
    friends: "",
    posts: "",
  });

  const [profileImg, setProfileImg] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [media, setMedia] = useState([]);
  const [showFriends, setShowFriends] = useState([]);
  const [showFollowers, setShowFollowers] = useState([]);
  const [showFollowings, setShowFollowings] = useState("");
  const [languagesKnown, setLanguagesKnown] = useState([]);
  const [editWorkPlaceId, setEditWorkPlaceId] = useState("");
  const [getEducation, setGetEducation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const showAddAndEditMessage = (msg) => {
    setShowAddEditMessages(msg);
  };

  const handleEditWorkPlace = (item) => {
    setShowAddAndEditWorkPlace(true);

    if (item) {
      setEditWorkPlaceId(item.id);
      setEditWorkData(item);
    } else {
      setEditWorkPlaceId("");
      setEditWorkData("");
    }
  };

  const handleDeleteWorkPlace = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      data: {
        confirm: "Y",
      },
      query: {
        id: id,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.delete_experience`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setMessage("Work place deleted successfully");

          userState();

          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleDeleteCollege = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      data: {
        confirm: "Y",
      },
      query: {
        id: id,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.delete_education`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          setMessage("Education deleted successfully");

          userState();

          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getFollowersList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/follow.followers`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "followers list")
        setShowFollowers(result.data);
        setUserStat((preVal) => ({ ...preVal, followers: result.totalCount }));
      })
      .catch((error) => console.log("error", error));
  };

  const getFollowingList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/follow.following`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setShowFollowings(result.data);
        setUserStat((preVal) => ({ ...preVal, following: result.totalCount }));

        // console.log(showFollowings, "followings");
      })
      .catch((error) => console.log("error", error));
  };

  const userPost = (postLimit) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        post: {
          user_id: userData.id,
        },
      },
      limit: "10",
      page: postLimit,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.user`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "post result");
        setUserPosts((preVal) => ({
          current_page: result.current_page,
          data: result.data,
          first_page_url: result.first_page_url,
          form: result.from,
          last_page: result.last_page,
          last_page_url: result.last_page_url,
          links: result.links,
          next_page_url: result.next_page_url,
          path: result.path,
          per_page: result.per_page,
          prev_page_url: result.prev_page_url,
          to: result.to,
          total: result.total,
        }));

        setUserStat((preVal) => ({ ...preVal, posts: result.total }));

        setTimeout(() => {
          setPostLoading(false);
          setLoading(false);
          getUserMedia();
        }, 1000);
      })
      .catch((error) => console.log("error", error));
  };

  const getUserMedia = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.get_media`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "user media");
        if (result && result.data) {
          setMedia(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getFriendList = (searchData) => {
    setFriendsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      search: searchData,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.friendships_web`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.data) {
          // console.log(result, "my friends list");
          setShowFriends(result.data);
          setUserStat((preVal) => ({ ...preVal, friends: result.data.length }));
        }
        setFriendsLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const userState = () => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.state`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "user state data");
        setUserAccountD(result.data.account);
        setShowUni(result.data.universes);
        setProfileImg(result.data.account.photo);
        setBannerImg(result.data.account.banner);
        setUserSocialLinks(result.data.social);
        setWorkplace(result.data.experiences);
        setGetEducation(result.data.education);

        var userLanguagesString;
        if (result.data.account.language !== null) {
          userLanguagesString = result.data.account.language
            .map((value) => value)
            .join(", ");
          // console.log(userLanguagesString, "userLanguagesString");
        }
        setLanguagesKnown(userLanguagesString);

        setLoading(false);

        // console.log(userSocialLinks, "user social account details");
      })
      .catch((error) => console.log("error", error));
  };

  const handleEditCollege = (item) => {
    setShowAddAndEditEducation(true);

    if (item) {
      setEduId(item.id);
      setEditEducationData(item);
    } else {
      setEduId("");
      setEditEducationData("");
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group create_post_btn">
        <div className="icon" onClick={() => previous()}>
          <IoIosArrowBack size={18} />
        </div>
        <div className="icon" onClick={() => next()}>
          <IoIosArrowForward size={18} />
        </div>
      </div>
    );
  };

  const LoadNextPost = (currentLimit) => {
    userPost(currentLimit + 1);

    setCurrentPage(currentLimit + 1);
  };

  const LoadPrevPost = (currentLimit) => {
    userPost(currentLimit - 1);

    setCurrentPage(currentLimit + 1);
  };

  const getUniverses = (item) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("language", "en");

    var raw = JSON.stringify({
      parentID: item.ID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/public.universes`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          // console.log(result, "result")
          history.push({
            pathname: "/",
            state: {
              universeName: item.universeName,
              selectedUniverseId: item.ID,
              subUniverses: result.data.subUnivers,
            },
          });
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => console.log("error", error));
  };

  const [searchString, setSearchString] = useState("");
  // state for keeping the timeout
  const [searchTextTimeout, setSearchTextTimeout] = useState(null);

  const handleFriendListSearch = (searchValue) => {
    if (searchValue !== "") {
      getFriendList(searchValue);
      setSearchString(searchValue);
    } else {
      getFriendList(searchValue);
      setSearchString("");
    }
  };

  const handleFriendSearch = (e) => {
    if (searchTextTimeout) {
      clearTimeout(searchTextTimeout);
    }

    setSearchTextTimeout(
      setTimeout(() => {
        console.log(e.target.value);
        handleFriendListSearch(e.target.value);
      }, 500)
    );
  };

  useEffect(() => {
    return () => clearTimeout(searchTextTimeout);
  }, [searchTextTimeout]);

  const handleMoreMedia = () => {
    setLoadingMedia(true);

    setTimeout(() => {
      setMediaLimit(mediaLimit + 20);
      setLoadingMedia(false);
    }, 1000);
  };

  return (
    <>
      <Container className="position-relative">
        {loading && (
          <div className="main_loader">
            <img src={loader} alt="loader" style={{ height: "100px" }} />
          </div>
        )}
        <Row>
          <Col sm={12}>
            {showMessage && message ? (
              <div className="alert-message">{message}</div>
            ) : (
              <></>
            )}
            <ProfileHeader
              setBannerImg={setBannerImg}
              setProfileImg={setProfileImg}
              userState={userState}
              profileImg={profileImg}
              bannerImg={bannerImg}
              userAccountD={userAccountD}
              setLoading={setLoading}
              token={token}
              setMessage={setMessage}
              setShowMessage={setShowMessage}
              userStat={userStat}
              userPost={userPost}
              userData={userData}
            />
          </Col>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={profileTab}
            activeKey={profileTab}
          >
            <Col sm={12}>
              <div className="card p-0">
                <div className="card-body p-0">
                  <div className="user-tabing">
                    <Nav
                      as="ul"
                      variant="pills"
                      className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                    >
                      <Nav.Item as="li" className=" col-12 col-sm-3 p-0 ">
                        <Nav.Link
                          href="#"
                          eventKey="first"
                          role="button"
                          className=" text-center p-2"
                          onClick={() => setProfileTab("first")}
                        >
                          Timeline
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-12 col-sm-3 p-0">
                        <Nav.Link
                          href="#"
                          eventKey="second"
                          role="button"
                          className="text-center p-2"
                          onClick={() => {
                            userState();
                            setProfileTab("second");
                          }}
                        >
                          About
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-12 col-sm-3 p-0">
                        <Nav.Link
                          href="#"
                          eventKey="third"
                          role="button"
                          className="text-center p-2"
                          onClick={() => setProfileTab("third")}
                        >
                          Friends
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-12 col-sm-3 p-0">
                        <Nav.Link
                          href="#"
                          eventKey="forth"
                          role="button"
                          className="text-center p-2"
                          onClick={() => setProfileTab("forth")}
                        >
                          Photos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Card.Body className=" p-0">
                    <Row>
                      <Col md={4} lg={4} className="profile_left">
                        {/* <Card>
                          <div className="card-header d-flex justify-content-between">
                            <div className="header-title">
                              <h4 className="card-title">Life Event</h4>
                            </div>
                            <div className="card-header-toolbar d-flex align-items-center">
                              <p className="m-0">
                                <Link to="#"> Create </Link>
                              </p>
                            </div>
                          </div>
                          <Card.Body>
                            <Row>
                              <Col sm={6} md={12}>
                                <div className="event-post position-relative">
                                  <Link to="#">
                                    <img
                                      src={img9}
                                      alt="gallary1"
                                      className="img-fluid rounded"
                                    />
                                  </Link>
                                  <div className="job-icon-position">
                                    <div className="job-icon bg-primary p-2 d-inline-block rounded-circle">
                                      <i className="ri-briefcase-line text-white"></i>
                                    </div>
                                  </div>
                                  <div className="card-body text-center p-2">
                                    <h5>Started New Job at Apple</h5>
                                    <p>January 24, 2019</p>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={6} md={12}>
                                <div className="event-post position-relative">
                                  <Link to="#">
                                    <img
                                      src={img10}
                                      alt="gallary1"
                                      className="img-fluid rounded"
                                    />
                                  </Link>
                                  <div className="job-icon-position">
                                    <div className="job-icon bg-primary p-2 d-inline-block rounded-circle">
                                      <i className="ri-briefcase-line text-white"></i>
                                    </div>
                                  </div>
                                  <div className="card-body text-center p-2">
                                    <h5>Freelance Photographer</h5>
                                    <p className="mb-0">January 24, 2019</p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card> */}

                        <Row>
                          <Col sm={6} md={12}>
                            <Card>
                              <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                  <h4 className="card-title">Photos</h4>
                                </div>
                              </div>
                              <Card.Body>
                                {media && media.length > 0 ? (
                                  <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                    {media.slice(0, 6).map((item, index) => {
                                      return (
                                        <li key={index}>
                                          {item.media_type === "image" && (
                                            <SingleLightbox
                                              thumb={item.media_url}
                                              large={item.media_url}
                                              className="img-fluid"
                                            />
                                          )}

                                          {item.media_type === "video" && (
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                setPreviewType(item.media_type);
                                                setPreviewURL(item.media_url);
                                                setVideoAudioPreview(true);
                                              }}
                                            >
                                              <img
                                                src={videoThumb}
                                                alt="gallary"
                                                className="img-fluid"
                                              />
                                            </Link>
                                          )}

                                          {item.media_type === "audio" && (
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                setPreviewType(item.media_type);
                                                setPreviewURL(item.media_url);
                                                setVideoAudioPreview(true);
                                              }}
                                            >
                                              <img
                                                src={audioThumb}
                                                alt="gallary"
                                                className="img-fluid"
                                              />
                                            </Link>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  <h5 className="text-center">No photos</h5>
                                )}

                                {media && media.length > 0 && (
                                  <Link
                                    className="d-block text-center w-100 fw-500 mt-2"
                                    to="#"
                                    onClick={() => setProfileTab("forth")}
                                  >
                                    View all media
                                  </Link>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>

                          <VideoPlayerPreview
                            previewType={previewType}
                            previewURL={previewURL}
                            videoAudioPreview={videoAudioPreview}
                            setVideoAudioPreview={setVideoAudioPreview}
                          />

                          <Col sm={6} md={12}>
                            <Card>
                              <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                  <h4 className="card-title">Friends</h4>
                                </div>
                                <div className="card-header-toolbar d-flex align-items-center">
                                  <p className="m-0">
                                    <Link to="/dashboard/app/friend-request">
                                      Add New{" "}
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <Card.Body>
                                {showFriends && showFriends.length > 0 ? (
                                  <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                    {showFriends
                                      .slice(0, 6)
                                      .map((item, index) => {
                                        return (
                                          <li key={index} className="">
                                            <Link
                                              className=""
                                              to={`/dashboard/app/friend-profile/${item.id}`}
                                            >
                                              <img
                                                src={
                                                  item.photo
                                                    ? item.photo
                                                    : noprofile
                                                }
                                                onError={(e) => {
                                                  e.target.src = noprofile;
                                                }}
                                                alt="gallary"
                                                className="img-fluid"
                                              />
                                            </Link>
                                            <h6 className="text-center mt-1 fw-500 text-ellipsis-2">
                                              {item.name}
                                            </h6>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                ) : (
                                  <h5 className="text-center">No friends</h5>
                                )}

                                {showFriends && showFriends.length > 0 && (
                                  <Link
                                    className="d-block text-center w-100 fw-500 mt-2"
                                    to="#"
                                    onClick={() => setProfileTab("third")}
                                  >
                                    View all friends
                                  </Link>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={8} lg={8}>
                        {showUni && showUni.length > 0 ? (
                          <div className="card card-block card-stretch position-relative d-flex justify-content-between create_post">
                            <div className="d-flex justify-content-between">
                              <h5 className="px-1 mb-1 text-muted">Universe</h5>
                              <Link to="/dashboard/app/edit-universe">
                                Edit
                              </Link>
                            </div>
                            <Carousel
                              arrows={false}
                              customButtonGroup={<ButtonGroup />}
                              infinite={false}
                              responsive={responsiveUniversesProfile}
                              autoPlay={false}
                              containerClass="carousel-container mb-0"
                              renderButtonGroupOutside={true}
                              itemClass="carousel-item-padding-40px"
                              sliderClass="react-multi-carousel-track"
                            >
                              {showUni.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="text-center clickable"
                                    onClick={() => {
                                      getUniverses(item);
                                    }}
                                  >
                                    <img
                                      src={item.universeIcon}
                                      alt="universe"
                                      className="rounded-circle story-img clickable no_pointer_events"
                                    />
                                    <div className="universes-text">
                                      {item.universeName}
                                    </div>
                                  </div>
                                );
                              })}
                            </Carousel>
                          </div>
                        ) : (
                          <Card>
                            <Card.Body>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5 className="text-muted mb-0">
                                  No Universe subscribed
                                </h5>
                                <Link
                                  className="fw-500"
                                  to="/dashboard/app/edit-universe"
                                >
                                  Add Universe
                                </Link>
                              </div>
                            </Card.Body>
                          </Card>
                        )}

                        <div className="mx-auto" style={{ maxWidth: "550px" }}>
                          {userPosts && userPosts.total > 0 ? (
                            <div>
                              <Card>
                                <div className="d-flex justify-content-between align-items-center py-2 border-bottom card-body lh-1">
                                  <h5 className="fw-bold">Posts</h5>

                                  <p className="mb-0">
                                    Showing:{" "}
                                    <NumericLabel
                                      params={{
                                        justification: "L",
                                        shortFormat: true,
                                      }}
                                    >
                                      {userPosts.to || "0"}
                                    </NumericLabel>
                                    {" of "}
                                    <NumericLabel
                                      params={{
                                        justification: "L",
                                        shortFormat: true,
                                      }}
                                    >
                                      {userPosts.total || "0"}
                                    </NumericLabel>
                                  </p>
                                </div>
                                <Card.Body>
                                  <Row>
                                    {userPosts.data.map((item, index) => {
                                      return (
                                        <Col
                                          xs={12}
                                          key={index}
                                          className="mb-2"
                                        >
                                          <ProfilePostItem
                                            setLoading={setLoading}
                                            token={token}
                                            userPost={userPost}
                                            currentPage={currentPage}
                                            setMessage={setMessage}
                                            setShowMessage={setShowMessage}
                                            item={item}
                                            componentOf="profile"
                                          />
                                        </Col>
                                      );
                                    })}
                                  </Row>

                                  {userPosts.total !== userPosts.to ? (
                                    <></>
                                  ) : (
                                    <div className="text-center">
                                      <h4 className="fw-semibold mb-0">
                                        No more posts
                                      </h4>
                                    </div>
                                  )}
                                </Card.Body>
                              </Card>

                              {userPosts.total > 10 && (
                                <div className="d-flex justify-content-between mb-4">
                                  {userPosts.current_page > 1 ? (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        LoadPrevPost(userPosts.current_page)
                                      }
                                    >
                                      <BsArrowLeft size={16} className="me-1" />{" "}
                                      Prev post
                                    </button>
                                  ) : (
                                    <div></div>
                                  )}

                                  {userPosts.total !== userPosts.to && (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        LoadNextPost(userPosts.current_page)
                                      }
                                    >
                                      Next post{" "}
                                      <BsArrowRight
                                        size={16}
                                        className="ms-1"
                                      />
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              {postLoading ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <img
                                    src={loader}
                                    alt="loader post"
                                    style={{ height: "70px", width: "70px" }}
                                  />
                                </div>
                              ) : (
                                <Card className="text-center">
                                  <div className="d-flex justify-content-between align-items-center py-2 border-bottom card-body lh-1">
                                    <h5 className="fw-bold">Posts</h5>

                                    <p className="mb-0">
                                      Showing:{" "}
                                      <NumericLabel
                                        params={{
                                          justification: "L",
                                          shortFormat: true,
                                        }}
                                      >
                                        {userPosts.to || "0"}
                                      </NumericLabel>
                                      {" of "}
                                      <NumericLabel
                                        params={{
                                          justification: "L",
                                          shortFormat: true,
                                        }}
                                      >
                                        {userPosts.total || "0"}
                                      </NumericLabel>
                                    </p>
                                  </div>
                                  <Card.Body>
                                    <h4>No posts found!</h4>
                                  </Card.Body>
                                </Card>
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="about1"
                  >
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col md={3}>
                            <Nav
                              variant="pills"
                              className=" basic-info-items list-inline d-block p-0 mx-0 mb-3"
                            >
                              <Nav.Item>
                                <Nav.Link href="#" eventKey="about1">
                                  Contact and Basic Info
                                </Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                <Nav.Link href="#" eventKey="about2">
                                  Family and Relationship
                                </Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                <Nav.Link href="#" eventKey="about3">
                                  Work and Education
                                </Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                <Nav.Link href="#" eventKey="about4">
                                  Places You've Lived
                                </Nav.Link>
                              </Nav.Item> */}
                              {userAccountD && userAccountD.aboutMe && (
                                <Nav.Item>
                                  <Nav.Link href="#" eventKey="about5">
                                    Details About You
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </Nav>
                          </Col>
                          <Col md={9} className="ps-md-4">
                            <Tab.Content>
                              <Tab.Pane eventKey="about1">
                                <h4>Contact Information</h4>
                                <hr />

                                {userAccountD && (
                                  <Row>
                                    {userAccountD.email && (
                                      <>
                                        <div className="col-3">
                                          <h6 className="mb-1">Email</h6>
                                        </div>
                                        <div className="col-9">
                                          <p className="mb-0">
                                            {userAccountD.email}
                                          </p>
                                        </div>
                                      </>
                                    )}

                                    {userAccountD.phone && (
                                      <>
                                        <div className="col-3">
                                          <h6 className="mb-1">Mobile</h6>
                                        </div>
                                        <div className="col-9">
                                          <p className="mb-0">
                                            {userAccountD.phone}
                                          </p>
                                        </div>
                                      </>
                                    )}

                                    {userAccountD.website && (
                                      <>
                                        <div className="col-3">
                                          <h6 className="mb-1">Websites</h6>
                                        </div>
                                        <div className="col-9">
                                          <p className="mb-0">
                                            {userAccountD.website}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </Row>
                                )}

                                {userSocialLinks &&
                                  (userSocialLinks.facebook ||
                                    userSocialLinks.twitter ||
                                    userSocialLinks.instagram ||
                                    userSocialLinks.youtube) && (
                                    <>
                                      <h4 className="mb-2 mt-3">
                                        Social links
                                      </h4>

                                      <div className="d-flex">
                                        {userSocialLinks.facebook && (
                                          <div className="about_items">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Facebook</Tooltip>
                                              }
                                            >
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={userSocialLinks.facebook}
                                              >
                                                <div className="icon bg-soft-info border-0 me-2">
                                                  <FaFacebookF
                                                    size={20}
                                                    color="#777d74"
                                                  />
                                                </div>
                                              </a>
                                            </OverlayTrigger>
                                          </div>
                                        )}

                                        {userSocialLinks.twitter && (
                                          <div className="about_items">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Twitter</Tooltip>
                                              }
                                            >
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={userSocialLinks.twitter}
                                              >
                                                <div className="icon bg-soft-info border-0 me-2">
                                                  <FaTwitter
                                                    size={20}
                                                    color="#777d74"
                                                  />
                                                </div>
                                              </a>
                                            </OverlayTrigger>
                                          </div>
                                        )}

                                        {userSocialLinks.instagram && (
                                          <div className="about_items">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Instagram</Tooltip>
                                              }
                                            >
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={userSocialLinks.instagram}
                                              >
                                                <div className="icon bg-soft-info border-0 me-2">
                                                  <FaInstagram
                                                    size={20}
                                                    color="#777d74"
                                                  />
                                                </div>
                                              </a>
                                            </OverlayTrigger>
                                          </div>
                                        )}

                                        {/* {userSocialLinks.gplus && (
                                        <div className='about_items'>
                                          <OverlayTrigger placement="top" overlay={
                                                <Tooltip >
                                                    Google +
                                                </Tooltip>
                                                }>
                                                <a target="_blank" rel="noreferrer" href={userSocialLinks.gplus}>
                                                    <div className='icon bg-soft-info border-0 me-2'>
                                                      <FaGooglePlusG size={20} color="#777d74" />
                                                    </div>
                                                </a>
                                          </OverlayTrigger>
                                        </div>
                                    )} */}

                                        {userSocialLinks.youtube && (
                                          <div className="about_items">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Youtube</Tooltip>
                                              }
                                            >
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={userSocialLinks.youtube}
                                              >
                                                <div className="icon bg-soft-info border-0 me-2">
                                                  <FaYoutube
                                                    size={20}
                                                    color="#777d74"
                                                  />
                                                </div>
                                              </a>
                                            </OverlayTrigger>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}

                                <h4 className="mt-3">Basic Information</h4>
                                <hr />
                                <Row>
                                  {/* <div className="col-3">
                                    <h6 className="mb-1">Birth Date</h6>
                                  </div>
                                  <div className="col-9">
                                    <p className="mb-0">
                                      <Moment format="DD MMMM">
                                        {userAccountD.dob}
                                      </Moment>
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <h6 className="mb-1">Birth Year</h6>
                                  </div>
                                  <div className="col-9">
                                    <p className="mb-0">
                                      <Moment format="YYYY">
                                        {userAccountD.dob}
                                      </Moment>
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <h6 className="mb-1">Gender</h6>
                                  </div>
                                  <div className="col-9">
                                    <p className="mb-0">
                                      {userAccountD.gender && (
                                        <>
                                          {userAccountD.gender === "M" ? "Male" : userAccountD.gender === "F" ? "Female" : userAccountD.gender === "O" ? "Non-binary" : userAccountD.gender}
                                        </>
                                      )}
                                    </p>
                                  </div> */}
                                  {/* <div className="col-3">
                                    <h6 className="mb-1">interested in</h6>
                                  </div>
                                  <div className="col-9">
                                    <p className="mb-0">Designing</p>
                                  </div> */}
                                  <div className="col-3">
                                    <h6 className="mb-1">language</h6>
                                  </div>
                                  <div className="col-9">
                                    <p className="mb-0">{languagesKnown}</p>
                                  </div>
                                </Row>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about2">
                                <h4 className="mb-3">Relationship</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <i className="ri-add-fill"></i>
                                    </div>
                                    <div className="media-support-info ms-3">
                                      <h6>Add Your Relationship Status</h6>
                                    </div>
                                  </li>
                                </ul>
                                <h4 className="mt-3 mb-3">Family Members</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <i className="ri-add-fill"></i>
                                    </div>
                                    <div className="media-support-info ms-3">
                                      <h6>Add Family Members</h6>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        src={user01}
                                        alt="story1"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="ms-3">
                                          <h6>Paul Molive</h6>
                                          <p className="mb-0">Brothe</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <i className="ri-edit-line me-1"></i>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex justify-content-between mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <img
                                        src={user02}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className=" ms-3">
                                          <h6>Anna Mull</h6>
                                          <p className="mb-0">Sister</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <i className="ri-edit-line me-1"></i>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        src={user03}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="ms-3">
                                          <h6>Paige Turner</h6>
                                          <p className="mb-0">Cousin</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <i className="ri-edit-line me-1"></i>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about3">
                                {showAddEditMessages ? (
                                  <div className="alert-message">
                                    {showAddEditMessages}
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {showAddAndEditWorkPlace && (
                                  <AddAndEditWorkPlaceModal
                                    addAndEditWorkPlace={
                                      showAddAndEditWorkPlace
                                    }
                                    setAddAndEditWorkPlace={
                                      setShowAddAndEditWorkPlace
                                    }
                                    showAddEditMessage={showAddAndEditMessage}
                                    editWorkPlaceId={editWorkPlaceId}
                                    setEditWorkPlaceId={setEditWorkPlaceId}
                                    editWorkData={editWorkData}
                                    userState={userState}
                                  />
                                )}

                                {showAddAndEditEducation && (
                                  <AddAndEditEducationModal
                                    addAndEditEducation={
                                      showAddAndEditEducation
                                    }
                                    setAddAndEditEducation={
                                      setShowAddAndEditEducation
                                    }
                                    showAddEditMessage={showAddAndEditMessage}
                                    eduId={eduId}
                                    setEduId={setEduId}
                                    editEducationData={editEducationData}
                                    userState={userState}
                                  />
                                )}

                                <h4 className="mb-2">Work</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li
                                    className="d-flex mb-3 align-items-center clickable hoverable fw-bold"
                                    onClick={() => {
                                      setShowAddAndEditWorkPlace(true);
                                      setEditWorkPlaceId("");
                                      setEditWorkData("");
                                    }}
                                  >
                                    <div className="user-img img-fluid">
                                      <i className="ri-add-fill"></i>
                                    </div>
                                    <div className="ms-1">
                                      <h6>Add Work Place</h6>
                                    </div>
                                  </li>
                                  {workplace &&
                                    workplace.map((item, index) => {
                                      return (
                                        <li
                                          className="d-flex mb-3 align-items-center justify-content-between work_edu_list"
                                          key={index}
                                        >
                                          <div className="work_edu_right">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className="about_items">
                                                <div className="icon bg-soft-info border-0">
                                                  <FaBriefcase
                                                    size={20}
                                                    color="#777d74"
                                                  />
                                                </div>
                                                <div className="fcc">
                                                  <h6>{item.title}</h6>
                                                  <span className="mb-0">
                                                    {item.company}
                                                    {", "} {item.jobLocation}
                                                  </span>
                                                  <span className="text-muted">
                                                    <Moment format="DD MMM YYYY">
                                                      {item.startingDate}
                                                    </Moment>

                                                    {" - "}

                                                    {item.present === "N" ? (
                                                      <Moment format="DD MMM YYYY">
                                                        {item.endingDate}
                                                      </Moment>
                                                    ) : (
                                                      <span className="text-muted">
                                                        Present
                                                      </span>
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="edit-relation d-flex align-items-center">
                                                <Link
                                                  to="#"
                                                  className="btn btn-icon btn-outline-primary small_btn me-1 me-md-2"
                                                  onClick={() =>
                                                    handleEditWorkPlace(item)
                                                  }
                                                >
                                                  <BsPen size={16} />
                                                </Link>

                                                <Link
                                                  to="#"
                                                  className="btn btn-icon btn-outline-danger small_btn"
                                                  onClick={() =>
                                                    handleDeleteWorkPlace(
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <BsTrash size={16} />
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>

                                <h4 className="mt-4 mb-2">Education</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li
                                    className="d-flex mb-3 align-items-center clickable hoverable fw-bold"
                                    onClick={() => {
                                      setShowAddAndEditEducation(true);
                                      setEduId("");
                                      setEditEducationData("");
                                    }}
                                  >
                                    <div className="user-img img-fluid">
                                      <i className="ri-add-fill"></i>
                                    </div>
                                    <div className="ms-1">
                                      <h6>Add Institute</h6>
                                    </div>
                                  </li>

                                  {getEducation.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className="d-flex mb-3 align-items-center justify-content-between work_edu_list"
                                      >
                                        <div className="work_edu_right">
                                          <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <div className="about_items">
                                              <div className="icon bg-soft-info border-0">
                                                <FaGraduationCap
                                                  size={20}
                                                  color="#777d74"
                                                />
                                              </div>
                                              <div className="fcc">
                                                <h6>{item.degree}</h6>
                                                <span className="text-muted">
                                                  {item.institute}
                                                  {", "} {item.eduLocation}
                                                </span>
                                                {item.isPresent === "y" ? (
                                                  <span className="text-muted">
                                                    Pursuing
                                                  </span>
                                                ) : (
                                                  <span className="text-muted">
                                                    Passed on{" "}
                                                    <Moment format="DD MMM YYYY">
                                                      {item.passingDate}
                                                    </Moment>
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            <div className="edit-relation d-flex align-items-center">
                                              <Link
                                                to="#"
                                                className="btn btn-icon btn-outline-primary small_btn me-1 me-md-2"
                                                onClick={() =>
                                                  handleEditCollege(item)
                                                }
                                              >
                                                <BsPen size={16} />
                                              </Link>

                                              <Link
                                                to="#"
                                                className="btn btn-icon btn-outline-danger small_btn"
                                                onClick={() =>
                                                  handleDeleteCollege(item.id)
                                                }
                                              >
                                                <BsTrash size={16} />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about4">
                                <h4 className="mb-3">
                                  Current City and Hometown
                                </h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        src={user01}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>Georgia</h6>
                                          <p className="mb-0">Georgia State</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <i className="ri-edit-line me-1"></i>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        src={user02}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>Atlanta</h6>
                                          <p className="mb-0">Atlanta City</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <i className="ri-edit-line me-1"></i>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <h4 className="mt-3 mb-3">
                                  Other Places Lived
                                </h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <i className="ri-add-fill"></i>
                                    </div>
                                    <div className="ms-3">
                                      <h6>Add Place</h6>
                                    </div>
                                  </li>
                                </ul>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about5">
                                <h4 className="mb-1">About You</h4>
                                <p>{userAccountD && userAccountD.aboutMe}</p>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Container>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="all-friends"
                  >
                    <Card>
                      <Card.Body className="p-2 p-sm-3">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h4>Friends</h4>
                          <input
                            type="text"
                            className="form-control rounded max-w-250"
                            placeholder="Search for friends"
                            onKeyUp={(e) => {
                              handleFriendSearch(e);
                            }}
                          />
                        </div>

                        <div className="friend-list-tab mt-2">
                          {/* <Nav
                            variant="pills"
                            className=" d-flex align-items-center justify-content-left friend-list-items p-0 mb-2"
                          >
                            <Nav.Item>
                              <Nav.Link
                                href="#"
                                eventKey="all-friends"
                              >
                                All Friends
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                href="#"
                                eventKey="followers"
                                onClick={getFollowersList}
                              >
                                Followers
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                href="#"
                                eventKey="following"
                                onClick={getFollowingList}
                              >
                                Following
                              </Nav.Link>
                            </Nav.Item>
                          </Nav> */}
                          <Tab.Content>
                            <Tab.Pane eventKey="all-friends">
                              <Row>
                                {friendsLoading ? (
                                  <div className="d-flex justify-content-center align-items-center">
                                    <img
                                      src={loader}
                                      alt="loader"
                                      style={{ height: "70px", width: "70px" }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {showFriends && showFriends.length > 0 ? (
                                      <>
                                        {showFriends.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="col-md-6 col-lg-6 mb-3"
                                            >
                                              <ProfileFriendsCard
                                                item={item}
                                                getFriendList={getFriendList}
                                                setMessage={setMessage}
                                                setShowMessage={setShowMessage}
                                                token={token}
                                              />
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <h5 className="text-center">
                                        No friends
                                      </h5>
                                    )}
                                  </>
                                )}
                              </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="followers">
                              <Row>
                                {showFollowers && showFollowers.length > 0 ? (
                                  <>
                                    {showFollowers.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-6 col-lg-6 mb-3"
                                        >
                                          <ProfileFollowersCard
                                            item={item}
                                            getFriendList={getFollowersList}
                                            setMessage={setMessage}
                                            setShowMessage={setShowMessage}
                                            token={token}
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <h5 className="text-center">No followers</h5>
                                )}
                              </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="following">
                              <Row>
                                {showFollowings && showFollowings.length > 0 ? (
                                  <>
                                    {showFollowings.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-6 col-lg-6 mb-3"
                                        >
                                          <ProfileFollowingCard
                                            item={item}
                                            getFriendList={getFollowingList}
                                            setMessage={setMessage}
                                            setShowMessage={setShowMessage}
                                            token={token}
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <h5 className="text-center">
                                    Not following anyone
                                  </h5>
                                )}
                              </Row>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Card.Body>
                    </Card>
                  </Tab.Container>
                </Tab.Pane>
                <Tab.Pane eventKey="forth">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                    <Card>
                      <Card.Body className="p-2 p-sm-3">
                        <h4>Photos</h4>
                        <div className="friend-list-tab mt-2">
                          <div className="card-body p-0">
                            <div className="d-flex gap-1 photo_video_card_wrap">
                              {/* old design */}
                              {/* <div>
                                <div className="user-images position-relative overflow-hidden">
                                  <Link to="#">
                                    <img
                                      src={img51}
                                      className="img-fluid rounded"
                                      alt="Responsive"
                                    />
                                  </Link>
                                  <div className="image-hover-data">
                                    <div className="product-elements-icon">
                                      <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                        <li>
                                          <Link
                                            to="#"
                                            className="pe-3 text-white"
                                          >
                                            {" "}
                                            60{" "}
                                            <i className="ri-thumb-up-line"></i>{" "}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="#"
                                            className="pe-3 text-white"
                                          >
                                            {" "}
                                            30{" "}
                                            <i className="ri-chat-3-line"></i>{" "}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="#"
                                            className="pe-3 text-white"
                                          >
                                            {" "}
                                            10{" "}
                                            <i className="ri-share-forward-line"></i>{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>Edit or Remove</Tooltip>
                                    }
                                  >
                                    <Link to="#" className="image-edit-btn">
                                      <i className="ri-edit-2-fill"></i>
                                    </Link>
                                  </OverlayTrigger>
                                </div>
                              </div> */}

                              {media && media.length > 0 ? (
                                <>
                                  {media
                                    .slice(0, mediaLimit)
                                    .map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="photo_video_card"
                                        >
                                          {item.media_type === "image" && (
                                            <div className="user-images position-relative overflow-hidden">
                                              <SingleLightbox
                                                thumb={item.media_url}
                                                large={item.media_url}
                                                className="img-fluid rounded"
                                              />
                                            </div>
                                          )}

                                          {item.media_type === "video" && (
                                            <Link
                                              className="user-images position-relative overflow-hidden h-100"
                                              to="#"
                                              onClick={() => {
                                                setPreviewType(item.media_type);
                                                setPreviewURL(item.media_url);
                                                setVideoAudioPreview(true);
                                              }}
                                            >
                                              <img
                                                src={videoThumb}
                                                alt="gallary"
                                                className="img-fluid rounded h-100"
                                              />
                                            </Link>
                                          )}

                                          {item.media_type === "audio" && (
                                            <Link
                                              className="user-images position-relative overflow-hidden audio_thumb_box"
                                              to="#"
                                              onClick={() => {
                                                setPreviewType(item.media_type);
                                                setPreviewURL(item.media_url);
                                                setVideoAudioPreview(true);
                                              }}
                                            >
                                              <img
                                                src={audioThumb}
                                                alt="gallary"
                                                className="img-fluid rounded"
                                              />
                                            </Link>
                                          )}
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <h5 className="text-center">No photos</h5>
                              )}
                            </div>

                            {loadingMedia && (
                              <div className="d-flex justify-content-center align-items-center">
                                <img
                                  src={loader}
                                  alt="loader"
                                  style={{ height: "100px" }}
                                />
                              </div>
                            )}

                            {media.length > mediaLimit && (
                              <div className="mt-3 text-center">
                                <span
                                  className="text-primary fw-500 clickable"
                                  onClick={handleMoreMedia}
                                >
                                  Show more
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Row>
      </Container>
    </>
  );
};

export default UserProfile;
