import React, { useState, useEffect } from "react";

import {
  Modal,
  Form,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import { Link} from "react-router-dom";

import "react-multi-carousel/lib/styles.css";
import ReactDatePicker from "react-datepicker";
import { servicePath } from "../app/variables";

function AddAndEditWorkPlaceModal({
  addAndEditWorkPlace,
  setAddAndEditWorkPlace,
  showAddEditMessage,
  editWorkPlaceId,
  setEditWorkPlaceId,
  editWorkData,
  userState,
}) {

  const validation = () => {
    let error = {}
    let isValid = true;

    if (!title || title === "") {
        error.title = "Title is required"
        isValid = false
    }
    if (!company || company === "") {
        error.company = "Company is required"
        isValid = false
    }
    if (!jobLocation || jobLocation === "") {
        error.jobLocation = "Job location is required"
        isValid = false
    }
    if (!startDate || startDate === "") {
        error.startDate = "Start date is required"
        isValid = false
    }

    if (currentlyWorking === "N" && (!endDate || endDate === "")) {
      error.endDate = "End date is required"
      isValid = false
    }

    setErrors(error)
    return isValid
  }

  const [errors, setErrors] = useState({})
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [jobLocation, setJobLocation] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState('N');

  const token = "Bearer" + " " + localStorage.getItem("access_token");

  useEffect(() => {
    if(editWorkData !== '') {
      setTitle(editWorkData.title)
      setCompany(editWorkData.company)
      setJobLocation(editWorkData.jobLocation)
      setStartDate(new Date(editWorkData.startingDate))
      setEndDate(new Date(editWorkData.endingDate))
      setCurrentlyWorking(editWorkData.present)
      setEditWorkPlaceId(editWorkData.id);
      // console.log(editWorkData, "editWorkData")
    }
  }, [editWorkData]);

  const currentDate = new Date();

  const updateExperience = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        id: editWorkPlaceId,
        present: currentlyWorking,
        company: company,
        title: title,
        jobLocation: jobLocation,
        startingDate: startDate,
        endingDate: endDate,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/user.update_experience`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showAddEditMessage("Work place updated successfully");
        setAddAndEditWorkPlace(false);

        userState()
        setTimeout(() => {
          showAddEditMessage("");
        }, 3000);
      })
      .catch((error) => console.log("error", error));
  };

  const addExperience = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        present: currentlyWorking,
        company: company,
        title: title,
        jobLocation: jobLocation,
        startingDate: startDate,
        endingDate: endDate,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/user.update_experience`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showAddEditMessage("Work place added successfully");
        setAddAndEditWorkPlace(false);
        userState()
        setTimeout(() => {
          showAddEditMessage("");
        }, 3000);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Modal
      // size={`${(selectMultiverseModal || tagFriendsModal) ? "md" : "md"}`}
      className="fade"
      id="post-modal"
      show={addAndEditWorkPlace}
      dialogClassName="modal-dialog-centered"
    >
      <div>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="post-modalLabel">
            {editWorkPlaceId ? "Edit Workplace" : "Add Workplace"}
          </Modal.Title>
          <Link
            to="#"
            className="lh-1 close_btn bg-light"
            onClick={() => setAddAndEditWorkPlace(false)}
          >
            <i className="ri-close-fill Wri-xl"></i>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group col-sm-12">
            <Form.Label htmlFor="company" className="form-label">
              Title:
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="title"
              placeholder="Title"
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <div className="text-danger">{errors.title}</div>
          </Form.Group>
          <Form.Group className="form-group col-sm-12">
            <Form.Label htmlFor="company" className="form-label">
              Company:
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="company"
              placeholder="Company"
              defaultValue={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <div className="text-danger">{errors.company}</div>
          </Form.Group>
          <Form.Group className="form-group col-sm-12">
            <Form.Label htmlFor="company" className="form-label">
              Job Location:
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="jobLocation"
              placeholder="Job Location"
              defaultValue={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
            />
            <div className="text-danger">{errors.jobLocation}</div>
          </Form.Group>

          <Row>
            <Col sm={6}>
              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="dob" className="form-label">
                  Start Date:
                </Form.Label>
                <ReactDatePicker
                  dateFormat='dd-MM-yyyy'
                  selected={startDate}
                  endDate={currentDate}
                  maxDate={currentDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Date"
                  className='form-control'
                />
                <div className="text-danger">{errors.startDate}</div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              {currentlyWorking === "N" && (
                <Form.Group className="form-group col-sm-12">
                  <Form.Label htmlFor="dob" className="form-label">
                    End Date:
                  </Form.Label>
                  <ReactDatePicker
                    dateFormat='dd-MM-yyyy'
                    selected={endDate}
                    endDate={currentDate}
                    maxDate={currentDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="Date"
                    className='form-control'
                  />
                  <div className="text-danger">{errors.endDate}</div>
                </Form.Group>
              )}
            </Col>
          </Row>
          
          <Form.Group className="form-group col-sm-12">
            <div className="form-label d-block">Currently Working Here:</div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio10"
                value="1"
                onChange={(e) => setCurrentlyWorking("Y")}
                checked={currentlyWorking === "Y" ? true : false}
              />
              <label className="form-check-label" htmlFor="inlineRadio10">
                {" "}
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio11"
                value="2"
                onChange={(e) => setCurrentlyWorking("N")}
                checked={currentlyWorking === "N" ? true : false}
              />
              <label className="form-check-label" htmlFor="inlineRadio11">
                No
              </label>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-end">
            {editWorkPlaceId ? (
              <Button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {validation() ? updateExperience() : <></>;}}
              >
                Update
              </Button>
            ) : (
              <Button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {validation() ? addExperience() : <></>;}}
              >
                Submit
              </Button>
            )}
            <Button
              type="reset"
              className="bg-soft-danger"
              onClick={() => setAddAndEditWorkPlace(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default AddAndEditWorkPlaceModal;
