import React, { createContext, useContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const ProfileContext = createContext();

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return context;
};

export const ProfileProvider = ({ children }) => {
  const [userInfo] = useLocalStorage("users", {});
  const [profileImage, setProfileImage] = useState(userInfo?.photo);

  const [storyBar, setStoryBar] = useState(false);

  const openStoryBar = () => setStoryBar(true);
  const closeStoryBar = () => setStoryBar(false);

  const toggleStoryBar = () => setStoryBar((prev) => !prev);

  const updateProfileImage = (image) => {
    setProfileImage(image);
  };

  return (
    <ProfileContext.Provider
      value={{
        profileImage,
        updateProfileImage,
        userInfo,
        storyBar,
        openStoryBar,
        closeStoryBar,
        toggleStoryBar,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
