import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import logoWeb from "../../../assets/images/logo_web.png";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import Card from "../../../components/Card";
import Footer from "../../../components/partials/dashboard/footerStyle/footer";
import HelmetMetaData from "../components/HelmetMetaData";
import PublicProfilePostItemDetail from "../components/PublicProfilePostItemDetail";
import { baseURL, servicePath } from "./variables";

const PublicPostDetails = () => {
  const { postId } = useParams();

  useEffect(() => {
    getPostDetails(postId);

    setLoading(true);
  }, [postId]);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState("");

  const getPostDetails = () => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: postId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/public.post_get_link`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result.post, "post details data")
        setPostData(result.post);
        setLoading(false);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <HelmetMetaData
        title={postData.post_body}
        description={postData.post_body}
        image={postData.media_url}
        url={`${baseURL}/dashboard/app/post-detail/${postData.id}`}
      />
      <div className="header-main flex-wrap align-items-center justify-content-between py-2">
        <div className="header_logo py-1">
          <Link to="/auth/sign-up">
            <img src={logoWeb} className="w-100" alt="" />
          </Link>
        </div>
      </div>

      <Container>
        <div
          style={{ maxWidth: "550px", minHeight: "90vh" }}
          className="mx-auto"
        >
          {showMessage && message ? (
            <div className="alert-message">{message}</div>
          ) : (
            <></>
          )}

          <Row>
            <Col sm="12" className="pt-2">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="card-title fw-500 mb-0">Post Details</h4>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="main_loader">
              <img src={loader} alt="loader" height="100px" />
            </div>
          ) : (
            <Row>
              <Col sm="12">
                <Card>
                  <Card.Body>
                    <div className="w-100">
                      {postData && (
                        <PublicProfilePostItemDetail item={postData} />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PublicPostDetails;
