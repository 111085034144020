import React from "react";
import { Modal } from "react-bootstrap";
import InteractedUserList from "./InteractedUserList";

const LikeCommentListModal = ({ handleClose, show, showModalTypeInfo }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <InteractedUserList
        handleClose={handleClose}
        showModalTypeInfo={showModalTypeInfo}
      />
    </Modal>
  );
};

export default LikeCommentListModal;
