import imageCompression from "browser-image-compression";
import React, { useCallback, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

function ImageVideoPreviewCreate({
  handleFileReset,
  setFileType,
  setPostType,
  file,
  setFile,
  setFileName,
  setUploadFile,
  editPostComponent,
  setPostEdited,
  setHasMediaError,
}) {
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileSizeValue, setFileSizeValue] = useState("");
  const [mediaType, setMediaType] = useState("image");
  const [videoDurationError, setVideoDurationError] = useState(false); // New state for video duration error

  const handleMediaFile = useCallback(
    async (mediaFile) => {
      try {
        if (editPostComponent) {
          setPostEdited(true);
        }

        const fileType = mediaFile.type.split("/")[0];
        setMediaType(fileType);

        if (fileType === "image") {
          setFileType(fileType);
          setPostType(fileType);
          const options = {
            maxSizeMB: 20,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(mediaFile, options);
          const compressedFileSize = compressedFile.size / (1024 * 1024);

          if (compressedFileSize > 20) {
            setFileSizeError(true);
            setFileSizeValue(compressedFileSize.toFixed(2));
          } else {
            setFileTypeError(false);
            setFile(URL.createObjectURL(compressedFile));
            setFileName(compressedFile.name);
            setUploadFile(compressedFile);
          }
        } else if (fileType === "audio" || fileType === "video") {
          setFileTypeError(false);
          setFile(URL.createObjectURL(mediaFile));
          setFileName(mediaFile.name);
          setUploadFile(mediaFile);
          setFileType(fileType);
          setPostType(fileType);

          if (fileType === "video") {
            // Check video duration
            const video = document.createElement("video");
            video.src = URL.createObjectURL(mediaFile);

            video.onloadedmetadata = () => {
              const durationInSeconds = video.duration;
              if (durationInSeconds < 10 || durationInSeconds > 60) {
                setVideoDurationError(true);
                setHasMediaError(true);
                setFileType("text");
                setPostType("text");
              } else {
                setVideoDurationError(false);
                setFileType(fileType);
                setPostType(fileType);
              }
            };
          }
        } else {
          setFileTypeError(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      editPostComponent,
      setHasMediaError,
      setPostEdited,
      setFile,
      setFileName,
      setUploadFile,
      setFileTypeError,
      setFileSizeValue,
      setMediaType,
      setFileType,
      setPostType,
    ]
  );

  const handleChange = (event) => {
    const mediaFile = event.target.files[0];
    handleMediaFile(mediaFile);
  };

  const handleInputReset = (e) => {
    e.target.value = "";
    if (editPostComponent) {
      setPostEdited(true);
    }
  };

  const handleCloseClick = () => {
    handleFileReset();
    setMediaType("image");
  };

  const renderMediaIcon = () => {
    if (mediaType === "image") {
      return (
        <>
          <i className="ri-image-fill"></i>
          <h5 className="mt-2 fw-bold">Add photo/video</h5>
        </>
      );
    } else if (mediaType === "audio") {
      return (
        <>
          <i className="ri-mic-fill"></i>
          <h5 className="mt-2 fw-bold">Add audio</h5>
        </>
      );
    } else if (mediaType === "video") {
      return (
        <>
          <i className="ri-video-fill"></i>
          <h5 className="mt-2 fw-bold">Add video</h5>
        </>
      );
    }
  };

  const renderMediaPlayer = () => {
    if (mediaType === "audio") {
      return (
        <div className="video_player_wrap d-flex align-items-center">
          <ReactAudioPlayer src={file} controls className="w-100 me-5" />
          <Link
            to="#"
            className="lh-1 close_btn border right_10 close_sm"
            onClick={handleFileReset}
          >
            <i className="ri-close-fill ri-xl"></i>
          </Link>
        </div>
      );
    } else if (mediaType === "video") {
      return (
        <div className="video_player_wrap">
          {videoDurationError && (
            <p className="text-danger my-2">
              Video duration should be within 10 to 60 seconds.
            </p>
          )}
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={file}
              width="100%"
              height="100%"
              controls={true}
              playsinline
            />
          </div>
          <Link
            to="#"
            className="lh-1 close_btn border right_10 close_sm"
            onClick={handleFileReset}
          >
            <i className="ri-close-fill ri-xl"></i>
          </Link>
        </div>
      );
    } else if (file !== "" && mediaType === "image") {
      return (
        <div className="upload_file_wrap">
          <div className="photo_preview">
            <img width="200" src={file} alt="Preview" />
            <Link
              to="#"
              className="lh-1 close_btn bg-white right_10 close_sm"
              onClick={handleFileReset}
            >
              <i className="ri-close-fill ri-xl"></i>
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mt-3 position-relative">
      {fileTypeError && (
        <h5 className="text-danger my-2">File type not supported</h5>
      )}

      {fileSizeError && (
        <h6 className="text-danger my-2">
          Uploaded media size is <strong>{fileSizeValue}MB</strong> after
          compression. Max file size allowed after compression is{" "}
          <strong>20MB</strong>
        </h6>
      )}

      <div className={`upload_file_wrap ${file !== "" ? "file_uploaded" : ""}`}>
        <div className="upload_input">
          <Form.Label htmlFor="uploadFile">
            <input
              type="file"
              name="uploadFile"
              accept="image/jpeg, image/png, image/jpg, video/mp4"
              id="uploadFile"
              onChange={handleChange}
              onClick={handleInputReset}
            />
            <div className="upload_file_inner d-flex flex-column align-items-center">
              {renderMediaIcon()}
              <span className="text-muted">or drag and drop</span>
            </div>
            <Link
              to="#"
              className="lh-1 close_btn border right_10"
              onClick={handleCloseClick}
            >
              <i className="ri-close-fill ri-xl"></i>
            </Link>
          </Form.Label>
        </div>
      </div>

      {renderMediaPlayer()}
    </div>
  );
}

export default ImageVideoPreviewCreate;
