import React, { useState , useEffect} from 'react'
import {Row, Col, Container, Form, Button, Image , Alert} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import "./sign-up.css"

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

import img1  from "../../../assets/images/LoginPage/Beer.jpg"
import img2  from "../../../assets/images/LoginPage/Fashion.jpg"
import img3  from "../../../assets/images/LoginPage/Food.jpg"
import img4  from "../../../assets/images/LoginPage/Sports.jpg"
import img5  from "../../../assets/images/LoginPage/Travel.jpg"
import img6  from "../../../assets/images/LoginPage/Wine.jpg"
import loader from "../../../assets/images/page-img/page-load-loader.gif"

//img
import logo from '../../../assets/images/logo_web.png'
import { servicePath } from '../app/variables'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {





  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [showMessage, setshowMessage] = useState(true)
  const [loading, setLoading] = useState(false)
  const [signInLoading, setSignInLoading] = useState(false)



  
  useEffect(() => {
   setTimeout(() => {
     setshowMessage(false)
   }, 3000);
  }, [])


  
  
  const location = useLocation()



  let history = useHistory();

  const login = () => {
    setSignInLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      origin: "web",
      data: {
        username: email,
        password: password,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/auth.login`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>{
          if(result.status === true && result.success === "Your now logged into Universimm.com"){
              history.push("/" , {message : result.success})
              localStorage.setItem("users" , JSON.stringify(result.data.user))
              localStorage.setItem("access_token" , result.data.access_token)
              console.error("userData", JSON.stringify(result.data.user));
            
          }
          else if(result.status === false){
                  setError(result.error)
          }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setSignInLoading(false))

  };

  sessionStorage.setItem("recoveryMail" , email)

  const resetPassword = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZXYudW5pdmVyc2ltbS5jb21cL2luZGV4LnBocFwvYXBpXC9hY3Rpb25cL2F1dGgubG9naW4iLCJpYXQiOjE2NTM5MTY4NTgsImV4cCI6MTY4NTQ1Mjg1OCwibmJmIjoxNjUzOTE2ODU4LCJqdGkiOiI3ZnhmNWFxZm8ycGdYV3ZWIiwic3ViIjo3LCJwcnYiOiJjMzJiNDU5OGZjNGNmNjdmYzZlZTM2MjY3ZmQ1ODg5NzhhZGM2ZmQ1In0.m_2AqcLRkQVJA56KpQRL3dFzf20tWFXVWLp0BmqrbTA"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      origin: "web",
      data: {
        email: email,
        phone: "",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/auth.request_password_change`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.status === true && result.success === "Account recovery code sent"){
          setMessage(result.success)
          history.push("/auth/reset-password" , {state : {message : result.success}})
        }

      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(true))
  };

  const swiperImages = [
    {
      image : img1
    },
    {
      image : img2
    },
    {
      image : img3
    },
    {
      image : img4
    },
    {
      image : img5
    },
    {
      image : img6
    },
  ]



  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          {/* <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div> */}
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5">
              <div className="sign-in-detail text-white">
                <Link className="sign-in-logo mb-5" to="#">
                  <Image src={logo} className="img-fluid" alt="logo" />
                </Link>
                <div className="sign-slider overflow-hidden ">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0 "
                  >
                    
                      {swiperImages.map((item , index) => {
                        return(
                          <SwiperSlide>
                          <Image
                          src={item.image}
                          className="img-fluid mb-4"
                          alt="logo"
                        />
                        <h4 className="mb-1 text-white">Find new friends</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the readable content.
                        </p>
                        </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
              {/* {location.state.state.successMessage === "Password changed" && showMessage ? <div className="successMessage">
                <Alert variant="primary">Password Changed Succesfully</Alert>
              </div> : <></>} */}
        

              <div className="sign-in-from">
                <h1 className="mb-0">Sign in</h1>
                <p>
                  Enter your email address and password to access admin panel.
                </p>
                <div className="text-danger">{error}</div>
                <Form className="mt-4">
                  <Form.Group className="form-group">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      className="mb-0"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      onInput={() => setError("")}
                    />
                    
                  </Form.Group>
                  <Form.Group className="form-group">
                    {loading ? <img src={loader} alt="loader" style={{ height: "50px" , float : "right"}} /> :
                    <div
                      onClick={() => resetPassword()}
                      className="forgot-password float-end text-primary cursor-pointer"
                    >
                        Forgot password?
                      
                    </div>}
                    <Form.Control
                      type="password"
                      className="mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      onInput={() => setError("")}
                    />
                  </Form.Group>
                  <div className="d-inline-block w-100">
                    <Form.Check className="d-inline-block mt-2 pt-1">
                      <Form.Check.Input
                        type="checkbox"
                        className="me-2"
                        id="customCheck11"
                      />
                      <Form.Check.Label>Remember Me</Form.Check.Label>{" "}
                    </Form.Check>
                    {signInLoading ? <img src={loader} alt="loader" style={{ height: "50px" , float : "right"}} /> :
                    <Button
                      variant="primary"
                      type="button"
                      className="float-end"
                      onClick={() => login()}
                    >
                      Sign in
                    </Button>}
                  </div>
                  <div className="sign-info">
                    <span className="dark-color d-inline-block line-height-2">
                      Don't have an account?{" "}
                      <Link to="/auth/sign-up">Sign up</Link>
                    </span>
                    <ul className="iq-social-media">
                      <li>
                        <Link to="#">
                          <i className="ri-facebook-box-line"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ri-twitter-line"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ri-instagram-line"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn
