export const baseURL = `${process.env.REACT_APP_API_HOST}`;
export const mainBaseURL = `${process.env.REACT_APP_API_ENDPOINT}/index.php`;
export const servicePath = `${mainBaseURL}/api/action`;

// console.log(baseURL, servicePath, "servicePath base")

export const responsiveUniversesProfile = {
  desktop: {
    breakpoint: { max: 3000, min: 901 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 900, min: 576 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 575, min: 481 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
    partialVisibilityGutter: 10,
  },
  xs: {
    breakpoint: { max: 480, min: 320 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    partialVisibilityGutter: 10,
  },
};

export const responsiveUniversesFeeds = {
  desktop: {
    breakpoint: { max: 3000, min: 901 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 900, min: 576 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 575, min: 381 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
    partialVisibilityGutter: 10,
  },
  xs: {
    breakpoint: { max: 380, min: 320 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    partialVisibilityGutter: 10,
  },
};

export const responsiveUniversesModal = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 7,
    slidesToSlide: 6, // optional, default to 1.
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1199, min: 576 },
    items: 6,
    slidesToSlide: 4, // optional, default to 1.
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    partialVisibilityGutter: 10,
  },
};
