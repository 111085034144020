import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import FormikFile from "../../../components/formiks/FormikFile";
import FormikSelect from "../../../components/formiks/FormikSelect";
import { yup } from "../../../constant/variables";
import axiosInstance from "../../../helper/axiosInstance";
import useUniverse from "../../../hooks/services/useUniverse";
import { INVALIDATE_STORY_TYPES } from "../../../hooks/services/useUniverseTypeStories";
import useLocalStorage from "../../../hooks/useLocalStorage";

const CreateStoryModal = ({ handleClose, show, storyType }) => {
  const [userInfo] = useLocalStorage("users", {});
  const isUniverse = storyType === "universe";
  const { data = [] } = useUniverse();
  // const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();

  const [isError, setIsError] = useState("");

  const { mutateAsync, isLoading, error } = useMutation(
    async (value) => {
      setIsError("");
      const { data } = await axiosInstance({
        url: "/app/api/v1/action/stories/create",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: (progressEvent) => {
        //   const percentCompleted = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   setProgress(percentCompleted);
        // },
        data: value,
      });

      return data;
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          queryClient.invalidateQueries([INVALIDATE_STORY_TYPES]);
          toast.success("Story created successfully");
          handleClose();
        } else {
          setIsError(res.message?.join(", "));
        }
      },
    }
  );

  useEffect(() => {
    if (error) {
      setIsError("Something went wrong, Try again later!");
    } else {
      setIsError("");
    }
  }, [error]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="py-2" closeButton>
        <Modal.Title className="text-capitalize">
          Upload {storyType} Story
        </Modal.Title>
      </Modal.Header>

      <div className="p-2">
        <Formik
          initialValues={{
            universe_id: "",
            media_urls: "",
          }}
          onSubmit={(values) => {
            const apiResponse = {
              user_id: userInfo?.id,
              story_type: storyType,
              origin: "web",
              media_type: "image",
            };
            const formDataToSend = new FormData();
            formDataToSend.append("user_id", apiResponse.user_id);
            formDataToSend.append("origin", apiResponse.origin);
            formDataToSend.append("story_type", apiResponse.story_type);
            formDataToSend.append("media_type", apiResponse.media_type);
            formDataToSend.append("universe_id", values.universe_id ?? "");
            formDataToSend.append("media_urls[]", values.media_urls);
            mutateAsync(formDataToSend);
          }}
          validationSchema={yup.object().shape({
            universe_id: isUniverse
              ? yup.string().required("Universe is required")
              : yup.string(),
            media_urls: yup
              .mixed()
              .required("File is required")
              .test("fileType", "Invalid file format", (value) => {
                if (!value) return true; // If no file is provided, skip the test

                const allowedFileTypes = [
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/gif",
                  "image/webp",
                  // "application/pdf",
                ]; // Add allowed file types here
                return allowedFileTypes.includes(value.type);
              }),
          })}
        >
          <Form>
            {isError && <div className="alert-message">{isError}</div>}
            <fieldset disabled={isLoading}>
              {isUniverse && (
                <FormikSelect
                  label={"Select Universe"}
                  name="universe_id"
                  options={data.map((item) => ({
                    label: item.universeName,
                    value: item.ID,
                  }))}
                />
              )}
              <FormikFile name={"media_urls"} />

              <button type="submit" className="btn btn-primary w-100">
                {/* {isLoading ? <>Uploading {progress}%</> : "Upload"} */}
                {isLoading ? "Uploading" : "Upload"}
              </button>
            </fieldset>
          </Form>
        </Formik>
      </div>
      {/* <ImageUploadComponent /> */}
    </Modal>
  );
};

export default CreateStoryModal;
