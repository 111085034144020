import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyCspYXc8akptSjTcmOonE0HecnJEAV1kLo",
  authDomain: "universimm7.firebaseapp.com",
  databaseURL: "https://universimm7.firebaseio.com",
  projectId: "universimm7",
  storageBucket: "universimm7.appspot.com",
  messagingSenderId: "365632737865",
  appId: "1:365632737865:web:39cb25a98ee7178f1b2598",
  measurementId: "G-BW7PQGLHY9"
};


const app = initializeApp(firebaseConfig);
var database = getDatabase(app);
  
export default database;
