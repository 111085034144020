import React from "react";
import SuggestedFriend from "../dashboard/app/suggestedFriend";
import "./StickySuggestedFriends.css";

const StickySuggestedFriends = () => {
  return (
    <div className="suggested-friends-main-sticky d-none d-xl-block">
      <SuggestedFriend />
    </div>
  );
};

export default StickySuggestedFriends;
