import React, {useEffect, useRef, useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import reactDom from 'react-dom';
import Carousel from "react-multi-carousel";
import { BiFilterAlt } from 'react-icons/bi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { responsiveUniversesModal, servicePath } from '../app/variables';

const PostFilterModal = ({
        universes,
        subUniverses, 
        getUniverses,
        multiverseCountry,
        setLoading,
        filterUniverseID,
        setFilterUniverseID,
        filterSubUniverseID,
        setFilterSubUniverseID,
        filterMultiverseID,
        setFilterMultiverseID,
        filterSubMultiverseID,
        setFilterSubMultiverseID,
        getAllPosts,
        setShowSubUniIndex,
        setSelectedUniverseIndex,
        setSelectedUniverseNameIndex,
        setSelectedSubUniIndex,
        setSelectedSubUniverseNameIndex,

        selectedUniverseIndex,
        selectedSubUniIndex,
        token,

        setSubsUniverseModal,
        setUniverId,
    }) => {
    
    const [filterModal, setFilterModal] = useState(false)
    const [selectedUniverse, setSelectedUniverse] = useState('')
    const [selectedUniverseName, setSelectedUniverseName] = useState('')
    const [selectedSubUni, setSelectedSubUni] = useState('')
    const [selectedSubUniverseName, setSelectedSubUniverseName] = useState('')
    
    const [showSubUni, setShowSubUni] = useState(false)
    const [showMultiverses, setShowMultiverses] = useState(false)

    
    const [subMultiverseList, setSubMultiverseList] = useState([])

    const [selectedMultiverse, setSelectedMultiverse] = useState({
        multiverseID: '',
        multiverseName: '',
    });

    const [selectedMultiverseCountry, setSelectedMultiverseCountry] = useState({
        submultiverseID: '',
        submultiverse: '',
    });

    const universeRef = useRef()
    const subUniverseRef = useRef()

    const autoSlideUniverse = (active, total) => {
        var currentActive = universeRef.current
        var carDiff = total - active

        if(currentActive.state && currentActive.state.slidesToShow) {
            var slideValue = active - currentActive.state.slidesToShow + carDiff

            if(carDiff < currentActive.state.slidesToShow) {
                universeRef.current.goToSlide(slideValue);
            } else {
                universeRef.current.goToSlide(active);
            }
        }
    }

    const autoSlideSubUniverse = (active, total) => {
        
        var currentActive = subUniverseRef.current
        var carDiff = total - active

        if(currentActive.state && currentActive.state.slidesToShow) {
            var slideValue = active - currentActive.state.slidesToShow + carDiff
            
            // console.log(total, active, slideValue,currentActive.state.slidesToShow, carDiff, "carDiff");

            if(carDiff < currentActive.state.slidesToShow) {
                subUniverseRef.current.goToSlide(slideValue);
            } else {
                subUniverseRef.current.goToSlide(active);
            }
        }
    }
    
    useEffect(() => {

        if (filterModal) {
            userState()
        }

        if(selectedUniverseIndex) {
            setSelectedUniverse(filterUniverseID.id)
            setSelectedUniverseName(filterUniverseID.name)

            setShowMultiverses(true)
            setShowSubUni(true)

            if(filterModal) {
                setTimeout(() => {
                    var getActiveIndex = document.getElementsByClassName("filter_current_universe")
                    // console.log(getActiveIndex.length, "getActiveIndex")
                    var slideIndexActive = 0
    
                    if(getActiveIndex && getActiveIndex.length != 0) {
                        slideIndexActive = getActiveIndex[0].attributes.data_id.nodeValue;
                    } else {
                        slideIndexActive = 0
                    }
    
                    if (universeRef && universeRef.current) {
                        autoSlideUniverse(slideIndexActive, 12);
                    }
                }, 1500);
            }
        } else {
            setSelectedUniverse('')
            setSelectedUniverseName('')
            setShowMultiverses(false)
            setShowSubUni(false)
        }

        if(selectedSubUniIndex) {
            setSelectedSubUni(filterSubUniverseID.id)
            setSelectedSubUniverseName(filterSubUniverseID.name)

            if(filterModal) {
                setTimeout(() => {
                    var getActiveIndex = document.getElementsByClassName("filter_current_subuniverse")
        
                    var slideIndexActive = 0
                    if(getActiveIndex && getActiveIndex.length != 0) {
                        slideIndexActive = getActiveIndex[0].attributes.data_id.nodeValue;
                    } else {
                        slideIndexActive = 0
                    }
    
                    if (universeRef && universeRef.current) {
                        autoSlideSubUniverse(slideIndexActive, subUniverses.length);
                    } else {
                        autoSlideSubUniverse(0, subUniverses.length);
                    }
                }, 1500);
            }
        } else {
            setSelectedSubUni('')
            setSelectedSubUniverseName('')
        }
        
    }, [selectedUniverseIndex, selectedSubUniIndex, filterModal])

    const handleClose = () => {setFilterModal(false)};

    const handleFilter = () => {
        setFilterModal(true)

        // if(filterUniverseID.id) {
        //     setSelectedUniverse(filterUniverseID.id)
        //     getUniverses(filterUniverseID.id);
        // }

        // if(filterSubUniverseID.id) {
        //     setSelectedSubUni(filterSubUniverseID.id)

        //     setShowSubUni(true)
        // } else {
        //     setShowSubUni(false)
        // }

        // setSelectedUniverseName('');
        // setSelectedSubUniverseName("");
        // setSelectedSubUni("");
        // setSelectedUniverse('');
        // setShowSubUni(false)

        // setShowMultiverses(false)

        // selectedMultiverse.multiverseID='';
        // selectedMultiverse.multiverseName='';
        // setSelectedMultiverse({...selectedMultiverse});

        // selectedMultiverseCountry.submultiverseID='';
        // selectedMultiverseCountry.submultiverse='';
        // setSelectedMultiverseCountry({...selectedMultiverseCountry});

        // filterUniverseID.id = ''
        // filterUniverseID.name = ''
        // setFilterUniverseID({...filterUniverseID});

        // filterSubUniverseID.id = ''
        // filterSubUniverseID.name = ''
        // setFilterSubUniverseID({...filterSubUniverseID});

        // filterMultiverseID.id = ''
        // filterMultiverseID.name = ''
        // setFilterMultiverseID({...filterMultiverseID});

        // filterSubMultiverseID.id = ''
        // filterSubMultiverseID.name = ''
        // setFilterSubMultiverseID({...filterSubMultiverseID});
    }

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const {
            carouselState: { currentSlide },
        } = rest;
        return (
            <div className="carousel-button-group create_post_btn">
            <div className="icon" onClick={() => previous()}>
                <IoIosArrowBack size={18} />
            </div>
            <div className="icon" onClick={() => next()}>
                <IoIosArrowForward size={18} />
            </div>
            </div>
        );
    };

    const handleCheckUniverse = (item, e) => {
        // setLoading(true)

        if(e.currentTarget.classList.contains('isSubscribed')){
            
            setSelectedUniverseName(item.universeName);
            setSelectedSubUniverseName("");
            setSelectedSubUni("");
            setSelectedUniverse(item.ID);
            getUniverses(item.ID);
            setShowSubUni(true)

            setShowMultiverses(true)

            setSubMultiverseList([])

            filterSubUniverseID.id = ''
            filterSubUniverseID.name = ''
            setFilterSubUniverseID({...filterSubUniverseID});

            filterMultiverseID.id = ''
            filterMultiverseID.name = ''
            setFilterMultiverseID({...filterMultiverseID});

            filterSubMultiverseID.id = ''
            filterSubMultiverseID.name = ''
            setFilterSubMultiverseID({...filterSubMultiverseID});

            selectedMultiverse.multiverseID='';
            selectedMultiverse.multiverseName='';
            setSelectedMultiverse({...selectedMultiverse});

            selectedMultiverseCountry.submultiverseID='';
            selectedMultiverseCountry.submultiverse='';
            setSelectedMultiverseCountry({...selectedMultiverseCountry});

            setTimeout(() => {
                setLoading(false)
            }, 500);

        } else {
            // console.log("not isSubscribed")

            setSubsUniverseModal(true)

            setUniverId(item.ID)

            setFilterModal(false)
        }
    }

    const handleSelectSubUni = (item) => {

        setSelectedSubUni(item.ID);
        setSelectedSubUniverseName(item.subUniverse)

        filterMultiverseID.id = ''
        filterMultiverseID.name = ''
        setFilterMultiverseID({...filterMultiverseID});

        setSubMultiverseList([])

        filterSubMultiverseID.id = ''
        filterSubMultiverseID.name = ''
        setFilterSubMultiverseID({...filterSubMultiverseID});

        selectedMultiverse.multiverseID='';
        selectedMultiverse.multiverseName='';
        setSelectedMultiverse({...selectedMultiverse});

        selectedMultiverseCountry.submultiverseID='';
        selectedMultiverseCountry.submultiverse='';
        setSelectedMultiverseCountry({...selectedMultiverseCountry});
    }

    const handleSelectPostMultiverse = (ID, name) => {
        getSubMultiverse(ID)
        setSubMultiverseList([])

        // console.log(name, ID)
        selectedMultiverse.multiverseID=ID;
        selectedMultiverse.multiverseName=name;
        setSelectedMultiverse({...selectedMultiverse});

        filterSubMultiverseID.id = ''
        filterSubMultiverseID.name = ''
        setFilterSubMultiverseID({...filterSubMultiverseID});

        selectedMultiverseCountry.submultiverseID='';
        selectedMultiverseCountry.submultiverse='';
        setSelectedMultiverseCountry({...selectedMultiverseCountry});
    }

    const handleSelectPostSubMultiverse = (ID, name) => {

        selectedMultiverseCountry.submultiverseID=ID;
        selectedMultiverseCountry.submultiverse=name;
        setSelectedMultiverseCountry({...selectedMultiverseCountry});
    }

    function handleImageProken(e) {
        reactDom.findDOMNode(e.target).parentNode.classList.remove('image_broken');
    }

    const userState = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };
        
        fetch(
            `${servicePath}/user.state`,
            requestOptions
        )
        .then((response) => response.json())
        .then((result) => {

            // console.log(result, "user state data");
            
            function getUniverIds(item) {
                var ids = item.ID

                if(result.data.universes.includes(ids)){

                    document.getElementById(`universe_card_m${ids}`).classList.remove('isSubscribed');
                } else {
                    document.getElementById(`universe_card_m${ids}`).classList.add('isSubscribed');
                }
            }

            if(result && result.data){        
                result.data.universes.forEach(getUniverIds)
            }
        })
        .catch((error) => console.log("error", error));
    };

    const getSubMultiverse = (ID) => {  

        setLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "parentID": ID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(
            `${servicePath}/public.multiverses`,
            requestOptions
        )
        .then((response) => response.json())
        .then((result) =>{

            if(result){
                // console.log(result, "sub multiverse")
                // setSelectedMultiverseCountry(result.data.multiverse)
    
                setSubMultiverseList(result.data.submultiverse)
            }
        })
        .catch((error) => console.log("error", error))
        .finally((error) => setLoading(false))
    };

    const resetFilter = () => {

        setSelectedUniverseName('');
        setSelectedSubUniverseName("");
        setSelectedSubUni("");
        setSelectedUniverse('');
        setShowSubUni(false)
        setShowMultiverses(false)
        setSubMultiverseList([])

        setShowSubUniIndex(false)
        setSelectedUniverseIndex('');
        setSelectedUniverseNameIndex('');
        setSelectedSubUniIndex('');
        setSelectedSubUniverseNameIndex('');

        selectedMultiverse.multiverseID='';
        selectedMultiverse.multiverseName='';
        setSelectedMultiverse({...selectedMultiverse});

        selectedMultiverseCountry.submultiverseID='';
        selectedMultiverseCountry.submultiverse='';
        setSelectedMultiverseCountry({...selectedMultiverseCountry});

        filterUniverseID.id = ''
        filterUniverseID.name = ''
        setFilterUniverseID({...filterUniverseID});

        filterSubUniverseID.id = ''
        filterSubUniverseID.name = ''
        setFilterSubUniverseID({...filterSubUniverseID});

        filterMultiverseID.id = ''
        filterMultiverseID.name = ''
        setFilterMultiverseID({...filterMultiverseID});

        filterSubMultiverseID.id = ''
        filterSubMultiverseID.name = ''
        setFilterSubMultiverseID({...filterSubMultiverseID});
        
        setTimeout(() => {
            getAllPosts()
            userState()
        }, 200);
    }

    const applyFilter = () => {
        setFilterModal(false)

        // console.log(selectedUniverse, selectedSubUni, selectedMultiverse.multiverseID, selectedMultiverseCountry.submultiverseID, "seleted data apply filter")

        if(selectedUniverse != '') {
            filterUniverseID.id = selectedUniverse
            filterUniverseID.name = selectedUniverseName
            setFilterUniverseID({...filterUniverseID});

            setSelectedUniverseIndex(selectedUniverse);
            setSelectedUniverseNameIndex(selectedUniverseName);

        } else {
            setSelectedUniverseIndex('');
            setSelectedUniverseNameIndex('');

            filterUniverseID.id = ''
            filterUniverseID.name = ''
            setFilterUniverseID({...filterUniverseID});
        }

        if(selectedSubUni) {
            filterSubUniverseID.id = selectedSubUni
            filterSubUniverseID.name = selectedSubUniverseName
            setFilterSubUniverseID({...filterSubUniverseID});

            setShowSubUniIndex(true)

            setSelectedSubUniIndex(selectedSubUni);
            setSelectedSubUniverseNameIndex(selectedSubUniverseName);
        } else {
            
            setShowSubUniIndex(false)

            setSelectedSubUniIndex('');
            setSelectedSubUniverseNameIndex('');

            filterSubUniverseID.id = ''
            filterSubUniverseID.name = ''
            setFilterSubUniverseID({...filterSubUniverseID});
        }

        if(selectedMultiverse.multiverseID) {
            filterMultiverseID.id = selectedMultiverse.multiverseID
            filterMultiverseID.name = selectedMultiverse.multiverseName
            setFilterMultiverseID({...filterMultiverseID});
        } else {
            filterMultiverseID.id = ''
            filterMultiverseID.name = ''
            setFilterMultiverseID({...filterMultiverseID});

            setSubMultiverseList([])
        }

        if(selectedMultiverseCountry.submultiverseID) {
            filterSubMultiverseID.id = selectedMultiverseCountry.submultiverseID
            filterSubMultiverseID.name = selectedMultiverseCountry.submultiverse
            setFilterSubMultiverseID({...filterSubMultiverseID});
        } else {
            filterSubMultiverseID.id = ''
            filterSubMultiverseID.name = ''
            setFilterSubMultiverseID({...filterSubMultiverseID});
        }

        // setShowSubUniIndex(false)
        // setSelectedUniverseIndex('');
        // setSelectedUniverseNameIndex('');
        // setSelectedSubUniIndex('');
        // setSelectedSubUniverseNameIndex('');

        setTimeout(() => {
            getAllPosts()
        }, 1000);
    }

return (
    <>  
        <Button variant="soft-primary" className='d-flex align-items-center py-1 px-2' onClick={handleFilter}>
            <span>Filters</span> <BiFilterAlt size={18} className="ms-1" />
        </Button>

        <Modal
            size=""
            className="fade filter_modal"
            id="post-modal"
            dialogClassName="modal-dialog-centered"
            onHide={handleClose}
            show={filterModal}
        >
            <div className="p-3">
                <div className="d-flex justify-content-between">
                    <h4 className="mb-2">Post filter</h4>
                    <Link to="#" className="lh-1" onClick={() => {setFilterModal(false)}}>
                        <i className="ri-close-fill ri-xl"></i>
                    </Link>
                </div>
                <Modal.Body className='p-0'>
                    <div className='filter_wrap mb-2'>
                        {universes && universes.length > 0 ? (
                            <div className="position-relative d-flex flex-column justify-content-between create_post py-0">
                                <h5 className="mb-1 fw-500 text-left">Select universe</h5>

                                <Carousel
                                    ref={universeRef}
                                    arrows={false}
                                    customButtonGroup={<ButtonGroup />}
                                    infinite = {false}
                                    responsive={responsiveUniversesModal}
                                    autoPlay={false}
                                    containerClass="carousel-container mb-0"
                                    renderButtonGroupOutside={true}
                                    itemClass="clickable"
                                    sliderClass="react-multi-carousel-track"
                                >
                                    {universes.map((item, index) => {
                                        return(
                                            <div key={item.ID} data_id={index} className={`text-center universe_sub_check image_broken uni_image_wrap filter_modal ${selectedUniverse === item.ID ? "filter_current_universe" : ''}`} id={`universe_card_m${item.ID}`} onClick={(e) => {handleCheckUniverse(item, e)}}>
                                                <img
                                                    src={item.universeIcon}
                                                    alt="sub universe"
                                                    className={selectedUniverse === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}
                                                    onError={(e) => {handleImageProken(e)}}
                                                />

                                                <span className={selectedSubUni === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}>
                                                    <span>{item.universeName.substring(0, 1)}</span>
                                                </span>
                                                
                                                <div  className="universes-text">{item.universeName}</div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                        ) : ('')}
                    </div>
                    
                    {showSubUni && (
                        <div className='filter_wrap mb-2'>
                            {subUniverses && subUniverses.length > 0 && showSubUni ? (
                                <div className="position-relative d-flex flex-column justify-content-between create_post py-0">
                                    <h5 className="mb-1 fw-500 text-left">Select sub universe</h5>
                                    <Carousel
                                        ref={subUniverseRef}
                                        arrows={false}
                                        customButtonGroup={<ButtonGroup />}
                                        responsive={responsiveUniversesModal}
                                        autoPlay={false}
                                        containerClass="carousel-container mb-0"
                                        itemClass="clickable"
                                        renderButtonGroupOutside={true}
                                    >
                                        {subUniverses.map((item, index) =>{
                                            return(
                                                <div key={item.ID} data_id={index} className={`text-center image_broken uni_image_wrap filter_modal ${selectedSubUni === item.ID ? "filter_current_subuniverse" : ''}`} onClick={() => {handleSelectSubUni(item)}}>
                                                    <img
                                                        src={item.universeIcon}
                                                        alt="sub universe"
                                                        className={selectedSubUni === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}
                                                        onError={(e) => {handleImageProken(e)}}
                                                    />

                                                    <span className={selectedSubUni === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}>
                                                        <span>{item.subUniverse.substring(0, 1)}</span>
                                                    </span>
                                                    
                                                    <div  className="universes-text">{item.subUniverse}</div>
                                                </div>
                                            )
                                        })
                                        }
                                    </Carousel>
                                </div>
                            ) : ('')}
                        </div>
                    )}
                    
                    {showMultiverses && (
                        <div className='filter_wrap mb-2'>
                            {multiverseCountry && multiverseCountry.length > 0 ? (
                                <div className="position-relative d-flex flex-column justify-content-between create_post py-0">
                                    <h5 className="mb-1 fw-500 text-left">Select multiverse</h5>
                                    <Carousel
                                        arrows={false}
                                        customButtonGroup={<ButtonGroup />}
                                        responsive={responsiveUniversesModal}
                                        autoPlay={false}
                                        containerClass="carousel-container mb-0"
                                        itemClass="clickable"
                                        renderButtonGroupOutside={true}
                                    >
                                        {multiverseCountry.map((item) =>{
                                            return(
                                                <div key={item.ID} className="text-center image_broken uni_image_wrap filter_modal" onClick={() => {handleSelectPostMultiverse(item.ID, item.multiverseName)}}>
                                                    <img
                                                        src={item.multiverseImg}
                                                        alt="sub universe"
                                                        className={selectedMultiverse.multiverseID === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}
                                                        onError={(e) => {handleImageProken(e)}}
                                                    />

                                                    <span className={selectedMultiverse.multiverseID === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}>
                                                        <span>{item.multiverseName.substring(0, 1)}</span>
                                                    </span>
                                                    
                                                    <div  className="universes-text">{item.multiverseName}</div>
                                                </div>
                                            )
                                        })
                                        }
                                    </Carousel>
                                </div>
                            ) : ('')}
                        </div>
                    )}

                    <div className='filter_wrap mb-2'>
                        {subMultiverseList && subMultiverseList.length > 0 ? (
                            <div className="position-relative d-flex flex-column justify-content-between create_post py-0">
                                <h5 className="mb-1 fw-500 text-left">Select sub multiverse</h5>
                                <Carousel
                                    arrows={false}
                                    customButtonGroup={<ButtonGroup />}
                                    responsive={responsiveUniversesModal}
                                    autoPlay={false}
                                    containerClass="carousel-container mb-0"
                                    itemClass="clickable"
                                    renderButtonGroupOutside={true}
                                >
                                    {subMultiverseList.map((item) =>{
                                        return(
                                            <div key={item.ID} className="text-center image_broken uni_image_wrap filter_modal" onClick={() => {handleSelectPostSubMultiverse(item.ID, item.submultiverse)}}>
                                                <img
                                                    src={item.multiverseImg}
                                                    alt="sub universe"
                                                    className={selectedMultiverseCountry.submultiverseID === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}
                                                    onError={(e) => {handleImageProken(e)}}
                                                />

                                                <span className={selectedMultiverseCountry.submultiverseID === item.ID ? "rounded-circle story-img-active" : "rounded-circle story-img"}>
                                                    <span>{item.submultiverse.substring(0, 1)}</span>
                                                </span>
                                                
                                                <div  className="universes-text">{item.submultiverse}</div>
                                            </div>
                                        )
                                    })
                                    }
                                </Carousel>
                            </div>
                        ) : ('')}
                    </div>
                </Modal.Body>
                
                {selectedUniverseName && (
                    <div className='mt-3 d-flex justify-content-center'>
                        <h6>
                            Filter posts for {' '}
                            <strong>{selectedUniverseName}</strong> 
                            {selectedSubUniverseName ? <span> - <strong>{selectedSubUniverseName}</strong></span> : ''} 
                            {selectedMultiverse.multiverseName ? <span> - <strong>{selectedMultiverse.multiverseName}</strong></span> : ''} 
                            {selectedMultiverseCountry.submultiverse ? <span> - <strong>{selectedMultiverseCountry.submultiverse}</strong></span> : ''}</h6>
                    </div>
                )}
                <Modal.Footer className='px-0 mt-3 py-0 d-flex justify-content-between border-0'>
                    <Button type="button" variant="outline-danger" onClick={() => {setFilterModal(false)}} className="me-2">
                        Cancel
                    </Button>
                    
                    <div className='d-flex'>
                        <Button type="button" variant="outline-secondary" onClick={resetFilter} className="me-2">
                            Reset
                        </Button>
                        <Button type="button" variant="primary" onClick={applyFilter}>
                            Apply
                        </Button>
                    </div>
                </Modal.Footer>
            </div>
        </Modal>
    </>
    );
};
export default PostFilterModal;
