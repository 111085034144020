import React, { useState } from "react";
import StoryWrapper from "../storyViews/StoryWrapper";
import CreateStoryModal from "./CreateStoryModal";
import "./UploadStories.css";

const CreateStories = () => {
  const [show, setShow] = useState(false);
  const [storyType, setStoryType] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <StoryWrapper>
      <div className="story-upload-main-canvas-container">
        <div
          className="upload-story-image upload-personal-story-image"
          onClick={() => {
            handleShow();
            setStoryType("profile");
          }}
        >
          <div className="upload-story-icon">
            <i className="fas fa-image"></i>
          </div>
          <p>Create a personal Story</p>
        </div>
        <div
          className="upload-story-image upload-universe-story-image"
          onClick={() => {
            handleShow();
            setStoryType("universe");
          }}
        >
          <div className="upload-story-icon">
            <i className="fas fa-image"></i>
          </div>
          <p>Create a universe Story</p>
        </div>
      </div>
      {show && (
        <CreateStoryModal
          show={show}
          handleClose={handleClose}
          storyType={storyType}
        />
      )}
    </StoryWrapper>
  );
};

export default CreateStories;
