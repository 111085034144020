import { useEffect, useState } from "react";

// Custom hook for managing state with local storage persistence
function useLocalStorage(key, initialValue) {
  // Retrieve data from local storage if available, otherwise use initial value
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  // Create state to hold the current value
  const [value, setValue] = useState(initial);

  // Update local storage whenever the state value changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  // Return the current value and a function to update it
  return [value, setValue];
}

export default useLocalStorage;
