import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BiX } from "react-icons/bi";
import { NavLink } from "react-router-dom";

import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const SocialSharePopup = ({
  openModal,
  toggleModal,
  title,
  shareUrl,
  mediaUrl,
}) => {
  const windowWidth = 1000;
  const windowHeight = 730;

  const shareUrlText = `${shareUrl}`;

  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = () => {
    const copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setCopySuccess("Copied!");
  };

  return (
    <Modal
      show={openModal}
      className="custom_share_modal d-flex align-items-center media-share-modal"
    >
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="position-relative p-2 p-sm-3 w-100">
          <div className="border-bottom">
            <div className="d-flex justify-content-between w-100">
              <h3>Share on</h3>
              <NavLink
                to="#"
                className="btn-link text-muted"
                onClick={() => toggleModal(false)}
              >
                <BiX size={25} />
              </NavLink>
            </div>
            <div xxs="12" className="d-flex justify-content-evenly py-3">
              <div className="Demo__some-network">
                <FacebookShareButton
                  url={shareUrlText}
                  quote={title}
                  hashtag={[]}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  className="Demo__some-network__share-button"
                  onShareWindowClose={() => console.log("FacebookShareButton")}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <div>
                  <FacebookShareCount
                    url={shareUrlText}
                    className="Demo__some-network__share-count"
                  />
                </div>
              </div>

              <div className="Demo__some-network">
                <TwitterShareButton
                  url={shareUrlText}
                  title={title}
                  hashtags={[]}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  className="Demo__some-network__share-button"
                  onShareWindowClose={() => console.log("TwitterShareButton")}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                <div className="Demo__some-network__share-count">&nbsp;</div>
              </div>

              <div className="Demo__some-network">
                <WhatsappShareButton
                  url={shareUrlText}
                  title={title}
                  separator=""
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  className="Demo__some-network__share-button"
                  onShareWindowClose={() => console.log("WhatsappShareButton")}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <div className="Demo__some-network__share-count">&nbsp;</div>
              </div>

              <div className="Demo__some-network">
                <LinkedinShareButton
                  title={title}
                  url={shareUrlText}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  className="Demo__some-network__share-button"
                  onShareWindowClose={() => console.log("LinkedinShareButton")}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <div className="Demo__some-network__share-count">&nbsp;</div>
              </div>

              <div className="Demo__some-network">
                <TelegramShareButton
                  url={shareUrlText}
                  title={title}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  className="Demo__some-network__share-button"
                  onShareWindowClose={() => console.log("TelegramShareButton")}
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <div></div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-3">
            <p className="fw-bold text-nowrap d-block mb-1">Copy Link</p>
            <div className="d-flex">
              <input
                type="text"
                className="form-control br-4"
                name="copy"
                defaultValue={shareUrlText}
                id="myInput"
                readOnly
                onClick={copyToClipboard}
              />
              <Button
                color="primary"
                type="button"
                onClick={copyToClipboard}
                className="btn-md px-4 ms-2 br-4"
              >
                Copy
              </Button>
            </div>
          </div>

          {copySuccess && (
            <div className="text-primary mt-1 fw-500">{copySuccess}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default SocialSharePopup;
