import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import logoWeb from "../../../assets/images/universimm-logo-4.png"
import Footer from '../../../components/partials/dashboard/footerStyle/footer'

const TermsofService = () => {
    const history = useHistory()
    const userDetail = JSON.parse(localStorage.getItem("users"))

    return (
        <>
            <div className="header-main flex-wrap align-items-center justify-content-between py-2 mb-4">
                {userDetail === null ? (
                    <Link to="/auth/sign-up" className='header_logo py-1'>
                        <img src={logoWeb} className="w-100" alt="logo"/>
                    </Link>
                ) : (
                    <Link to="#" className='header_logo py-1' onClick={history.goBack}>
                        <img src={logoWeb} className="w-100" alt="logo"/>
                    </Link>
                )}

                {userDetail === null && (
                    <Link to="/auth/sign-up" className="btn btn-primary iq-sign-btn" role="button">Sign In
                        <i className="ri-login-box-line ms-2"></i>
                    </Link>
                )}
            </div>

            <Container>
                <Row className="docs-paras pt-4 pb-5 extra_page row">
                    <Col lg="12">
                        <h4 className="mb-2 fw-bold text-center">Welcome to Universimm!</h4>
                        <p>These terms and conditions outline the rules and regulations for the use of Universimm's website and apps, located at <a href="https://www.universimm.com">https://www.universimm.com.</a></p>
                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Universimm if you do not agree to take all of the terms and conditions stated on this page. By accessing or using Universimm, you agree to these Terms, including our Privacy Policy and Cookies Policy. Our main goal is to provide content that is relevant to you. We help you discover what you really love and like.  When you create a Universimm account, you must provide us with accurate and complete information. You must also be 13 years or older or if you have proper parental consent under the laws of your country. We will update software from time, and these Terms will apply to any updates. You are allowed to post content, including photos, comments, links, videos and voice messages. You are responsible for all user content that you post. If you delete your account all this info is owned by you and will be transferred. You grant Universimm and our users a non-exclusive, royalty-free, transferable, worldwide license to use, perform, and distribute your User Content. We reserve the right to remove or modify User Content, or change the way it’s used in</p>
                        <h4 className="mb-2 fw-bold text-center">Cookies</h4>
                        <p>We employ the use of cookies. By accessing Universimm, you agreed to use cookies in agreement with the Universimm's Privacy Policy & Cookies Policy found at <a href="https://www.universimm.com/user/cookie-policy">https://www.universimm.com/user/cookie-policy</a></p>
                        <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                        <h4 className="mb-2 fw-bold text-center">License</h4>
                        <p>Unless otherwise stated, Universimm and/or its licensors own the intellectual property rights for all material on Universimm. All intellectual property rights are reserved. You may access this from Universimm for your own personal use subject to restrictions set in these terms and conditions.
                        </p>
                        <h4 className="mb-2 fw-bold text-center">You must not:</h4>
                        <ul>
                            <li>Republish material from Universimm</li>
                            <li>Sell, rent or sub-license material from Universimm</li>
                            <li>Reproduce, duplicate or copy material from Universimm</li>
                            <li>Redistribute content from Universimm</li>

                        </ul>
                        <h4 className="mb-2 fw-bold text-center">This Agreement shall begin on the date hereof</h4>
                        <p>Parts of this website and apps offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Universimm does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Universimm,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Universimm shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                        <p>Universimm reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or which breach the Terms and Conditions.</p>
                        <p>You warrant and represent that:</p>
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property rights, including without limitation copyright, patent or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>

                        </ul>
                        <p>You hereby grant Universimm a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

                        <h4 className="mb-2 fw-bold text-center">Hyperlinking to our Content</h4>
                        <p>The following organizations may link to our Website without prior written approval:</p>
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                            <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</li>
                        </ul>
                        <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>
                        <h4 className="mb-2 fw-bold">We may consider and approve other link requests from the following types of organizations:</h4>
                        <ul>
                            <li>commonly-known consumer and/or business information sources;</li>
                            <li>dot.com community sites;</li>
                            <li>associations or other groups representing charities;</li>
                            <li>online directory distributors;</li>
                            <li>internet portals;</li>
                            <li>accounting, law and consulting firms; and</li>
                            <li>educational institutions and trade associations</li>
                        </ul>
                        <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Universimm; and (d) the link is in the context of general resource information.</p>
                        <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
                        <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an email to Universimm. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
                        <p>Approved organizations may hyperlink to our Website as follows:</p>
                        <ul>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator being linked to; or</li>
                            <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                        </ul>
                        <p>No use of Universimm's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                        <h4 className="mb-2 fw-bold text-center">iFrames</h4>
                        <p>Without prior approval and written permission, you may not create frames around our Web pages that alter in any way the visual presentation or appearance of our Website.</p>
                        <h4 className="mb-2 fw-bold text-center">Content Liability</h4>
                        <p>We are not responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                        <h4 className="mb-2 fw-bold text-center">Your Privacy</h4>
                        <p>Please read Privacy Policy: <a href="https://www.universimm.com/user/privacy-policy">https://www.universimm.com/user/privacy-policy</a></p>
                        <h4 className="mb-2 fw-bold text-center">Reservation of Rights</h4>
                        <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                        <h4 className="mb-2 fw-bold text-center">Removal of links from our website</h4>
                        <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly</p>
                        <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date</p>
                        <h4 className="mb-2 fw-bold text-center">Copyright & Security</h4>
                        <p>Universimm is protected by copyright. We care about the security of our users. While we work to protect the security of your content and account we can’t guarantee that unauthorized third parties won’t be able to defeat our security measures. We ask that you keep your password secure. Please notify us immediately of any compromise or unauthorized use of your account. We may revise our Terms from time to time and the most current version will always be posted on our website. You agree to our Terms & Privacy Policy.</p>
                        <h4 className="mb-2 fw-bold text-center">Third Party websites</h4>
                        <p>Universimm may contain links to third party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. We don’t endorse or assume any responsibility for any such third party sites, information, materials, products, or services. If you access any third party website, service, or content from Universimm, you do so at your own risk and you agree that Universimm has no liability arising from your use of or access to any third party website, service, or content. Universimm is a free service. We reserve the right to refuse service to anyone. If you register a business account on Universimm we are not responsible for any legal issues that may arise and will not pay any legal costs. Universimm grants you access, but it’s not responsible for how you operate your business. Universimm takes no responsibility and assumes no liability for any User Content that you or any other person or third party posts.. You understand and agree that you may be exposed to User Content that’s inaccurate or inappropriate for children. Please report any bad content to us. </p>
                        <h4 className="mb-2 fw-bold text-center">Backups </h4>
                        <p>We perform regular backups on Website & Apps and Content and will do our best to ensure completeness and accuracy of these backups. In the event of a crash or data loss we will restore backups automatically to minimize the impact and downtime. </p>
                        <h4 className="mb-2 fw-bold text-center">Disclamier</h4>
                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                        <ul>
                            <li>limit or exclude our or your liability for death or personal injury;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        </ul>
                        <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
                        <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access our Website or Apps and its Services. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                    </Col>
                </Row>
            </Container>

            <Footer hideBtn="1" />
        </>
    )
}

export default TermsofService
