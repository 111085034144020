import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import dummyUserImage from "../../../assets/images/no-profile-picture.jpg";
import { servicePath } from "../app/variables";
import { Linkify } from "./Functions";
import LinkPreview from "./LinkPreview";

const PostCommentsC = ({
  item,
  userData,
  token,
  commentCount,
  setCommentCount,
  getPostComments,
  postID,
}) => {
  const [commentLike, seCommentLike] = useState({
    isLiked: false,
  });

  const [commentDelete, setCommentDelete] = useState({
    isDeleted: false,
  });

  useEffect(() => {
    if (item.id !== "") {
      seCommentLike({
        isLiked: item.isLikedByMe,
      });
    }
  }, [item]);

  const history = useHistory();

  const friendProfile = (item) => {
    history.push(`/dashboard/app/friend-profile/${item.details.id}`);
  };

  const userProfile = () => {
    history.push(`/dashboard/app/profile`);
  };

  const handleCommentActions = (commentId, status, type) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var statusVal = 0;

    if (status == false) {
      statusVal = 1;
      // console.log(statusVal, type, status)
    } else {
      statusVal = 0;
      // console.log(statusVal, type, status)
    }

    var raw = JSON.stringify({
      action: type,
      data: {},
      query: {
        id: commentId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/comment.post`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)

        if (status) {
          seCommentLike({
            isLiked: false,
          });
        } else {
          seCommentLike({
            isLiked: true,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCommentDelete = (commentId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      action: "delete",
      data: {},
      query: {
        id: commentId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/comment.post`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)

        if (result) {
          setCommentDelete({
            isDeleted: true,
          });

          setCommentCount({
            cCount: commentCount.cCount - 1,
          });

          getPostComments(postID, 1, 5);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // function handleReadMore(e) {
  //   reactDom.findDOMNode(e.target).parentNode.classList.remove('read_less_active');
  // };

  // function handleReadLess(e) {
  //   reactDom.findDOMNode(e.target).parentNode.classList.add('read_less_active');
  // };

  return (
    <>
      <li
        className={`${
          commentDelete.isDeleted === true ? "d-none" : "d-flex mb-2"
        }`}
        key={item.id}
      >
        <div className="d-flex flex-nowrap">
          {userData && item.details.id === userData.id ? (
            <NavLink
              to="#"
              location={{}}
              onClick={() => userProfile()}
              className="me-2"
            >
              <div className="user-img">
                <img
                  src={
                    item.details && item.details.photo
                      ? item.details.photo
                      : dummyUserImage
                  }
                  alt="userimg"
                  className="avatar-35 rounded-circle img-fluid"
                  onError={(e) => {
                    e.target.src = dummyUserImage;
                  }}
                />
              </div>
            </NavLink>
          ) : (
            <NavLink
              to="#"
              location={{}}
              onClick={() => friendProfile(item)}
              className="me-2"
            >
              <div className="user-img">
                <img
                  src={
                    item.details && item.details.photo
                      ? item.details.photo
                      : dummyUserImage
                  }
                  alt="userimg"
                  className="avatar-35 rounded-circle img-fluid"
                  onError={(e) => {
                    e.target.src = dummyUserImage;
                  }}
                />
              </div>
            </NavLink>
          )}

          <div className="comment-data-block ms-2 w-100">
            <div className="comment-text">
              <h6>{item.details && item.details.name}</h6>
              <div className="">
                <p className="mb-0 text-black">
                  {item.comment && <Linkify>{item.comment}</Linkify>}

                  <LinkPreview data={item.comment} />
                </p>

                {/* hide when text length is less that 400 words and remove read_less_active class as well */}
                {/* <NavLink
                        onClick={handleReadMore}
                        className="btn-link font-weight-bold text-decoration-none read_more"
                        to="#"
                        location={{}}
                    >
                        Read more
                    </NavLink>

                    <NavLink
                        onClick={handleReadLess}
                        className="btn-link font-weight-bold text-decoration-none read_less"
                        to="#"
                        location={{}}
                    >
                        Read less
                    </NavLink> */}
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center comment-activity">
              {commentLike.isLiked == false && (
                <Link
                  to="#"
                  className="me-2 d-flex align-items-center"
                  onClick={() => {
                    handleCommentActions(item.id, commentLike.isLiked, "like");
                  }}
                >
                  <span className="ms-1 fw-bold lh-1">
                    {commentLike.isLiked == true ? "Liked" : "Like"}
                  </span>
                </Link>
              )}

              {commentLike.isLiked == true && (
                <Link
                  to="#"
                  className="me-2 d-flex align-items-center"
                  onClick={() => {
                    handleCommentActions(
                      item.id,
                      commentLike.isLiked,
                      "dislike"
                    );
                  }}
                >
                  <span className="ms-1 fw-bold lh-1">
                    {commentLike.isLiked == true ? "Liked" : "Like"}
                  </span>
                </Link>
              )}

              {userData && item.details.id === userData.id && (
                <Link
                  to="#"
                  className="me-3 d-flex align-items-center"
                  onClick={() => {
                    handleCommentDelete(item.id);
                  }}
                >
                  <span className="ms-1 fw-bold lh-1">
                    {commentDelete.isDeleted == true ? "" : "Delete"}
                  </span>
                </Link>
              )}
              {/* <Link to="#">reply</Link>
                <Link to="#">translate</Link> */}
              <span>{item.createdAt}</span>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};
export default PostCommentsC;
