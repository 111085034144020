import React, { useState } from 'react'
import { Dropdown, Button, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import "../../../views/index.css"
import { Link, useHistory, } from 'react-router-dom'
import NumericLabel from 'react-pretty-numbers';
import { BiBlock, BiUserPlus } from "react-icons/bi";
import Swal from 'sweetalert2'

// images
import dummyBanner from '../../../assets/images/banner.jpg'
import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
import SingleLightbox from './SingleLightbox'
import { servicePath } from '../app/variables'

function FriendProfileHeader({
    setBannerImg,
    setProfileImg,
    userState,
    profileImg,
    bannerImg,
    userAccountD,
    token,
    setMessage,
    setShowMessage,
    userStat,
    userData,
    isFriend,
    hasBlock,
    hasRequest,
    requestSent,
    hasDenied,
    handleRemoveFriend,
    handleBlockUser,
    handleConfirmRequest,
    handleCancelRequest,
}) {

    const [blockConfirm, setBlockConfirm] = useState(false)

    const handleRejectRequest = (requestId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "request_id": requestId,
                "status": "denied"
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/friend.update`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.success === "Request was denied") {
                    setMessage("Request rejected")
                    setShowMessage(true)

                    setTimeout(() => {
                        setShowMessage(false)
                    }, 2000)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleAddFriend = (friendId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "friend_user_id": friendId
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/friend.send`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.success === "Request sent") {
                    setMessage("Request sent successfully")
                    setShowMessage(true)

                    userState(userAccountD.id)

                    setTimeout(() => {
                        setShowMessage(false)
                    }, 2000)
                }
            })
            .catch(error => console.log('error', error));
    };

    const questionAlert = (requestId) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                cancelButton: 'btn btn-outline-primary btn-lg ms-2',
                confirmButton: 'btn btn-primary btn-lg',
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'cancel',
            confirmButtonText: 'Yes, delete it!',

            reverseButtons: false,
            showClass: {
                popup: 'animate__animated animate__zoomIn'
            },
            hideClass: {
                popup: 'animate__animated animate__zoomOut'
            }

        }).then((result) => {
            if (result.isConfirmed) {
                handleRejectRequest(requestId)

                swalWithBootstrapButtons.fire({
                    title: 'Deleted!',
                    text: 'Your Request has been deleted.',
                    icon: 'success',
                    showClass: {
                        popup: 'animate__animated animate__zoomIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__zoomOut'
                    }
                })
            }
        })
    }

    const onBannerError = () => {
        setBannerImg('')
    }

    const onProfileError = () => {
        setProfileImg('')
    }

    // const handleFollowUser = (friendId, action) => {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", token);
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "data": {
    //             "following_id": friendId
    //         },
    //         "action": action
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(`${servicePath}/follow.user`, requestOptions)
    //     .then(response => response.json())
    //     .then(result => {
    //         // console.log(result)
    //         if(result.success){
    //             if(action == 'follow') {
    //                 setMessage("Followed successfully")
    //             } else {
    //                 setMessage("Unfollowed successfully")
    //             }
    //             setShowMessage(true)

    //             userState(userAccountD.id);

    //             setTimeout(() =>{
    //                     setShowMessage(false)
    //             }, 2000)
    //         }
    //     })
    //     .catch(error => console.log('error', error));
    // };

    const history = useHistory()

    const chatUser = (e, data) => {

        e.preventDefault();

        if (data) {
            history.push({
                pathname: "/dashboard/app/chat",
                state: { userData: userAccountD }
            })
        }
    }

    const handleClose = () => {setBlockConfirm(false)};

    return (
        <>
            <Card>
                <Card.Body className="profile-page p-0">
                    <div className="profile-header top_banner">
                        <div className="position-relative w-100">
                            <div className="banner_img">
                                <img className='d-none' src={bannerImg}
                                    onError={onBannerError}
                                />
                                {bannerImg !== '' ? (
                                    <SingleLightbox
                                        thumb={bannerImg || dummyBanner}
                                        large={bannerImg || dummyBanner}
                                        className="rounded img-fluid"
                                    />
                                ) : (
                                    <img src={dummyBanner} alt="thumbnail" className="rounded img-fluid" />
                                )}
                            </div>
                        </div>
                        <div className="user-detail d-flex align-items-center justify-content-center flex-column text-center mb-3">
                            <div className="profile-img image-main">
                                <div className="position-relative">
                                    <img className='d-none' src={profileImg}
                                        onError={onProfileError}
                                    />
                                    {profileImg !== '' ? (
                                        <SingleLightbox
                                            thumb={profileImg || dummyUserImage}
                                            large={profileImg || dummyUserImage}
                                            className="img-thumbnail align-self-center avatar-120"
                                        />
                                    ) : (
                                        <img src={dummyUserImage} alt="thumbnail" className="img-thumbnail align-self-center avatar-120" />
                                    )}
                                </div>
                            </div>

                            {userData && userAccountD.id === userData.id ? (
                                <div className="profile-detail">
                                    <h3>{userData.name}</h3>
                                </div>
                            ) : (
                                <div className="profile-detail">
                                    <h3>{userAccountD ? userAccountD.name : 'Paul'}</h3>
                                </div>
                            )}
                        </div>
                        <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative w-100">

                            <div className='d-flex friend_btn'>
                                {isFriend && (
                                    <div className="card-header-toolbar d-flex align-items-center">
                                        <Dropdown>
                                            <Dropdown.Toggle className="btn btn-primary m-0 px-2 py-1 br-8">
                                                <i className="ri-check-line me-1"></i>
                                                Friends
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right">
                                                <Dropdown.Item href="#" onClick={() => { handleRemoveFriend(userAccountD.id) }}>
                                                    <i className="ri-user-unfollow-line me-1"></i>
                                                    Unfriend
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" className="text-danger" onClick={() => {setBlockConfirm(true)}}>
                                                    <BiBlock className='me-1' />
                                                    Block
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )}

                                {!hasDenied && (
                                    <>
                                        {!isFriend && !requestSent && !hasRequest &&
                                            <Button color="primary" className='btn btn-primary m-0 px-2 py-1 br-8' onClick={() => { handleAddFriend(userAccountD.id) }}>
                                                <BiUserPlus size={20} className="me-1" />
                                                Add Friend
                                            </Button>
                                        }

                                        {!isFriend && requestSent && (
                                            <div className="card-header-toolbar d-flex align-items-center">
                                                <Dropdown >
                                                    <Dropdown.Toggle className="btn btn-primary m-0 px-2 py-1 br-8">
                                                        <i className="ri-user-shared-line me-1"></i>
                                                        Requested
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                        <Dropdown.Item href="#" onClick={() => { handleCancelRequest(requestSent.id) }}>
                                                            <i className="ri-close-line me-1"></i>
                                                            Cancel
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </>
                                )}

                                {!isFriend && hasRequest && (
                                    <div className="card-header-toolbar d-flex align-items-center">
                                        <Dropdown>
                                            <Dropdown.Toggle className="btn btn-primary m-0 px-2 py-1 br-8">
                                                <i className="ri-user-received-line me-1"></i>
                                                Respond
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right">
                                                <Dropdown.Item href="#" onClick={() => { handleConfirmRequest(hasRequest.id) }}>
                                                    <i className="ri-user-follow-line me-1"></i>
                                                    Confirm
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" className="danger" onClick={(e) => { questionAlert(hasRequest.id) }}>
                                                    <i className="ri-user-unfollow-line me-1"></i>
                                                    Delete request
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )}

                                {!hasBlock && !isFriend && (
                                    <Button className='btn btn-soft-danger ms-2 px-2 py-1 br-8' onClick={() => {setBlockConfirm(true)}}>
                                        <BiBlock size={20} className="me-1" />
                                        Block
                                    </Button>
                                )}

                                {/* {isFollowing ? (
                            <div className="card-header-toolbar d-flex align-items-center ms-1">
                                <Dropdown>
                                    <Dropdown.Toggle className="btn btn-soft-light m-0 px-2 py-1 br-8">
                                        <span className='text-black'>Following</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right">
                                        <Dropdown.Item href="#" onClick={() => {handleFollowUser(isFollowing.following_id, 'unfollow')}}>
                                            <i className="ri-user-unfollow-line me-1"></i>
                                            Unfollow
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        ) : (
                            <Button color="" className='btn btn-soft-light m-0 ms-1 px-2 py-1 br-8' onClick={() => {handleFollowUser(userAccountD.id, 'follow')}}>
                                <span className='text-black'>Follow</span>
                            </Button>
                        )} */}

                                {isFriend && (
                                    <Link
                                        className='d-flex align-items-center btn btn-soft-light px-2 py-1 ms-1 br-8'
                                        to="#"
                                        onClick={(e) => { chatUser(e, userData) }}
                                    >
                                        <i className="ri-message-3-line fs-6 lh-1 text-black"></i>
                                        <span className='ms-1 lh-1 text-black'>Message</span>
                                    </Link>
                                )}
                            </div>

                            <div className="social-info">
                                <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                    <li className="text-center">
                                        <h6>Posts</h6>

                                        <p className="mb-0">
                                            <NumericLabel
                                                params={{ justification: 'L', shortFormat: true }}
                                            >
                                                {userStat.posts || '0'}
                                            </NumericLabel>
                                        </p>
                                    </li>
                                    <li className="text-center ps-3">
                                        <h6>Friends</h6>
                                        <p className="mb-0">
                                            <NumericLabel
                                                params={{ justification: 'L', shortFormat: true }}
                                            >
                                                {userStat.friends || '0'}
                                            </NumericLabel>
                                        </p>
                                    </li>
                                    {/* <li className="text-center ps-3">
                                <h6>Followers</h6>
                                <p className="mb-0">
                                <NumericLabel
                                    params={{ justification: 'L', shortFormat: true }}
                                    >
                                    {userStat.followers || '0'}
                                </NumericLabel>
                                </p>
                            </li>
                            <li className="text-center ps-3">
                                <h6>Following</h6>
                                <p className="mb-0">
                                <NumericLabel
                                    params={{ justification: 'L', shortFormat: true }}
                                    >
                                    {userStat.following || '0'}
                                </NumericLabel>
                                </p>
                            </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Modal
                size="md"
                className="fade"
                id="post-modal"
                dialogClassName="modal-dialog-centered"
                onHide={handleClose}
                show={blockConfirm}
            >
                <div className="text-center p-2">
                    <Modal.Body>
                        <h5 className='mb-2 fw-500'>Are you sure you want to block this user?</h5>

                        <p className='text-muted mb-0 lh-1-4'>Once you block someone you will not be able to see their profile and you cannot message them.</p>
                    </Modal.Body>
                    <Modal.Footer className='px-0 pt-0 pb-3 d-flex justify-content-center border-0'>
                        <Button variant="primary" onClick={handleClose} className="me-2 px-3">
                            Cancel
                        </Button>
                        <Button variant="outline-danger" className='px-4' onClick={() => { handleBlockUser(userAccountD.id) }}>
                            Block
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

export default FriendProfileHeader;