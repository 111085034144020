import React from "react";
import StickyStoryViews from "./StickyStoryViews";
import "./StoryViews.css";
const StoryWrapper = ({ children }) => {
  return (
    <div>
      <StickyStoryViews />

      <div className="story-wrapper">{children}</div>
    </div>
  );
};

export default StoryWrapper;
