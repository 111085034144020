import React from "react";
import { useLocation } from "react-router-dom";

//header
import Header from "../../components/partials/dashboard/headerStyle/header";

//sidebar
// import RightSidebar from '../../components/partials/dashboard/sidebarStyle/rightsidebar'

//sidebar
// import Sidebar from '../../components/partials/dashboard/sidebarStyle/sidebar'

//footer

//default
import Darkmode from "../../components/partials/dashboard/footerStyle/darkmode";
import classNames from "../../helper/classeNames";
import DefaultRouter from "../../router/default-router";

const Default = () => {
  const { pathname } = useLocation();
  const noBorder = ["/dashboard/stories"].includes(pathname);
  return (
    <>
      <div className="wrapper">
        {/* <Sidebar /> */}
        <Header />
        {/* <RightSidebar /> */}
        <div
          id="content-pages"
          className={classNames(!noBorder && "content-page")}
        >
          <DefaultRouter />
        </div>
      </div>
      <Darkmode />
      {/* <Footer /> */}
    </>
  );
};

export default Default;
