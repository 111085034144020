import React, { useState, useEffect } from 'react'
import { Modal, Card, Form, Button } from 'react-bootstrap'
import { servicePath } from '../app/variables';
import { useHistory } from 'react-router-dom'

const DeleteDeactivateAccount = ({
    token,
    userId,
    setMessage,
    setShowMessage,
}) => {

    const history = useHistory()

    const [accountStatus, setAccountAccountStatus] = useState('');

    const [confirmAction, setConfirmAction] = useState(false)

    const handleRadioAction = (e) => {
        const { value, checked } = e.target;

        console.log(checked, value)

        setAccountAccountStatus(value)
    };

    const handleDeleteAccount = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        console.log(userId, "user id");

        var raw = JSON.stringify({
            "data": {
                "user_id": userId
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/user.delete`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)

                if (result.message === "your account deleted successfully") {
                    setMessage("Account deleted successfully")
                    setShowMessage(true)

                    setTimeout(() => {
                        setShowMessage(false)
                    }, 2000)

                    setTimeout(() => {
                        history.push("/auth/sign-up")

                        setTimeout(() =>{
                            localStorage.removeItem("users")
                            localStorage.removeItem("access_token")
                            
                            document.body.classList.remove('dark');
                            localStorage.setItem("dark_mode" , false);
                        }, 100)
                    }, 3000);
                } else {
                    setMessage("Something went wrong")
                    setShowMessage(true)

                    setTimeout(() => {
                        setShowMessage(false)
                    }, 2000)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleDeactivateAccount = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        console.log(userId, "user id");

        var raw = JSON.stringify({
            "data": {
                "user_id": userId
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/user.deactivate`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)

                if (result.message === "your account deactivated") {
                    setMessage("Account deactivated successfully")
                    setShowMessage(true)

                    setTimeout(() => {
                        setShowMessage(false)
                    }, 2000)

                    setTimeout(() => {
                        history.push("/auth/sign-up")

                        setTimeout(() =>{
                            localStorage.removeItem("users")
                            localStorage.removeItem("access_token")
                            
                            document.body.classList.remove('dark');
                            localStorage.setItem("dark_mode" , false);
                        }, 100)
                    }, 3000);
                } else {
                    setMessage("Something went wrong")
                    setShowMessage(true)

                    setTimeout(() => {
                        setShowMessage(false)
                    }, 2000)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleFinalAction = () => {
        console.log("true")

        if (accountStatus == "deactivate") {
            console.log("deactive api");
            handleDeactivateAccount()
        }

        if (accountStatus == "delete") {
            console.log("delete api");

            handleDeleteAccount()
        }
    };

    const handleClose = () => { setConfirmAction(false) };

    const handleReset = () => {
        setAccountAccountStatus('');

        console.log(accountStatus);
    };

    return (
        <>
            <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Account Status</h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="acc-edit">
                        <Form>
                            <div>
                                <Form.Check.Label className="form-check-label" htmlFor="deactivate" onClick={() => { setAccountAccountStatus("deactivate") }}>
                                    <div className='d-flex align-items-center'>
                                        <Form.Check.Input type="radio" className="form-check-input me-1 mt-0" name="accStatus" onChange={(e) => { handleRadioAction(e) }} id="deactivate" value="deactivate" />
                                        <h4 className='fw-500 ms-1'>Deactivate Account</h4>
                                    </div>
                                    <h6 className='ps-3 fw-500 mb-1 lh-1 ms-1'>This can be temporary. </h6>
                                </Form.Check.Label>

                                <div className='ps-2'>
                                    <ul>
                                        <li>You can reactivate whenever you want.</li>
                                        <li>People can't see your timeline or search for you.</li>
                                        <li>Some information may remain visible to others &#40;example: messages you sent&#41;</li>
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <Form.Check.Label className="form-check-label" htmlFor="delete" onClick={() => { setAccountAccountStatus("delete") }}>
                                    <div className='d-flex align-items-center'>
                                        <Form.Check.Input type="radio" className="form-check-input me-1 mt-0" name="accStatus" onChange={(e) => { handleRadioAction(e) }} id="delete" value="delete" />
                                        <h4 className='fw-500 ms-1'>Delete Account</h4>
                                    </div>

                                    <h6 className='ps-3 fw-500 mb-1 lh-1 ms-1'>This is permanent.</h6>
                                </Form.Check.Label>

                                <div className='ps-2'>
                                    <ul>
                                        <li>You can't regain access once it is deleted.</li>
                                        <li>Friends may still have access to messages you sent after your account has been deleted.</li>
                                        <li>All your data and personal information will be deleted.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='d-flex justify-content-end'>
                                <Button type="reset" className="btn btn-soft-danger me-2" onClick={handleReset}>Cancel</Button>

                                {accountStatus == "deactivate" ? (
                                    <Button type="button" className="btn btn-primary me-2" onClick={() => { setConfirmAction(true) }}>Continue to account deactivation</Button>
                                ) : accountStatus != '' ? (
                                    <Button type="button" className="btn btn-primary me-2" onClick={() => { setConfirmAction(true) }}>Continue to account deletion</Button>
                                ) : (
                                    <Button type="button" className="btn btn-primary me-2" disabled>Continue to account deactivation</Button>
                                )}
                            </div>
                        </Form>
                    </div>
                </Card.Body>
            </Card>

            <Modal
                size="md"
                className="fade"
                id="post-modal"
                dialogClassName="modal-dialog-centered"
                onHide={handleClose}
                show={confirmAction}
            >
                <div className="text-center p-2">
                    <Modal.Body className='modal-body p-0'>
                        <h5 className='my-2 fw-500'>Are you sure you want to <span className='fw-bold'>{accountStatus}</span> your account?</h5>
                    </Modal.Body>
                    <Modal.Footer className='px-0 pt-0 pb-2 d-flex justify-content-center border-0'>
                        <Button variant="primary" onClick={handleClose} className="me-2 px-3">
                            Cancel
                        </Button>
                        <Button variant="outline-danger" className='px-4' onClick={(e) => { handleFinalAction(e) }}>
                            <span className='text-capitalize'>{accountStatus}</span>
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default DeleteDeactivateAccount;
