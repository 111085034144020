import React, { useState, useEffect } from 'react'
import {Row, Col, Container, OverlayTrigger, Tooltip, Button, Pagination} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link, useHistory} from 'react-router-dom'
import loader from "../../../assets/images/page-img/page-load-loader.gif"
import { BiHide, BiListCheck, BiTrashAlt } from 'react-icons/bi'
import { getNotificationUnreadCount } from '../components/Functions'
import noprofile from "../../../assets/images/no-profile-picture.jpg"
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { servicePath } from './variables'

const Notification = () => {

   const history = useHistory()

   const token = "Bearer" +" "+ localStorage.getItem("access_token")

   const [loading, setLoading] = useState(false)
   const [notificationLists, setNotificationLists] = useState([])
   const [notificationListsPage, setNotificationListsPage] = useState([])
   const [showMessage, setShowMessage] = useState(false)
   const [message, setMessage] = useState("")
   const [notificationUnreadCount, setNotificationUnreadCount] = useState('0')

   useEffect(() => {
      setLoading(true)

      // console.log(servicePath, "servicePath")
      
      if(token !== null){
         getNotificationListData()
         getNotificationUnreadCount(token, setNotificationUnreadCount)
      }
   },[])
   
   const getNotificationListData = (targetPage) => {
         setLoading(true)
         // console.log(targetPage)
         
         var myHeaders = new Headers();
         myHeaders.append(
               "Authorization",
               token
         );
         myHeaders.append("Content-Type", "application/json");
   
         var raw = JSON.stringify({
            "limit": "10"
         });

         var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
         };
   
         fetch(`${servicePath}/notification.get_web?page=${targetPage}` ,requestOptions)
         .then((response) => response.json())
         .then((result) => {
            if(result) {
               console.log(result, "notifications new")

               setNotificationListsPage(result)
               
               if(result.data) {
                  setNotificationLists(result.data)
               }
               setLoading(false)
            }
         })
         .catch((error) => console.log("error", error));
   };

   const postDetailPage = (postId) => {
         history.push(`/dashboard/app/post-detail/${postId}`);
   };

   const markNotificationRead = (id) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
         "data": {
            "id": id
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(
            `${servicePath}/notification.single_read`,
            requestOptions
      )
      .then((response) => response.json())
      .then((result) => {
            // console.log(result, "notifications")

            setMessage("Notification read successfully")
            setShowMessage(true)

            setTimeout(() =>{
               setShowMessage(false)
            }, 2000)

            getNotificationListData()
            // getNotificationUnreadList(token, setNotificationLists)
            getNotificationUnreadCount(token, setNotificationUnreadCount)
      })
      .catch((error) => console.log("error", error));
   };

   const markAllNotificationRead = () => {
         var myHeaders = new Headers();
         myHeaders.append("Authorization", token);
         myHeaders.append("Content-Type", "application/json");

         var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
         };
   
         fetch(
               `${servicePath}/notification.read`,
               requestOptions
         )
         .then((response) => response.json())
         .then((result) => {
            if(result){
               // console.log(result, "notifications")
   
               setMessage("All notification read successfully")
               setShowMessage(true)
   
               setTimeout(() =>{
                  setShowMessage(false)
               }, 2000)
   
               getNotificationListData()
               // getNotificationUnreadList(token, setNotificationLists)
               getNotificationUnreadCount(token, setNotificationUnreadCount)
            }
         })
         .catch((error) => console.log("error", error));
   };

   const notificationHide = (id) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
         "data": {
            "notification_id": id
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(`${servicePath}/notification.hidden`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
            // console.log(result, "notifications")

            if(result.message !== '') {
               setMessage("Notification hidden successfully")
               setShowMessage(true)
   
               setTimeout(() =>{
                  setShowMessage(false)
               }, 2000)
   
               getNotificationListData()
               // getNotificationUnreadList(token, setNotificationLists)
               getNotificationUnreadCount(token, setNotificationUnreadCount)
            }
      })
      .catch((error) => console.log("error", error));
   };

   const notificationDelete = (id) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
         "data": {
            "notification_id": id
         }
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };

      fetch(
            `${servicePath}/notification.delete`,
            requestOptions
      )
      .then((response) => response.json())
      .then((result) => {
            if(result.message !== '') {
               setMessage("Notification deleted successfully")
               setShowMessage(true)
   
               setTimeout(() =>{
                  setShowMessage(false)
               }, 2000)
   
               getNotificationListData()
               // getNotificationUnreadList(token, setNotificationLists)
               getNotificationUnreadCount(token, setNotificationUnreadCount)
            }
      })
      .catch((error) => console.log("error", error));
   };

   return(
      <>
         <Container>
            {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
            <Row>
                  <Col sm="12" className='pt-2'>
                     <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="card-title fw-500 mb-0">Notifications</h4>
                        {notificationLists && notificationLists.length > 0 && notificationUnreadCount > 0 && (
                           <Link to="#" className="fw-500 btn-link text-nowrap" onClick={() => {markAllNotificationRead()}} >
                              Read all
                           </Link>
                        )}
                     </div>
                  </Col>
            </Row>
            
            {loading && (
                  <div className="main_loader">
                     <img src={loader} alt="loader" height="100px" />
                  </div>
            )}

            <Row>
               <Col sm="12">
                  {notificationLists && notificationLists.length > 0 ? (
                     <>
                        {notificationLists.map((item) => {
                           return(
                              <Card key={item.id} className={`p-0 ${item.isRead == 0 ? 'notify_unread' : 'notify_read'}`}>
                                 <Card.Body className="p-2">
                                    <ul className="notification-list m-0 p-0">
                                       <li className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between flex-1">
                                          <div className='d-flex align-items-center justify-content-between'>
                                             <Link to={`/dashboard/app/friend-profile/${item.sender}`} className="">
                                                <img 
                                                   src={item.metaData.senderphoto} 
                                                   alt="user" 
                                                   className="rounded-circle avatar-50"
                                                   onError={(e) => {
                                                      e.target.src = noprofile
                                                   }}  
                                                />
                                             </Link>
                                             <div className="w-100">
                                                <div className="d-flex justify-content-between">
                                                   <div className="ms-2 me-sm-2">
                                                      <h6 className='text-ellipsis-3'>
                                                         <strong>{item.metaData.SenderName}</strong> {' '} 
                                                         {item.notificationString} {' '}
                                                      
                                                         {item.post_details && item.post_details.post_body && item.post_details.post_body !== '' ? (
                                                            <span onClick={() => (postDetailPage(item.post_details.id))} className='fw-500 text-decoration-underline clickable hoverable'>
                                                               {item.post_details.post_body}
                                                            </span>
                                                         ) : (
                                                            <>
                                                               {item.notificationType !== 'u_friends' && (
                                                                  <span onClick={() => (postDetailPage(item.post_details.id))} className='clickable'>
                                                                     - <span className='text-primary'>View post</span>
                                                                  </span>
                                                               )}
                                                            </>
                                                         )}
                                                         
                                                      </h6>
                                                      <p className="mb-0">{item.createdAt}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className='d-flex ps-5 ps-sm-0 mt-1 mt-sm-0'>
                                             {item.isRead == 0 && (
                                                <OverlayTrigger placement="top" overlay={
                                                   <Tooltip>
                                                      Mark as read
                                                   </Tooltip>
                                                   }>
                                                   <Button className="btn btn-soft-primary btn-icon small_btn me-2" onClick={() => {markNotificationRead(item.id)}}>
                                                      <BiListCheck size={22} />
                                                   </Button>
                                                </OverlayTrigger>
                                             )}
                                             <OverlayTrigger placement="top" overlay={
                                                <Tooltip>
                                                   Hide
                                                </Tooltip>
                                                }>
                                                <Button className="btn btn-soft-warning btn-icon small_btn me-2" onClick={() => {notificationHide(item.id)}}>
                                                   <BiHide size={18} />
                                                </Button>
                                             </OverlayTrigger>
                                             <OverlayTrigger placement="top" overlay={
                                                <Tooltip>
                                                   Delete
                                                </Tooltip>
                                                }>
                                                <Button className="btn btn-soft-danger btn-icon small_btn" onClick={() => {notificationDelete(item.id)}}>
                                                   <BiTrashAlt size={18} />
                                                </Button>
                                             </OverlayTrigger>
                                          </div>
                                       </li>
                                    </ul>
                                 </Card.Body>
                              </Card> 
                           )
                        })}
                     </>
                  ) : (
                     <div className='d-flex justify-content-center'>
                        <h5 className='fw-500 my-5'>No notification</h5>
                     </div>
                  )}
                  

                  {notificationListsPage && notificationListsPage.links && notificationListsPage.links.length > 0 ? (
                     <div className='d-flex justify-content-between mb-4'>
                        {notificationListsPage.prev_page_url !== null ? ( 
                           <button 
                              type="button" 
                              className='btn btn-outline-primary'
                              onClick={() => getNotificationListData(notificationListsPage.current_page - 1)}
                           >
                              <BsArrowLeft size={16} className="me-1" /> Prev page
                           </button>
                        ) : (
                           <div></div>
                        )}

                        {notificationListsPage.next_page_url !== null ? ( 
                           <button 
                              type="button" 
                              className='btn btn-outline-primary'
                              onClick={() => getNotificationListData(notificationListsPage.current_page + 1)}
                           >
                              Next page <BsArrowRight size={16} className="ms-1" />
                           </button>
                        ) : (
                           <div></div>
                        )}
                     </div>
                  ) : ('') }
               </Col>
            </Row>
         </Container>
      </>
   )
}

export default Notification