import React, { useState , useEffect } from "react";
import axios from "axios";
import {
Row,
Col,
Dropdown,
Modal,
Accordion,
Form,
Image,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CustomToggle from "../../../components/dropdowns";
import "react-multi-carousel/lib/styles.css";

import ImageVideoPreview from "./ImageVideoPreview";

import dummyUserImage from "../../../assets/images/no-profile-picture.jpg"
import { servicePath } from "../app/variables";

function EditPostModal({postItem, createPostModal, setCreatePostModal, token, componentOf, getAllPosts, currentPage, userPost, setLoading, setShowMessage, setMessage}) {

    const location = useLocation()

    const [multiverseCountry, setMultiverseCountry] = useState([])
    const [friendList, setFriendList] = useState([])
    const [friendListDisplay, setFriendListDisplay] = useState(friendList)
    const [postEdited, setPostEdited] = useState(false)
    const [postType, setPostType] = useState("");

    const [taggedFriends, setTaggedFriends] = useState([]);

    //Start: for ImageVideoPreview component
    const [file, setFile] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');

    const [firstName, setfirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [postBodyMsg, setPostBodyMsg] = useState("");
    const [uploadFile, setUploadFile] = useState('')

    function handleFileReset() {
        setFile('');
        setFileType("");
        setFileName("");
        
        setPostType("text");
        setPostEdited(true)
    }

    function handleDisplayFriends(){
        if(postItem.tagged_users !== 0) {
            var activeIds = []

            for (var i=0; i < postItem.tagged_users.length; i++) {
                activeIds.push(postItem.tagged_users[i].id) 
            } 

            let initialFriends = friendList.filter(function(item){
                return activeIds.indexOf(item.id) === -1;
            });

            setFriendListDisplay(initialFriends)

            // console.log(activeIds, initialFriends, "activeIds");
        }
    }

    const [selectedMultiverse, setSelectedMultiverse] = useState({
        multiverseID: '',
        multiverseName: '',
    });

    const [selectedMultiverseCountry, setSelectedMultiverseCountry] = useState({
        submultiverseID: '',
        submultiverse: '',
    });

    const [postAudience, setPostAudience] = useState("Public")

    const [addPhotoActive, setAddPhotoActive] = useState(false)
    const [selectMultiverseModal, setSelectMultiverseModal] = useState(false)
    const [tagFriendsModal, setTagFriendsModal] = useState(false)

    const getFriendsList = (searchData) => {

        if(friendListDisplay && friendListDisplay.length === 0) {
            setLoading(true);
        }

        var myHeaders = new Headers();

        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "search": searchData
        });
        
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
    
        fetch(
            `${servicePath}/friend.friendships_web`,
            requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
            
            // console.log(result, "friends data list")
            if(result) {
                
                if(result.data && result.data.length > 0) {
                    setFriendList(result.data)
                    setFriendListDisplay(result.data)
                }

                var taggedFriendsIds = [];

                function gettaggedFriendsIds(item) {
                    taggedFriendsIds.push(item.id);
                }

                if(taggedFriends && taggedFriends.length > 0) {
                    taggedFriends.forEach(gettaggedFriendsIds)

                    function getUniverIds(item) {
                        var ids = item.id
    
                        if(taggedFriendsIds && taggedFriendsIds.length > 0 && taggedFriendsIds.includes(ids)){
                            document.getElementById(`friend_id_${ids}`).classList.add('d-none');
                        } else {
                            document.getElementById(`friend_id_${ids}`).classList.remove('d-none');
                        }
                    }
    
                    if(result.data && result.data.length > 0) {
                        result.data.forEach(getUniverIds)

                        // console.log(result.data, "result.data.result.data.")
                    }
                }

                setLoading(false);
            }
        })
        .catch((error) => console.log("error", error));
    };

    const getMultiverCountries = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            `${servicePath}/public.multiverses_tree`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if(result && result.data && result.data.multiverse) {
                    // console.log("multiverse country", result);
                    setMultiverseCountry(result.data.multiverse)
                }

                if(result) {
                    setLoading(false)
                }
            })
            .catch((error) => console.log("error", error))
            .finally((error) => setLoading(false))
    };

    const handleSelectPostMultiverse = (e, ID, name) => {

        setPostEdited(true)

        if (e.currentTarget.classList.contains('collapsed')) {
            selectedMultiverseCountry.submultiverseID='';
            selectedMultiverseCountry.submultiverse='';
            setSelectedMultiverseCountry({...selectedMultiverseCountry});
        }
    
        selectedMultiverse.multiverseID=ID;
        selectedMultiverse.multiverseName=name;
        setSelectedMultiverse({...selectedMultiverse});
    
        // console.log("selectedMultiverse", selectedMultiverse);
    }
    
    const handleSelectPostSubMultiverse = (ID, name) => {
        setPostEdited(true)
        // console.log("selected post sub multiverse", ID, name);
    
        selectedMultiverseCountry.submultiverseID=ID;
        selectedMultiverseCountry.submultiverse=name;
        setSelectedMultiverseCountry({...selectedMultiverseCountry});
    
        // console.log("selectedMultiverseCountry", selectedMultiverseCountry);
    }
    
    const handleTagAddition = (id, username, name, photo, index) => {
        setPostEdited(true)

        // const newFriendList = friendListDisplay.filter((_, i) => i !== index);

        // setFriendList(newFriendList);

        document.getElementById(`friend_id_${id}`).classList.add('d-none');

        let arrtaggedFriends = [...taggedFriends]

        arrtaggedFriends.push({
            id: id,
            username: username,
            name: name,
            photo: photo
        });

        setTaggedFriends(arrtaggedFriends)

        // setFriendListDisplay(newFriendList);

        // console.log(newFriendList, "newFriendListDisplay");
        // console.log(taggedFriends, "taggedFriends");
    };
    
    const handleTagDelete = (id, username, name, photo, index) => {
    
        const newFriendTagList = taggedFriends.filter((_, i) => i !== index);
    
        setTaggedFriends(newFriendTagList);

        // friendListDisplay.push({
        //     id: id,
        //     username: username,
        //     name: name,
        //     photo: photo
        // });

        document.getElementById(`friend_id_${id}`).classList.remove('d-none');

        // console.log(newFriendTagList, "newFriendTagList");
        // console.log(taggedFriends, "taggedFriends");
        // console.log(friendListDisplay, "friendListDisplay")
    }

    const handleTaggedFriends = () => {
        console.log("final taggedFriends");
        setPostEdited(true)
    };

    const handleCreatePostMsg = (e) => {
        setPostEdited(true)
        if(fileType !== '') {
            setPostType(fileType);
        } else {
            setPostType("text");
            setPostBodyMsg(e.target.value)
        }
    };
    
    const handleSelectedSubmultiver = () => {
        console.log("final selectedMultiverseCountry");
        setPostEdited(true)
    };

    useEffect(() => {
        if(createPostModal) {
            setFileName("");
            setPostEdited(false);

            if(postItem.sub_universe_id) {
                console.log(postItem, postItem.sub_universe_id, "FileName FileName");
            } else {
                console.log(postItem, postItem.sub_universe_id, "else postitem");
            }

            getMultiverCountries()

            if (postItem.tagged_users !== "0") {
                setTaggedFriends(postItem.tagged_users);

                // console.log("ttt 0")
            } else {
                setTaggedFriends([]);
            }
            
            setfirstName(postItem.details.firstName)
            setLastName(postItem.details.lastName)
            setPostAudience(postItem.privacy)

            // console.log(friendList, "postItem.tagged_users")

            if(postItem.multiverse_id !== null) {
                selectedMultiverse.multiverseID=postItem.multiverse_id.ID;
                selectedMultiverse.multiverseName=postItem.multiverse_id.multiverseName;
                setSelectedMultiverse({...selectedMultiverse});
            }

            if(postItem.multiverse_id !== null) {
                selectedMultiverseCountry.submultiverseID=postItem.sub_multiverse_id.ID;
                selectedMultiverseCountry.submultiverse=postItem.sub_multiverse_id.multiverseName;
                setSelectedMultiverseCountry({...selectedMultiverseCountry});
            }

            setPostType(postItem.post_type)

            if(postItem.media_url !== null && postItem.post_type !== "text") {
                setAddPhotoActive(true)
                setFile(postItem.media_url)
                setFileType(postItem.post_type)
            } else {
                setFile('');
                setFileType('');
                setPostType("text");
                setAddPhotoActive(false);
            }
        }
    }, [createPostModal])

    const handleUpdatePost = (e) => {
        e.preventDefault()
        setLoading(true)
        var bodyFormData = new FormData();

        const {postBody} = e.target.elements

        var uploadFileData
        if(uploadFile !== undefined && fileType !== '') {
            // console.log(uploadFile, "uploadFile")
            uploadFileData = uploadFile;

            // console.log(uploadFile, uploadFileData, fileType, postType, fileName, "uploadFile")
        } else {
            uploadFileData = '0';

            // console.log(uploadFile, uploadFileData, fileType, postType, fileName, "uploadFile")
        }

        var taggedFriendsIds = taggedFriends.map(value => value.id).join(", ");

        // var selectedSubMultiverseValue = [];
        // if(selectedMultiverseCountry.submultiverseID) {
        //     selectedSubMultiverseValue.push(selectedMultiverseCountry.submultiverseID);

        //     console.log(selectedMultiverseCountry.submultiverseID)
        // } else {
        //     selectedSubMultiverseValue = []
        // }

        // console.log(selectedSubMultiverseValue, "selectedSubMultiverseValue")

        if(fileType !== "" && postType !== "text" && fileName !== "") {

            // console.log("uploaded new file")

            bodyFormData = {
                "postid": postItem.id,
                "user_id": postItem.user_id || "",    
                "wall_id":"0",   
                "activity_id":"0",   
                "shared_id":"0",                        
                "post_body": postBody.value || "",  
                "post_type": fileType || postType,  
                "feed_type":"feed",  
                "privacy": postAudience,  
                "media_url": uploadFileData,   
                "status":"published",                           
                "tagged_users": taggedFriendsIds || 0,   
                "hash_tags":"0",
                "universe_id": postItem.universe_id && postItem.universe_id.ID || 0,
                "sub_universe_id": postItem.sub_universe_id && postItem.sub_universe_id.ID || 0,
                "multiverse_id": selectedMultiverse.multiverseID || 0,
                "sub_multiverse_id": selectedMultiverseCountry.submultiverseID || 0,
                "emotions":0,
                "likes":0,
                "haha":0,
                "wow":0,
                "Sad":0,
                "angry":0
            }

        } else if(fileType !== "" && postType !== "text" && fileName === "") {
            // console.log("old media url")

            bodyFormData = {
                "postid": postItem.id,
                "user_id": postItem.user_id || "",    
                "wall_id":"0",   
                "activity_id":"0",   
                "shared_id":"0",                        
                "post_body": postBody.value || "",  
                "post_type": fileType || postType,  
                "feed_type":"feed",  
                "privacy": postAudience,
                "status":"published",                           
                "tagged_users": taggedFriendsIds || 0,   
                "hash_tags":"0",
                "universe_id": postItem.universe_id && postItem.universe_id.ID || 0,
                "sub_universe_id": postItem.sub_universe_id && postItem.sub_universe_id.ID || 0,
                "multiverse_id": selectedMultiverse.multiverseID || 0,
                "sub_multiverse_id": selectedMultiverseCountry.submultiverseID || 0,
                "emotions":0,
                "likes":0,
                "haha":0,
                "wow":0,
                "Sad":0,
                "angry":0
            }
        } else {
            // console.log("only text post")

            bodyFormData = {
                "postid": postItem.id,
                "user_id": postItem.user_id || "",    
                "wall_id":"0",   
                "activity_id":"0",   
                "shared_id":"0",                        
                "post_body": postBody.value || "",  
                "post_type": fileType || postType,  
                "feed_type":"feed",  
                "privacy": postAudience,
                "media_url": '0', 
                "status":"published",                           
                "tagged_users": taggedFriendsIds || 0,   
                "hash_tags":"0",
                "universe_id": postItem.universe_id.ID || 0,
                "sub_universe_id": postItem.sub_universe_id && postItem.sub_universe_id.ID || 0,
                "multiverse_id": selectedMultiverse.multiverseID || 0,
                "sub_multiverse_id": selectedMultiverseCountry.submultiverseID || 0,
                "emotions":0,
                "likes":0,
                "haha":0,
                "wow":0,
                "Sad":0,
                "angry":0
            }
        }
        
        axios
            .post(`${servicePath}/post.update`, bodyFormData, {
                headers: {
                    "Authorization": token,
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((res) => {
                // console.log("update post API response", res.data);
                if (res.data.post.id > 0) {
                    setCreatePostModal(false);

                setMessage("Post updated successfully")
                setShowMessage(true)

                if(componentOf === "index") {
                    getAllPosts(currentPage);
                } else {
                    userPost(currentPage);
                }

                setTimeout(() =>{
                    setShowMessage(false)
                }, 2000)

                    setUploadFile('');

                    setTaggedFriends([]);
                }
            })
            .catch((err) => {
                console.log(err, "false");
                setCreatePostModal(false);
                setLoading(false);
                setMessage("There is some error, Please try after sometime")
                setShowMessage(true)
                setTimeout(() => {
                    setShowMessage(false)
                }, 2000)
            });
    }

    const [searchString, setSearchString] = useState('')
    // state for keeping the timeout
    const [searchTextTimeout, setSearchTextTimeout] = useState(null);

    const handleFriendListSearch = (searchValue) => {
        if (searchValue !== '') {
            getFriendsList(searchValue);
            setSearchString(searchValue);
        } else {
            getFriendsList(searchValue);
            setSearchString('');
        }
    };

    const handleFriendSearch = (e) => {
        if (searchTextTimeout) {
            clearTimeout(searchTextTimeout);
        }

        setSearchTextTimeout(
            setTimeout(() => {
                console.log(e.target.value)
                handleFriendListSearch(e.target.value);
            }, 500),
        );
    };

    useEffect(() => {
        return () => clearTimeout(searchTextTimeout);
    }, [searchTextTimeout]);

    return (
        <Modal
            size={`${(selectMultiverseModal || tagFriendsModal) ? "md" : "md"}`}
            className="fade"
            id="post-modal"
            show={createPostModal}
            dialogClassName="modal-dialog-centered"
        >
            <div className={`create_post_step ${(selectMultiverseModal || tagFriendsModal) ? "d-none" : "active"}`}>
                <Modal.Header className="d-flex justify-content-between py-2">
                    <Modal.Title id="post-modalLabel" className="lh-1">Edit Post</Modal.Title>
                    <Link to="#" className="lh-1 close_btn bg-light" onClick={() => { setCreatePostModal(false) }}>
                        <i className="ri-close-fill ri-xl"></i>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="d-flex w-100">
                            <div className="user-img avatar-50 rounded-circle me-2">
                                <Image
                                    src={postItem.details && postItem.details.photo ? postItem.details.photo : dummyUserImage}
                                    className=""
                                    alt="user"
                                    onError={(e) => {
                                        e.target.src = dummyUserImage
                                    }}
                                />
                            </div>
                            <div className="">
                                <div className={`${taggedFriends && taggedFriends.length > 0 ? 'h5 mb-1 lh-1' : 'h5 mb-1 lh-1'}`}>
                                    <strong>{firstName && lastName === "" ? "N/A" : firstName + " " + lastName + " "}</strong>{taggedFriends && taggedFriends.length > 0 ? <>is with <strong>{taggedFriends && taggedFriends[0].name}</strong> </> : ''} {taggedFriends && taggedFriends.length > 1 ? <>and <strong>{(taggedFriends.length - 1) > 1 ? `${taggedFriends.length - 1} others` : "1 other"}</strong></> : ''}
                                </div>

                            {postItem && <p className="mb-2 lh-1"><strong>{postItem.universe_id && postItem.universe_id.universeName}</strong>{postItem.sub_universe_id ? <strong>{', '} {postItem.sub_universe_id.universeName}</strong> : ''} {postItem.createdAt ? `(${postItem.createdAt})` : ''}</p>
}

                            <div className="d-flex align-items-center flex-wrap badge_btns">
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} role="button">
                                        <span className="btn btn-light me-2">
                                            {postAudience === "Public" ? <i className="ri-global-line h4"></i> : <>{postAudience === "Multiverse" ? <i className="ri-settings-2-line h4"></i> : <>{postAudience === "Universe" ? <i className="ri-star-line h4"></i> : <>{postAudience === "Friends" ? <i className="ri-user-follow-line h4"></i> : <></>}</>}</>}</>}
                                            {postAudience}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="m-0 p-0">
                                        <div className="px-3 pt-3 pb-0">
                                            <h5>Choose audience</h5>
                                        </div>
                                        <Dropdown.Item className="p-3" to="#">
                                            <div className="d-flex align-items-top">
                                                <i className="ri-global-line h4"></i>
                                                <div className="data ms-2" onClick={() => setPostAudience("Public")}>
                                                    <h6>Public</h6>
                                                    <p className="mb-0">
                                                        Anyone on or off Universimm
                                                    </p>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className=" p-3" to="#">
                                            <div className="d-flex align-items-top">
                                                <i className="ri-star-line h4"></i>
                                                <div className="data ms-2" onClick={() => setPostAudience("Universe")}>
                                                    <h6>Universe</h6>
                                                    <p className="mb-0">
                                                        Anyone on selected Universe
                                                    </p>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className=" p-3" to="#">
                                            <div className="d-flex align-items-top">
                                                <i className="ri-user-follow-line h4"></i>
                                                <div className="data ms-2" onClick={() => setPostAudience("Friends")}>
                                                    <h6>Friends</h6>
                                                    <p className="mb-0">
                                                        Anyone who is your friend
                                                    </p>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                
                                {selectedMultiverse && selectedMultiverse.multiverseName && 
                                    <span className="btn btn-light custom_badge me-2">
                                        <i className="ri-settings-2-line h4"></i> {selectedMultiverse.multiverseName}
                                    </span>
                                }

                                {selectedMultiverseCountry && selectedMultiverseCountry.submultiverse && 
                                    <span className="btn btn-light custom_badge">
                                        <i className="ri-flag-line h4"></i> {selectedMultiverseCountry.submultiverse}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <form
                    className="post-text w-100"
                    onSubmit={handleUpdatePost}
                    action="/api-endpoitn" methot="POST"
                    >
                        <textarea
                            type="text"
                            rows="4"
                            name="postBody"
                            id="postBody"
                            className="form-control rounded write-something"
                            placeholder={`Share your ${postItem.universe_id && postItem.universe_id.universeName} ${postItem.universe_id && postItem.sub_universe_id ? `& ${postItem.universe_id && postItem.sub_universe_id.universeName} posts` : 'posts'}`}
                            style={{ border: "none" }}
                            onChange={handleCreatePostMsg}
                            defaultValue={postItem.post_body || ''}
                        />

                        {addPhotoActive && (
                            <ImageVideoPreview
                                setUploadFile={setUploadFile} 
                                setAddPhotoActive={setAddPhotoActive} 
                                addPhotoActive={addPhotoActive} 
                                handleFileReset={handleFileReset} 
                                setPostType={setPostType} 
                                file={file} 
                                setFile={setFile} 
                                fileType={fileType} 
                                setFileType={setFileType} 
                                setFileName={setFileName} 
                                setPostEdited={setPostEdited}
                                editPostComponent="true"
                            />
                        )}

                            <div className="d-flex align-items-center border p-2 my-2 br-8">
                                <Link to="#" className="d-flex me-2 me-md-3 post-icon" onClick={() => { handleFileReset(); setAddPhotoActive(!addPhotoActive) }}>
                                    <i className="ri-gallery-fill me-1"></i>
                                    Photo/Video
                                </Link>
                                <Link to="#" className="d-flex me-2 me-md-3 post-icon" onClick={() => { handleDisplayFriends(); setTagFriendsModal(!tagFriendsModal); getFriendsList(searchString)}}>
                                    <i className="ri-group-fill me-1"></i>
                                    Tag
                                </Link>
                                <Link to="#" className="d-flex me-2 me-md-3 post-icon" onClick={() => setSelectMultiverseModal(!selectMultiverseModal)}>
                                    <i className="ri-settings-2-line me-1"></i>
                                    Multiverses
                                </Link>
                                {/* <Link to="#" className="d-flex post-icon">
                                <i className="ri-emotion-happy-line me-1"></i>
                                Feeling
                            </Link> */}
                        </div>
                        <button type="submit" className="btn btn-primary w-100 d-flex justify-content-center mx-auto" disabled={!postEdited}>
                            Update post
                        </button>
                    </form>
                </div>
            </Modal.Body>
        </div>

        <div className={`create_post_step ${tagFriendsModal ? "active" : "d-none"}`}>
            <Modal.Header className="d-flex justify-content-between align-items-center">
                <Link to="#" className="lh-1" onClick={() => setTagFriendsModal(!tagFriendsModal)}>
                    <i className="ri-arrow-left-line ri-xl lh-1"></i>
                </Link>
                <Modal.Title className="h5" id="post-modalLabel">Tag friends</Modal.Title>
                <Link to="#" className="lh-1" onClick={() => {handleTaggedFriends();setTagFriendsModal(!tagFriendsModal)}}>
                    Done
                </Link>
            </Modal.Header>
            <Modal.Body>
            <div className="tag_frined_wrap">
                
                {taggedFriends && taggedFriends.length > 0 && (
                    <div className={`tagged_friend_list ${taggedFriends.length > 0 ? 'mb-3' : ''}`}>
                        {taggedFriends.map((item, index) =>{
                            return(
                                <div key={item.id} className="friend_item d-flex align-items-center" onClick={() => {handleTagDelete(item.id, item.username, item.name, item.photo, index)}}>
                                    <h6 className="lh-1">{item.name}</h6>
                                    <Link to="#" className="lh-1">
                                        <i className="ri-close-fill ri-xl"></i>
                                    </Link>
                                </div>
                            )
                            })
                        }
                    </div>
                )}

                        <input
                            type="text"
                            className="form-control rounded mb-3"
                            placeholder="Search for friends"
                            onKeyUp={(e) => { handleFriendSearch(e) }}
                        />

                        {friendListDisplay && friendListDisplay.length > 0 ? (
                            <>
                                <div className="friend_list">
                                    {friendListDisplay.map((item, index) => {
                                        return (
                                            <div key={item.id} id={`friend_id_${item.id}`}>
                                                <div className="friend_item d-flex align-items-center" onClick={() => { handleTagAddition(item.id, item.username, item.name, item.photo, index) }}>
                                                    <Image
                                                        src={item.photo || dummyUserImage}
                                                        alt={item.name}
                                                        onError={(e) => {
                                                            e.target.src = dummyUserImage
                                                        }}
                                                    />
                                                    <div className="ms-2">
                                                        <h6 className="lh-1 fw-500 mb-0">{item.name}</h6>
                                                        {/* <p className="text-muted mb-0 lh-1">{item.username}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </>
                        ) : (
                            <div className="d-flex justify-content-center py-4">
                                <h5 className="text-muted">No more friends found in the list</h5>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </div>

        <div className={`create_post_step ${selectMultiverseModal ? "active" : "d-none"}`}>
            <Modal.Header className="d-flex justify-content-between align-items-center">
                <Link to="#" className="lh-1" onClick={() => setSelectMultiverseModal(!selectMultiverseModal)}>
                    <i className="ri-arrow-left-line ri-xl lh-1"></i>
                </Link>
                <Modal.Title className="h5" id="post-modalLabel">Select multiverse & country</Modal.Title>
                <Link to="#" className="lh-1" onClick={() => {handleSelectedSubmultiver();setSelectMultiverseModal(!selectMultiverseModal)}}>
                    Done
                </Link>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="">
                        <div className="multiverse_list">
                        <Accordion id="multiverse_list" defaultActiveKey="0">
                            {multiverseCountry.map((item) =>{
                                return(
                                    <Accordion.Item key={item.ID} eventKey={item.ID}>
                                        <Accordion.Header id={`multiverse${item.ID}`} onClick={(e) => handleSelectPostMultiverse(e, item.ID, item.multiverseName)}>
                                            {item.multiverseName}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="country_list">
                                                {item.submultiverse && item.submultiverse.map((subItem) =>{
                                                    return(
                                                        <Form.Check key={subItem.ID} className="form-check radio check_badge">
                                                            <Form.Check.Input type="radio" name="multiverseCountry" id={`country${subItem.ID}`}/>
                                                            <Form.Check.Label htmlFor={`country${subItem.ID}`} onClick={() => handleSelectPostSubMultiverse(subItem.ID, subItem.submultiverse)}>{subItem.submultiverse}</Form.Check.Label>
                                                        </Form.Check>
                                                    )
                                                    })
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                                })
                            }
                        </Accordion>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </div>
    </Modal>
    );
}

export default EditPostModal;