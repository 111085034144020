import axios from "axios";
import * as cheerio from "cheerio";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { servicePath } from "../app/variables";

function extractMetadata(html, baseUrl) {
  const $ = cheerio.load(html);
  const title = $("title").text();
  const description =
    $('meta[name="description"]').attr("content") ||
    $('meta[name="Description"]').attr("content") ||
    $('meta[property="og:description"]').attr("content") ||
    $('meta[name="og:description"]').attr("content");
  const image =
    $('meta[property="og:image"]').attr("content") ||
    $('meta[name="og:image"]').attr("content");

  const faviconUrl =
    $('link[rel="icon"]').attr("href") ||
    $('link[rel="shortcut icon"]').attr("href") ||
    $('link[rel="apple-touch-icon"]').attr("href") ||
    $('link[rel="apple-touch-icon-precomposed"]').attr("href");

  let domainUrl = null;
  if (faviconUrl) {
    const absoluteFaviconUrl = new URL(baseUrl);
    domainUrl = absoluteFaviconUrl.origin?.replace("www.", "");
  }

  return {
    title,
    description,
    image: image || faviconUrl,
    domain: domainUrl,
    favicon: faviconUrl,
  };
}

function NealsLinkPreview({ url }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [isImageCorrupted, setIsImageCorrupted] = useState(false);

  useEffect(() => {
    const getPreview = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          `${servicePath}/public.thumb_share_url`,
          {
            data: {
              url,
            },
          }
        );
        const html = response.data?.data?.content;
        setData(extractMetadata(html, url));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getPreview();
  }, [url]);

  const linkCardStyles = {
    maxWidth: "640px",
    minWidth: "320px",
    padding: "12px",
    border: "1px solid #e0e0e0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const linkImageStyles = {
    width: "100%",
    height: "300px",
    marginBottom: "8px",
    objectFit: "cover",
  };

  const linkTitleStyles = {
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "8px",
  };

  const linkDescriptionStyles = {
    fontSize: "14px",
    marginBottom: "8px",
  };
  if (error) {
    console.log(error);
  }
  return (
    <>
      {!error && (
        <div className="my-2">
          {loading ? (
            <div style={linkCardStyles} className="skeletor">
              <div
                style={{
                  ...linkImageStyles,
                  borderRadius: "5px",
                  background: "#ddd",
                }}
              />
              <h2
                style={{
                  ...linkTitleStyles,
                  border: "5px solid #ddd",
                  borderRadius: "5px",
                }}
              >
                {" "}
              </h2>
              <p
                style={{
                  ...linkDescriptionStyles,
                  border: "10px solid #ddd",
                  borderRadius: "5px",
                }}
              />
            </div>
          ) : (
            <div style={linkCardStyles}>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#000" }}
              >
                {data.image && !isImageCorrupted && (
                  <img
                    style={linkImageStyles}
                    src={data.image}
                    alt="Link"
                    onError={() => {
                      setIsImageCorrupted(true);
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data.favicon && (
                    <img
                      src={data.favicon}
                      width={15}
                      height={15}
                      className="me-1"
                      alt="favicon"
                    />
                  )}
                  {data.domain && (
                    <p className="p-0 m-0" style={{ fontSize: "10px" }}>
                      {data.domain}
                    </p>
                  )}
                </div>
                <h2 style={linkTitleStyles}>{data.title}</h2>
                <p style={linkDescriptionStyles}>{data.description}</p>
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
}

NealsLinkPreview.propTypes = {
  url: PropTypes.string.isRequired,
};

export default NealsLinkPreview;
