import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import NumericLabel from "react-pretty-numbers";
import { Link } from "react-router-dom";
import dummyBanner from "../../../assets/images/banner.jpg";
import dummyUserImage from "../../../assets/images/no-profile-picture.jpg";
import Card from "../../../components/Card";
import { useProfile } from "../../../context/ProfileContext";
import "../../../views/index.css";
import { servicePath } from "../app/variables";
import SingleLightbox from "../components/SingleLightbox";
import { UpdatedProfileImage } from "./Functions";

function ProfileHeader({
  setBannerImg,
  setProfileImg,
  userState,
  profileImg,
  bannerImg,
  userAccountD,
  setLoading,
  token,
  setMessage,
  setShowMessage,
  userPost,
  userStat,
  userData,
}) {
  const [selectedFile, setSelectedFile] = useState();

  const { updateProfileImage: UpdateProfileContext } = useProfile();

  useEffect(() => {
    if (!selectedFile) {
      // console.log(bannerImg)
      return;
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      setBannerImg(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const uploadProfilePic = (dataimg) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("profile_image", dataimg);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.profile_upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === "Profile Uploaded successfully") {
          setMessage("Profile Picture Uploaded Successfully");
          setShowMessage(true);
          userState();
          if (result.data.photo) {
            UpdatedProfileImage(result.data.photo);
            UpdateProfileContext(result.data.photo);
          }
          userPost();

          setLoading(false);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        } else {
          setMessage("Something went wrong, try after some time!");
          setShowMessage(true);
          setLoading(false);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const uploadBannerImage = (dataimg) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("banner_image", dataimg);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.upload_banner`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "result")
        if (result.success === "Banner Uploaded successfully") {
          setMessage("Banner Uploaded successfully");
          setShowMessage(true);
          userState();

          setBannerImg(result.data.banner);

          setLoading(false);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        } else {
          setMessage("Something went wrong, try after some time!");
          setShowMessage(true);
          setLoading(false);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // const updateProfileImage=(e)=>{
  //     uploadProfilePic(e.target.files[0]);
  // }

  async function updateProfileImage(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 20,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      var compressedFileSize = compressedFile.size / 1024 / 1024;

      await console.log("image compressed");

      if (compressedFileSize > 20) {
        setMessage("File size too large");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          setMessage("");
        }, 2000);
      } else {
        uploadProfilePic(compressedFile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateBannerImage(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 20,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      var compressedFileSize = compressedFile.size / 1024 / 1024;

      await console.log("image compressed");

      if (compressedFileSize > 20) {
        setMessage("File size too large");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          setMessage("");
        }, 2000);
      } else {
        uploadBannerImage(compressedFile);

        if (!event.target.files || event.target.files.length === 0) {
          setSelectedFile("");
          return;
        } else {
          setSelectedFile(event.target.files[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // const updateBannerImage=(e)=>{

  //     if (!e.target.files || e.target.files.length === 0) {
  //         setSelectedFile('')
  //         return
  //     } else {
  //         setSelectedFile(e.target.files[0])
  //     }
  // }

  const onBannerError = () => {
    setBannerImg("");
  };

  const onProfileError = () => {
    setProfileImg("");
  };

  return (
    <Card>
      <Card.Body className="profile-page p-0">
        <div className="profile-header top_banner">
          <div className="position-relative w-100">
            <div className="banner_img">
              <img className="d-none" src={bannerImg} onError={onBannerError} />
              {bannerImg !== "" ? (
                <SingleLightbox
                  thumb={bannerImg || dummyBanner}
                  large={bannerImg || dummyBanner}
                  className="rounded img-fluid"
                />
              ) : (
                <img
                  src={dummyBanner}
                  alt="thumbnail"
                  className="rounded img-fluid"
                />
              )}
            </div>
            {userData && userAccountD.id === userData.id && (
              <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                <li className="upload-icon bg-white">
                  <input
                    type="file"
                    name="banner"
                    id="uploadBanner"
                    className="file-upload"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={updateBannerImage}
                  />
                  <label htmlFor="uploadBanner">
                    <i className="ri-pencil-line text-primary" id="1"></i>
                  </label>
                </li>
                <li className="upload-icon setting_icon bg-white">
                  <Link to="/dashboard/app/user-profile-edit">
                    <i className="ri-settings-4-line"></i>
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="user-detail d-flex align-items-center justify-content-center flex-column text-center mb-3">
            <div className="profile-img image-main">
              <div className="position-relative">
                <img
                  className="d-none"
                  src={profileImg}
                  onError={onProfileError}
                />
                {profileImg !== "" ? (
                  <SingleLightbox
                    thumb={profileImg || dummyUserImage}
                    large={profileImg || dummyUserImage}
                    className="img-thumbnail align-self-center avatar-120"
                  />
                ) : (
                  <img
                    src={dummyUserImage}
                    alt="thumbnail"
                    className="img-thumbnail align-self-center avatar-120"
                  />
                )}

                {userData && userAccountD.id === userData.id && (
                  <div className="p-image">
                    <input
                      type="file"
                      name="profile"
                      id="profileImage"
                      className="file-upload"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={updateProfileImage}
                    />
                    <label
                      htmlFor="profileImage"
                      className="w-100 h-100 clickable"
                    >
                      <i
                        className="ri-pencil-line upload-button text-white"
                        id="1"
                      ></i>
                    </label>
                  </div>
                )}
              </div>
            </div>

            {userData && userAccountD.id === userData.id ? (
              <div className="profile-detail">
                <h3>{userData.name}</h3>
              </div>
            ) : (
              <div className="profile-detail">
                <h3>{userAccountD ? userAccountD.name : "Paul"}</h3>
              </div>
            )}
          </div>
          <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative w-100">
            {/* <div className="social-links">
                        <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                            <li className="text-center pe-3">
                                <Link to="#">
                                <img
                                    src={img3}
                                    className="img-fluid rounded"
                                    alt="facebook"
                                />
                                </Link>
                            </li>
                            <li className="text-center pe-3">
                                <Link to="#">
                                <img
                                    src={img4}
                                    className="img-fluid rounded"
                                    alt="Twitter"
                                />
                                </Link>
                            </li>
                            <li className="text-center pe-3">
                                <Link to="#">
                                <img
                                    src={img5}
                                    className="img-fluid rounded"
                                    alt="Instagram"
                                />
                                </Link>
                            </li>
                            <li className="text-center pe-3">
                                <Link to="#">
                                <img
                                    src={img6}
                                    className="img-fluid rounded"
                                    alt="Google plus"
                                />
                                </Link>
                            </li>
                            <li className="text-center pe-3">
                                <Link to="#">
                                <img
                                    src={img7}
                                    className="img-fluid rounded"
                                    alt="You tube"
                                />
                                </Link>
                            </li>
                            <li className="text-center md-pe-3 pe-0">
                                <Link to="#">
                                <img
                                    src={img8}
                                    className="img-fluid rounded"
                                    alt="linkedin"
                                />
                                </Link>
                            </li>
                        </ul>
                    </div> */}
            <div className="d-flex">
              {/* {userData &&
                            <div className="card-header-toolbar d-flex align-items-center">
                                <Dropdown className="primary_drop_btn">
                                    <Dropdown.Toggle className="btn btn-primary m-0 px-2 py-1">
                                    <i className="ri-check-line me-1"></i>
                                    Friends
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right">
                                        <Dropdown.Item href="#">
                                            Unfollow
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            Unfriend
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            Block
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        } */}
            </div>
            <div className="social-info">
              <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                <li className="text-center">
                  <h6>Posts</h6>

                  <p className="mb-0">
                    <NumericLabel
                      params={{ justification: "L", shortFormat: true }}
                    >
                      {userStat.posts || "0"}
                    </NumericLabel>
                  </p>
                </li>
                <li className="text-center ps-3">
                  <h6>Friends</h6>
                  <p className="mb-0">
                    <NumericLabel
                      params={{ justification: "L", shortFormat: true }}
                    >
                      {userStat.friends || "0"}
                    </NumericLabel>
                  </p>
                </li>
                {/* <li className="text-center ps-3">
                                <h6>Followers</h6>
                                <p className="mb-0">
                                <NumericLabel
                                    params={{ justification: 'L', shortFormat: true }}
                                    >
                                    {userStat.followers || '0'}
                                </NumericLabel>
                                </p>
                            </li>
                            <li className="text-center ps-3">
                                <h6>Following</h6>
                                <p className="mb-0">
                                <NumericLabel
                                    params={{ justification: 'L', shortFormat: true }}
                                    >
                                    {userStat.following || '0'}
                                </NumericLabel>
                                </p>
                            </li> */}
              </ul>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProfileHeader;
