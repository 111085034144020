import React, { useState, useEffect } from "react";

import {
  Modal,
  Accordion,
  Form,
  Image,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";
import ReactDatePicker from 'react-datepicker'
import { servicePath } from "../app/variables";

function AddandEditEducationModal({
  addAndEditEducation,
  setAddAndEditEducation,
  showAddEditMessage,
  setEduId,
  eduId,
  editEducationData,
  userState
}) {

  const validation = () => {
    let error = {}
    let isValid = true;

    if (!institute || institute === "") {
        error.institute = "Institute is required"
        isValid = false
    }
    if (!degree || degree === "") {
        error.degree = "Degree is required"
        isValid = false
    }
    if (!location || location === "") {
        error.location = "Location is required"
        isValid = false
    }
    if (currentlyPursuing === 'n' && (!passingDate || passingDate === "")) {
        error.passingDate = "Passing date is required"
        isValid = false
    }

    setErrors(error)
    return isValid
  }

  const [errors, setErrors] = useState({})

  const [institute, setInstitute] = useState('');
  const [degree, setDegree] = useState('');
  const [location, setLocation] = useState('');

  const [passingDate, setPassingDate] = useState('');
  const [currentlyPursuing, setCurrentlyPursuing] = useState('n');

  const token = "Bearer" + " " + localStorage.getItem("access_token");

  useEffect(() => {
    if(editEducationData !== '') {
      // console.log(editEducationData.isPresent, "editEducationData")
      setInstitute(editEducationData.institute)
      setDegree(editEducationData.degree)
      setLocation(editEducationData.eduLocation)
      setPassingDate(new Date(editEducationData.passingDate))
      setCurrentlyPursuing(editEducationData.isPresent)

      setEduId(editEducationData.id);
    }
  }, [editEducationData]);

  const currentDate = new Date();

  const updateEducation = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        id: eduId,
        institute: institute,
        degree: degree,
        eduLocation: location,
        passingDate: passingDate,
        isPresent: currentlyPursuing,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/user.update_education`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result){
          setAddAndEditEducation(false);
          showAddEditMessage("Education updated successfully");
          userState()
          setTimeout(() => {
            showAddEditMessage("");
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const AddEduction = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        institute: institute,
        degree: degree,
        eduLocation: location,
        passingDate: passingDate,
        isPresent: currentlyPursuing,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${servicePath}/user.update_education`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAddAndEditEducation(false);
        showAddEditMessage("Education added successfully");
        userState()
        setTimeout(() => {
          showAddEditMessage("");
        }, 3000);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Modal
      // size={`${(selectMultiverseModal || tagFriendsModal) ? "md" : "md"}`}
      className="fade"
      id="post-modal"
      show={addAndEditEducation}
      dialogClassName="modal-dialog-centered"
    >
      <div>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="post-modalLabel">
            {eduId ? "Edit Education" : "Add Education"}
          </Modal.Title>
          <Link
            to="#"
            className="lh-1 close_btn bg-light"
            onClick={() => setAddAndEditEducation(false)}
          >
            <i className="ri-close-fill Wri-xl"></i>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group col-sm-12">
            <Form.Label htmlFor="institute" className="form-label">
              Institute:
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="institute"
              placeholder="Institute"
              defaultValue={institute}
              onChange={(e) => setInstitute(e.target.value)}
            />
            <div className="text-danger">{errors.institute}</div>
          </Form.Group>
          <Form.Group className="form-group col-sm-12">
            <Form.Label htmlFor="degree" className="form-label">
              Degree:
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="degree"
              placeholder="Degree"
              defaultValue={degree}
              onChange={(e) => setDegree(e.target.value)}
            />
            <div className="text-danger">{errors.degree}</div>
          </Form.Group>
          <Form.Group className="form-group col-sm-12">
            <Form.Label htmlFor="location" className="form-label">
              Location:
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="location"
              placeholder="Location"
              defaultValue={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <div className="text-danger">{errors.location}</div>
          </Form.Group>

          <Row>
            {currentlyPursuing === 'n' && (
              <Col sm={12}>
                <Form.Group className="form-group col-sm-12">
                  <Form.Label htmlFor="passingDate" className="form-label">
                    Passing Date:
                  </Form.Label>
                  <ReactDatePicker
                      dateFormat='dd-MM-yyyy'
                      selected={passingDate}
                      endDate={currentDate}
                      maxDate={currentDate}
                      onChange={(date) => setPassingDate(date)}
                      placeholderText="Date"
                      className='form-control'
                  />
                  <div className="text-danger">{errors.passingDate}</div>
                </Form.Group>
              </Col>
            )}
            <Col sm={12}>
              <Form.Group className="form-group col-sm-12">
                <div className="form-label d-block">Currently Pursuing:</div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio10"
                    value="1"
                    onChange={() => setCurrentlyPursuing("y")}
                    checked={currentlyPursuing === "y" ? true : false}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio10">
                    {" "}
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio11"
                    value="2"
                    onChange={(e) => setCurrentlyPursuing("n")}
                    checked={currentlyPursuing === "n" ? true : false}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio11">
                    No
                  </label>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            {eduId ? (
              <Button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {validation() ? updateEducation() : <></>;}}
              >
                Update
              </Button>
            ) : (
              <Button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {validation() ? AddEduction() : <></>;}}
              >
                Submit
              </Button>
            )}

            <Button
              type="reset"
              className="bg-soft-danger"
              onClick={() => setAddAndEditEducation(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default AddandEditEducationModal;
