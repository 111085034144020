import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import "./user-profile-edit.css";

//image
import imageCompression from "browser-image-compression";
import noprofile from "../../../assets/images/no-profile-picture.jpg";
import { useProfile } from "../../../context/ProfileContext";
import { UpdatedProfileImage } from "../components/Functions";
import { servicePath } from "./variables";

const UserProfileEdit = () => {
  const userValidation = () => {
    let error = {};
    let isValid = true;
    let emailReg = "/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/";

    if (!firstName || firstName === "") {
      error.firstName = "Please enter first name";
      isValid = false;
    }
    if (!lastName || lastName === "") {
      error.lastName = "Please enter last name";
      isValid = false;
    }
    // if (!gender || gender === "") {
    //     error.gender = "Please select gender"
    //     isValid = false
    // }

    if (!email && email === "" && email.match(emailReg)) {
      error.email = "Enter valid email";
      isValid = false;
    }
    // if (!dob || dob === "") {
    //     error.dob = "Please select birthday"
    //     isValid = false
    // }
    setErrors(error);
    return isValid;
  };

  const passwordValidation = () => {
    let error = {};
    let isValid = true;

    if (!currentPassword || currentPassword === "") {
      error.currentPassword = "Current password is required";
      isValid = false;
    }
    if (!newPassword || newPassword === "") {
      error.newPassword = "New password is required";
      isValid = false;
    }

    if (newPassword !== "" && newPassword.length < 8) {
      error.newPassword = "Password must be of 8 characters";
      isValid = false;
    }

    if (!confirmPassword || confirmPassword === "") {
      error.confirmPassword = "Confirm password is required";
      isValid = false;
    }
    if (
      confirmPassword !== "" &&
      newPassword !== "" &&
      confirmPassword !== newPassword
    ) {
      error.confirmPassword = "New and confirm password should be same";
      isValid = false;
    }
    setErrors(error);
    return isValid;
  };

  const userData = JSON.parse(localStorage.getItem("users"));
  const token = "Bearer" + " " + localStorage.getItem("access_token");

  const [errors, setErrors] = useState({});

  const [emailError, setEmailError] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");

  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [homeCity, setHomeCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [userAge, setUserAge] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cPasswordError, setCPasswordError] = useState(false);

  const { updateProfileImage } = useProfile();

  const genders = [
    {
      g: "Male",
      o: "M",
    },
    {
      g: "Female",
      o: "F",
    },
    {
      g: "Non-binary",
      o: "O",
    },
  ];

  const currentDate = new Date();

  useEffect(() => {
    if (userData !== null) {
      showDetails();
    }
  }, []);

  const showDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.me`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        if (result && result.data && result.data.name) {
          var userNameHead = document.getElementById("user_name_head");
          userNameHead.innerText = result.data.name;

          // console.log(result.data.name, "result.data.name")
        }

        if (result && result.data) {
          setFirstName(result.data.firstName);
          setLastName(result.data.lastName);
          setUserName(result.data.username);
          setDob(new Date(result.data.dob));
          setGender(result.data.gender);
          setAddress(result.data.address);
          setPhone(result.data.phone);
          setProfileImage(result.data.photo);
          setCountry(result.data.country);
          setState(result.data.state);
          setMaritalStatus(result.data.marital_status);
          setCurrentCity(result.data.currentCity);
          setHomeCity(result.data.homeCity);
          setAboutMe(result.data.aboutMe);
          setUserAge(result.data.age);
          setEmail(result.data.email);
          setWebsite(result.data.website);
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  const uploadProfilePic = (dataimg) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("profile_image", dataimg);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.profile_upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === "Profile Uploaded successfully") {
          setMessage("Profile Picture Uploaded Successfully");
          setShowMessage(true);
          showDetails();

          if (result.data.photo) {
            UpdatedProfileImage(result.data.photo);
            updateProfileImage(result.data.photo);
            setLoading(false);
          }
          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const userUpdate = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        id: userData.id,
        username: userName,
        email: email,
        gender: gender,
        firstName: firstName,
        lastName: lastName,
        name: firstName + lastName,
        phone: phone || "",
        address: address || "",
        website: website,
        aboutMe: aboutMe,
        dob: dob,
        currentCity: currentCity,
        homeCity: homeCity,
        marital_status: maritalStatus,
        state: state,
        age: userAge,
        country: country,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.update_user`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === "Settings updated successfully") {
          setMessage("Profile Update Succesfully");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);

          showDetails();
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  const updatePassword = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password_match: currentPassword,
      new_password: newPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.reset_password`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "password update")
        if (result.success) {
          setMessage("Password Update Succesfully");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);

          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setCPasswordError(false);
        }

        if (result.error) {
          setMessage(result.error);
          setCPasswordError(true);
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  // {console.log(userInfo.address)}

  // console.log(maritalStatus , userAge , profileImage , country , "data");
  // console.log(userInfo.userName);

  const age = [
    {
      a: "18-35",
    },
    {
      a: "36-45",
    },
    {
      a: "46-62",
    },
    {
      a: "63-100",
    },
  ];

  const countryList = [
    {
      c: "USA",
    },
    {
      c: "Canada",
    },
    {
      c: "India",
    },
    {
      c: "Africa",
    },
  ];

  const stateList = [
    {
      s: "Georgia",
    },
    {
      s: "Florida",
    },
    {
      s: "California",
    },
    {
      s: "Louisiana",
    },
    {
      s: "Connecticut",
    },
  ];

  const marital_statusList = [
    {
      m: "Single",
    },
    {
      m: "Married",
    },
    {
      m: "Widowed",
    },
    {
      m: "Divorced",
    },
    {
      m: "Separated",
    },
  ];

  // const changeimage=(e)=>{
  //     uploadProfilePic(e.target.files[0]);
  // }

  const handleEmail = (e) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regEmail.test(e.target.value)) {
      console.log("email", "Valid");
      setEmailError(false);
    } else {
      console.log("email", "invalid");

      setEmailError(true);
    }
  };

  async function changeimage(event) {
    setLoading(true);

    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 20,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      var compressedFileSize = compressedFile.size / 1024 / 1024;

      await console.log("compression success");

      if (compressedFileSize > 20) {
        setMessage("File size too large");
        setShowMessage(true);
        setLoading(false);

        setTimeout(() => {
          setShowMessage(false);
          setMessage("");
        }, 2000);
      } else {
        uploadProfilePic(compressedFile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Container>
        {showMessage && message ? (
          <div className="alert-message">{message}</div>
        ) : (
          <></>
        )}

        {loading && (
          <div className="main_loader">
            <img src={loader} alt="loader" height="100px" />
          </div>
        )}

        <Tab.Container defaultActiveKey="first">
          <Row>
            <Col lg="12" className="position-relative">
              <Card>
                <Card.Body className="p-0">
                  <div>
                    <Nav
                      as="ul"
                      variant="pills"
                      className="iq-edit-profile row"
                    >
                      <Nav.Item as="li" className="col-6">
                        <Nav.Link eventKey="first" role="button">
                          Personal Info
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-6 ps-0">
                        <Nav.Link eventKey="second" role="button">
                          Change Password
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item as="li" className="col-sm-4">
                                    <Nav.Link  eventKey="third" role="button">
                                        Email and SMS
                                    </Nav.Link>
                                </Nav.Item> */}
                      {/* <Nav.Item as="li" className="col-sm-4">
                                    <Nav.Link eventKey="fourth" role="button">
                                        Manage Contact
                                    </Nav.Link>
                                </Nav.Item> */}
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              {/* <div className="iq-edit-list-data"> */}
              <Tab.Content>
                <Tab.Pane eventKey="first" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Personal Information</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group className="form-group align-items-center">
                        <Col md="12">
                          <div className="profile-img-edit d-flex mx-auto">
                            <img
                              className="profile-pic"
                              src={profileImage || noprofile}
                              alt="profile-pic"
                              onError={(e) => {
                                e.target.src = noprofile;
                              }}
                            />
                            {/* {profileImage ? <img className="profile-pic" src={profileImage} alt="profile-pic"/> : <img className="profile-pic" src={noprofile} alt="profile-pic"/>} */}

                            <div className="p-image">
                              <input
                                type="file"
                                name="profile"
                                id="input"
                                className="file-upload"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={changeimage}
                              />
                              <label htmlFor="input">
                                <i
                                  className="ri-pencil-line upload-button text-white"
                                  id="1"
                                ></i>
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Form.Group>
                      <Row>
                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="fname" className="form-label">
                            First Name:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="fname"
                            name="firstName"
                            value={firstName || ""}
                            placeholder="First name"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <div className="text-danger">{errors.firstName}</div>
                        </Form.Group>
                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="lastName" className="form-label">
                            Last Name:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Last name"
                            value={lastName || ""}
                            name="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <div className="text-danger">{errors.lastName}</div>
                        </Form.Group>
                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="userName" className="form-label">
                            Username:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="userName"
                            placeholder="Username"
                            value={userName || ""}
                            name="userName"
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="email" className="form-label">
                            Email:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Uni@universimm.com"
                            name="email"
                            value={email || ""}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={handleEmail}
                            disabled="disabled"
                          />
                          {/* <div className="text-danger">{errors.email}</div> */}

                          {(errors.email || emailError) && (
                            <div className="text-danger">
                              Please enter valid email
                            </div>
                          )}
                        </Form.Group>
                        {/* <Form.Group className="form-group col-sm-6">
                                            <Form.Label htmlFor="cname" className="form-label">City:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="cname" placeholder="Atlanta" name="city" value={userInfo.city || '} onChange={inputchange}/>
                                        </Form.Group> */}
                        {/* <Form.Group className="form-group col-sm-6">
                                            <div className="form-label d-block">Gender:</div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio10"  value="1" onChange ={() => setGender("Male")}   checked= {gender === "Male"} />
                                                <label className="form-check-label" htmlFor="inlineRadio10"> Male</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11"  value="2" onChange ={() => setGender("Female")}  checked= {gender === "Female"} />
                                                <label className="form-check-label" htmlFor="inlineRadio11">Female</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11"  value="3" onChange ={() => setGender("Others")}  checked= {gender === "Others"} />
                                                <label className="form-check-label" htmlFor="inlineRadio11">Others</label>
                                            </div>
                                        </Form.Group> */}

                        {/* <Form.Group className="form-group col-sm-6">
                                            <Form.Label htmlFor="gender" className="form-label">Gender:</Form.Label>
                                            <Form.Select
                                                onChange={(e) => setGender(e.target.value)}
                                                value={gender}
                                                id="gender"
                                                name='gender'
                                            >
                                                <option disabled>Choose Gender</option>
                                                {genders.map((item, index) => {
                                                    return <option key={index} value={item.o}>{item.g}</option>;
                                                })}
                                            </Form.Select>
                                            <div className="text-danger">{errors.gender}</div>
                                        </Form.Group> */}

                        {/* <Form.Group className="form-group col-sm-6">
                                            <Form.Label htmlFor="dob" className="form-label">Date Of Birth:</Form.Label>
                                            <ReactDatePicker
                                                dateFormat='dd-MM-yyyy'
                                                selected={dob}
                                                endDate={currentDate}
                                                maxDate={currentDate}
                                                onChange={(date) => setDob(date)}
                                                placeholderText="Date"
                                                className='form-control'
                                            />
                                            <div className="text-danger">{errors.dob}</div>
                                        </Form.Group> */}

                        <Form.Group className="form-group col-sm-6">
                          <Form.Label className="form-label">
                            Marital Status:
                          </Form.Label>
                          <Form.Select
                            className="form-select"
                            aria-label="Default select example"
                            name="maritalStatus"
                            value={maritalStatus || "Single"}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                          >
                            <option disabled>Select Marital Status</option>
                            {marital_statusList.map((item, index) => {
                              return <option key={index}>{item.m}</option>;
                            })}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="form-group col-sm-6">
                          <Form.Label className="form-label">Age:</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            name="age"
                            value={userAge || ""}
                            onChange={(e) => setUserAge(e.target.value)}
                          />
                        </Form.Group>

                        {/* <AddressDynamic setCountry={setCountry} setState={setState} setCurrentCity={setCurrentCity} />
                                        <p>{country} {state} {currentCity}</p> */}

                        {/* <Form.Group className="form-group col-sm-6">
                                            <Form.Label  className="form-label">Country:</Form.Label>
                                            <Form.Select className="form-select" aria-label="Default select example 3" name="country" value={country || ''} onChange={(e) => setCountry(e.target.value)}>
                                                <option disabled>Select Your Country</option>
                                                {countryList.map((item , index) =>{
                                                    return(
                                                        <option key={index} >{item.c}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group> */}

                        {/* <Form.Group className="form-group col-sm-6">
                                            <Form.Label className="form-label">State:</Form.Label>
                                            <Form.Select  name="state" className="form-select" aria-label="Default select example 4" onChange={(e) => setState(e.target.value)} value={state || ''}>
                                            <option disabled>Select State</option>
                                            
                                            {stateList.map((item , index) => {
                                                return(
                                                <option key={index}>{item.s}</option>
                                                )
                                            })}
                                            </Form.Select>
                                        </Form.Group> */}

                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="currcity" className="form-label">
                            Current City:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="uname"
                            name="currentCity"
                            value={currentCity || ""}
                            placeholder=""
                            onChange={(e) => setCurrentCity(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="currcity" className="form-label">
                            Home City:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="uname"
                            name="homeCity"
                            value={homeCity || ""}
                            placeholder=""
                            onChange={(e) => setHomeCity(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="website" className="form-label">
                            Website:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="uname"
                            name="website"
                            value={website || ""}
                            placeholder="www.universimm.com"
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="form-group col-sm-6">
                          <Form.Label htmlFor="phone" className="form-label">
                            Contact Number:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone || ""}
                          />
                        </Form.Group>
                        <Form.Group className="form-group col-sm-12">
                          <Form.Label className="form-label d-flex justify-content-between">
                            <span>About Me:</span>

                            <span>{aboutMe && aboutMe.length}</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            className="form-control"
                            maxLength={101}
                            rows={3}
                            style={{ lineHeight: "22px" }}
                            placeholder=""
                            name="aboutMe"
                            onChange={(e) => setAboutMe(e.target.value)}
                            value={aboutMe || ""}
                          ></Form.Control>
                          <div className="text-danger">
                            {aboutMe &&
                              aboutMe.length === 101 &&
                              "Max 101 characters allowed!"}
                          </div>
                        </Form.Group>
                        {/* <Form.Group className="form-group col-sm-12">
                                            <Form.Label className="form-label">Address:</Form.Label>
                                            <Form.Control as="textarea" className="form-control" rows={5} style={{lineHeight: "22px"}} placeholder=" 37 Cardinal Lane
                                            Petersburg, VA 23803
                                            United States of America
                                            Zip Code: 85001" name="address" onChange={(e) => setAddress(e.target.value)} value={address || ''}>
                                            </Form.Control>
                                        </Form.Group> */}
                      </Row>
                      <button
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          userValidation() ? userUpdate() : <></>;
                        }}
                      >
                        Submit
                      </button>
                      {/* <Button type="reset" className="bg-soft-danger">Cancel</Button> */}
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Change Password</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group className="form-group">
                          <div className="d-flex justify-content-between flex-column-reverse flex-sm-row">
                            <Form.Label
                              htmlFor="currentPassword"
                              className="form-label"
                            >
                              Current Password:
                            </Form.Label>

                            <span className="mb-3 mb-sm-0">
                              <strong>Forgot:</strong>{" "}
                              <Link to="/auth/reset-password">
                                Reset Password
                              </Link>
                            </span>
                          </div>
                          <Form.Control
                            type="Password"
                            className="form-control"
                            id="currentPassword"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            value={currentPassword || ""}
                          />
                          <div className="text-danger">
                            {errors.currentPassword}
                          </div>
                          {cPasswordError && !errors.currentPassword && (
                            <div className="text-danger">
                              Current password incorrect
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label
                            htmlFor="newPassword"
                            className="form-label"
                          >
                            New Password:
                          </Form.Label>
                          <Form.Control
                            type="Password"
                            className="form-control"
                            id="newPassword"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword || ""}
                          />
                          <div className="text-danger">
                            {errors.newPassword}
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password:
                          </Form.Label>
                          <Form.Control
                            type="Password"
                            className="form-control"
                            id="confirmPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword || ""}
                          />
                          <div className="text-danger">
                            {errors.confirmPassword}
                          </div>
                        </Form.Group>
                        <Button
                          type="button"
                          className="btn btn-primary me-2"
                          onClick={() => {
                            passwordValidation() ? updatePassword() : <></>;
                          }}
                        >
                          Submit
                        </Button>
                        {/* <Button type="reset" className="bg-soft-danger">Cancel</Button> */}
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className="fade show">
                  <Card>
                    <Card.Header className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Email and SMS</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label
                            className="col-md-3"
                            htmlFor="emailnotification"
                          >
                            Email Notification:
                          </Form.Label>
                          <Form.Check className="col-md-9 form-check form-switch">
                            <Form.Check.Input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked11"
                              defaultChecked
                            />
                            <Form.Check.Label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckChecked11"
                            >
                              Enable email notifications
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label
                            className="col-md-3"
                            htmlFor="smsnotification"
                          >
                            SMS Notification:
                          </Form.Label>
                          <Form.Check className="col-md-9 form-check form-switch">
                            <Form.Check.Input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked12"
                              defaultChecked
                            />
                            <Form.Check.Label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckChecked12"
                            >
                              Enable SMS notifications
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label className="col-md-3" htmlFor="npass">
                            When To Email
                          </Form.Label>
                          <Col md="9">
                            <Form.Check className="form-check">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault12"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="flexCheckDefault12"
                              >
                                You have new notifications.
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                id="email02"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email02"
                              >
                                You're sent a direct message
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                type="checkbox"
                                className="form-check-input"
                                id="email03"
                                defaultChecked
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email03"
                              >
                                Someone adds you as a connection
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Form.Group>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label className="col-md-3" htmlFor="npass">
                            When To Escalate Emails
                          </Form.Label>
                          <Col md="9">
                            <Form.Check className="form-check">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                id="email04"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email04"
                              >
                                Upon new order.
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                id="email05"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email05"
                              >
                                New membership approval
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                type="checkbox"
                                className="form-check-input"
                                id="email06"
                                defaultChecked
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email06"
                              >
                                Member registration
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Form.Group>
                        <Button type="submit" className="btn btn-primary me-2">
                          Submit
                        </Button>
                        {/* <Button type="reset" className="bg-soft-danger">Cancel</Button> */}
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="fourth" className="fade show">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Manage Contact</h4>
                                </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="cno"  className="form-label">Contact Number:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="cno" onChange={(e) => setPhone(e.target.value)} value={phone || ''}/>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="email"  className="form-label">Email:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} value={email || ''}/>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="url"  className="form-label">Website:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="url" onChange={(e) => setWebsite(e.target.value)} value={website || ''}/>
                                        </Form.Group>
                                        <Button type="button" className="btn btn-primary me-2" onClick={() => userUpdate()}>Submit</Button>
                                        <Button type="reset" className="bg-soft-danger">Cancel</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Tab.Pane> */}
              </Tab.Content>
              {/* </div> */}
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default UserProfileEdit;
