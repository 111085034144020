export default function markSubscribedStatus(array1, array2) {
  const newArray1 = array1.map((item1) => {
    const foundItem2 = array2.find((item2) => item2.ID === item1.ID);
    if (foundItem2) {
      return { ...item1, subscribed: true };
    } else {
      return { ...item1, subscribed: false };
    }
  });

  return newArray1;
}
