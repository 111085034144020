import imageCompression from "browser-image-compression";
import React, { useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

function ImageVideoPreview({
  setAddPhotoActive,
  addPhotoActive,
  handleFileReset,
  setPostType,
  file,
  setFile,
  fileType,
  setFileType,
  setFileName,
  setUploadFile,
  editPostComponent,
  setPostEdited,
}) {
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  const [fileSizeValue, setFileSizeValue] = useState("");

  async function handleChange(event) {
    if (editPostComponent) {
      setPostEdited(true);
    }

    const imageFile = event.target.files[0];

    var fileTypeName = event.target.files[0].type.substring(0, 5);

    const options = {
      maxSizeMB: 20,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      var compressedFileSize = compressedFile.size / 1024 / 1024;

      await console.log("image compressed");

      if (compressedFileSize > 20) {
        setFileSizeError(true);

        var actualFIleSize = compressedFileSize.toFixed(2);
        setFileSizeValue(actualFIleSize);
      } else {
        if (fileTypeName === "image") {
          setFileTypeError(false);

          setFile(URL.createObjectURL(compressedFile));
          setFileName(compressedFile.name);
          setUploadFile(compressedFile);

          setFileType(fileTypeName);

          if (fileTypeName != "" || fileTypeName != undefined) {
            setPostType(fileTypeName);
          } else {
            setPostType("text");
          }
        } else {
          setFileTypeError(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // function handleChange(e) {
  //     console.log(e.target.files);

  //     var fileTypeName = e.target.files[0].type.substring(0, 5);

  //     if(fileTypeName === 'image') {
  //         setFileTypeError(false)

  //         setFile(URL.createObjectURL(e.target.files[0]));
  //         setFileName(e.target.files[0].name);

  //         setFileType(fileTypeName)

  //         if(fileTypeName !='' || fileTypeName != undefined) {
  //             setPostType(fileTypeName)
  //         } else {
  //             setPostType("text");
  //         }
  //     } else {
  //         setFileTypeError(true)
  //     }
  // }

  function handleInputReset(e) {
    e.target.value = "";

    if (editPostComponent) {
      setPostEdited(true);
    }
  }

  return (
    <div className="mt-3 position-relative">
      {fileTypeError && (
        <h5 className="text-danger my-2">File type not support</h5>
      )}

      {fileSizeError && (
        <h6 className="text-danger my-2">
          Uploaded image size is <strong>{fileSizeValue}MB</strong> after
          compression. Max file size allowed after compression is{" "}
          <strong>1MB</strong>
        </h6>
      )}

      <div className={`upload_file_wrap ${file != "" ? "file_uploaded" : ""}`}>
        <div className="upload_input">
          <Form.Label htmlFor="uploadFile">
            <input
              type="file"
              name="uploadFile"
              accept="image/jpeg, image/png, image/jpg"
              id="uploadFile"
              onChange={handleChange}
              onClick={handleInputReset}
            />
            <div className="upload_file_inner d-flex flex-column align-items-center">
              <i className="ri-gallery-fill"></i>
              <h5 className="mt-2 fw-bold">Add photos</h5>
              <span className="text-muted">or drag and drop</span>
            </div>
            <Link
              to="#"
              className="lh-1 close_btn border right_10"
              onClick={() => {
                handleFileReset();
                setAddPhotoActive(!addPhotoActive);
              }}
            >
              <i className="ri-close-fill ri-xl"></i>
            </Link>
          </Form.Label>
        </div>
      </div>
      {fileType == "audio" && (
        <div className="video_player_wrap d-flex align-items-centre">
          <ReactAudioPlayer src={file} controls className="w-100 me-5" />
          <Link
            to="#"
            className="lh-1 close_btn border right_10 close_sm"
            onClick={handleFileReset}
          >
            <i className="ri-close-fill ri-xl"></i>
          </Link>
        </div>
      )}
      {fileType == "video" ? (
        <div className="video_player_wrap">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={file}
              width="100%"
              height="100%"
              controls={true}
              playsinline
            />
          </div>
          <Link
            to="#"
            className="lh-1 close_btn border right_10 close_sm"
            onClick={handleFileReset}
          >
            <i className="ri-close-fill ri-xl"></i>
          </Link>
        </div>
      ) : (
        <>
          {file != "" && fileType == "image" && (
            <div className="upload_file_wrap">
              <div className="photo_preview">
                <img width="200" src={file} />
                <Link
                  to="#"
                  className="lh-1 close_btn bg-white right_10 close_sm"
                  onClick={handleFileReset}
                >
                  <i className="ri-close-fill ri-xl"></i>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ImageVideoPreview;
