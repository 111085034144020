import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import useUniverse from "../../../hooks/services/useUniverse";

import { DEFAULT_AVATAR } from "../../../constant/variables";
import { useProfile } from "../../../context/ProfileContext";
import classNames from "../../../helper/classeNames";
import markSubscribedStatus from "../../../helper/markSubscribedStatus";
import useUserState from "../../../hooks/services/useUserState";
import useQueryParams from "../../../hooks/useQueryParams";
import "./StoryViews.css";
const StickyStoryViews = () => {
  const { data = [], isLoading } = useUniverse();

  const { data: useStateData, isLoading: userStateLoading } = useUserState();
  const { universeId } = useQueryParams();

  const { profileImage, userInfo, storyBar, closeStoryBar } = useProfile();
  const { universeType } = useQueryParams();

  const UPDATE_UNIVERSE = markSubscribedStatus(
    data,
    useStateData?.universes ?? []
  ).filter((item) => item.subscribed);

  return (
    <div
      className={classNames(
        "storybar-main-sticky ",
        storyBar ? "d-block" : "d-none d-xl-block"
      )}
    >
      <div className="story-sidebar">
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div style={{ paddingBottom: "15px" }}>
            <strong>Your Stories</strong>
          </div>
          <button className="btn d-xl-none" onClick={closeStoryBar}>
            <i className="ri-close-fill ri-xl"></i>
          </button>
        </div>

        <ul className="p-0 stories-list">
          <li
            style={{
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
            }}
            className={classNames(
              "mb-3 d-block",
              universeType === "profile" && "isActiveStory"
            )}
          >
            <Link
              className="d-block"
              // to={`/dashboard/stories?universeId`}
              to={`/dashboard/stories?universeType=profile`}
              style={{ color: "inherit" }}
            >
              <div style={{ gap: 10 }} className="d-flex align-items-center">
                <img
                  src={profileImage}
                  alt=""
                  width={50}
                  height={50}
                  className="rounded-circle border-3 border border-secondary shadow-md"
                  id="story_pro_pic"
                  onError={(e) => {
                    e.target.src = DEFAULT_AVATAR;
                  }}
                />

                <div>
                  <strong className="p-0 m-0">{userInfo?.name}</strong>
                </div>
              </div>
            </Link>
          </li>
          <li
            className={classNames(
              "mb-3 d-block"
              // Number(universeId) === Number(item.ID) && "isActiveStory"
            )}
          >
            <div className="d-flex justify-content-between">
              <Link
                to={"/dashboard/stories/create"}
                className="btn btn-primary btn-sm mb-1"
              >
                <i className="fas fa-plus"></i> Create Story
              </Link>
            </div>
          </li>
        </ul>
      </div>

      <div style={{ paddingBottom: "15px" }}>
        <h2>Stories</h2>
        <strong>All Stories</strong>
      </div>
      {isLoading | userStateLoading ? (
        <Loader />
      ) : (
        <ul className="p-0 stories-list">
          {UPDATE_UNIVERSE.map((item, index) => (
            <li
              style={{
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
              }}
              className={classNames(
                "mb-3 d-block",
                Number(universeId) === Number(item.ID) && "isActiveStory"
              )}
              key={index}
            >
              <Link
                className="d-block"
                to={`/dashboard/stories?universeId=${item.ID}&universeType=universe`}
                style={{ color: "inherit" }}
              >
                <div style={{ gap: 10 }} className="d-flex align-items-center">
                  <img
                    src={item?.universeIcon}
                    alt=""
                    width={50}
                    height={50}
                    style={{
                      border: `3px solid ${
                        item.subscribed ? "#2dcdb2" : "#b7b6b6"
                      } `,
                    }}
                    className="rounded-circle  shadow-md"
                  />

                  <div>
                    <strong className="p-0 m-0">{item?.universeName}</strong>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StickyStoryViews;
