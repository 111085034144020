import { useEffect, useState } from "react";

const useQueryParams = () => {
  const [params, setParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const newParams = {};

    searchParams.forEach((value, key) => {
      newParams[key] = value;
    });

    setParams(newParams);
  }, [window.location.search]);

  return params;
};

export default useQueryParams;
