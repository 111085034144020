import React, { useEffect, useState } from 'react'
import { Container, Row, Dropdown } from 'react-bootstrap'
import { useLocation, Link } from 'react-router-dom'

import { servicePath } from "../app/variables";

import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'

const Blocking = () => {

    const token = "Bearer" + " " + localStorage.getItem("access_token")

    const location = useLocation()

    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [blockedUsers, setBlockedUsers] = useState([])

    useEffect(() => {
        setLoading(true)

        if (token !== null) {
            getBlockedUsersData()
        }
    }, [])

    const getBlockedUsersData = () => {
        setLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${servicePath}/friend.getblock`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result, "blocked users")

                setLoading(false);

                if (result && result.data) {
                    setBlockedUsers(result.data)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleUnblockUser = (userID) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data":{
                "blocked_user_id": userID
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/friend.unblock`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            if (result.success == "User unblocked successfully") {
                setMessage("Unblocked successfully")
                setShowMessage(true)

                getBlockedUsersData()

                setTimeout(() => {
                    setShowMessage(false)
                }, 2000)
            }
        })
        .catch(error => console.log('error', error));
    };

    return (
        <Container fluid>
            <h4 className='mb-3'>Blocked users</h4>
            
            {loading && (
                <div className="main_loader">
                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                </div>
            )}

            {showMessage && message ? <div className="alert-message">{message}</div> : <></>}

            {!loading && (
                <Row>
                    {blockedUsers && blockedUsers.length > 0 ? (
                        <>
                            {blockedUsers.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="col-md-6 col-lg-6 col-xl-4 mb-3"
                                    >
                                        <div className="iq-friendlist-block custom_friends p-1 p-sm-2 rounded">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center friend_wrap me-2">
                                                    <Link to={`/dashboard/app/friend-profile/${item.id}`}>
                                                        <img
                                                            src={item && item.photo ? item.photo : dummyUserImage}
                                                            alt="profile-img"
                                                            className="img-fluid me-2"
                                                            onError={(e) => {
                                                                e.target.src = dummyUserImage
                                                            }}
                                                        />
                                                    </Link>
                                                    <div className="friend-info primary_drop_btn">
                                                        <h5 className="fw-bold mb-0 text-wrap">{item.name}</h5>

                                                        {/* <button type="button" className="btn btn-sm btn-primary" onClick={() => { handleUnblockUser(item.id) }}>
                                                            Unblock
                                                        </button> */}
                                                    </div>
                                                </div>
                                                <div className="card-header-toolbar d-flex align-items-center primary_drop_btn">
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => { handleUnblockUser(item.id) }}>
                                                        Unblock
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <h5 className="text-center">
                            No record found!
                        </h5>
                    )}
                </Row>
            )}
        </Container>
    )
}

export default Blocking;
