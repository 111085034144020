import { ErrorMessage, Field } from "formik";

const FormikSelect = ({ label, name, options, ...rest }) => {
  return (
    <div className="mb-2">
      <label htmlFor={name} className="text-dark fw-bolder">
        {label}
      </label>
      <Field
        as="select"
        className="form-select"
        id={name}
        name={name}
        aria-label={label}
        {...rest}
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
};

export default FormikSelect;
