import axios from "axios";
import { mainBaseURL } from "../views/dashboard/app/variables";

const token = "Bearer " + localStorage.getItem("access_token");
const axiosInstance = axios.create({
  baseURL: mainBaseURL, // Your API base URL
  headers: {
    Authorization: token,
    "Content-Type": "application/json", // You can include other headers here as needed
  },
});

export default axiosInstance;
