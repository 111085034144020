import React, {useState, useEffect} from 'react'
import { Row, Col, Container, Form} from 'react-bootstrap'
import Card from '../../../components/Card'
import { servicePath } from './variables'

const UserPrivacySetting =() =>{
    
    const [uFriends, setUFriends] = useState(false)
    const [uLikes, setULikes] = useState(false)
    const [uComments, setUComments] = useState(false)
    const [uEmail, setUEmail] = useState(false)
    const [uBirthday, setUBirthday] = useState(false)
    const [uTags, setUTags] = useState(false)

    const [message, setMessage] = useState("")
    const [showMessage, setShowMessage] = useState(true)

    const userData = JSON.parse(localStorage.getItem("users"))

    useEffect(() => {
        if(userData !== null){
            userState();
        }
    }, []);

    const userState = () => {
        var myHeaders = new Headers();

        const token = "Bearer" + " " + localStorage.getItem("access_token");

        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(
        `${servicePath}/user.state`,
        requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
            // console.log(result, "notification settings");
            if(result.data.notification && result.data.notification.ID) {
                
                setUFriends(result.data.notification.u_friends === 'on' ? true : false);
                setULikes(result.data.notification.u_likes === 'on' ? true : false)
                setUComments(result.data.notification.u_comments === 'on' ? true : false);
                setUEmail(result.data.notification.u_emails === 'on' ? true : false);
                setUBirthday(result.data.notification.u_birthdays === 'on' ? true : false)
                setUTags(result.data.notification.u_tags === 'on' ? true : false)
            }
        })
        .catch((error) => console.log("error", error));
        };

    const updatePrivacySettings = () => {
        const token = "Bearer" +" "+ localStorage.getItem("access_token")

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "friends": uFriends ? 'on' : 'off',
                "likes": uLikes ? 'on' : 'off',
                "comments": uComments ? 'on' : 'off',
                "emails": uEmail ? 'on' : 'off',
                "birthdays": uBirthday ? 'on' : 'off',
                "tags": uTags ? 'on' : 'off',
            }
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${servicePath}/user.update_notification`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if(result.success ===  "User Preference Updated"){
                    setMessage("Notification settings updated")
                    setShowMessage(true)
                    setTimeout(() => {
                        setShowMessage(false)
                    }, 3000);
                }
        })
        .catch((error) => console.log("error", error))
        .finally((error) => 
            console.log(error)
        )
    };

    return(
        <>
            <Container className='position-relative'>
                {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Notification Setting</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form className="notify_settings">
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <h5 className="mb-0">U-Friends</h5>
                                            <p className='mb-0'>Get notified when friends adds you</p>
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check className="col-md-9 form-check form-switch">
                                                <Form.Check.Input className="form-check-input" type="checkbox" id="u_friends" onChange={() => {setUFriends(!uFriends)}} checked={uFriends}/>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <h5 className="mb-0">U-Likes</h5>
                                            <p className='mb-0'>Get notified when people like your posts</p>
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check className="col-md-9 form-check form-switch">
                                                <Form.Check.Input className="form-check-input" type="checkbox" id="u_likes" onChange={() => {setULikes(!uLikes)}} checked={uLikes}/>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <h5 className="mb-0">U-Comments</h5>
                                            <p className='mb-0'>Get notified when someone comments on your posts</p>
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check className="col-md-9 form-check form-switch">
                                                <Form.Check.Input className="form-check-input" type="checkbox" id="u_likes" onChange={() => {setUComments(!uComments)}} checked={uComments}/>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <h5 className="mb-0">U-Email</h5>
                                            <p className='mb-0'>All notifications</p>
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check className="col-md-9 form-check form-switch">
                                                <Form.Check.Input className="form-check-input" type="checkbox" id="u_likes" onChange={() => {setUEmail(!uEmail)}} checked={uEmail}/>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <h5 className="mb-0">U-Birthday</h5>
                                            <p className='mb-0'>Get notified about your friends birthday</p>
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check className="col-md-9 form-check form-switch">
                                                <Form.Check.Input className="form-check-input" type="checkbox" id="u_likes" onChange={() => {setUBirthday(!uBirthday)}} checked={uBirthday}/>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <h5 className="mb-0">U-Tags</h5>
                                            <p className='mb-0'>Get notified when you are tagged</p>
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check className="col-md-9 form-check form-switch">
                                                <Form.Check.Input className="form-check-input" type="checkbox" id="u_likes" onChange={() => {setUTags(!uTags)}} checked={uTags}/>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <button type='button' className="btn btn-primary me-2" onClick={() => updatePrivacySettings()}>Submit</button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserPrivacySetting;