import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  DirAction,
  ModeAction,
  getDarkMode,
  getRtlMode,
} from "../../../../store/mode/mode";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    rtlMode: getRtlMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      DirAction,
    },
    dispatch
  ),
});

const Footer = (props) => {
  const darkModeLocal = localStorage.getItem("dark_mode");

  if (darkModeLocal === "true") {
    props.ModeAction(true);
  } else {
    props.ModeAction(false);
  }

  var dark = props.darkMode;
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("dark");
  if (mode !== null) {
    document.body.classList = "";
    dark = false;
    switch (mode) {
      case "true":
        document.body.classList = "dark";
        dark = true;
        break;
      case "false":
        document.body.classList = "";
        dark = false;
        break;
      default:
        document.body.classList = "";
        dark = false;
        break;
    }
  }
  return (
    <>
      <footer className="iq-footer w-100 ms-0 bg-white" style={{ zIndex: 666 }}>
        <Container>
          <Row>
            <Col
              sm={5}
              lg={6}
              className="d-flex justify-content-center justify-content-sm-start"
            >
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="/user/privacy-policy">Privacy Policy</Link>
                </li>{" "}
                <li className="list-inline-item">
                  <Link to="/user/terms-conditions">Terms & conditions</Link>
                </li>
              </ul>
            </Col>
            <Col
              sm={7}
              lg="6"
              className="d-flex justify-content-center justify-content-sm-end"
            >
              <span>
                Copyright 2023
                <a href="https://www.universimm.com"> Universimm </a> All Rights
                Reserved.
              </span>
            </Col>
          </Row>
        </Container>
      </footer>

      <div className="iq-float-menu">
        <input
          type="checkbox"
          className="iq-float-menu-open"
          name="menu-open"
          id="menu-open"
        />
        <Button
          bsPrefix="iq-float-menu-item bg-danger"
          onClick={() => {
            props.ModeAction(!props.darkMode);
          }}
        >
          <i
            className={`${
              props.darkMode ? "ri-moon-clear-line" : "ri-sun-line"
            }`}
            checked={props.darkMode || dark}
            data-active={
              props.darkMode ? "true" : "false" || dark ? "true" : "false"
            }
          ></i>
        </Button>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
