import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import Card from "../../components/Card";
import CustomToggle from "../../components/dropdowns";
import "../index.css";
import ProfilePostItem from "./components/ProfilePostItem";

import { useQueryClient } from "@tanstack/react-query";
import reactDom from "react-dom";
import NumericLabel from "react-pretty-numbers";
import dummyUserImage from "../../assets/images/no-profile-picture.jpg";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { INVALIDATE_UNIVERSE } from "../../hooks/services/useUniverse";
import { USER_STATES } from "../../hooks/services/useUserState";
import StickyStoryViews from "../Stories/storyViews/StickyStoryViews";
import StickySuggestedFriends from "../SuggestedFriends/StickySuggestedFriends";
import { responsiveUniversesFeeds, servicePath } from "./app/variables";
import ImageVideoPreviewCreate from "./components/ImageVideoPreviewCreate";
import LinkPreview from "./components/LinkPreview";
import PostFilterModal from "./components/PostFilterModal";
import SubscribeUniverseModal from "./components/SubscribeUniverseModal";

const Index = (props) => {
  const history = useHistory();

  const location = useLocation();

  const universeNameProfile = location?.state?.universeName;
  const universeIdProfile = location?.state?.selectedUniverseId;
  const subUniversesList = location?.state?.subUniverses;
  const queryClient = useQueryClient();

  // const [multiverses, setMultiverses] = useState([])
  const [multiverseCountry, setMultiverseCountry] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [friendListDisplay, setFriendListDisplay] = useState(friendList);

  const [universes, setUniverses] = useState([]);
  const [selectedUniverse, setSelectedUniverse] = useState(false);
  const [selectedUniverseName, setSelectedUniverseName] = useState("");
  const [selectedSubUni, setSelectedSubUni] = useState("");
  const [selectedSubUniverseName, setSelectedSubUniverseName] = useState("");
  const [postType, setPostType] = useState("");
  const [postBodyMsg, setPostBodyMsg] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [postCreated, setPostCreated] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [hasMediaError, setHasMediaError] = useState(false);

  const [filterUniverseID, setFilterUniverseID] = useState({
    id: "",
    name: "",
  });

  const [filterSubUniverseID, setFilterSubUniverseID] = useState({
    id: "",
    name: "",
  });

  const [filterMultiverseID, setFilterMultiverseID] = useState({
    id: "",
    name: "",
  });

  const [filterSubMultiverseID, setFilterSubMultiverseID] = useState({
    id: "",
    name: "",
  });

  const [allFeeds, setAllFeeds] = useState({
    current_page: "",
    data: [],
    first_page_url: "",
    form: "",
    last_page: "",
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  });

  // const [createPostMsg, setCreatePostMsg] = useState('');
  const [taggedFriends, setTaggedFriends] = useState([]);

  //Start: for ImageVideoPreview component
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);

  function handleFileReset() {
    setFile("");
    setFileType("");
    setFileName("");
    setAddPhotoActive(false);
    setHasMediaError(false);
    setPostType("text");

    // console.log(file, fileType, fileName);
  }

  function handleImageProken(e) {
    reactDom.findDOMNode(e.target).parentNode.classList.remove("image_broken");
  }

  const [selectedMultiverse, setSelectedMultiverse] = useState({
    multiverseID: "",
    multiverseName: "",
  });

  const [selectedMultiverseCountry, setSelectedMultiverseCountry] = useState({
    submultiverseID: "",
    submultiverse: "",
  });

  const [selectUniverseError, setSelectUniverseError] = useState(false);
  const [subsUniverseModal, setSubsUniverseModal] = useState(false);

  const [showSubUni, setShowSubUni] = useState(false);
  const [subUniverses, setSubUniverses] = useState([]);
  const [userID, setUserID] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const [postAudience, setPostAudience] = useState("Public");

  const [addPhotoActive, setAddPhotoActive] = useState(false);
  const [selectMultiverseModal, setSelectMultiverseModal] = useState(false);
  const [tagFriendsModal, setTagFriendsModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [univerId, setUniverId] = useState("");

  // const [unmatchedUniverse, setUnmatchedUniverse] = useState('')

  const handleClose = () => {
    setSelectUniverseError(false);
    setSubsUniverseModal(false);
  };
  // const handleShow = () => setShow(true);

  const userDetail = JSON.parse(localStorage.getItem("users"));
  const token = "Bearer" + localStorage.getItem("access_token");

  const universeRef = useRef();
  const subUniverseRef = useRef();

  const activeIndex = universeIdProfile;

  useEffect(() => {
    // console.log("it's index page")

    if (userDetail !== null) {
      showDetails();
      getMultiverCountries();
    }

    setTimeout(() => {
      if (userDetail !== null && universeNameProfile) {
        setSelectedUniverseName(universeNameProfile);
        setSelectedUniverse(universeIdProfile);

        getUniverses(universeIdProfile);

        setSelectedSubUniverseName("");
        setSelectedSubUni("");

        setSubUniverses(subUniversesList);

        filterUniverseID.id = universeIdProfile;
        filterUniverseID.name = universeNameProfile;
        setFilterUniverseID({ ...filterUniverseID });

        setPostCreated(false);

        getAllPosts();

        setShowSubUni(true);

        // console.log(universeIdProfile, showSubUni, universeNameProfile, subUniverses)

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        if (userDetail !== null) {
          getAllPosts();
          getUniverses();
        }
      }
    }, 500);
  }, []);

  const getUniverses = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("language", "en");

    var raw = JSON.stringify({
      parentID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/public.universes`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "sub uni result")
        if (result && result.data) {
          setUniverses(result.data.universes);
          setSubUniverses(result.data.subUnivers);
        }
        userState();
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  const showDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.me`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        if (!result.status) {
          setUserID(result.data.id);
          setfirstName(result.data.firstName);
          setLastName(result.data.lastName);
          setProfileImage(result.data.photo);
        } else {
          setUserID("");
          setfirstName("");
          setLastName("");
          setProfileImage(dummyUserImage);
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  // function checkMatchedUni(item) {
  //     var ids = item.universe_id.ID

  //     if(!unmatchedUniverse.includes(ids)){
  //         console.log(ids, "idsids")
  //         if(item.isUniverseMatch === false && userDetail.id !== item.user_id) {
  //             unmatchedUniverse.push(item.universe_id);

  //             console.log(unmatchedUniverse, "unmatchedUniverseunmatchedUniver")
  //         }
  //     } else {
  //         console.log("matched")
  //     }
  // }

  const getAllPosts = (targetPage) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    myHeaders.append("language", "en");

    var raw = JSON.stringify({
      limit: "10",
      page: targetPage,
      universes: filterUniverseID.id,
      sub_universe: filterSubUniverseID.id,
      multiverse: filterMultiverseID.id,
      sub_multivers: filterSubMultiverseID.id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/post.get_all?page=${targetPage}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "all post api");

        if (result && result.data && result.data.length > 0) {
          setAllFeeds((preVal) => ({
            ...preVal,
            current_page: result.current_page,
            data: result.data,
            first_page_url: result.first_page_url,
            form: result.from,
            last_page: result.last_page,
            last_page_url: result.last_page_url,
            links: result.links,
            next_page_url: result.next_page_url,
            path: result.path,
            per_page: result.per_page,
            prev_page_url: result.prev_page_url,
            to: result.to,
            total: result.total,
          }));
        } else {
          setAllFeeds((preVal) => ({
            ...preVal,
            current_page: result.current_page,
            data: [],
            first_page_url: result.first_page_url,
            form: result.from,
            last_page: result.last_page,
            last_page_url: result.last_page_url,
            links: result.links,
            next_page_url: result.next_page_url,
            path: result.path,
            per_page: result.per_page,
            prev_page_url: result.prev_page_url,
            to: result.to,
            total: result.total,
          }));
        }

        if (result) {
          setLoading(false);
        }

        // var unmatchedUni = result.not_follow_universes.map(value => value.universeName).join(", ");

        // console.log(unmatchedUni, "unmatchedUni")

        // setUnmatchedUniverse(unmatchedUni);

        // setTimeout(() =>{
        //     allFeeds.data.forEach(checkMatchedUni);
        // }, 2000)
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  const LoadNextPost = (currentLimit) => {
    getAllPosts(currentLimit + 1);

    setCurrentPage(currentLimit + 1);

    window.scrollTo({
      top: 350,
      behavior: "smooth",
    });
  };

  const LoadPrevPost = (currentLimit) => {
    getAllPosts(currentLimit - 1);

    setCurrentPage(currentLimit + 1);

    window.scrollTo({
      top: 350,
      behavior: "smooth",
    });
  };

  const getFriendsList = (searchData) => {
    setLoading(true);

    var myHeaders = new Headers();

    myHeaders.append("Authorization", token);

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      search: searchData,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.friendships_web`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "friends data list")
        if (result) {
          if (result.data && result.data.length > 0) {
            setFriendList(result.data);
            setFriendListDisplay(result.data);
          }

          var taggedFriendsIds = [];

          function gettaggedFriendsIds(item) {
            taggedFriendsIds.push(item.id);
          }

          if (taggedFriends && taggedFriends.length > 0) {
            taggedFriends.forEach(gettaggedFriendsIds);
          }

          function getUniverIds(item) {
            var ids = item.id;

            if (
              taggedFriendsIds &&
              taggedFriendsIds.length > 0 &&
              taggedFriendsIds.includes(ids)
            ) {
              document
                .getElementById(`friend_id_${ids}`)
                .classList.add("d-none");
            } else {
              document
                .getElementById(`friend_id_${ids}`)
                .classList.remove("d-none");
            }
          }

          if (result.data && result.data.length > 0) {
            result.data.forEach(getUniverIds);
          }

          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getMultiverCountries = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/public.multiverses_tree`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.data && result.data.multiverse) {
          // console.log("multiverse country", result);
          setMultiverseCountry(result.data.multiverse);
        }
      })
      .catch((error) => console.log("error", error))
      .finally((error) => setLoading(false));
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group create_post_btn">
        <div className="icon" onClick={() => previous()}>
          <IoIosArrowBack size={18} />
        </div>
        <div className="icon" onClick={() => next()}>
          <IoIosArrowForward size={18} />
        </div>
      </div>
    );
  };

  const userState = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.state`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "user state data");
        function getUniverIds(item) {
          var ids = item.ID;

          if (result.data.universes.includes(ids)) {
            document
              .getElementById(`universe_card${ids}`)
              .classList.remove("isSubscribed");
          } else {
            document
              .getElementById(`universe_card${ids}`)
              .classList.add("isSubscribed");
          }
        }

        if (result && result.data) {
          result.data.universes.forEach(getUniverIds);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const openCreatePostModal = () => {
    // getFriendsList();
    // setTaggedFriends([]);
    setPostAudience("Public");

    // selectedMultiverseCountry.submultiverseID='';
    // selectedMultiverseCountry.submultiverse='';
    // setSelectedMultiverseCountry({...selectedMultiverseCountry});

    // selectedMultiverse.multiverseID='';
    // selectedMultiverse.multiverseName='';

    // setSelectedMultiverse({...selectedMultiverse});

    // filterMultiverseID.id = ''
    // filterMultiverseID.name = ''
    // setFilterMultiverseID({...filterMultiverseID});

    // filterSubMultiverseID.id = ''
    // filterSubMultiverseID.name = ''
    // setFilterSubMultiverseID({...filterSubMultiverseID});

    if (selectedUniverseName !== "" && selectedUniverse !== "") {
      if (firstName !== "") {
        setCreatePostModal(true);
      } else {
        history.push("/auth/sign-up", {
          message: "Please Sign in first to create post",
        });
      }
    } else {
      setSelectUniverseError(true);
    }
  };

  const handleSelectPostMultiverse = (e, ID, name) => {
    if (e.currentTarget.classList.contains("collapsed")) {
      selectedMultiverseCountry.submultiverseID = "";
      selectedMultiverseCountry.submultiverse = "";
      setSelectedMultiverseCountry({ ...selectedMultiverseCountry });
    }

    selectedMultiverse.multiverseID = ID;
    selectedMultiverse.multiverseName = name;
    setSelectedMultiverse({ ...selectedMultiverse });

    filterMultiverseID.id = ID;
    filterMultiverseID.name = name;
    setFilterMultiverseID({ ...filterMultiverseID });

    setTimeout(() => {
      getAllPosts();
    }, 1000);

    // console.log("selectedMultiverse", selectedMultiverse);
  };

  const handleSelectPostSubMultiverse = (ID, name) => {
    // console.log("selected post sub multiverse", ID, name);

    selectedMultiverseCountry.submultiverseID = ID;
    selectedMultiverseCountry.submultiverse = name;
    setSelectedMultiverseCountry({ ...selectedMultiverseCountry });

    filterSubMultiverseID.id = ID;
    filterSubMultiverseID.name = name;
    setFilterSubMultiverseID({ ...filterSubMultiverseID });

    // console.log("selectedMultiverseCountry", selectedMultiverseCountry);

    setTimeout(() => {
      getAllPosts();
    }, 1000);
  };

  const handleTagAddition = (id, username, name, photo, index) => {
    // const newFriendList = friendListDisplay.filter((_, i) => i !== index);

    // setFriendList(newFriendList);

    document.getElementById(`friend_id_${id}`).classList.add("d-none");

    let arrtaggedFriends = [...taggedFriends];

    arrtaggedFriends.push({
      id: id,
      username: username,
      name: name,
      photo: photo,
    });

    setTaggedFriends(arrtaggedFriends);

    // setFriendListDisplay(newFriendList);

    // console.log(newFriendList, "newFriendListDisplay");
    // console.log(taggedFriends, "taggedFriends");
  };

  const handleTagDelete = (id, username, name, photo, index) => {
    const newFriendTagList = taggedFriends.filter((_, i) => i !== index);

    setTaggedFriends(newFriendTagList);

    // friendListDisplay.push({
    //     id: id,
    //     username: username,
    //     name: name,
    //     photo: photo
    // });

    document.getElementById(`friend_id_${id}`).classList.remove("d-none");

    // console.log(newFriendTagList, "newFriendTagList");
    // console.log(taggedFriends, "taggedFriends");
    // console.log(friendListDisplay, "friendListDisplay")
  };

  const handleCreatePostMsg = (e) => {
    if (fileType !== "") {
      setPostType(fileType);
    } else {
      setPostType("text");
      setPostBodyMsg(e.target.value);
    }

    // console.log(fileType, "file type", postType, "post type");
  };

  // const handleSelectedSubmultiver = () => {
  //     console.log(selectedMultiverseCountry, "final selectedMultiverseCountry");
  // };

  const handleCreatePost = (e) => {
    e.preventDefault();
    setLoading(true);
    var bodyFormData = new FormData();

    const { postBody } = e.target.elements;

    var uploadFileData;

    if (uploadFile) {
      // console.log(uploadFile, "uploadFile")
      uploadFileData = uploadFile;
    } else {
      uploadFileData = "0";
    }

    // bodyFormData.append("media_url", uploadFile.files[0]);

    var taggedFriendsIds = taggedFriends.map((value) => value.id).join(", ");

    var selectedSubUniverseValue = [];
    if (selectedSubUni) {
      selectedSubUniverseValue.push(selectedSubUni);
    } else {
      selectedSubUniverseValue = [];
    }

    var selectedSubMultiverseValue = [];
    if (selectedMultiverseCountry.submultiverseID) {
      selectedSubMultiverseValue.push(
        selectedMultiverseCountry.submultiverseID
      );

      // console.log(selectedMultiverseCountry.submultiverseID)
    } else {
      selectedSubMultiverseValue = [];
    }

    // console.log(selectedSubMultiverseValue, "selectedSubMultiverseValue")

    bodyFormData = {
      user_id: userID || "",
      wall_id: "0",
      activity_id: "0",
      shared_id: "0",
      post_body: postBody.value || "",
      post_type: fileType ? fileType : postType,
      feed_type: "feed",
      privacy: postAudience,
      media_url: uploadFileData,
      status: "published",
      tagged_users: taggedFriendsIds || 0,
      hash_tags: "0",
      universe_id: selectedUniverse || 0,
      sub_universe_id: selectedSubUni || 0,
      multiverse_id: selectedMultiverse.multiverseID || 0,
      sub_multiverse_id: selectedMultiverseCountry.submultiverseID || 0,
      emotions: 0,
      likes: 0,
      haha: 0,
      wow: 0,
      Sad: 0,
      angry: 0,
    };

    axios
      .post(`${servicePath}/post.create`, bodyFormData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })
      .then((res) => {
        // console.log("create post API response", res.data);
        if (res && res.data && res.data.post.id > 0) {
          setCreatePostModal(false);

          setMessage("Post created successfully");
          setShowMessage(true);

          setPostCreated(true);

          getAllPosts();
          setLoading(false);
          setFile("");
          setCreatePostModal(false);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);

          setTimeout(() => {
            setPostCreated(false);
          }, 5000);

          setUploadFile("");

          setTaggedFriends([]);
        }
      })
      .catch((err) => {
        // console.log(err, "false");
        setCreatePostModal(false);
        setLoading(false);
        setMessage("There is some error, Please try after sometime");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
  };

  const handleCheckUniverse = (item, e) => {
    // console.log(item.ID, "handleCheckUniverse")

    // autoSlideSubUniverse(0, subUniverses.length);

    filterSubUniverseID.id = "";
    filterSubUniverseID.name = "";
    setFilterSubUniverseID({ ...filterSubUniverseID });

    filterMultiverseID.id = "";
    filterMultiverseID.name = "";
    setFilterMultiverseID({ ...filterMultiverseID });

    filterSubMultiverseID.id = "";
    filterSubMultiverseID.name = "";
    setFilterSubMultiverseID({ ...filterSubMultiverseID });

    if (e.currentTarget.classList.contains("isSubscribed")) {
      // console.log("already sub univ")
      setSelectedUniverseName(item.universeName);
      setSelectedSubUniverseName("");
      setSelectedSubUni("");
      setSelectedUniverse(item.ID);
      getUniverses(item.ID);
      setShowSubUni(true);

      filterUniverseID.id = item.ID;
      filterUniverseID.name = item.universeName;
      setFilterUniverseID({ ...filterUniverseID });

      setTimeout(() => {
        getAllPosts();
      }, 1000);
    } else {
      // console.log(filterUniverseID.id, "not sub univ")
      setSubsUniverseModal(true);

      setUniverId(item.ID);

      // setSelectedUniverse("");

      // setSelectedUniverseName(item.universeName);

      // setSelectedSubUniverseName("");
      // setSelectedSubUni("");

      // filterUniverseID.id = ''
      // filterUniverseID.name = item.universeName
      // setFilterUniverseID({...filterUniverseID});

      // setTimeout(() => {
      //     getAllPosts()

      //     setLoading(false)
      // }, 1000);
    }
  };

  const handleCheckSubUniverse = (item) => {
    setSelectedSubUni(item.ID);
    setSelectedSubUniverseName(item.subUniverse);

    filterSubUniverseID.id = item.ID;
    filterSubUniverseID.name = item.subUniverse;
    setFilterSubUniverseID({ ...filterSubUniverseID });

    setTimeout(() => {
      // console.log(filterSubUniverseID.id, "filterSubUniverseID")
      getAllPosts();
    }, 1000);
  };

  const subscribeUniverse = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      universes: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.subscribe_to_universes_single`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "sub uni");

        if (result) {
          setSubsUniverseModal(false);

          setSelectedUniverse(univerId);

          userState();
          getUniverses(univerId);
          setShowSubUni(true);
          queryClient.invalidateQueries([USER_STATES]);
          queryClient.invalidateQueries([INVALIDATE_UNIVERSE]);

          const filtered = result.data.universes.filter((universe) => {
            return universe.ID === univerId;
          });

          filterUniverseID.id = univerId;
          filterUniverseID.name = filtered[0].universeName;
          setFilterUniverseID({ ...filterUniverseID });

          setSelectedUniverseName(filtered[0].universeName);

          setTimeout(() => {
            getAllPosts();
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const resetPostFilter = () => {
    setSelectedUniverseName("");
    setSelectedSubUniverseName("");
    setSelectedSubUni("");
    setSelectedUniverse("");
    setShowSubUni(false);

    selectedMultiverse.multiverseID = "";
    selectedMultiverse.multiverseName = "";
    setSelectedMultiverse({ ...selectedMultiverse });

    selectedMultiverseCountry.submultiverseID = "";
    selectedMultiverseCountry.submultiverse = "";
    setSelectedMultiverseCountry({ ...selectedMultiverseCountry });

    filterUniverseID.id = "";
    filterUniverseID.name = "";
    setFilterUniverseID({ ...filterUniverseID });

    filterSubUniverseID.id = "";
    filterSubUniverseID.name = "";
    setFilterSubUniverseID({ ...filterSubUniverseID });

    filterMultiverseID.id = "";
    filterMultiverseID.name = "";
    setFilterMultiverseID({ ...filterMultiverseID });

    filterSubMultiverseID.id = "";
    filterSubMultiverseID.name = "";
    setFilterSubMultiverseID({ ...filterSubMultiverseID });

    setTimeout(() => {
      getAllPosts();
      userState();
    }, 200);
  };

  const autoSlideUniverse = (active, total) => {
    var currentActive = universeRef.current;
    var carDiff = total - active;

    if (currentActive.state && currentActive.state.slidesToShow) {
      var slideValue = active - currentActive.state.slidesToShow + carDiff;

      if (carDiff < currentActive.state.slidesToShow) {
        universeRef.current.goToSlide(slideValue);
      } else {
        universeRef.current.goToSlide(active);
      }
    }
  };

  const autoSlideSubUniverse = (active, total) => {
    var currentActive = subUniverseRef.current;
    var carDiff = total - active;

    if (currentActive.state && currentActive.state.slidesToShow) {
      var slideValue = active - currentActive.state.slidesToShow + carDiff;

      // console.log(total, active, slideValue,currentActive.state.slidesToShow, carDiff, "carDiff");

      if (carDiff < currentActive.state.slidesToShow) {
        subUniverseRef.current.goToSlide(slideValue);
      } else {
        subUniverseRef.current.goToSlide(active);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (userDetail !== null && selectedUniverse) {
        var getActiveIndex =
          document.getElementsByClassName("current_universe");

        var slideIndexActive = 0;
        if (getActiveIndex && getActiveIndex.length != 0) {
          slideIndexActive = getActiveIndex[0].attributes.data_id.nodeValue;
        } else {
          slideIndexActive = 0;
        }

        if (universeRef && universeRef.current) {
          autoSlideUniverse(slideIndexActive, 12);
        }
      }
    }, 1500);
  }, [activeIndex, selectedUniverse]);

  useEffect(() => {
    setTimeout(() => {
      if (userDetail !== null && selectedSubUni) {
        var getActiveIndex = document.getElementsByClassName(
          "current_subuniverse"
        );

        var slideIndexActive = 0;
        if (getActiveIndex && getActiveIndex.length != 0) {
          slideIndexActive = getActiveIndex[0].attributes.data_id.nodeValue;
        } else {
          slideIndexActive = 0;
        }

        if (universeRef && universeRef.current) {
          autoSlideSubUniverse(slideIndexActive, subUniverses.length);
        } else {
          autoSlideSubUniverse(0, subUniverses.length);
        }
      }
    }, 1500);
  }, [selectedSubUni]);

  const [searchString, setSearchString] = useState("");
  // state for keeping the timeout
  const [searchTextTimeout, setSearchTextTimeout] = useState(null);

  const handleFriendListSearch = (searchValue) => {
    if (searchValue !== "") {
      getFriendsList(searchValue);
      setSearchString(searchValue);
    } else {
      getFriendsList(searchValue);
      setSearchString("");
    }
  };

  const handleFriendSearch = (e) => {
    if (searchTextTimeout) {
      clearTimeout(searchTextTimeout);
    }

    setSearchTextTimeout(
      setTimeout(() => {
        // console.log(e.target.value)
        handleFriendListSearch(e.target.value);
      }, 500)
    );
  };

  useEffect(() => {
    return () => clearTimeout(searchTextTimeout);
  }, [searchTextTimeout]);

  return (
    <div>
      <StickyStoryViews />
      <StickySuggestedFriends />
      <Container
        className="position-relative mx-auto"
        style={{ maxWidth: "800px" }}
      >
        {loading && (
          <div className="main_loader">
            <img src={loader} alt="loader" style={{ height: "100px" }} />
          </div>
        )}
        {showMessage && message ? (
          <div className="alert-message">{message}</div>
        ) : (
          <></>
        )}

        <Row className="justify-content-center">
          <Col md={12} lg={11} xl={10}>
            {universes && universes.length > 0 ? (
              <div className="card card-block card-stretch position-relative d-flex justify-content-between create_post">
                <h4 className="px-1 mb-1">Universe</h4>
                <Carousel
                  ref={universeRef}
                  arrows={false}
                  customButtonGroup={<ButtonGroup />}
                  infinite={false}
                  responsive={responsiveUniversesFeeds}
                  autoPlay={false}
                  swipeable={true}
                  draggable={false}
                  containerClass="carousel-container mb-0"
                  renderButtonGroupOutside={true}
                  itemClass="clickable"
                  sliderClass="react-multi-carousel-track"
                >
                  {universes.map((item, index) => {
                    return (
                      <div
                        key={item.ID}
                        data_id={index}
                        className={`text-center universe_sub_check image_broken uni_image_wrap ${
                          selectedUniverse === item.ID ? "current_universe" : ""
                        }`}
                        id={`universe_card${item.ID}`}
                        onClick={(e) => {
                          handleCheckUniverse(item, e);
                        }}
                      >
                        <img
                          src={item.universeIcon}
                          alt="sub universe"
                          className={
                            selectedUniverse === item.ID
                              ? "rounded-circle story-img-active"
                              : "rounded-circle story-img"
                          }
                          onError={(e) => {
                            handleImageProken(e);
                          }}
                        />

                        <span
                          className={
                            selectedSubUni === item.ID
                              ? "rounded-circle story-img-active"
                              : "rounded-circle story-img"
                          }
                        >
                          <span>{item.universeName.substring(0, 1)}</span>
                        </span>

                        <div className="universes-text">
                          {item.universeName}
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            ) : (
              ""
            )}

            <SubscribeUniverseModal
              handleClose={handleClose}
              subsUniverseModal={subsUniverseModal}
              setSubsUniverseModal={setSubsUniverseModal}
              subscribeUniverse={subscribeUniverse}
              univerId={univerId}
            />

            <Modal
              size="md"
              className="fade"
              id="post-modal"
              dialogClassName="modal-dialog-centered"
              onHide={handleClose}
              show={selectUniverseError}
            >
              <div className="text-center p-3">
                <div className="d-flex justify-content-between">
                  <span></span>
                  <Link
                    to="#"
                    className="lh-1"
                    onClick={() => {
                      setSelectUniverseError(false);
                    }}
                  >
                    <i className="ri-close-fill ri-xl"></i>
                  </Link>
                </div>
                <Modal.Body>
                  <h5>Please select universe to create post</h5>
                </Modal.Body>
              </div>
            </Modal>

            {showSubUni && subUniverses && subUniverses.length > 0 && (
              <div className="card card-block card-stretch position-relative d-flex justify-content-between create_post">
                <h4 className="px-1 mb-1">Sub Universe</h4>
                <Carousel
                  ref={subUniverseRef}
                  arrows={false}
                  customButtonGroup={<ButtonGroup />}
                  responsive={responsiveUniversesFeeds}
                  autoPlay={false}
                  containerClass="carousel-container mb-0"
                  itemClass="clickable"
                  renderButtonGroupOutside={true}
                >
                  {subUniverses.map((item, index) => {
                    return (
                      <div
                        key={item.ID}
                        data_id={index}
                        className={`text-center image_broken uni_image_wrap ${
                          selectedSubUni === item.ID
                            ? "current_subuniverse"
                            : ""
                        }`}
                        onClick={() => {
                          handleCheckSubUniverse(item);
                        }}
                      >
                        <img
                          src={item.universeIcon}
                          alt="sub universe"
                          className={
                            selectedSubUni === item.ID
                              ? "rounded-circle story-img-active"
                              : "rounded-circle story-img"
                          }
                          onError={(e) => {
                            handleImageProken(e);
                          }}
                        />

                        <span
                          className={
                            selectedSubUni === item.ID
                              ? "rounded-circle story-img-active"
                              : "rounded-circle story-img"
                          }
                        >
                          <span>{item.subUniverse.substring(0, 1)}</span>
                        </span>

                        <div className="universes-text">{item.subUniverse}</div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}

            <Row style={{ maxWidth: "550px" }} className="mx-auto">
              <Col sm={12}>
                <Card
                  id="post-modal-data"
                  className="card-block card-stretch card-height"
                >
                  <div className="card-header d-flex justify-content-between py-2 px-2">
                    <div className="header-title">
                      <h4 className="card-title">Create Post</h4>
                    </div>
                  </div>
                  <Card.Body className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="user-img avatar-50 rounded-circle">
                        <Image
                          src={profileImage || dummyUserImage}
                          alt="user"
                          onError={(e) => {
                            e.target.src = dummyUserImage;
                          }}
                        />
                      </div>
                      <form className="post-text ms-2 w-100">
                        <div
                          onClick={() => {
                            openCreatePostModal();
                            handleFileReset();
                            setAddPhotoActive(false);
                            handleFileReset();
                          }}
                        >
                          {selectedUniverseName !== "" &&
                          selectedUniverse !== "" ? (
                            <h6>
                              Share your <strong>{selectedUniverseName}</strong>{" "}
                              {selectedSubUniverseName ? (
                                <span>
                                  & <strong>{selectedSubUniverseName}</strong>{" "}
                                  posts
                                </span>
                              ) : (
                                "posts"
                              )}
                            </h6>
                          ) : (
                            <h6>
                              Select a Universe to make a post via topic or
                              country
                            </h6>
                          )}
                        </div>
                      </form>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex align-items-center">
                      <Link
                        to="#"
                        className="d-flex me-2 me-md-3 post-icon"
                        onClick={() => {
                          openCreatePostModal();
                          setAddPhotoActive(true);
                        }}
                      >
                        <i className="ri-gallery-fill me-1"></i>
                        Photo/Video
                      </Link>
                      <Link
                        to="#"
                        className="d-flex me-2 me-md-3 post-icon"
                        onClick={() => {
                          openCreatePostModal();
                          setTagFriendsModal(!tagFriendsModal);
                          getFriendsList();
                        }}
                      >
                        <i className="ri-group-fill me-1"></i>
                        Tag
                      </Link>
                      <Link
                        to="#"
                        className="d-flex me-2 me-md-3 post-icon"
                        onClick={() => {
                          openCreatePostModal();
                          setSelectMultiverseModal(!selectMultiverseModal);
                        }}
                      >
                        <i className="ri-settings-2-line me-1"></i>
                        Multiverses
                      </Link>
                      {/* <Link to="#" className="d-flex post-icon">
                                    <i className="ri-emotion-happy-line me-1"></i>
                                    Feeling
                                </Link> */}
                    </div>
                  </Card.Body>

                  <Modal
                    size={`${
                      selectMultiverseModal || tagFriendsModal ? "md" : "md"
                    }`}
                    className="fade"
                    id="post-modal"
                    show={createPostModal}
                    dialogClassName="modal-dialog-centered"
                  >
                    <div
                      className={`create_post_step ${
                        selectMultiverseModal || tagFriendsModal
                          ? "d-none"
                          : "active"
                      }`}
                    >
                      <Modal.Header className="d-flex justify-content-between py-2">
                        <Modal.Title id="post-modalLabel" className="lh-1">
                          Create Post
                        </Modal.Title>
                        <Link
                          to="#"
                          className="lh-1 close_btn bg-light"
                          onClick={() => {
                            setCreatePostModal(false);
                            setPostBodyMsg("");
                          }}
                        >
                          <i className="ri-close-fill ri-xl"></i>
                        </Link>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="">
                          <div className="d-flex w-100">
                            <div className="user-img avatar-50 rounded-circle me-2">
                              <Image
                                src={profileImage || dummyUserImage}
                                alt="user"
                                onError={(e) => {
                                  e.target.src = dummyUserImage;
                                }}
                              />
                            </div>
                            <div className="">
                              <div
                                className={`${
                                  taggedFriends.length > 0
                                    ? "h5 mb-1 lh-1"
                                    : "h5 mb-1 lh-1"
                                }`}
                              >
                                <strong>
                                  {firstName && lastName === ""
                                    ? "N/A"
                                    : firstName + " " + lastName + " "}
                                </strong>
                                {taggedFriends.length > 0 ? (
                                  <>
                                    is with{" "}
                                    <strong>{taggedFriends[0].name}</strong>{" "}
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                {taggedFriends.length > 1 ? (
                                  <>
                                    and{" "}
                                    <strong>
                                      {" "}
                                      {taggedFriends.length - 1 > 1
                                        ? `${taggedFriends.length - 1} others`
                                        : "1 other"}
                                    </strong>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="d-flex align-items-center flex-wrap badge_btns">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    as={CustomToggle}
                                    role="button"
                                  >
                                    <span className="btn btn-light me-2">
                                      {postAudience === "Public" ? (
                                        <i className="ri-global-line h4"></i>
                                      ) : (
                                        <>
                                          {postAudience === "Multiverse" ? (
                                            <i className="ri-settings-2-line h4"></i>
                                          ) : (
                                            <>
                                              {postAudience === "Universe" ? (
                                                <i className="ri-star-line h4"></i>
                                              ) : (
                                                <>
                                                  {postAudience ===
                                                  "Friends" ? (
                                                    <i className="ri-user-follow-line h4"></i>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                      {postAudience}
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="m-0 p-0">
                                    <div className="px-3 pt-3 pb-0">
                                      <h5>Choose audience</h5>
                                    </div>
                                    <Dropdown.Item className="p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <i className="ri-global-line h4"></i>
                                        <div
                                          className="data ms-2"
                                          onClick={() =>
                                            setPostAudience("Public")
                                          }
                                        >
                                          <h6>Public</h6>
                                          <p className="mb-0">
                                            Anyone on or off Universimm
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item className="p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-settings-2-line h4"></i>
                                                                        <div className="data ms-2" onClick={() => setPostAudience("Multiverse")}>
                                                                            <h6>Multiverse</h6>
                                                                            <p className="mb-0">
                                                                                Anyone on selected Multiverse
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item> */}
                                    <Dropdown.Item className=" p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <i className="ri-star-line h4"></i>
                                        <div
                                          className="data ms-2"
                                          onClick={() =>
                                            setPostAudience("Universe")
                                          }
                                        >
                                          <h6>Universe</h6>
                                          <p className="mb-0">
                                            Anyone on selected Universe
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className=" p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <i className="ri-user-follow-line h4"></i>
                                        <div
                                          className="data ms-2"
                                          onClick={() =>
                                            setPostAudience("Friends")
                                          }
                                        >
                                          <h6>Friends</h6>
                                          <p className="mb-0">
                                            Anyone who is your friend
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>

                                {selectedMultiverse &&
                                  selectedMultiverse.multiverseName && (
                                    <span className="btn btn-light custom_badge me-2">
                                      <i className="ri-settings-2-line h4"></i>{" "}
                                      {selectedMultiverse.multiverseName}
                                    </span>
                                  )}

                                {selectedMultiverseCountry &&
                                  selectedMultiverseCountry.submultiverse && (
                                    <span className="btn btn-light custom_badge">
                                      <i className="ri-flag-line h4"></i>{" "}
                                      {selectedMultiverseCountry.submultiverse}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                          <form
                            className="post-text w-100"
                            onSubmit={handleCreatePost}
                            action="/api-endpoitn"
                            methot="POST"
                          >
                            <textarea
                              type="text"
                              rows="2"
                              name="postBody"
                              id="postBody"
                              className="form-control rounded write-something"
                              placeholder={`Share your ${selectedUniverseName} ${
                                selectedSubUniverseName
                                  ? `& ${selectedSubUniverseName} posts`
                                  : "posts"
                              } `}
                              style={{ border: "none" }}
                              onChange={(e) => handleCreatePostMsg(e)}
                            />

                            <LinkPreview data={postBodyMsg} />

                            {addPhotoActive && (
                              <ImageVideoPreviewCreate
                                setUploadFile={setUploadFile}
                                setAddPhotoActive={setAddPhotoActive}
                                addPhotoActive={addPhotoActive}
                                handleFileReset={handleFileReset}
                                setPostType={setPostType}
                                file={file}
                                setFile={setFile}
                                fileType={fileType}
                                setFileType={setFileType}
                                setFileName={setFileName}
                                setHasMediaError={setHasMediaError}
                              />
                            )}

                            <div className="d-flex align-items-center border p-2 my-2 br-8">
                              <Link
                                to="#"
                                className="d-flex me-2 me-md-3 post-icon"
                                onClick={() => {
                                  handleFileReset();
                                  setAddPhotoActive(true);
                                }}
                              >
                                <i className="ri-gallery-fill me-1"></i>
                                Photo/Video
                              </Link>
                              <Link
                                to="#"
                                className="d-flex me-2 me-md-3 post-icon"
                                onClick={() => {
                                  setTagFriendsModal(!tagFriendsModal);
                                  getFriendsList();
                                }}
                              >
                                <i className="ri-group-fill me-1"></i>
                                Tag
                              </Link>
                              <Link
                                to="#"
                                className="d-flex me-2 me-md-3 post-icon"
                                onClick={() =>
                                  setSelectMultiverseModal(
                                    !selectMultiverseModal
                                  )
                                }
                              >
                                <i className="ri-settings-2-line me-1"></i>
                                Multiverses
                              </Link>
                              {/* <Link to="#" className="d-flex post-icon">
                                                        <i className="ri-emotion-happy-line me-1"></i>
                                                        Feeling
                                                    </Link> */}
                            </div>

                            <button
                              type="submit"
                              className="btn btn-primary w-100 d-flex justify-content-center mx-auto"
                              disabled={
                                (postType === "text" && postBodyMsg === "") ||
                                hasMediaError
                              }
                            >
                              {loading
                                ? `Posting...${
                                    postType === "video" ? `(${progress}%)` : ""
                                  }`
                                : "Post"}
                            </button>
                          </form>
                        </div>
                      </Modal.Body>
                    </div>

                    <div
                      className={`create_post_step ${
                        tagFriendsModal ? "active" : "d-none"
                      }`}
                    >
                      <Modal.Header className="d-flex justify-content-between align-items-center">
                        <Link
                          to="#"
                          className="lh-1"
                          onClick={() => setTagFriendsModal(!tagFriendsModal)}
                        >
                          <i className="ri-arrow-left-line ri-xl lh-1"></i>
                        </Link>
                        <Modal.Title className="h5" id="post-modalLabel">
                          Tag friends
                        </Modal.Title>
                        <Link
                          to="#"
                          className="lh-1"
                          onClick={() => {
                            setTagFriendsModal(!tagFriendsModal);
                          }}
                        >
                          Done
                        </Link>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="tag_frined_wrap">
                          {taggedFriends && taggedFriends.length > 0 && (
                            <div
                              className={`tagged_friend_list ${
                                taggedFriends.length > 0 ? "mb-3" : ""
                              }`}
                            >
                              {taggedFriends.map((item, index) => {
                                return (
                                  <div
                                    key={item.id}
                                    className="friend_item d-flex align-items-center"
                                    onClick={() => {
                                      handleTagDelete(
                                        item.id,
                                        item.username,
                                        item.name,
                                        item.photo,
                                        index
                                      );
                                    }}
                                  >
                                    <h6 className="lh-1">{item.name}</h6>
                                    <Link to="#" className="lh-1">
                                      <i className="ri-close-fill ri-xl"></i>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          <input
                            type="text"
                            className="form-control rounded mb-3"
                            placeholder="Search for friends"
                            onKeyUp={(e) => {
                              handleFriendSearch(e);
                            }}
                          />

                          {friendListDisplay && friendListDisplay.length > 0 ? (
                            <>
                              <div className="friend_list">
                                {friendListDisplay.map((item, index) => {
                                  return (
                                    <div
                                      key={item.id}
                                      id={`friend_id_${item.id}`}
                                    >
                                      <div
                                        className="friend_item d-flex align-items-center"
                                        onClick={() => {
                                          handleTagAddition(
                                            item.id,
                                            item.username,
                                            item.name,
                                            item.photo,
                                            index
                                          );
                                        }}
                                      >
                                        <Image
                                          src={item.photo || dummyUserImage}
                                          alt={item.name}
                                          onError={(e) => {
                                            e.target.src = dummyUserImage;
                                          }}
                                        />
                                        <div className="ms-2">
                                          <h6 className="lh-1 fw-500 mb-0">
                                            {item.name}
                                          </h6>
                                          {/* <p className="text-muted mb-0 lh-1">{item.username}</p> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <div className="d-flex justify-content-center py-4">
                              <h5 className="text-muted">
                                No more friends found in the list
                              </h5>
                            </div>
                          )}
                        </div>
                      </Modal.Body>
                    </div>

                    <div
                      className={`create_post_step ${
                        selectMultiverseModal ? "active" : "d-none"
                      }`}
                    >
                      <Modal.Header className="d-flex justify-content-between align-items-center">
                        <Link
                          to="#"
                          className="lh-1"
                          onClick={() =>
                            setSelectMultiverseModal(!selectMultiverseModal)
                          }
                        >
                          <i className="ri-arrow-left-line ri-xl lh-1"></i>
                        </Link>
                        <Modal.Title className="h5" id="post-modalLabel">
                          Select multiverse & country
                        </Modal.Title>
                        <Link
                          to="#"
                          className="lh-1"
                          onClick={() => {
                            setSelectMultiverseModal(!selectMultiverseModal);
                          }}
                        >
                          Done
                        </Link>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col className="">
                            <div className="multiverse_list">
                              <Accordion
                                id="multiverse_list"
                                defaultActiveKey="0"
                              >
                                {multiverseCountry.length > 0 &&
                                  multiverseCountry.map((item) => {
                                    return (
                                      <Accordion.Item
                                        key={item.ID}
                                        eventKey={item.ID}
                                      >
                                        <Accordion.Header
                                          id={`multiverse${item.ID}`}
                                          onClick={(e) =>
                                            handleSelectPostMultiverse(
                                              e,
                                              item.ID,
                                              item.multiverseName
                                            )
                                          }
                                        >
                                          {item.multiverseName}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="country_list">
                                            {item.submultiverse &&
                                              item.submultiverse.map(
                                                (subItem) => {
                                                  return (
                                                    <Form.Check
                                                      key={subItem.ID}
                                                      className="form-check radio check_badge"
                                                    >
                                                      <Form.Check.Input
                                                        type="radio"
                                                        name="multiverseCountry"
                                                        id={`country${subItem.ID}`}
                                                      />
                                                      <Form.Check.Label
                                                        htmlFor={`country${subItem.ID}`}
                                                        onClick={() =>
                                                          handleSelectPostSubMultiverse(
                                                            subItem.ID,
                                                            subItem.submultiverse
                                                          )
                                                        }
                                                      >
                                                        {subItem.submultiverse}
                                                      </Form.Check.Label>
                                                    </Form.Check>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    );
                                  })}
                              </Accordion>
                            </div>
                          </Col>
                        </Row>
                      </Modal.Body>
                    </div>
                  </Modal>
                </Card>
              </Col>

              {postCreated && (
                <Col sm={12}>
                  <Card className="card_shadow">
                    <Card.Body className="p-2">
                      <h5 className="text-center mb-0">
                        You posted to <strong>{filterUniverseID.name}</strong>
                        {filterSubUniverseID.name ? (
                          <span>
                            {" "}
                            - <strong>{filterSubUniverseID.name}</strong>
                          </span>
                        ) : (
                          ""
                        )}
                        {filterMultiverseID.name ? (
                          <span>
                            {" "}
                            - <strong>{filterMultiverseID.name}</strong>
                          </span>
                        ) : (
                          ""
                        )}
                        {filterSubMultiverseID.name ? (
                          <span>
                            {" "}
                            - <strong>{filterSubMultiverseID.name}</strong>
                          </span>
                        ) : (
                          ""
                        )}
                      </h5>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {!postCreated && filterUniverseID && filterUniverseID.id && (
                <Col sm={12}>
                  <Card className="card_shadow">
                    <Card.Body className="text-center p-2">
                      <h5 className="mb-0">
                        Showing posts for{" "}
                        <strong>{filterUniverseID.name}</strong>
                        {filterSubUniverseID.name ? (
                          <span>
                            {" "}
                            - <strong>{filterSubUniverseID.name}</strong>
                          </span>
                        ) : (
                          ""
                        )}
                        {filterMultiverseID.name ? (
                          <span>
                            {" "}
                            - <strong>{filterMultiverseID.name}</strong>
                          </span>
                        ) : (
                          ""
                        )}
                        {filterSubMultiverseID.name ? (
                          <span>
                            {" "}
                            - <strong>{filterSubMultiverseID.name}</strong>
                          </span>
                        ) : (
                          ""
                        )}
                      </h5>
                      <Link
                        to="#"
                        className="fw-bold btn-link text-nowrap"
                        onClick={() => {
                          resetPostFilter();
                        }}
                      >
                        Reset filter
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {/* <Col sm={12}>
                            {unmatchedUniverse && (
                                <Card className="text-center">
                                    <Card.Body>
                                        <h4>Hidden posts from friends</h4>
                                        <p className='text-muted mb-0'>Subscribe to <strong>{unmatchedUniverse}</strong> universe to see these hidden posts.</p>

                                        <Link to="/dashboard/app/edit-universe" className="btn-link fw-bold">
                                            Update universe
                                        </Link>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col> */}

              <Col sm={12}>
                <Card className="card-block card-stretch card-height post_cards">
                  <div className="d-flex justify-content-between align-items-center py-2 border-bottom card-body lh-1">
                    <h5 className="fw-bold">Posts</h5>

                    <div className="d-flex align-items-center">
                      <p className="mb-0 me-2">
                        Showing:{" "}
                        <NumericLabel
                          params={{ justification: "L", shortFormat: true }}
                        >
                          {allFeeds.to || "0"}
                        </NumericLabel>
                        {" of "}
                        <NumericLabel
                          params={{ justification: "L", shortFormat: true }}
                        >
                          {allFeeds.total || "0"}
                        </NumericLabel>
                      </p>
                      <PostFilterModal
                        universes={universes}
                        subUniverses={subUniverses}
                        getUniverses={getUniverses}
                        multiverseCountry={multiverseCountry}
                        setLoading={setLoading}
                        filterUniverseID={filterUniverseID}
                        setFilterUniverseID={setFilterUniverseID}
                        filterSubUniverseID={filterSubUniverseID}
                        setFilterSubUniverseID={setFilterSubUniverseID}
                        filterMultiverseID={filterMultiverseID}
                        setFilterMultiverseID={setFilterMultiverseID}
                        filterSubMultiverseID={filterSubMultiverseID}
                        setFilterSubMultiverseID={setFilterSubMultiverseID}
                        getAllPosts={getAllPosts}
                        setShowSubUniIndex={setShowSubUni}
                        setSelectedUniverseIndex={setSelectedUniverse}
                        setSelectedUniverseNameIndex={setSelectedUniverseName}
                        setSelectedSubUniIndex={setSelectedSubUni}
                        setSelectedSubUniverseNameIndex={
                          setSelectedSubUniverseName
                        }
                        selectedUniverseIndex={selectedUniverse}
                        selectedSubUniIndex={selectedSubUni}
                        token={token}
                        setSubsUniverseModal={setSubsUniverseModal}
                        setUniverId={setUniverId}
                      />
                    </div>
                  </div>
                  {allFeeds && allFeeds.total > 0 ? (
                    <Card.Body>
                      {allFeeds.data.map((item, index) => {
                        return (
                          <div className="w-100" key={index}>
                            {item.isUniverseMatch ||
                            userDetail.id === item.user_id ? (
                              <ProfilePostItem
                                setLoading={setLoading}
                                token={token}
                                getAllPosts={getAllPosts}
                                currentPage={currentPage}
                                setMessage={setMessage}
                                setShowMessage={setShowMessage}
                                item={item}
                                componentOf="index"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </Card.Body>
                  ) : (
                    <Card.Body className="text-center">
                      <h4>No feeds found!</h4>
                    </Card.Body>
                  )}
                </Card>
              </Col>

              <Col sm={12}>
                {allFeeds.total !== allFeeds.to ? (
                  <></>
                ) : (
                  <>
                    <Card className="text-center">
                      <Card.Body>
                        <h4>No more posts</h4>
                        <p className="text-muted mb-0">
                          Add more friends to see more posts in your Feed.
                        </p>

                        <Link
                          to="/dashboard/app/friend-request"
                          className="btn-link fw-bold"
                        >
                          {/* <i className="ri-group-fill me-1"></i> */}
                          Add more friend
                        </Link>
                      </Card.Body>
                    </Card>
                  </>
                )}

                {allFeeds.total > 10 && (
                  <div className="d-flex justify-content-between mb-4">
                    {allFeeds.current_page > 1 ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => LoadPrevPost(allFeeds.current_page)}
                      >
                        <BsArrowLeft size={16} className="me-1" /> Prev post
                      </button>
                    ) : (
                      <div></div>
                    )}
                    {allFeeds.total !== allFeeds.to && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => LoadNextPost(allFeeds.current_page)}
                      >
                        Next post <BsArrowRight size={16} className="ms-1" />
                      </button>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
