import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { servicePath } from "../../views/dashboard/app/variables";
export const INVALIDATE_UNIVERSE = "publicUniverseList";

const useUniverse = () =>
  useQuery([INVALIDATE_UNIVERSE], async () => {
    const { data } = await axios({
      url: `${servicePath}/public.universes`,
    });

    return data?.data?.universes ?? [];
  });

export default useUniverse;
