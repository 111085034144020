import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  DirAction,
  ModeAction,
  getDarkMode,
  getRtlMode,
} from "../../../../store/mode/mode";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    rtlMode: getRtlMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      DirAction,
    },
    dispatch
  ),
});

const DarkMode = (props) => {
  const darkModeLocal = localStorage.getItem("dark_mode");

  if (darkModeLocal === "true") {
    props.ModeAction(true);
  } else {
    props.ModeAction(false);
  }

  var dark = props.darkMode;
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("dark");
  if (mode !== null) {
    document.body.classList = "";
    dark = false;
    switch (mode) {
      case "true":
        document.body.classList = "dark";
        dark = true;
        break;
      case "false":
        document.body.classList = "";
        dark = false;
        break;
      default:
        document.body.classList = "";
        dark = false;
        break;
    }
  }
  return (
    <div className="iq-float-menu">
      <input
        type="checkbox"
        className="iq-float-menu-open"
        name="menu-open"
        id="menu-open"
      />
      <Button
        bsPrefix="iq-float-menu-item bg-danger"
        onClick={() => {
          props.ModeAction(!props.darkMode);
        }}
      >
        <i
          className={`${props.darkMode ? "ri-moon-clear-line" : "ri-sun-line"}`}
          checked={props.darkMode || dark}
          data-active={
            props.darkMode ? "true" : "false" || dark ? "true" : "false"
          }
        ></i>
      </Button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DarkMode);
