import React from "react";
import { Route, Switch } from "react-router-dom";
import Index from "../views/dashboard/index";

// Universimm pages
import Blocking from "../views/dashboard/app/blocking";
import Chat from "../views/dashboard/app/chat";
import EditUniverse from "../views/dashboard/app/edit-universe";
import FriendProfile from "../views/dashboard/app/friend-profile";
import FriendRequest from "../views/dashboard/app/friend-request";
import Notification from "../views/dashboard/app/notification";
import notifSetting from "../views/dashboard/app/notification-setting";
import PostDetail from "../views/dashboard/app/post-detail";
import SavedPosts from "../views/dashboard/app/saved-posts";
import SentRequests from "../views/dashboard/app/sent-request";
import UserAccountSetting from "../views/dashboard/app/user-account-setting";
import UserPrivacySetting from "../views/dashboard/app/user-privacy-setting";
import userProfile from "../views/dashboard/app/user-profile";
import UserProfileEdit from "../views/dashboard/app/user-profile-edit";

//TransitionGroup
import { CSSTransition, TransitionGroup } from "react-transition-group";

// Email
import Email from "../views/dashboard/email/email";
import EmailCompose from "../views/dashboard/email/email-compose";

//extrapages universimm
// import PrivacyPolicy from '../views/dashboard/extrapages/privacy-policy';
// import TermsConditions from '../views/dashboard/extrapages/terms-conditions';

import CreateStories from "../views/Stories/CreateStories/CreateStories";
import StoryViews from "../views/Stories/storyViews/StoryViews";
import Error404 from "../views/dashboard/errors/error404";

const DefaultRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadeUp" timeout={300}>
        <Switch>
          <Route exact path="/" component={Index} />
          {/* app */}

          <Route exact path="/dashboard/app/profile" component={userProfile} />
          <Route
            exact
            path="/dashboard/app/user-privacy-setting"
            component={UserPrivacySetting}
          />
          <Route
            exact
            path="/dashboard/app/notification-setting"
            component={notifSetting}
          />
          <Route
            exact
            path="/dashboard/app/friend-profile/:id"
            component={FriendProfile}
          />
          <Route exact path="/dashboard/app/chat" component={Chat} />
          <Route
            exact
            path="/dashboard/app/notification"
            component={Notification}
          />
          <Route exact path="/dashboard/app/file" component={File} />
          <Route
            exact
            path="/dashboard/app/friend-request"
            component={FriendRequest}
          />
          <Route
            exact
            path="/dashboard/app/sent-request"
            component={SentRequests}
          />
          <Route
            exact
            path="/dashboard/app/user-account-setting"
            component={UserAccountSetting}
          />
          <Route
            exact
            path="/dashboard/app/user-profile-edit"
            component={UserProfileEdit}
          />
          <Route
            exact
            path="/dashboard/app/saved-posts"
            component={SavedPosts}
          />
          <Route exact path="/dashboard/stories" component={StoryViews} />
          <Route
            exact
            path="/dashboard/stories/create"
            component={CreateStories}
          />
          <Route exact path="/dashboard/app/blocking" component={Blocking} />
          <Route
            exact
            path="/dashboard/app/edit-universe"
            component={EditUniverse}
          />
          <Route
            exact
            path="/dashboard/app/post-detail/:postId"
            component={PostDetail}
          />

          {/* Email */}
          <Route exact path="/dashboard/Email/email" component={Email} />
          <Route
            exact
            path="/dashboard/Email/email-compose"
            component={EmailCompose}
          />

          {/* extrapages */}
          {/* <Route exact path="/user/privacy-policy" component={PrivacyPolicy}/>
                    <Route exact path="/user/terms-conditions" component={TermsConditions}/> */}

          <Route path="*" component={Error404} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
