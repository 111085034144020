import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import loader from "../../../assets/images/page-img/page-load-loader.gif"
import { servicePath } from './variables'
import DeleteDeactivateAccount from '../components/DeleteDeactivateAccount'

const UserAccountSetting = () => {

    const userValidation = () => {
        let error = {}
        let isValid = true;
        let emailReg = "^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"

        if (!email || email === "" || email.match(emailReg)) {
            error.email = "Enter valid email"
            isValid = false
        }
        setErrors(error)
        return isValid
    }

    const userData = JSON.parse(localStorage.getItem('users'))
    const token = "Bearer" + " " + localStorage.getItem("access_token")

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [emailError, setEmailError] = useState(false)
    const [userName, setUsername] = useState("");
    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [instagram, setInstagram] = useState("");
    const [gplus, setGplus] = useState("");
    const [youtube, setYoutube] = useState("");
    const [message, setMessage] = useState("")
    const [showMessage, setShowMessage] = useState(true)
    const [englishLang, setEnglishLang] = useState(false)
    const [frenchLang, setFrenchLang] = useState(false)
    const [hindiLang, setHindiLang] = useState(false)
    const [spanishLang, setSpanishLang] = useState(false)
    const [arabicLang, setArabicLang] = useState(false)
    const [italianLang, setItalianLang] = useState(false)
    const [userLanguages, setUserLanguages] = useState({
        languages: [],
        response: [],
    });

    const handleLangChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = userLanguages;

        // console.log(`${value} is ${checked}`);

        // Case 1 : The user checks the box
        if (checked) {
            setUserLanguages({
                languages: [...languages, value],
                response: [...languages, value],
            });

            // console.log(userLanguages, "userLanguages")
        }

        // Case 2  : The user unchecks the box
        else {
            setUserLanguages({
                languages: languages.filter((e) => e !== value),
                response: languages.filter((e) => e !== value),
            });
        }
    };

    useEffect(() => {
        if (userData !== null) {
            userState()
        }
    }, [])

    const userState = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Accept", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            `${servicePath}/user.state?`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(result.data.social, "result.data.social");
                setUsername(result.data.account.username);
                setUserId(result.data.account.id);
                setEmail(result.data.account.email);
                setFacebook(result.data.social.facebook || '')
                setTwitter(result.data.social.twitter || '')
                setInstagram(result.data.social.instagram || '')
                setGplus(result.data.social.gplus || '')
                setYoutube(result.data.social.youtube || '')

                const langStr = result.data.account.language;

                if (langStr != null) {
                    setUserLanguages({
                        languages: langStr,
                        response: langStr,
                    });
                }

                if (langStr != null && langStr.includes("English")) {
                    setEnglishLang(true);
                } else {
                    setEnglishLang(false);
                }

                if (langStr != null && langStr.includes("French")) {
                    setFrenchLang(true);
                } else {
                    setFrenchLang(false);
                }

                if (langStr != null && langStr.includes("Hindi")) {
                    setHindiLang(true);
                } else {
                    setHindiLang(false);
                }

                if (langStr != null && langStr.includes("Spanish")) {
                    setSpanishLang(true);
                } else {
                    setSpanishLang(false);
                }

                if (langStr != null && langStr.includes("Arabic")) {
                    setArabicLang(true);
                } else {
                    setArabicLang(false);
                }

                if (langStr != null && langStr.includes("Italian")) {
                    setItalianLang(true);
                } else {
                    setItalianLang(false);
                }

                if (result.data.social.length > 0) {
                    // console.log('has Social Details')

                    setEmail(result.data.social.email);
                } else {
                    // console.log('no Social details')
                }

                // console.log(userLanguages, "userLanguages")
            })
            .catch((error) => console.log("error", error))
            .finally((error) => setLoading(false))
    };

    const userUpdate = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        // console.log(userLanguages, "languages update")

        var userLanguagesString
        if (userLanguages && userLanguages.languages) {
            userLanguagesString = userLanguages.languages.map(value => value).join(",");
            // console.log(userLanguagesString, "userLanguagesString");
        }

        var raw = JSON.stringify({
            data: {
                id: userData.id,
                username: userName,
                email: email,
                language: userLanguagesString,
            },
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${servicePath}/user.update_user`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {

                if (result.success == "Settings updated successfully") {
                    setMessage("Settings updated successfully")
                    setShowMessage(true)
                    setTimeout(() => {
                        setShowMessage(false)
                    }, 3000);
                    userState()
                }
            })
            .catch((error) => console.log("error", error))
            .finally((error) => setLoading(false))
    };

    const userSocialUpdate = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            data: {
                facebook: facebook,
                twitter: twitter,
                linkedin: '',
                youtube: youtube,
                instagram: instagram,
                gplus: gplus,
                skype: '',
                pinterest: '',
                tumblr: '',
                snapchat: '',
            },
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${servicePath}/user.update_social_settings`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.success == "Social Settings updated successfully") {
                    setMessage("Settings updated successfully")
                    setShowMessage(true)
                    setTimeout(() => {
                        setShowMessage(false)
                    }, 3000);
                }
            })
            .catch((error) => console.log("error", error))
            .finally((error) => setLoading(false))
    };

    const handleUsernameChange = () => {
        console.log('updated username')
    }

    const handleEmail = (e) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (regEmail.test(e.target.value)) {
            console.log("email", "Valid");
            setEmailError(false)
        } else {
            console.log("email", "invalid");

            setEmailError(true)
        }
    }

    return (
        <>
            <Container className="position-relative">
                {loading && (<div className="main_loader">
                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                </div>)}

                {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
                <Row>
                    <Col md="6" lg="6">
                        <Card>
                            <Card.Header className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Account Setting</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="acc-edit">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="userName" className="form-label">Username:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="userName" name='userName' onChange={(e) => { setUsername(e.target.value); handleUsernameChange(e.target.value) }} value={userName || ''} />
                                        </Form.Group>

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="email" className="form-label">Email:</Form.Label>
                                            <Form.Control type="email" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} value={email || ''} onBlur={handleEmail} disabled="disabled" />
                                            {/* <div className="text-danger">{errors.email}</div> */}

                                            {(errors.email || emailError) && (
                                                <div className="text-danger">Please enter valid email</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="form-group">
                                            <Form.Label className="d-block form-label">Language Known:</Form.Label>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input type="checkbox" className="form-check-input" name="languages" onChange={(e) => { handleLangChange(e); setEnglishLang(!englishLang) }} id="english" value="English" checked={englishLang} />
                                                <Form.Check.Label className="form-check-label" htmlFor="english">English</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input type="checkbox" className="form-check-input" name="languages" onChange={(e) => { handleLangChange(e); setFrenchLang(!frenchLang) }} id="french" value="French" checked={frenchLang} />
                                                <Form.Check.Label className="form-check-label" htmlFor="french">French</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input type="checkbox" className="form-check-input" name="languages" onChange={(e) => { handleLangChange(e); setHindiLang(!hindiLang) }} id="hindi" value="Hindi" checked={hindiLang} />
                                                <Form.Check.Label className="form-check-label" htmlFor="hindi">Hindi</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input type="checkbox" className="form-check-input" name="languages" onChange={(e) => { handleLangChange(e); setSpanishLang(!spanishLang) }} id="spanish" value="Spanish" checked={spanishLang} />
                                                <Form.Check.Label className="form-check-label" htmlFor="spanish">Spanish</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input type="checkbox" className="form-check-input" name="languages" onChange={(e) => { handleLangChange(e); setArabicLang(!arabicLang) }} id="arabic" value="Arabic" checked={arabicLang} />
                                                <Form.Check.Label className="form-check-label" htmlFor="arabic">Arabic</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input type="checkbox" className="form-check-input" name="languages" onChange={(e) => { handleLangChange(e); setItalianLang(!italianLang) }} id="italian" value="Italian" checked={italianLang} />
                                                <Form.Check.Label className="form-check-label" htmlFor="italian">Italian</Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>

                                        <Button type="button" className="btn btn-primary me-2" onClick={() => { userValidation() ? userUpdate() : <></>; }}>Submit</Button>
                                        {/* <Button type="reset" className="btn bg-soft-danger">Cancel</Button> */}
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>

                        <DeleteDeactivateAccount
                            userId={userId}
                            token={token}
                            setMessage={setMessage}
                            setShowMessage={setShowMessage}
                        />
                    </Col>
                    <Col md="6" lg="6">
                        <Card>
                            <Card.Header className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Social Media</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="acc-edit">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="facebook" className="form-label">Facebook:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="facebook" onChange={(e) => setFacebook(e.target.value)} value={facebook || ''} />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="twitter" className="form-label">Twitter:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="twitter" onChange={(e) => setTwitter(e.target.value)} value={twitter || ''} />
                                        </Form.Group>
                                        {/* <Form.Group className="form-group">
                                            <Form.Label htmlFor="google" className="form-label">Google +:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="google" onChange={(e) => setGplus(e.target.value)} value={gplus || ''} />
                                        </Form.Group> */}
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="instagram" className="form-label">Instagram:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="instagram" onChange={(e) => setInstagram(e.target.value)} value={instagram || ''} />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="youtube" className="form-label">Youtube:</Form.Label>
                                            <Form.Control type="text" className="form-control" id="youtube" onChange={(e) => setYoutube(e.target.value)} value={youtube || ''} />
                                        </Form.Group>
                                        <Button type="button" className="btn btn-primary me-2" onClick={() => userSocialUpdate()}>Submit</Button>
                                        {/* <Button type="reset" className="btn bg-soft-danger">Cancel</Button> */}
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserAccountSetting;