import React, { useCallback, useEffect, useState } from "react";
import { Card, Dropdown, Form, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import Button from "@restart/ui/Button";
import { onChildAdded, onChildChanged, ref } from "firebase/database";
import { BiBlock } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import logoWeb from "../../../../assets/images/logo_web.png";
import noprofile from "../../../../assets/images/no-profile-picture.jpg";
import loader from "../../../../assets/images/page-img/loader_sq.gif";
import { useProfile } from "../../../../context/ProfileContext";
import database from "../../../../firebase";
import { servicePath } from "../../../../views/dashboard/app/variables";

// import ChatMessageCount from './messagecount'
const Header = () => {
  // alert(localStorage.getItem("data"))
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [getNotificationList, setNotificationList] = useState([]);
  const [notificationUnreadCount, setNotificationUnreadCount] = useState(0);
  // const [messageUnreadCount, setMessageUnreadCount] = useState(0)

  const [chatCount, setChatCount] = useState({
    count: 0,
  });

  const { openStoryBar, storyBar } = useProfile();

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showSearch, setShowSearch] = useState("");
  const [searchCount, setSearchCount] = useState("");
  const [searchString, setSearchString] = useState("");
  const [loadSearchPeople, setLoadSearchPeople] = useState(false);
  const [peopleList, setPeopleList] = useState({
    current_page: "",
    data: [],
    first_page_url: "",
    form: "",
    last_page: "",
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: "",
    total: "",
  });

  const history = useHistory();

  const minisidebar = () => {
    document.body.classList.toggle("sidebar-main");
  };

  const userDetail = JSON.parse(localStorage.getItem("users"));
  const token = "Bearer" + " " + localStorage.getItem("access_token");

  useEffect(() => {
    // console.log("app access without login")
    showDetails();

    if (history.location.pathname) {
      if (userDetail !== null) {
        showDetails();
        getUnReadMessageCount();
        getFriendRequests();
        getNotificationUnreadList();
        getNotificationUnreadCount();
      }
    }
  }, [history.location.pathname]);

  const userProfile = useCallback(() => {
    history.push("/dashboard/app/profile");
  }, [history]);

  const friendProfile = useCallback(
    (id) => {
      history.push(`/dashboard/app/friend-profile/${id}`);
    },
    [history]
  );

  const editProfile = useCallback(() => {
    history.push("/dashboard/app/user-profile-edit");
  }, [history]);

  const accountSettings = useCallback(() => {
    history.push("/dashboard/app/user-account-setting");
  }, [history]);

  const privacySettings = useCallback(() => {
    history.push("/dashboard/app/user-privacy-setting");
  }, [history]);

  const notifySettings = useCallback(() => {
    history.push("/dashboard/app/notification-setting");
  }, [history]);

  const savedPosts = useCallback(() => {
    history.push("/dashboard/app/saved-posts");
  }, [history]);

  const blockedUsers = useCallback(() => {
    history.push("/dashboard/app/blocking");
  }, [history]);

  const friendRequestsPage = useCallback(() => {
    history.push("/dashboard/app/friend-request");
  }, [history]);

  const allNotificationsPage = useCallback(() => {
    history.push("/dashboard/app/notification");
  }, [history]);

  const logout = () => {
    setLoading(true);

    history.push("/auth/sign-up");

    setTimeout(() => {
      localStorage.removeItem("users");
      localStorage.removeItem("access_token");

      document.body.classList.remove("dark");
      localStorage.setItem("dark_mode", false);
    }, 100);
  };

  const showDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/user.me`, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        if (result.status == false && result.info == "user_not_found") {
          console.log(result, "user me response");

          setLoading(true);

          history.push("/auth/sign-up");

          setTimeout(() => {
            localStorage.removeItem("users");
            localStorage.removeItem("access_token");

            document.body.classList.remove("dark");
            localStorage.setItem("dark_mode", false);
          }, 100);
        } else {
          if (result.info === "token_invalid") {
            console.log(result.info, "resultresult");

            setMessage("You are not logged in! Please login to continue!");
            setShowMessage(true);

            setTimeout(() => {
              history.push("/auth/sign-up");
              localStorage.removeItem("users");
            }, 2000);
          } else {
            if (result && result.data && result.data.id) {
              // console.log(result.data, "datauser");

              setfirstName(result.data.firstName);
              setLastName(result.data.lastName);
              setProfileImage(result.data.photo);

              const userLocal = {
                id: result.data.id,
                email: result.data.email,
                name: result.data.name,
                firstName: result.data.firstName,
                lastName: result.data.lastName,
                photo: result.data.photo,
                firebase_id: result.data.firebase_id,
              };

              localStorage.setItem("users", JSON.stringify(userLocal));

              // console.log(JSON.parse(localStorage.getItem("users")), "datauser2")
            }
          }
        }
      })
      .catch((error) => console.log("error user.me", error))
      .finally((error) => setLoading(false));
  };

  const getFriendRequests = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        mode: "received",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.requests`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "friend request lists")

        if (result && result.data) {
          setRequestList(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleConfirmRequest = (requestId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        request_id: requestId,
        status: "accepted",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "Request was accepted") {
          setMessage("Request confirmed");
          setShowMessage(true);

          getFriendRequests();

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleRejectRequest = (requestId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        request_id: requestId,
        status: "denied",
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "Request was denied") {
          setMessage("Request rejected");
          setShowMessage(true);

          getFriendRequests();

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const questionAlert = (requestId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-outline-primary btn-lg ms-2",
        confirmButton: "btn btn-primary btn-lg",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "cancel",
        confirmButtonText: "Yes, delete it!",

        reverseButtons: false,
        showClass: {
          popup: "animate__animated animate__zoomIn",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleRejectRequest(requestId);

          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your Request has been deleted.",
            icon: "success",
            showClass: {
              popup: "animate__animated animate__zoomIn",
            },
            hideClass: {
              popup: "animate__animated animate__zoomOut",
            },
          });
        }
      });
  };

  const getNotificationUnreadList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      limit: "4",
      page: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/notification.unread_web`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "notifications unread")

        if (result && result.data && result.data.length > 0) {
          setNotificationList(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getNotificationUnreadCount = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${servicePath}/notification.unread_count`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "notifications unread count")
        if (result && result.data) {
          setNotificationUnreadCount(result.data.unread_count);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const markNotificationRead = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: {
        id: id,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/notification.single_read`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "notifications")

        if (
          result.data &&
          result.data.message === "notification read successfully"
        ) {
          getNotificationUnreadList();
          getNotificationUnreadCount();

          getUnReadMessageCount();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const notificationDetails = (item) => {
    // console.log(item)

    if (item.post_details != "" && item.user_details === null) {
      history.push(`/dashboard/app/post-detail/${item.post_details.id}`);
    }

    if (item.post_details != null && item.user_details != null) {
      history.push(`/dashboard/app/post-detail/${item.post_details.id}`);
    }

    if (item.user_details != "" && item.post_details === null) {
      history.push(`/dashboard/app/friend-profile/${item.sender}`);
    }

    markNotificationRead(item.id);
  };

  const [searchText, setSearchText] = useState("");

  // state for keeping the timeout
  const [searchTextTimeout, setSearchTextTimeout] = useState(null);

  const handleUserSearch = (searchValue) => {
    setLoadSearchPeople(true);

    if (searchValue !== "") {
      getPeopleSearch(searchValue);
      setSearchString(searchValue);

      // console.log(searchValue, "search");
    } else {
      getPeopleSearch(searchValue);
      setSearchString("");
    }
  };

  const handleMainSearch = (e) => {
    if (searchTextTimeout) {
      clearTimeout(searchTextTimeout);
    }
    setSearchText(e.target.value);

    setSearchTextTimeout(
      setTimeout(() => {
        handleUserSearch(e.target.value);
      }, 500)
    );
  };

  useEffect(() => {
    return () => clearTimeout(searchTextTimeout);
  }, [searchTextTimeout]);

  const getPeopleSearch = (searchData) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      limit: "10",
      page: 1,
      search: searchData,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/minded.pepole`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          // console.log(result, "pepole search list")

          if (result && result.data && result.data.length > 0) {
            setPeopleList((preVal) => ({
              ...preVal,
              current_page: result.current_page,
              data: result.data,
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links: result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page: result.per_page,
              prev_page_url: result.prev_page_url,
              to: result.to,
              total: result.total,
            }));
          } else {
            setPeopleList((preVal) => ({
              ...preVal,
              current_page: result.current_page,
              data: [],
              first_page_url: result.first_page_url,
              form: result.from,
              last_page: result.last_page,
              last_page_url: result.last_page_url,
              links: result.links,
              next_page_url: result.next_page_url,
              path: result.path,
              per_page: result.per_page,
              prev_page_url: result.prev_page_url,
              to: result.to,
              total: result.total,
            }));
          }

          setSearchCount(result.total);
          setLoadSearchPeople(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleAddFriend = (friendId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      data: {
        friend_user_id: friendId,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.send`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.success === "Request sent") {
          setMessage("Request sent successfully");
          setShowMessage(true);

          getPeopleSearch(searchString);

          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleNotify = () => {
    getNotificationUnreadList();
    getNotificationUnreadCount();
  };

  const getUnReadMessageCount = () => {
    var newUserData = [];
    const userDetail = JSON.parse(localStorage.getItem("users"));
    // console.log(userDetail, "get data from firebase");
    const chatListRef = ref(database, "chatList/" + userDetail.firebase_id);
    var unreadCount = 0;
    // setMessageUnreadCount(0);
    onChildAdded(chatListRef, (data) => {
      if (data.key != null) {
        const dataVal = data.val();

        // this.getUserImage(data.key);

        newUserData.push({
          id: data.key,
          name: dataVal.name,
          message: dataVal.message,
          messageType: dataVal.messageType,
          profilePic: dataVal.profilePic,
          timeStamp: dataVal.timeStamp,
          unReadMessageCount: dataVal.unReadMessageCount,
          userId: dataVal.userId,
        });

        if (parseInt(dataVal.unReadMessageCount) > 0) {
          unreadCount = parseInt(unreadCount) + 1;

          // setMessageUnreadCount(unreadCount);

          // this.setState({
          //     unreadMessagesCount: unreadCount,
          // });
          console.log(unreadCount, "unreadCount");

          setChatCount({
            count: unreadCount,
          });

          setTimeout(() => {
            setChatCount({
              count: unreadCount,
            });
          }, 1000);
        }
      }
    });
    onChildChanged(chatListRef, (data) => {
      var updatedChatList = newUserData;
      // console.log("onChildChanged", "ChatMessageCount");
      var updatedUnreadCount = 0;
      if (data.key != null) {
        const dataVal = data.val();
        for (var i = 0; i < newUserData.length; i++) {
          if (data.key == newUserData[i].id) {
            // console.log(dataVal, "dataVal dataValuse");
            updatedChatList[i] = {
              id: data.key,
              name: dataVal.name,
              message: dataVal.message,
              messageType: dataVal.messageType,
              profilePic: dataVal.profilePic,
              timeStamp: dataVal.timeStamp,
              unReadMessageCount: dataVal.unReadMessageCount,
              userId: dataVal.userId,
            };
          }
          if (parseInt(newUserData[i].unReadMessageCount) > 0) {
            updatedUnreadCount++;

            console.log(updatedUnreadCount, "updatedUnreadCount");
          }
          // setMessageUnreadCount(unreadCount);
          setChatCount({
            count: updatedUnreadCount,
          });

          setTimeout(() => {
            setChatCount({
              count: updatedUnreadCount,
            });
          }, 1000);
        }
      }
    });
  };

  return (
    <>
      {showMessage && message ? (
        <div className="alert-message">{message}</div>
      ) : (
        <></>
      )}
      <div className="iq-top-navbar d-flex align-items-center">
        {showSearch && (
          <div className="search_dropdown">
            <Card>
              <Card.Header className="d-flex justify-content-between py-3">
                <Card.Title id="post-modalLabel" className="lh-1 mb-0">
                  Search
                </Card.Title>
                <Link
                  to="#"
                  className="lh-1"
                  onClick={() => {
                    setShowSearch(false);
                  }}
                >
                  <i className="ri-close-fill ri-xl"></i>
                </Link>
              </Card.Header>
              <Card.Body className="pb-0">
                {loadSearchPeople ? (
                  <div className="d-flex justify-content-center my-3 w-100">
                    <img src={loader} alt="loader" style={{ height: "40px" }} />
                  </div>
                ) : (
                  <ul className="request-list list-inline m-0 p-0">
                    {peopleList && peopleList.total > 0 ? (
                      <>
                        {peopleList.data.map((item) => {
                          return (
                            <li
                              key={item.id}
                              className="d-flex align-items-center justify-content-between flex-wrap pb-2 mb-2"
                            >
                              <Link
                                to={`/dashboard/app/friend-profile/${item.id}`}
                                className="w-100 d-flex align-items-center user_rqst_details people_list"
                                onClick={() => {
                                  setShowSearch(false);
                                }}
                              >
                                <div className="img-fluid">
                                  <Image
                                    className="avatar-50 rounded-circle"
                                    src={item.photo || noprofile}
                                    alt=""
                                    onError={(e) => {
                                      e.target.src = noprofile;
                                    }}
                                  />
                                </div>

                                <div className="ms-2 w-100 user_detail">
                                  <h6 className="mb-0 text-ellipsis-1 fw-500">
                                    {item.name}
                                  </h6>
                                  {/* <p className="mb-0 text-muted text-ellipsis-1">{item.username}</p> */}
                                </div>
                              </Link>

                              <div className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  className="btn btn-sm btn-primary rounded"
                                  onClick={() => {
                                    handleAddFriend(item.id);
                                  }}
                                >
                                  <i className="ri-user-add-line me-1"></i>
                                  Add Friend
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <h5 className="text-center mb-3">
                        {" "}
                        {searchCount === 0 && searchString !== ""
                          ? "No result found!"
                          : "No result found!"}
                      </h5>
                    )}
                  </ul>
                )}
              </Card.Body>
            </Card>
          </div>
        )}
        <div className="iq-navbar-custom w-100 mt-0">
          <Navbar expand="lg" variant="light" className="p-0">
            <div className="iq-navbar-logo d-flex justify-content-between align-items-center">
              <Link to="/" className="order-2 order-sm-1">
                {/* <div className="mobile_logo me-1 d-flex d-sm-none">
                                    <Image src={logoMobile} className="img-fluid" alt=""/>
                                </div> */}
                <div className="web_logo">
                  <Image src={logoWeb} className="img-fluid" alt="" />
                </div>
              </Link>
              {/* <div className="iq-menu-bt pb-0 d-flex pt-0 pt-sm-2 mx-1 order-1 order-sm-2">
                                <div className="wrapper-menu" onClick={minisidebar}>
                                    <div className="main-circle">
                                        <i className="ri-menu-line"></i>
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className="iq-search-bar device-search">
              <Form action="#" className="searchbox">
                <span className="search-link">
                  <i className="ri-search-line"></i>
                </span>
                <input
                  type="text"
                  className="text search-input"
                  placeholder="Search Universimm..."
                  onClick={() => {
                    getPeopleSearch("");
                    setShowSearch(true);
                  }}
                  onChange={(e) => handleMainSearch(e)}
                />
              </Form>
            </div>
            <Navbar.Toggle as="button">
              <i className="ri-menu-3-line "></i>
            </Navbar.Toggle>
            <Navbar.Collapse>
              <Nav as="ul" className="ms-auto navbar-list icons_head">
                {userDetail ? (
                  <>
                    {/* <Dropdown as="li" className="nav-item">
                                    <Dropdown.Toggle href="/" as={Button} className="d-flex align-items-center" variant="search-toggle">
                                        <i className="ri-home-line"></i>
                                    </Dropdown.Toggle>
                                </Dropdown> */}
                    <Dropdown as="li" className="nav-item">
                      <Dropdown.Toggle
                        className="d-flex align-items-center"
                        href="#"
                        as={Button}
                        variant="search-toggle"
                      >
                        <div className="count_dot_wrap position-relative">
                          <i className="ri-group-line"></i>
                          {requestList &&
                            requestList.counters &&
                            requestList.counters.pending > 0 && (
                              <span className="count_dot notification_count">
                                {requestList &&
                                  requestList.counters &&
                                  requestList.counters.pending}
                              </span>
                            )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="sub-drop mt-1 sub-drop-large"
                        aria-labelledby="group-drop"
                      >
                        <Card className="shadow-none m-0">
                          <Card.Header className="d-flex justify-content-between bg-primary">
                            <div className="header-title">
                              <h5 className="mb-0 text-white">
                                Friend Request
                              </h5>
                            </div>
                            <span className="badge bg-light d-flex align-items-center p-1">
                              <h6 className="lh-1">
                                {requestList &&
                                requestList.counters &&
                                requestList.counters.pending
                                  ? requestList.counters.pending
                                  : 0}
                              </h6>
                            </span>
                          </Card.Header>
                          <Card.Body className="p-0">
                            {requestList &&
                            requestList.requests &&
                            requestList.requests.length > 0 ? (
                              <>
                                {requestList.requests.map((item, index) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className={`${
                                        index < 5 ? "d-block" : "d-none"
                                      }`}
                                    >
                                      {index < 5 && (
                                        <div className="iq-friend-request">
                                          <div
                                            className={`iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between py-2 ${
                                              requestList.requests.length === 1
                                                ? "border-0"
                                                : ""
                                            }`}
                                          >
                                            <Dropdown.Item
                                              href="#"
                                              onClick={() =>
                                                friendProfile(item.params.id)
                                              }
                                              className="w-100 user_rqst_details"
                                            >
                                              <div className="d-flex align-items-center w-100">
                                                <Image
                                                  className="avatar-50 rounded"
                                                  src={
                                                    item.params.photo ||
                                                    noprofile
                                                  }
                                                  alt=""
                                                  onError={(e) => {
                                                    e.target.src = noprofile;
                                                  }}
                                                />
                                                <div className="ms-2 w-100">
                                                  <h6 className="mb-0 text-ellipsis-1 fw-500">
                                                    {item.params.name}
                                                  </h6>
                                                  {/* <p className="mb-0">40 friends</p> */}
                                                  {/* <p className="mb-0 text-muted text-ellipsis-1">{item.params.username}</p> */}
                                                </div>
                                              </div>
                                            </Dropdown.Item>
                                            <div className="d-flex align-items-center mt-0">
                                              <Link
                                                to="#"
                                                className="me-2 btn btn-primary rounded"
                                                onClick={() => {
                                                  handleConfirmRequest(item.id);
                                                }}
                                              >
                                                Confirm
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={(e) => {
                                                  questionAlert(item.id);
                                                }}
                                                className="btn btn-outline-danger btn-icon"
                                                data-extra-toggle="delete"
                                                data-closest-elem=".item"
                                              >
                                                <i className="ri-delete-bin-line"></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="d-flex flex-column align-items-center p-3">
                                <h5 className="text-center mb-2">
                                  No friend request
                                </h5>

                                <Dropdown.Item
                                  href="#"
                                  onClick={() => friendRequestsPage()}
                                  className="d-flex justify-content-center btn text-primary mb-1 px-2 drop_btn"
                                >
                                  Add new friend
                                </Dropdown.Item>
                              </div>
                            )}

                            {requestList &&
                              requestList.requests &&
                              requestList.requests.length > 0 && (
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => friendRequestsPage()}
                                  className="d-flex justify-content-center btn text-primary mb-1 px-2 drop_btn"
                                >
                                  {requestList.requests.length > 5
                                    ? "View More Request"
                                    : "Add new friend"}
                                </Dropdown.Item>
                              )}
                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="nav-item">
                      <Dropdown.Toggle
                        className="d-flex align-items-center"
                        href="#"
                        as={Button}
                        variant="search-toggle"
                      >
                        <div
                          className="count_dot_wrap position-relative"
                          onClick={(e) => handleNotify(e)}
                        >
                          <i className="ri-notification-4-line"></i>
                          {notificationUnreadCount > 0 && (
                            <span
                              id="notifyCount"
                              className="count_dot notification_count"
                            >
                              {notificationUnreadCount}
                            </span>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="sub-drop mt-1"
                        aria-labelledby="group-drop"
                      >
                        <Card className="shadow-none m-0">
                          <Card.Header className="d-flex justify-content-between bg-primary">
                            <div className="header-title">
                              <h5 className="mb-0 text-white">Notifications</h5>
                            </div>
                            <span className="badge bg-light d-flex align-items-center p-1">
                              <h6 className="lh-1">
                                {notificationUnreadCount}
                              </h6>
                            </span>
                          </Card.Header>

                          <Card.Body className="p-0">
                            {getNotificationList &&
                            getNotificationList.length > 0 ? (
                              <>
                                {getNotificationList.map((item) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className="iq-sub-card d-flex align-items-center justify-content-between clickable"
                                      onClick={() => notificationDetails(item)}
                                    >
                                      <Link to="#" className="">
                                        <img
                                          src={item.metaData.senderphoto}
                                          alt="user"
                                          className="avatar-50 rounded"
                                          onError={(e) => {
                                            e.target.src = noprofile;
                                          }}
                                        />
                                      </Link>
                                      <div className="ms-2 w-100">
                                        <h6 className="text-ellipsis-3">
                                          <strong>
                                            {item.metaData.SenderName}
                                          </strong>{" "}
                                          {item.notificationString}{" "}
                                          {item.post_details &&
                                          item.post_details.post_body &&
                                          item.post_details.post_body !== "" ? (
                                            <span className="fw-500 text-decoration-underline clickable hoverable">
                                              {item.post_details.post_body}
                                            </span>
                                          ) : (
                                            <>
                                              {item.notificationType !==
                                                "u_friends" && (
                                                <span className="clickable">
                                                  -{" "}
                                                  <span className="text-primary">
                                                    View post
                                                  </span>
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </h6>

                                        {/* <h6 className="mb-0"><span className='fw-500'>{item.metaData.SenderName}</span> {' '} {item.notificationString}</h6> */}
                                        <div className="d-flex justify-content-between align-items-center">
                                          {/* <p className="mb-0">95 MB</p> */}
                                          <small className="float-right font-size-12">
                                            {item.createdAt}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                                {notificationUnreadCount > 0 && (
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => allNotificationsPage()}
                                    className="d-flex justify-content-center btn text-primary mb-1 px-2 drop_btn"
                                  >
                                    View all notifications
                                  </Dropdown.Item>
                                )}
                              </>
                            ) : (
                              <div className="text-center">
                                <h6 className="text-muted mt-4 text-center">
                                  No new notification
                                </h6>

                                <Dropdown.Item
                                  href="#"
                                  onClick={() => allNotificationsPage()}
                                  className="d-flex justify-content-center btn text-primary mb-1 px-2 drop_btn"
                                >
                                  View all notifications
                                </Dropdown.Item>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    </Dropdown>
                    <li className="nav-item">
                      <Link
                        className="d-flex align-items-center"
                        to="/dashboard/app/chat"
                      >
                        <div className="count_dot_wrap position-relative">
                          <i className="ri-message-3-line"></i>
                          {chatCount.count > 0 && (
                            <span
                              id="msgUnreadCount"
                              className="count_dot notification_count"
                            >
                              {chatCount.count}
                            </span>
                          )}
                          {/* <ChatMessageCount /> */}
                        </div>
                      </Link>
                    </li>

                    {!storyBar && (
                      <li className="nav-item d-block d-xl-none">
                        <button className="btn mt-1" onClick={openStoryBar}>
                          <i className="fas fa-circle-notch text-warning"></i>
                        </button>
                      </li>
                    )}
                    {/* <Dropdown as="li" className="nav-item">
                                    <Dropdown.Toggle href="#" as={Button} variant="search-toggle">
                                        <i className="ri-mail-line"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="sub-drop mt-1 dropdown-menu" aria-labelledby="mail-drop">
                                        <Card className="shadow-none m-0">
                                            <Card.Header className="d-flex justify-content-between bg-primary">
                                                <div className="header-title bg-primary">
                                                    <h5 className="mb-0 text-white">All Message</h5>
                                                </div>
                                                <small className="badge bg-light text-dark">4</small>
                                            </Card.Header>
                                            <Card.Body className="p-0 ">
                                                <Link to="#" className="iq-sub-card d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Image className="avatar-40 rounded" src={noprofile} alt=""/>
                                                        </div>
                                                        <div className=" w-100 ms-3">
                                                            <h6 className="mb-0 ">Bni Emma Watson</h6>
                                                            <small className="float-left font-size-12">13 Jun</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="#" className="iq-sub-card d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center">
                                                            <Image className="avatar-40 rounded" src={user2} alt=""/>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                                                            <small className="float-left font-size-12">20 Apr</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="#" className="iq-sub-card d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Image className="avatar-40 rounded" src={user3} alt=""/>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h6 className="mb-0 ">Why do we use it?</h6>
                                                            <small className="float-left font-size-12">30 Jun</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="#" className="iq-sub-card d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Image className="avatar-40 rounded" src={user4} alt=""/>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h6 className="mb-0 ">Variations Passages</h6>
                                                            <small className="float-left font-size-12">12 Sep</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="#" className="iq-sub-card d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Image className="avatar-40 rounded" src={user5} alt=""/>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                                                            <small className="float-left font-size-12">5 Dec</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                    <Dropdown as="li" className="nav-item">
                      <Dropdown.Toggle
                        href="#"
                        as={Button}
                        bsPrefix="d-flex align-items-center search-toggle"
                      >
                        <Image
                          src={profileImage || noprofile}
                          className="img-fluid rounded-circle"
                          id="header_profile_img"
                          alt="user"
                          onError={(e) => {
                            e.target.src = noprofile;
                          }}
                        />
                        <div className="caption">
                          <h6
                            className="mb-0 line-height text-nowrap"
                            id="user_name_head"
                          >
                            {firstName && lastName === ""
                              ? "N/A"
                              : firstName + " " + lastName}
                          </h6>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="sub-drop mt-1 dropdown-menu caption-menu"
                        aria-labelledby="drop-down-arrow"
                      >
                        <Card className="shadow-none m-0">
                          <Card.Header className="bg-primary">
                            <div className="header-title">
                              <h5 className="mb-0 text-white">
                                Hello{" "}
                                {firstName && lastName === ""
                                  ? "N/A"
                                  : firstName + " " + lastName}
                              </h5>
                              {/* <span className="text-white font-size-12">Available</span> */}
                            </div>
                          </Card.Header>
                          <Card.Body className="p-0 ">
                            <Dropdown.Item
                              href="#"
                              onClick={() => userProfile()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-primary">
                                  <i className="ri-file-user-line"></i>
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">My profile</h6>
                                  <p className="mb-0 font-size-12">
                                    View personal profile details.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => editProfile()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-warning">
                                  <i className="ri-profile-line"></i>
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">Edit profile</h6>
                                  <p className="mb-0 font-size-12">
                                    Modify your personal details.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => accountSettings()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-info">
                                  <i className="ri-account-box-line"></i>
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">Account settings</h6>
                                  <p className="mb-0 font-size-12">
                                    Manage your account parameters.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => privacySettings()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-danger">
                                  <i className="ri-lock-line"></i>
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">Privacy settings</h6>
                                  <p className="mb-0 font-size-12">
                                    Control your privacy parameters.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => notifySettings()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-warning">
                                  <i className="ri-notification-4-line"></i>
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">
                                    Notification settings
                                  </h6>
                                  <p className="mb-0 font-size-12">
                                    Control your notification settings.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => savedPosts()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-success">
                                  <i className="ri-bookmark-line"></i>
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">Saved posts</h6>
                                  <p className="mb-0 font-size-12">
                                    Check your saved posts.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => blockedUsers()}
                              className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                            >
                              <div className="d-flex align-items-center">
                                <div className="rounded card-icon bg-soft-danger">
                                  <BiBlock className="mb-0 text-danger" />
                                </div>
                                <div className="ms-3">
                                  <h6 className="mb-0 ">Blocking</h6>
                                  <p className="mb-0 font-size-12">
                                    Check your blocked users.
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <div
                              className="d-flex w-100 justify-content-center py-2 px-3"
                              onClick={() => logout()}
                            >
                              <Link
                                to="#"
                                className="btn btn-primary iq-sign-btn h-auto lh-1 py-2"
                                role="button"
                              >
                                Sign out
                                <i className="ri-login-box-line ms-2"></i>
                              </Link>
                            </div>
                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <div className="d-inline-block w-100 text-center px-3">
                    <Link
                      to="/auth/sign-up"
                      className="btn btn-primary iq-sign-btn"
                      role="button"
                    >
                      Sign In
                      <i className="ri-login-box-line ms-2"></i>
                    </Link>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;
