import * as React from 'react'
import axios from "axios";
import {Link, useLocation} from 'react-router-dom'
import {Container, Row, Col, Form, Tab, Nav, Button, Dropdown} from 'react-bootstrap'
import Card from '../../../components/Card'
import InputEmoji from "react-input-emoji";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import SingleLightbox from '../components/SingleLightbox';
import database from '../../../firebase';
import noprofile from "../../../assets/images/no-profile-picture.jpg"
import { ref, onValue, onChildAdded, onChildChanged, onChildRemoved, set, update, push, child, remove, off, } from "firebase/database";
import imageCompression from 'browser-image-compression';
import { servicePath } from './variables';
class MChat extends React.Component{
    
    constructor(props) {
        super(props);
        this.messagesEndRef = React.createRef();
        this.inputEmojiRef = React.createRef();
        // console.log("hhd", this.props);
    }
    state = {
        loggedInUserFirebaseId:"",
        chatUserId:"",
        loggedInUserData:{},
        chatUserFirebaseId:"",
        chatUserFirebaseName:"",
        chatUserFirebasephoto:"",
        chatUserFcmToken:"",
        chatList :[],
        chatMessageList :[],
        toggleChatSidebar: false,
        startChatActive: false,
        showUserDetails: false,
        btnDisable: true,
        activeUser:{ About: true },
        commentText:"",
        file:"",
        fileType:"",
        fileName:"",
        userOnlineStatus:"Offline",
        token : "Bearer" +" "+ localStorage.getItem("access_token"),
        isUploadingFile:false,
        userMessageNodeReference: null,
        blockUserRef: null,
        isUserBlocked: false,
        amIBlocked: false,
    }

    ChatSidebarClose =() =>{
        this.setState({
            toggleChatSidebar: false,
        })
    }
    ChatSidebarOpen =() =>{
        this.setState({
            toggleChatSidebar: true,
        })
    }
    StartChat =() =>{
        this.setState({
            startChatActive: true,
        })
    }
    StartChatUser = (e, id, name, photo, index) =>{
        var updatedChatList = [...this.state.chatList];
        updatedChatList[index].unReadMessageCount = 0;
        this.setState({
            chatList:updatedChatList,
            chatMessageList:[],
            startChatActive: true,
            toggleChatSidebar: false,
            userName : name,
            userOnlineStatus : "Offline",
            chatUserFirebaseId: id,
            chatUserFirebaseName: name,
            chatUserFirebasephoto: photo,
        }, () =>{

            // console.log(this.state.chatUserFirebaseId, "chatUserFirebasephoto")
            // console.log('chatList/' + this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId+"/unReadMessageCount");
            // update read count to 0
            // firebase.database().ref().update({'users/USERNAME/status': "Active"})
            const chatlistNodeLoggedInUser = ref(database, 'chatList/' + this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId+"");
            const updates = {};
            updates['unReadMessageCount'] = 0;
            update(chatlistNodeLoggedInUser,updates)
            this.getUserOnlineStatus(this.state.chatUserFirebaseId);
            this.getChatMessages(this.state.chatUserFirebaseId);
            this.checkBlock();
        });
        
    }

    // toggleUserDetails =() =>{
    //     this.setState({
    //         showUserDetails: true,
    //         toggleChatSidebar: false,
            
    //     });
    // }

    handleFileReset =() =>{
        this.setState({
            file: '',
            fileType: '',
            fileName: '',
        });
        // console.log(this.state.file, this.state.fileType, this.state.fileName);

        if(this.state.commentText === '') {
            this.setState({
                btnDisable:true,
            });
        }
    }

    handleChangeMsg = (msg) => {
        this.setState({
            commentText:msg,
        })
        
        if(msg !== "" || this.state.file !== '') {
            this.setState({
                btnDisable:false,
            })
        } else {
            this.setState({
                btnDisable:true,
            })
        }
    };

    handleSendMsg = () => {
        if(this.state.commentText.trim() === ""){
            return;
        }
        // console.group(this.state.commentText, "chat msg")

        this.setState({
            btnDisable : true
        })

        const messsageNodeRef = ref(database, 'chatList/' + this.state.chatUserFirebaseId +"/"+this.state.loggedInUserFirebaseId);
        var unread = 0;
        onValue(messsageNodeRef, (dataMessage) => {
            if(dataMessage.key !=null){
                const dataVal = dataMessage.val()
                if(dataVal.unReadMessageCount !=null && parseInt(dataVal.unReadMessageCount) > 0){
                    unread = parseInt(dataVal.unReadMessageCount)+1;
                }
                else{
                    unread = 1; 
                }
            }
        });
        // insert in chat list for sender 
        set(ref(database, 'chatList/' + this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId), {
            "userId":""+this.state.loggedInUserData.id,
            "senderId":this.state.loggedInUserFirebaseId,
            "message":this.state.commentText.trim(),
            "messageType":"text",
            "name":this.state.chatUserFirebaseName,
            "profilePic":this.state.chatUserFirebasephoto,
            "timeStamp": ""+Date.now(),
            "unReadMessageCount": 0,
        });
        // insert in chat list for receiver 
        set(ref(database, 'chatList/' + this.state.chatUserFirebaseId+"/"+this.state.loggedInUserFirebaseId), {
            "userId":""+this.state.loggedInUserData.id,
            "senderId":this.state.loggedInUserFirebaseId,
            "message":this.state.commentText.trim(),
            "messageType":"text",
            "name":this.state.loggedInUserData.name,
            "profilePic": this.state.loggedInUserData.photo,
            "timeStamp": ""+Date.now(),
            "unReadMessageCount": unread,
        });
        

        const newMessageKey = push(child(ref(database), 'messages')).key;
        // console.log(newMessageKey, "newMessageKey");
        set(ref(database, 'messages/' + newMessageKey), {
            "senderId":this.state.loggedInUserFirebaseId,
            "messageBody":this.state.commentText.trim(),
            "type":"text",
            "senderName":this.state.loggedInUserData.name,
            "profilePic":this.state.loggedInUserData.photo,
            "timestamp": ""+Date.now(),
            "readStatus": "1",
        });

        // Sender Message 
        const updates = {};
        updates[newMessageKey] = 1;
        const senderUserMessagerewf = ref(database, 'userMessages/' + this.state.loggedInUserFirebaseId+'/'+this.state.chatUserFirebaseId);
        const receiverUserMessagerewf = ref(database, 'userMessages/' + this.state.chatUserFirebaseId+'/'+this.state.loggedInUserFirebaseId);
        // console.log('userMessages/' + this.state.loggedInUserFirebaseId+'/'+this.state.chatUserFirebaseId, "newMessageKey")
        update(senderUserMessagerewf,updates)
        update(receiverUserMessagerewf,updates)
        this.sendNotification(this.state.chatUserFcmToken, this.state.commentText.trim(), this.state.loggedInUserData.name)
        
        this.setState({
            btnDisable : false,
            commentText:'',
        },()=>{
            // console.log(this.state.commentText, "commentText");
            this.inputEmojiRef.value = "";
        })
    };

    sendImageMessage = (imagePath) => {
        this.setState({
            btnDisable : true
        })

        const messsageNodeRef = ref(database, 'chatList/' + this.state.chatUserFirebaseId +"/"+this.state.loggedInUserFirebaseId);
        var unread = 0;
        onValue(messsageNodeRef, (dataMessage) => {
            if(dataMessage.key !=null){
                const dataVal = dataMessage.val()
                if(dataVal.unReadMessageCount !=null && parseInt(dataVal.unReadMessageCount) > 0){
                    unread = parseInt(dataVal.unReadMessageCount)+1;
                }
                else{
                    unread = 1; 
                }
            }
        });
        // insert in chat list for sender 
        set(ref(database, 'chatList/' + this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId), {
            "userId":""+this.state.loggedInUserData.id,
            "senderId":this.state.loggedInUserFirebaseId,
            "message":imagePath,
            "messageType":"Image",
            "name":this.state.chatUserFirebaseName,
            "profilePic":this.state.chatUserFirebasephoto,
            "timeStamp": ""+Date.now(),
            "unReadMessageCount": 0,
        });
        // insert in chat list for receiver 
        set(ref(database, 'chatList/' + this.state.chatUserFirebaseId+"/"+this.state.loggedInUserFirebaseId), {
            "userId":""+this.state.loggedInUserData.id,
            "senderId":this.state.loggedInUserFirebaseId,
            "message":imagePath,
            "messageType":"Image",
            "name":this.state.loggedInUserData.name,
            "profilePic": this.state.loggedInUserData.photo,
            "timeStamp": ""+Date.now(),
            "unReadMessageCount": unread,
        });
        

        const newMessageKey = push(child(ref(database), 'messages')).key;
        // console.log(newMessageKey, "newMessageKey");
        set(ref(database, 'messages/' + newMessageKey), {
            "senderId":this.state.loggedInUserFirebaseId,
            "messageBody":imagePath,
            "type":"Image",
            "senderName":this.state.loggedInUserData.name,
            "profilePic":this.state.loggedInUserData.photo,
            "timestamp": ""+Date.now(),
            "readStatus": "1",
        });



        // Sender Message 
        const updates = {};
        updates[newMessageKey] = 1;
        const senderUserMessagerewf = ref(database, 'userMessages/' + this.state.loggedInUserFirebaseId+'/'+this.state.chatUserFirebaseId);
        const receiverUserMessagerewf = ref(database, 'userMessages/' + this.state.chatUserFirebaseId+'/'+this.state.loggedInUserFirebaseId);
        // console.log('userMessages/' + this.state.loggedInUserFirebaseId+'/'+this.state.chatUserFirebaseId, "newMessageKey")
        update(senderUserMessagerewf,updates)
        update(receiverUserMessagerewf,updates)
    }
    

    handleChatImageUpdate = (imageData) => {

        // console.log(e.target.files);

        // console.log(this.state.token);
        var uploadFileData = imageData;
        // console.log(e.target.value);
        
        var fileTypeName = uploadFileData.type.substring(0, 5);

        this.setState({
            file:URL.createObjectURL(uploadFileData),
            fileName:uploadFileData.name,
            fileType:fileTypeName,
            btnDisable:true,
            isUploadingFile: true,
        });

        var bodyFormData = {
            "chat_media": uploadFileData,
        }
        axios
        .post(`${servicePath}/user.chat_media`, bodyFormData, {
            headers: {
                "Authorization": this.state.token,
                "Content-Type": "multipart/form-data"
            },
        })
        .then((res) => {
            // console.log("Upload Image response ", res.data);
            // console.log("Upload Image response ", res.data.data.chat_media);
            if(res.data.data.chat_media !==null && res.data.data.chat_media !==""){
                this.sendImageMessage(res.data.data.chat_media);
            }
            this.setState({
                file:"",
                fileName:"",
                fileType:"",
                btnDisable:false,
                isUploadingFile: false,
            });
        })
        .catch((err) => {
            // console.log(err, "false");
            this.setState({
                file:"",
                fileName:"",
                fileType:"",
                btnDisable:false,
                isUploadingFile: false,
            });
        });
    };

    handleChatImage = async (event) => {

        const imageFile = event.target.files[0];

        const options = {
            maxSizeMB: 20,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);

            // console.log(compressedFile)

            var compressedFileSize = compressedFile.size / 1024 / 1024;

            if(compressedFileSize > 20) {
                this.props.setMessage("File size too large")
                this.props.setShowMessage(true)

                setTimeout(() => {
                    this.props.setShowMessage(false)
                    this.props.setMessage('')
                }, 2000);
            } else {
                this.handleChatImageUpdate(compressedFile);
            }

        } catch (error) {
            console.log(error, "compress");
        }
        
        event.target.value = ''
    }

    handleSendMsgBtn = (e) => {
        console.group(this.state.commentText, this.state.file, "chat msg")
        this.handleSendMsg();
        e.preventDefault();
        // this.setState({
        //     commentText:'',
        //     btnDisable:true,
        // });
        this.handleFileReset()
    };

    getChatData = ()  =>  {
        var newUserData = [];
        this.setState({
            chatList: []
        })
        const userDetail = JSON.parse(localStorage.getItem("users"));
        if(userDetail){
            this.setState({
                loggedInUserFirebaseId: userDetail.firebase_id,
                loggedInUserData: userDetail,
            })
        }
        // console.log(userDetail, "get data from firebase");
        const chatListRef = ref(database, 'chatList/'+userDetail.firebase_id);
        onChildAdded(chatListRef, (data) => {
            if(data.key !=null){
                const dataVal = data.val()
                var messageTime = this.getDateTimeForChatList(dataVal.timeStamp);

                // this.getUserImage(data.key);
                
                newUserData.push({
                    id: data.key,
                    name: dataVal.name,
                    message: dataVal.message,
                    messageType: dataVal.messageType,
                    profilePic: dataVal.profilePic,
                    timeStamp: dataVal.timeStamp,
                    unReadMessageCount: dataVal.unReadMessageCount,
                    userId: dataVal.userId,
                    messageTime: messageTime,
                    onlineStatus: "Offline"
                });
            }
            
            newUserData.sort((a,b) => b.timeStamp - a.timeStamp);
            this.setState({
                chatList:newUserData
            },() => {
                
            })
            this.state.chatList.forEach(async (item, index) => {
                await this.getOnlineStatus(item['id'], index);
            })
        });
        onChildChanged(chatListRef, (data) => {
            var updatedChatList = [...this.state.chatList];
            var updatedUnreadCount = 0;
            if(data.key !=null){
                const dataVal = data.val()
                for(var i=0; i < newUserData.length; i++){
                    if(data.key == newUserData[i].id){
                        // console.log(dataVal, "dataVal dataValuse");
                        updatedChatList[i] = {
                            id: data.key,
                            name: dataVal.name,
                            message: dataVal.message,
                            messageType: dataVal.messageType,
                            profilePic: dataVal.profilePic,
                            timeStamp: dataVal.timeStamp,
                            unReadMessageCount: dataVal.unReadMessageCount,
                            userId: dataVal.userId
                        }
                    }
                    if(parseInt(newUserData[i].unReadMessageCount) > 0){
                        updatedUnreadCount++
                    }

                    updatedChatList.sort((a,b) => b.timeStamp - a.timeStamp);
                }
                this.setState({
                    chatList:updatedChatList
                }, ()=>{
                    this.state.chatList.forEach(async (item, index) => {
                        await this.getOnlineStatus(item['id'], index);
                    })
                })
            }
        });

        onChildRemoved(chatListRef, (data) => {
            if(data.exists()){
                var updatedChatList = this.state.chatList;
                const dataVal = data.val()
                this.setState({chatList: this.state.chatList.filter(function(chatItem) { 
                    return chatItem.id !== data.key
                })});
                // for(var i=0; i < newUserData.length; i++){
                //     if(data.key === updatedChatList[i].id){
                //         updatedChatList.slice(i, 1);
                //         console.log(updatedChatList, "updatedChatList");
                        
                //     }
                // }
                // this.setState({
                //     chatList:updatedChatList
                // })
            }
        });
    };

    getUserOnlineStatus = (firebaseId) =>{
        // console.log(firebaseId, "openChat");
        const userRef = ref(database, 'users/'+firebaseId);
        onValue(userRef, (data) => {
            if(data.key !=null){
                const dataVal = data.val()
                // console.log(dataVal, "openChat");
                if((typeof dataVal.userId != "undefined")){
                    this.setState({
                        chatUserId : dataVal.userId 
                    })
                }
                if((typeof dataVal.userState != "undefined") && (typeof dataVal.userState.state != "undefined") && (dataVal.userState.state ==="Online" || dataVal.userState.state === "online")){
                    this.setState({
                        userOnlineStatus:"Online",
                    })
                }
                else{
                    this.setState({
                        userOnlineStatus:"Offline",
                    })
                }

                if(dataVal.fcmToken !=null && dataVal.fcmToken !=""){
                    this.setState({
                        chatUserFcmToken: dataVal.fcmToken,
                    })
                }
            }
        });
    }

    getOnlineStatus = async (firebaseId, index) =>{
        var updatedChatList = [...this.state.chatList];
        let upcomingGamesList = await ref(database, 'users/'+firebaseId+"/userState")
        onValue(upcomingGamesList, snapshot => {
            upcomingGamesList = snapshot.val()
            // console.log('upcoming t2',upcomingGamesList)
            if(upcomingGamesList == null){
                updatedChatList[index].onlineStatus = "Offline";
            }
            else{
                if((typeof upcomingGamesList.state != "undefined") && (upcomingGamesList.state ==="Online" || upcomingGamesList.state === "online")){
                    // console.log(upcomingGamesList.state , "openChat22");
                    updatedChatList[index].onlineStatus = "Online";
                }
                else{
                    updatedChatList[index].onlineStatus = "Offline";
                }
            }
            this.setState({
                chatList: updatedChatList
            })            
        });
    }

    getUserImage = (firebaseId) => {
        // console.log(firebaseId, "openChat");
        const userRef = ref(database, 'users/'+firebaseId+"/photo");
        onValue(userRef, (data) => {
            if(data.key !=null){
                const dataVal = data.val()
                // console.log(dataVal, "dataImage "+firebaseId);
            }
        });
    }

    getChatMessages = () =>  {
        
        // this.setState({
        //     chatMessageList:[],
        // })

        
        var mMessageList = [];
        // const userMesssageNodeRef = ref(database, 'userMessages/'+this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId);
        if(this.state.userMessageNodeReference != null){
            off(this.state.userMessageNodeReference)
        }
        this.setState({
            userMessageNodeReference : ref(database, 'userMessages/'+this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId)
        }, () => {
            onChildAdded(this.state.userMessageNodeReference, (data) => {
                if(data.key !=null){
                    
    
                    const messsageNodeRef = ref(database, 'messages/'+data.key);
                    onValue(messsageNodeRef, (dataMessage) => {
                        
                        
                        if(dataMessage.key !=null){
                            const dataVal = dataMessage.val()
                            if(dataVal !=null && dataVal.messageBody !=null){
                                // console.log(dataVal.messageBody , mMessageList.length);
                                var messageTime = this.getDateTimeForMessage(dataVal.timestamp);
                                var messageDate = this.getDateForMessage(dataVal.timestamp);
                                mMessageList.push({
                                    messageId: dataMessage.key,
                                    senderId: dataVal.senderId,
                                    senderName: dataVal.senderName,
                                    messageBody: dataVal.messageBody,
                                    readStatus: ""+dataVal.readStatus,
                                    type: dataVal.type,
                                    timestamp: messageTime,
                                    messageDate: messageDate,
                                    isMyMessage: dataVal.senderId === this.state.loggedInUserFirebaseId ? 1:0,
                                })                            
                            }
                            
                        }

                        // console.log( "sorted ", mMessageList.length);
                        mMessageList.sort((a,b) => a.timeStamp - a.timeStamp)
                        this.setState({
                            chatMessageList: mMessageList,
                        },() => {
                            // console.log(this.state.chatMessageList.length, "chatMessageList length");
                            if(this.state.chatMessageList.length > 0){
                                const chatlistNodeLoggedInUser = ref(database, 'chatList/' + this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId+"");
                                const updates = {};
                                updates['unReadMessageCount'] = 0;
                                update(chatlistNodeLoggedInUser,updates)
                            }
                            
                            
                        })
                        if(this.state.chatMessageList.length > 2){
                            this.scrollToBottom();
                        }
                        
                        // setTimeout(()=>{
                            
                        //     this.setState({
                        //         chatMessageList: mMessageList,
                        //     },() => {
                        //         // console.log(this.state.chatMessageList.length, "chatMessageList length");
                        //         if(this.state.chatMessageList.length > 0){
                        //             const chatlistNodeLoggedInUser = ref(database, 'chatList/' + this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId+"");
                        //             const updates = {};
                        //             updates['unReadMessageCount'] = 0;
                        //             update(chatlistNodeLoggedInUser,updates)
                        //         }
                                
                                
                        //     })
                        //     if(this.state.chatMessageList.length > 2){
                        //         this.scrollToBottom();
                        //     }
                            
                        // }, 600)

                    });
                    
                    
                }
            });
        })
        
        
        

        // onChildRemoved(userMesssageNodeRef, (data) => {
        //     this.state.chatMessageList.map((item, index) => {
        //         if(item.messageId === data.key){
                    
        //         }
        //     });
        // });
        
        
        // console.log(this.state.chatMessageList, "chatMessageList");

    }

    getDateTimeForChatList = (timestamp) =>{
        // console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp));
        var differenceinDays = Math.round((Date.now() - timestamp) / (1000 * 60 * 60 * 24));
        if(differenceinDays === 0){
            // console.log(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp));    
            // console.log(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit',day: '2-digit'}).format(timestamp));    
            return ""+(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit'}).format(timestamp));
        }
        if(differenceinDays === 1){
            return "Yesterday";
        }
        // console.log(differenceinDays, "diff")
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit',day: '2-digit'}).format(timestamp);

    }

    getDateForMessage = (timestamp) =>{
        // console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp));
        var differenceinDays = Math.round((Date.now() - timestamp) / (1000 * 60 * 60 * 24));
        if(differenceinDays === 0){
            
            return "Today";
        }
        if(differenceinDays === 1){
            return "Yesterday";
        }
        // console.log(differenceinDays, "diff")
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit',day: '2-digit'}).format(timestamp);

    }

    getDateTimeForMessage = (timestamp) =>{
        // console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp));
        return ""+(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit'}).format(timestamp));

    }

    

    scrollToBottom = () => {
        // console.log("scrollToBottom");
        this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    deleteChat = () =>{
        this.setState({
            startChatActive: false,
            toggleChatSidebar: true,
        })
        const userMesssageNodeRef = ref(database, 'userMessages/'+this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId);
        const chatListRef = ref(database, 'chatList/'+this.state.loggedInUserFirebaseId+"/"+this.state.chatUserFirebaseId);
        remove(userMesssageNodeRef).then(()=>{
            remove(chatListRef).then(()=>{
                // console.log("deletechatList", this.state.loggedInUserFirebaseId);
            });
        });
    }


    sendNotification = (fcmTokens, message, senderName) =>{
        // console.log(fcmTokens+", "+message+", "+senderName);
        var bodyFormData = {
            "registration_ids": [fcmTokens],
            "collapse_key": "type_a",
            "notification" : {
                "title": senderName,
                "body" : message,
            }
        }
        axios
        .post("https://fcm.googleapis.com/fcm/send", bodyFormData, {
            headers: {
                "Authorization": "key=AAAAVSFo0kk:APA91bGk1C7dxJnuHWSq-Pt7yKLtf-xKgz-lD07bCLg-Q_CwVYNsPh3dgNvxWVtfMJqY2L6fFH5C4E1DqL-BKGIOBsKzWSIAtg9fLEmKrjaRIrfKCRlb-IWR7J4LWqQ6GigYj3nHtTfH",
                'content-type': 'application/json',
            },
        })
        .then((res) => {
            // console.log("Upload Image response ", res.data);
        })
        .catch((err) => {
            console.log(err, "false");
        });
    }

    componentDidMount(){
        this.getChatData();
        if(this.props.userData !=null && this.props.userData.firebase_id !=null){
            console.log(this.props.userData, "userdata firebase id");
            this.setState({
            chatMessageList:[],
            startChatActive: true,
            toggleChatSidebar: false,
            userName : this.props.userData.name,
            userOnlineStatus : "Offline",
            chatUserFirebaseId: this.props.userData.firebase_id,
            chatUserFirebaseName: this.props.userData.name,
            chatUserFirebasephoto: this.props.userData.photo,
        }, ()=>{
            this.getUserOnlineStatus(this.props.userData.firebase_id);
            this.getChatMessages(this.props.userData.firebase_id);
        });
        // this.props.userData.firebase_id, this.props.userData.name, this.props.userData.photo
        
        }
    }

    checkBlock = () =>{
        if(this.state.userMessageNodeReference != null){
            off(this.state.userMessageNodeReference)
        }
        this.setState({
            
            blockUserRef : ref(database, 'blockusers/'+this.state.chatUserFirebaseId+'/'+this.state.loggedInUserFirebaseId)
        }, () => {
            onChildAdded(this.state.blockUserRef, (data) => {
                if(data.key !=null){
                    const dataVal = data.val()

                    console.log(dataVal, "onChildAdded")
                    
                    if((typeof dataVal != "undefined") && dataVal !=null && parseInt(dataVal) > 0){
                        this.setState({
                            amIBlocked : true,
                        }, ()=>{
                            console.log(dataVal, "onChildAdded")
                        });
                    }
                    else{
                        this.setState({
                            amIBlocked : false,
                        }, ()=>{
                            console.log(dataVal, "onChildAdded")
                        })
                    }

                }
                
            });
            onChildChanged(this.state.blockUserRef, (data) => {
                if(data.key !=null){
                    const dataVal = data.val()
                    console.log(dataVal, "onChildChanged1")
                    
                    if((typeof dataVal != "undefined") && dataVal !=null && parseInt(dataVal) > 0){
                        this.setState({
                            amIBlocked : true,
                        }, ()=>{
                            console.log(this.state.amIBlocked, "onChildChanged1")
                        });
                    }
                    else{
                        this.setState({
                            amIBlocked : false,
                        }, ()=>{
                            console.log(this.state.amIBlocked, "onChildChanged2")
                        })
                    }
                    
                }
                
            });

        })

        this.getBlockStatusFromServer()


    }

    getBlockStatusFromServer = ()=>{
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.state.token);
        myHeaders.append("Content-Type", "application/json");
  
        
        var raw = JSON.stringify({
           "data": {
              "firebase_id": this.state.chatUserFirebaseId
           }
        });
        console.log("raw", raw)
        var requestOptions = {
           method: 'POST',
           headers: myHeaders,
           body: raw,
           redirect: 'follow'
        };
  
        fetch(`${servicePath}/user.blockstatus`, requestOptions)
           .then(response => response.json())
           .then(result => {
              
              if(result.status){
                // console.log(result.data.has_block, "ddddd")
                // console.log(result.data.is_blocked, "ddddd2")
                if(result.data.is_blocked !=null){
                    this.setState({
                        chatUserId: result.data.user_id,
                        isUserBlocked: result.data.has_block,
                        amIBlocked: result.data.is_blocked,
                    }, ()=>{
                        console.log(this.state.isUserBlocked, "isUserBlocked")
                        console.log(this.state.amIBlocked, "amIBlocked")
                    })
                }
              }
  
              
           })
           .catch(error => console.log('error', error));
    }


    blockUnblock = (blockStr)=>{
        console.log(this.state.loggedInUserData, "Loggedin");
        console.log(blockStr, "ChatUserData");

        if(blockStr === 'Unblock'){
            this.handleUnblockUser(this.state.chatUserId)
        }
        else{
            this.handleBlockUser(this.state.chatUserId)
        }
    }

    handleBlockUser = (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.state.token);
        myHeaders.append("Content-Type", "application/json");
  
        console.log(id, "block id");
  
        var raw = JSON.stringify({
           "data": {
              "status": "blocked",
              "blocked_user_id": id
           }
        });
  
        var requestOptions = {
           method: 'POST',
           headers: myHeaders,
           body: raw,
           redirect: 'follow'
        };
  
        fetch(`${servicePath}/friend.block`, requestOptions)
           .then(response => response.json())
           .then(result => {
              console.log(result)
  
              if (result.success === "User blocked successfully") {
                this.setState({
                    isUserBlocked:true,
                })
                const blockRef = ref(database,  'blockusers/'+this.state.loggedInUserFirebaseId+'/'+this.state.chatUserFirebaseId);
                const updates = {};
                updates['isBlocked'] = "1";
                update(blockRef , updates)
              } else {
                 
              }
           })
           .catch(error => console.log('error', error));
     };
  
     handleUnblockUser = (userID) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.state.token);
        myHeaders.append("Content-Type", "application/json");
  
        var raw = JSON.stringify({
           "data": {
              "blocked_user_id": userID
           }
        });
  
        var requestOptions = {
           method: 'POST',
           headers: myHeaders,
           body: raw,
           redirect: 'follow'
        };
  
        fetch(`${servicePath}/friend.unblock`, requestOptions)
           .then(response => response.json())
           .then(result => {
              console.log(result)
              if (result.success == "User unblocked successfully") {
                this.setState({
                    isUserBlocked:false,
                })
                const blockRef = ref(database,  'blockusers/'+this.state.loggedInUserFirebaseId+'/'+this.state.chatUserFirebaseId);
                const updates = {};
                updates['isBlocked'] = "0";
                update(blockRef , updates)
              }
           })
           .catch(error => console.log('error', error));
     };

    render(){
        // console.log("hhdsssss", this.props.location);
        return(
            <>
            <Container className='chat_page'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="start">                        
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Body className="chat-page p-0">
                                    <div className="chat-data-block">
                                        <Row>
                                            <Col lg="3" className={`chat-data-left scroller ${this.state.toggleChatSidebar === true ? 'show' : '' }`}>
                                                <div className="chat-search ps-2">
                                                    <div className="d-flex align-items-center mt-3">
                                                        {/* <div className="chat-profile me-2">
                                                            <img src={user1} alt="chat-user" className="avatar-50" onClick={() => toggleUserDetails()}/>
                                                        </div>
                                                        <div className="chat-caption">
                                                            <h5 className="mb-0">Bni Jordan</h5>
                                                            <p className="m-0">Web Designer</p>
                                                        </div> */}
                                                        <h5 className="fw-bold mb-0">Chat list</h5>
                                                        <Button type="submit" onClick={this.ChatSidebarClose} variant=" close-btn-res p-0 me-2"><i className="ri-close-fill"></i></Button>
                                                    </div>

                                                    {/* <div className="chat-searchbar">
                                                        <Form.Group className="form-group chat-search-data m-0">
                                                            <input type="text" className="form-control round" id="chat-search" placeholder="Search"/>
                                                            <i className="ri-search-line"></i>
                                                        </Form.Group>
                                                    </div> */}
                                                </div>
                                                <div className="chat-sidebar-channel scroller mt-2 ps-2">
                                                    <Nav variant="pills" className="iq-chat-ui nav flex-column pt-2 pe-1">
                                                        {this.state.chatList && this.state.chatList.length > 0 ? (
                                                            <>
                                                                {this.state.chatList.map((item, index) => {
                                                                    return (
                                                                        <li key={index} className={`clickable chat_users position-relative ${item.id === this.state.chatUserFirebaseId ? "active" : ""}`} onClick={(e) => this.StartChatUser(e, item.id, item.name, item.profilePic, index)}>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar me-2 position-static">
                                                                                    <img 
                                                                                        src={item.profilePic} 
                                                                                        alt="chatuserimage" 
                                                                                        className="avatar-40"
                                                                                        onError={(e) => {
                                                                                            e.target.src = noprofile
                                                                                        }}
                                                                                    />
                                                                                    {item.onlineStatus === "Online" && 
                                                                                        <span className="avatar-status list"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                                    }
                                                                                </div>
                                                                                <div className="chat-sidebar-name">
                                                                                    <h6 className="mb-0 text-ellipsis-2 fw-500">{item.name} </h6>
                                                                                    {item.messageType === "Image" ? (
                                                                                        <span className='text-ellipsis-2 mb-0 lh-1'>
                                                                                            <i className="ri-gallery-fill me-1"></i>
                                                                                        </span>
                                                                                    ):(
                                                                                        <span className='text-ellipsis-2 mb-0 lh-1'>{item.message && item.message.length > 30 ? item.message.substring(0, 30)+"..." : item.message }</span>
                                                                                    )}
                                                                                    
                                                                                </div>
                                                                                <div className="d-flex flex-column justify-content-center chat-meta float-right text-center ms-1">
                                                                                    {item.unReadMessageCount > 0 && 
                                                                                        <div className="chat-msg-counter bg-primary text-white mb-1">{item.unReadMessageCount > 99 ? "99+" : item.unReadMessageCount}</div>
                                                                                    }
                                                                                    <span className="text-nowrap mb-0 text-primary chat_time lh-1">{item.messageTime}</span>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <h6 className='text-center'>No chats founds</h6>
                                                        )}
                                                        
                                                    </Nav>
                                                </div>
                                            </Col>
                                            <Col lg={9} className="chat-data position-relative p-0 chat-data-right">
                                            {this.state.startChatActive === false ? (
                                                    <div className="chat-start">
                                                        <span className="iq-start-icon d-none d-lg-block text-primary">
                                                            <i className="ri-message-3-line"></i>
                                                        </span>
                                                        <div className='d-flex flex-column align-items-center d-lg-none' onClick={() => this.ChatSidebarOpen()}>
                                                            <span className="iq-start-icon text-primary mb-2">
                                                                <i className="ri-message-3-line"></i>
                                                            </span>
                                                            <span className='fw-bold'>Start Conversation</span>
                                                        </div>
                                                        {/* <Button id="chat-start" onClick={() => this.StartChat()} bsPrefix="btn btn-outline-primary mt-3">
                                                            Start Conversation!
                                                        </Button> */}
                                                    </div>
                                                ) : (
                                                    <>
                                                <div className="chat-head">
                                                    <header className="d-flex justify-content-between align-items-center bg-white p-2">
                                                        <div className="d-flex align-items-center">
                                                            <div className="sidebar-toggl d-block d-lg-none me-3" onClick={() => this.ChatSidebarOpen()}>
                                                                <i className="ri-menu-3-line"></i>
                                                            </div>
                                                            <div className="avatar chat-user-profile m-0 me-2">
                                                                <img 
                                                                    src={this.state.chatUserFirebasephoto}
                                                                    alt="avatar" 
                                                                    className="avatar-40"
                                                                    onError={(e) => {
                                                                        e.target.src = noprofile
                                                                    }}
                                                                />
                                                                {this.state.userOnlineStatus === "Online"?
                                                                <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                :null
                                                                }
                                                            </div>
                                                            <div>
                                                                <h5 className="mb-0 lh-1 mb-1">{this.state.chatUserFirebaseName}</h5>
                                                                <h6 className="d-block text-nowrap mb-0 text-primary lh-1 small">{this.state.userOnlineStatus}</h6>
                                                            </div>
                                                        </div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="bg-transparent border-0 p-0 lh-1 hide_arrow">
                                                                <i className="ri-more-2-line text-primary fs-4"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className=" m-0 p-0">
                                                                {/* <Dropdown.Item
                                                                    className="px-2 py-1"
                                                                    to="#"
                                                                    onClick={() => this.deleteChat()}
                                                                >
                                                                    Delete chat
                                                                </Dropdown.Item> */}

                                                                <Dropdown.Item
                                                                    className="px-2 py-1"
                                                                    to="#"
                                                                    onClick={() => this.blockUnblock(!this.state.isUserBlocked ? 'Block':'Unblock')}
                                                                >
                                                                    {
                                                                        !this.state.isUserBlocked ? 'Block':'Unblock'
                                                                    }
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </header>
                                                </div>
                                                <div className="chat-content scroller">

                                                    { this.state.chatMessageList.length > 0 ?  (
                                                        <>
                                                        {this.state.chatMessageList.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {index === 0 ? (
                                                                        <div className='chat-divider'>
                                                                            <span>{item.messageDate}</span>
                                                                        </div>
                                                                    ) : index > 1 && this.state.chatMessageList[index].messageDate != this.state.chatMessageList[index-1].messageDate 
                                                                    ? <div className='chat-divider'>
                                                                        <span>{item.messageDate}</span>
                                                                    </div>
                                                                    :(
                                                                        ''
                                                                    )}
                                                                    <div className={`${item.isMyMessage === 0 ? 'chat chat-left' : 'chat chat d-flex other-user' }`}>
                                                                        <div className="chat-user">
                                                                            <div className="avatar m-0">
                                                                                <img 
                                                                                    src={item.isMyMessage === 0 ? this.state.chatUserFirebasephoto : this.state.loggedInUserData.photo} alt="avatar" 
                                                                                    className="avatar-35 rounded-circle"
                                                                                    onError={(e) => {
                                                                                        e.target.src = noprofile
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <span className="chat-time mt-0">{item.timestamp}</span>
                                                                        </div>
                                                                        <div className="chat-detail">
                                                                            {item.type === "Image" || item.type === "image" ? (
                                                                                <div className='chat-message image_preview'>
                                                                                    <SingleLightbox
                                                                                        thumb={item.messageBody}
                                                                                        large={item.messageBody}
                                                                                        className="align-self-center avatar-120"
                                                                                    />
                                                                                </div>
                                                                            ):(
                                                                                <div className="chat-message">
                                                                                    <p>{item.messageBody}</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        </>
                                                        
                                                    ): (<></>) }
                                                    {
                                                        this.state.isUploadingFile ? (
                                                            <div className="main_loader">
                                                                <img src={loader} alt="loader" style={{ height: "100px" }} />
                                                            </div>
                                                        ) :<></>
                                                    }

                                                    <div ref={this.messagesEndRef}  style={{height:10}} />
                                                </div>
                                                
                                                <div className="chat-footer p-2 bg-white">
                                                    {/* <div className='w-100 position-relative'>
                                                        {this.state.file && (
                                                            <div className='image_preview popup'>
                                                                <h6 className='mb-2 fw-500 text-muted'>Preview</h6>
                                                                <img src={this.state.file} />
                                                                <Link to="#" className="lh-1 close_btn border right_10" onClick={() => {this.handleFileReset()}}>
                                                                    <i className="ri-close-fill ri-xl"></i>
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </div> */}
                                                    {
                                                        this.state.amIBlocked || this.state.isUserBlocked ? (
                                                            <div>This person is no longer available on messaging</div>
                                                        ) 
                                                        : 
                                                        (
                                                        <Form className="d-flex align-items-center" action="#">
                                                        {this.state.file ? (
                                                            <>
                                                                <Form.Control type="text" className="react-input-emoji--input form-contro" placeholder=" " disabled/>

                                                                <div className="chat-attagement d-flex px-2">
                                                                    <svg fill="#777d74" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="react-input-emoji--button--icon"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path><path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path></svg>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <InputEmoji
                                                                ref={el => this.inputEmojiRef = el}
                                                                onChange={(msg) => {this.handleChangeMsg(msg)}}
                                                                value={this.commentText}
                                                                cleanOnEnter
                                                                placeholder="Type something"
                                                                onEnter={() => {this.handleSendMsg()}}
                                                            />
                                                        )}
                                                        
                                                        {!this.state.commentText && (
                                                            <div className="chat-attagement d-flex">
                                                                <Form.Label className='mb-0 clickable' htmlFor="chatImage">
                                                                    <input type="file" accept="image/jpeg, image/png, image/jpg" name="chatImage" id="chatImage" onChange={this.handleChatImage} />
                                                                    <i className="ri-attachment-line me-2"/>
                                                                </Form.Label>
                                                            </div>
                                                        )}
                                                        <Button type="submit" variant="primary d-flex align-items-center px-2" onClick={(e) => {this.handleSendMsgBtn(e)}} disabled={this.state.btnDisable}>
                                                            <i className="far fa-paper-plane" aria-hidden="true"></i>
                                                            <span className="d-none d-lg-block ms-1">Send</span>
                                                        </Button>
                                                    </Form>
                                                        )
                                                    }
                                                    
                                                </div>

                                                <div id="user-detail-popup" className={`scroller ${this.state.showUserDetails === true ? 'show' : '' }`}>
                                                    <div className="user-profile">
                                                        <Button type="submit" variant=" close-popup p-3"><i className="ri-close-fill" onClick={() => {
                                                            this.setState({
                                                                showUserDetails: false,
                                                            })
                                                        }}></i></Button>
                                                        <div className="user text-center mb-3">
                                                            <Link className="avatar m-0" to="">
                                                                <img 
                                                                    src={noprofile} 
                                                                    alt="avatar"
                                                                    className='avatar-60'
                                                                    onError={(e) => {
                                                                        e.target.src = noprofile
                                                                    }}
                                                                />
                                                            </Link>
                                                            <div className="user-name mt-3">
                                                                <h4 className="text-center">Anish Augurs</h4>
                                                            </div>
                                                            <div className="user-desc">
                                                                <p className="text-center">Web Designer</p>
                                                            </div>
                                                        </div>
                                                        <hr className='my-0'/>
                                                        <div className="user-detail text-left mt-3 px-3">
                                                            <h5 className="mb-0 fw-500">About</h5>
                                                            <p>It is long established fact that a reader will be distracted bt the reddable.</p>
                                                            <h5 className="mt-3 mb-0 fw-500">Status</h5>
                                                            <ul className="user-status p-0">
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-success pe-1"></i><span>Online</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-warning pe-1"></i><span>Away</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-danger pe-1"></i><span>Do Not Disturb</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-light pe-1"></i><span>Offline</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
            </>
        );
    };
}

export default MChat;    