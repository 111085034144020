import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import MChat from './mchat'

 const Chat = () => {

    const location = useLocation()  

    const userData = location?.state?.userData;

    const [message, setMessage] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    
    // useEffect(() => {
    //     console.log(location, userData, "userData")
    // }, [])

    return (
        <>  
            {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
            <MChat userData={userData} setShowMessage={setShowMessage} setMessage={setMessage} />
        </>
    )
}

export default Chat;
