/* eslint-disable import/prefer-default-export */

import { servicePath } from "../app/variables";

export const LongText = ({ content, limit }) => {
  if (content.length <= limit) {
    return content;
  }
  const toShow = `${content.substring(0, limit)}...`;
  return toShow;
};

export const LongTextLess = ({ content, limit }) => {
  if (content.length <= limit) {
    return content;
  }

  var toShow;
  if (limit !== "") {
    toShow = `${content.substring(0, limit)}...`;
  } else {
    toShow = `${content}`;
  }

  return toShow;
};

export const UpdatedProfileImage = (value) => {
  if (value) {
    var targetProfile = document.getElementById("header_profile_img");
    targetProfile.src = value;
  }
};

export const getNotificationUnreadList = (token, setNotificationList) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    limit: "4",
    page: 1,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${servicePath}/notification.unread_web`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result, "notifications unread")
      if (result && result.data && result.data.length > 0) {
        setNotificationList(result.data);
      }
    })
    .catch((error) => console.log("error", error));
};

export const getNotificationUnreadCount = (
  token,
  setNotificationUnreadCount
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(`${servicePath}/notification.unread_count`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result) {
        // console.log(result, "notifications unread count")

        if (result.data && result.data.unread_count) {
          setNotificationUnreadCount(result.data.unread_count);
        }

        var notifyCount = document.getElementById("notifyCount");

        if (notifyCount != null) {
          if (result.data.unread_count > 0) {
            notifyCount.innerHTML = result.data.unread_count;
          }

          if (result.data.unread_count === 0) {
            notifyCount.style.display = "none";
          } else {
            notifyCount.style.display = "flex";
          }
        }
      }
    })
    .catch((error) => console.log("error", error));
};

export const subscribeUniverseSingle = (
  id,
  token,
  setShowMessage,
  setMessage
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    universes: id,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${servicePath}/user.subscribe_to_universes_single`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result, "sub uni");
      if (result) {
        setShowMessage(true);
        setMessage("Universe subscribed successfully");

        setTimeout(() => {
          setMessage("");
          setShowMessage(false);
        }, 2000);
      }
    })
    .catch((error) => console.log("error", error));
};

export const Linkify = ({ children }) => {
  const isUrl = (word) => {
    const urlPattern =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return word.match(urlPattern);
  };

  const addMarkup = (word) => {
    if (word.substring(0, 3) === "www") {
      return isUrl(word)
        ? `<a href="https://${word}" target="_blank" rel="noreferrer">${word}</a>`
        : word;
    } else {
      return isUrl(word)
        ? `<a href="${word}" target="_blank" rel="noreferrer">${word}</a>`
        : word;
    }
  };

  const words = children.split(" ");
  const formatedWords = words.map((w) => addMarkup(w));
  const html = formatedWords.join(" ");
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
