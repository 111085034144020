import React from "react";
import { Helmet } from "react-helmet-async";
import { baseURL } from '../app/variables';

export default function HelmetMetaData(props) {
    let currentUrl = props.url !== null ? props.url : baseURL;
    let quote = props.quote !== null ? props.title : "";
    let title = props.title !== null ? props.title : "Universimm";
    let image = props.image !== undefined ? props.image : `${baseURL}/preview.jpg`;
    let description = props.description !== null ? props.description : "Share your passions and interests with the people you love.";
    let hashtag = props.hashtag !== undefined ? props.hashtag : "#Universimm";
    return (
        <Helmet>
            <meta property="url" content={currentUrl} />
            <meta property="title" content={title} />
            <meta property="quote" content={title} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />

            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={title} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />

            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="Universimm" />
            <meta property="og:description" content={description} />
        </Helmet>
    );
}