import React,{useState , useEffect} from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import "../app/SelectUniverse.css"
import { AiOutlinePlusCircle, AiFillCheckCircle } from 'react-icons/ai';
import { Link, useHistory, useLocation } from "react-router-dom";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import { servicePath } from "./variables";

const SelectUniverse = () => {

  const history = useHistory()
  const location = useLocation()

  const [universes, setUniverses] = useState([])
  const [show, setShow] = useState(true)
  const [univerId, setUniverId] = useState([])
  const [selectUniverseError, setSelectUniverseError] = useState(false)
  const [loading, setLoading] = useState(true)
  
  const token = "Bearer" +" "+ localStorage.getItem("access_token")

  useEffect(() => {
    if(token !== null){
      userState()
    }
  }, [])

  const handleClose = () => {
    setShow(false); setSelectUniverseError(false)
  };

  var getMessage = sessionStorage.getItem("message")

  useEffect(() => {
      setTimeout(() =>{
        setShow(false);
      }, 1000)
  }, [])

  const userState = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      token
    );
    myHeaders.append("Content-Type", "application/json");
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
  
    fetch(
      `${servicePath}/user.state?`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
          // console.log(result.data.universes.length, "user state data");

          if(result.data.universes.length > 0){
            window.location.href = '/'
          } else {
            getUniverse()
            setLoading(false)
          }
      })
      .catch((error) => console.log("error", error));
  };

  const getUniverse = () =>{
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };
    
    fetch(`${servicePath}/public.universes`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setUniverses(result.data.universes)
      })
      .catch(error => console.log('error', error));
  }

  const subscribeUniverse = (id) =>{
    setLoading(true);
    // alert(id);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        universes: id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${servicePath}/user.subscribe_to_universes`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if(result.data.universe_count > 1){
          setTimeout(() => {
            {history.push("/dashboard/app/profile",{signin : true})}
          }, 1000);
        }
      })
      .catch(error => console.log('error', error));
  }

  const addUniverses = (ids, e) =>{
    //([...univerId , ids])
    if (e.currentTarget.classList.contains('isActive')) {
        // console.log('Element has contain class');
        e.currentTarget.classList.toggle('isActive');

        if(univerId.includes(ids)){
          var index = univerId.findIndex(id => id === ids);

          const newUniverId = univerId.filter((_, i) => i !== index);

          setUniverId(newUniverId);
        }
    } else {
        e.currentTarget.classList.toggle('isActive');
        univerId.push(ids);
        setUniverId(univerId);
        // console.log(univerId);
    }
  }

  const handleUniverseSubmit = () => {
    // console.log(univerId)

    if(univerId.length > 2) {
      subscribeUniverse(univerId)
    } else {
      setSelectUniverseError(true);
    }
  }

  return (
    <Container fluid className="position-relative">
      {loading ? (<div className="main_loader">
          <img src={loader} alt="loader" style={{ height: "100px" }} />
      </div>) : (
        <Row>
          <Col xxl="7" xl="9" lg="10" md="12" sm="12" className="universeContainer mx-auto text-center pt-4">
            {show && location.state ?   <div className="alert-message">
              {location.state.message}
              </div> : <></>}
              {show && getMessage === "Verified"? <div className="alert-message">Your Email is Verified</div> : <></>}
    
            <div className="d-flex justify-content-end px-2">
              <h5 className="text-muted fw-bold clickable mb-3" onClick={handleUniverseSubmit}>Next</h5>
            </div>
          
            <div className="">
              <h3 className="text-center text-dark fw-bold">Choose your universe</h3>
              <h5 className="text-muted mb-3">Select 3 or more Universes</h5>
              <div className="d-flex justify-content-center flex-wrap select_univer_wrap">
                {universes.map((item, index) => {
                  return (
                    <div className="px-1 py-1 position-relative universe_card" key={index} onClick = {(e) => addUniverses(item.ID, e)}>
                      <div className="position-absolute iconDiv">
                        <AiOutlinePlusCircle size={30} className="text-white add"/>
                        <AiFillCheckCircle size={30} className="text-white added"/>
                      </div>
                      <img src={item.universeIcon} alt="universe" className = "universeImage" />
                      <div className="position-absolute  textDiv ">
                        <p className="mb-0">{item.universeName}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
    
          <Modal
              size="sm"
              className="fade"
              id="post-modal"
              dialogClassName="modal-dialog-centered"
              onHide={handleClose}
              show={selectUniverseError}
          >
              <div className="text-center p-3">
                  <div className="d-flex justify-content-between">
                      <span></span>
                      <Link to="#" className="lh-1" onClick={() => {setSelectUniverseError(false)}}>
                          <i className="ri-close-fill ri-xl"></i>
                      </Link>
                  </div>
                  <Modal.Body>
                      <h5>Please select at least 3 universe</h5>
                  </Modal.Body>
              </div>
          </Modal>
        </Row>
      )}
    </Container>
  );
};

export default SelectUniverse;
