import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import dummyUserImage from "../../assets/images/no-profile-picture.jpg";
import loader from "../../assets/images/page-img/loader_sq.gif";
import axiosInstance from "../../helper/axiosInstance";
import { servicePath } from "../../views/dashboard/app/variables";

const InteractedUserList = ({ handleClose, showModalTypeInfo }) => {
  const URL =
    showModalTypeInfo.type === "like"
      ? "/app/api/v1/action/post/likes-users"
      : "/app/api/v1/action/post/commensts-users";
  const {
    data = [],
    isLoading,
    error,
  } = useQuery([URL, showModalTypeInfo.postId], async () => {
    const { data } = await axiosInstance({
      url: URL,
      method: "post",
      data: {
        post_id: showModalTypeInfo.postId,
      },
    });
    return data?.data ?? {};
  });

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const token = "Bearer" + " " + localStorage.getItem("access_token");

  const handleAddFriend = (friendId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      data: {
        friend_user_id: friendId,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${servicePath}/friend.send`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === "Request sent") {
          setMessage("Request sent successfully");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  if (error) {
    console.log(error);
  }

  return (
    <>
      {showMessage && message && <div className="alert-message">{message}</div>}
      <div className="friend_list_card px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="header-title">
            <h6 className="card-title fw-500">
              {showModalTypeInfo.type === "like" && "People who liked"}
              {showModalTypeInfo.type === "comment" && "People who commented"}
            </h6>
          </div>
          <button
            onClick={handleClose}
            className="reset-btn"
            style={{ marginTop: "-4px" }}
          >
            <i className="ri-close-fill ri-xl text-dark"></i>
          </button>
        </div>
        <hr className="m-0 p-0" />

        <div style={{ height: "450px", overflowY: "auto" }}>
          {isLoading ? (
            <div className="d-flex justify-content-center my-3 w-100">
              <img src={loader} alt="loader" style={{ height: "40px" }} />
            </div>
          ) : (
            <ul className="request-list list-inline m-0 p-0 pt-2">
              {isLoading && (
                <div className="main_loader">
                  <img src={loader} alt="loader" style={{ height: "40px" }} />
                </div>
              )}
              {data.map((item) => (
                <li key={item.id}>
                  <div>
                    <div className="d-flex align-items-center">
                      <Link to={`/dashboard/app/friend-profile/${item.id}`}>
                        <div className="img-fluid">
                          <Image
                            className="avatar-50 rounded-circle"
                            src={item.photo || dummyUserImage}
                            alt=""
                            onError={(e) => {
                              e.target.src = dummyUserImage;
                            }}
                          />
                        </div>
                      </Link>
                      <div className="ms-2">
                        <Link to={`/dashboard/app/friend-profile/${item.id}`}>
                          <h6 className="mb-0  fw-500">{item.name}</h6>
                        </Link>
                        {false && (
                          <Link
                            style={{ fontSize: "12px" }}
                            to={"#"}
                            className="fw-500"
                            onClick={() => {
                              handleAddFriend(item.id);
                            }}
                          >
                            <i className="ri-user-add-line me-1"></i>
                            Add Friend
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default InteractedUserList;
