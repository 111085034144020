import React, {useState, useEffect} from 'react'
import { Row, Col, Container, Form} from 'react-bootstrap'
import Card from '../../../components/Card'
import { servicePath } from './variables'

const UserPrivacySetting =() =>{
    
    const [uActivity, setUActivity] = useState("public")
    const [friendList, setFriendList] = useState("public")
    const [contactInfo, setContactInfo] = useState("public")
    const [dob, setDob] = useState("public")
    const [workEdu, setWorkEdu] = useState("public")
    const [socialProfiles, setSocialProfiles] = useState("public")

    const [message, setMessage] = useState("")
    const [showMessage, setShowMessage] = useState(true)

    const userData = JSON.parse(localStorage.getItem("users"))

    useEffect(() => {
        if(userData !== null){
            userState();
        }
    }, []);

    const userState = () => {
        var myHeaders = new Headers();

        const token = "Bearer" + " " + localStorage.getItem("access_token");

        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(
        `${servicePath}/user.state`,
        requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
                // console.log(result, "friend user state data");
                setUActivity(result.data.privacy.u_activity)
                setFriendList(result.data.privacy.friends_listing)
                setContactInfo(result.data.privacy.see_email);
                setDob(result.data.privacy.see_birthday);
                setWorkEdu(result.data.privacy.aboutme)
                setSocialProfiles(result.data.privacy.social_profiles)
        })
        .catch((error) => console.log("error", error));
        };

    const updatePrivacySettings = () => {
        const token = "Bearer" +" "+ localStorage.getItem("access_token")

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            token
        );
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "data": {
                "u_activity": uActivity,
                "friends_listing": friendList,
                "see_email": contactInfo,
                "see_birthday": dob,
                "aboutme": workEdu,
                "social_profiles": socialProfiles
            }
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${servicePath}/user.update_privacy_settings`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
            if(result.success ===  "Privacy settings updated successfully"){
                setMessage("Privacy settings updated")
                setShowMessage(true)
                setTimeout(() => {
                    setShowMessage(false)
                }, 3000);
            }
        })
        .catch((error) => console.log("error", error))
        .finally((error) => 
            console.log(error)
        )
    };

    return(
        <>
            <Container className='position-relative'>
                {showMessage && message ? <div className="alert-message">{message}</div> : <></>}
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Privacy Setting</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form className="acc-privacy">
                                    <div className="data_privacy mb-3">
                                        <h5 className="mb-1">Who can see your U-Activity &#40;Posts&#41;?</h5>

                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="uActivity" id="public1" onChange ={() => setUActivity("public")} checked={uActivity === "public"}/>{' '}
                                            <Form.Check.Label htmlFor="public1" className="pl-2 radio_align">Public</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="uActivity" id="friend1" onChange ={() => setUActivity("friends")} checked={uActivity === "friends"}/>{' '}
                                            <Form.Check.Label htmlFor="friend1" className="pl-2 radio_align">Friends</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="uActivity" id="onlyme1" onChange ={() => setUActivity("me")} checked={uActivity === "me"}/>{' '}
                                            <Form.Check.Label htmlFor="onlyme1" className="pl-2 radio_align">Only Me</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="data_privacy mb-3">
                                        <h5 className="mb-1">Who can see your friends list?</h5>

                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="friendList" id="public2" onChange ={() => setFriendList("public")} checked={friendList === "public"}/>{' '}
                                            <Form.Check.Label htmlFor="public2" className="pl-2 radio_align">Public</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="friendList" id="friend2" onChange ={() => setFriendList("friends")} checked={friendList === "friends"}/>{' '}
                                            <Form.Check.Label htmlFor="friend2" className="pl-2 radio_align">Friends</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="friendList" id="onlyme2" onChange ={() => setFriendList("me")} checked={friendList === "me"}/>{' '}
                                            <Form.Check.Label htmlFor="onlyme2" className="pl-2 radio_align">Only Me</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="data_privacy mb-3">
                                        <h5 className="mb-1">Who can see your contact information?</h5>

                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="contactInfo" id="public3" onChange ={() => setContactInfo("public")} checked={contactInfo === "public"}/>{' '}
                                            <Form.Check.Label htmlFor="public3" className="pl-2 radio_align">Public</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="contactInfo" id="friend3" onChange ={() => setContactInfo("friends")} checked={contactInfo === "friends"}/>{' '}
                                            <Form.Check.Label htmlFor="friend3" className="pl-2 radio_align">Friends</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="contactInfo" id="onlyme3" onChange ={() => setContactInfo("me")} checked={contactInfo === "me"}/>{' '}
                                            <Form.Check.Label htmlFor="onlyme3" className="pl-2 radio_align">Only Me</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="data_privacy mb-3">
                                        <h5 className="mb-1">Who can see your birthday?</h5>

                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="dob" id="public4" onChange ={() => setDob("public")} checked={dob === "public"}/>{' '}
                                            <Form.Check.Label htmlFor="public4" className="pl-2 radio_align">Public</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="dob" id="friend4" onChange ={() => setDob("friends")} checked={dob === "friends"}/>{' '}
                                            <Form.Check.Label htmlFor="friend4" className="pl-2 radio_align">Friends</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="dob" id="onlyme4" onChange ={() => setDob("me")} checked={dob === "me"}/>{' '}
                                            <Form.Check.Label htmlFor="onlyme4" className="pl-2 radio_align">Only Me</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="data_privacy mb-3">
                                        <h5 className="mb-1">Who can see your work and education?</h5>

                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="workEdu" id="public5" onChange ={() => setWorkEdu("public")} checked={workEdu === "public"}/>{' '}
                                            <Form.Check.Label htmlFor="public5" className="pl-2 radio_align">Public</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="workEdu" id="friend5" onChange ={() => setWorkEdu("friends")} checked={workEdu === "friends"}/>{' '}
                                            <Form.Check.Label htmlFor="friend5" className="pl-2 radio_align">Friends</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="workEdu" id="onlyme5" onChange ={() => setWorkEdu("me")} checked={workEdu === "me"}/>{' '}
                                            <Form.Check.Label htmlFor="onlyme5" className="pl-2 radio_align">Only Me</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="data_privacy mb-3">
                                        <h5 className="mb-1">Who can see your social profiles?</h5>

                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="socialProfiles" id="public6" onChange ={() => setSocialProfiles("public")} checked={socialProfiles === "public"}/>{' '}
                                            <Form.Check.Label htmlFor="public6" className="pl-2 radio_align">Public</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="socialProfiles" id="friend6" onChange ={() => setSocialProfiles("friends")} checked={socialProfiles === "friends"}/>{' '}
                                            <Form.Check.Label htmlFor="friend6" className="pl-2 radio_align">Friends</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="me-2">
                                            <Form.Check.Input type="radio" name="socialProfiles" id="onlyme6" onChange ={() => setSocialProfiles("me")} checked={socialProfiles === "me"}/>{' '}
                                            <Form.Check.Label htmlFor="onlyme6" className="pl-2 radio_align">Only Me</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <button type='button' className="btn btn-primary me-2" onClick={() => updatePrivacySettings()}>Submit</button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserPrivacySetting;