import React from "react";
import { Route, Switch } from "react-router-dom";

// auth
import ConfirmMail from "../views/dashboard/auth/confirm-mail";
import LockScreen from "../views/dashboard/auth/lock-screen";
import ResetPassword from "../views/dashboard/auth/reset-password";
import SignIn from "../views/dashboard/auth/sign-in";
import SignUp from "../views/dashboard/auth/sign-up";
import Verify from "../views/dashboard/auth/Verify";

// errors
import Error404 from "../views/dashboard/errors/error404";
import Error500 from "../views/dashboard/errors/error500";

//extrapages universimm
import PublicPostDetails from "../views/dashboard/app/public-post-detail";
import CookiePolicy from "../views/dashboard/extrapages/cookie-policy";
import PrivacyPolicy from "../views/dashboard/extrapages/privacy-policy";
import TermsConditions from "../views/dashboard/extrapages/terms-conditions";

const SimpleRouter = () => {
  return (
    <>
      <Switch>
        {/* auth */}
        <Route path="/auth/confirm-mail" component={ConfirmMail} />
        <Route path="/auth/lock-screen" component={LockScreen} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        <Route path="/auth/sign-in" component={SignIn} />
        <Route path="/auth/verify" component={Verify} />
        <Route path="/auth/sign-up" component={SignUp} />
        <Route
          exact
          path="/dashboard/app/post-detail/:postId"
          component={PublicPostDetails}
        />

        {/* error */}

        <Route path="/errors/error404" component={Error404} />
        <Route path="/errors/error500" component={Error500} />

        {/* extrapages */}
        <Route path="/user/privacy-policy" component={PrivacyPolicy} />
        <Route path="/user/terms-conditions" component={TermsConditions} />
        <Route path="/user/cookie-policy" component={CookiePolicy} />
      </Switch>
    </>
  );
};

export default SimpleRouter;
