import React, {useState} from 'react'
import { Button, Dropdown, Modal } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import CustomToggle from '../../../components/dropdowns'
import dummyUserImage from '../../../assets/images/no-profile-picture.jpg'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';
import { servicePath } from '../app/variables';
import BrokenImg from '../../../assets/images/broken_img_1.jpg';
import { Linkify } from './Functions';
import LinkPreview from './LinkPreview';

function SavedPostItem({item, componentOf, setLoading, token, setShowMessage, setMessage, getSavedPost, currentPage}) {
    // console.log(item, "post item details");

    const userData = JSON.parse(localStorage.getItem('users'))

    const [deletePostConfirm, setDeletePostConfirm] = useState(false)

    const handleClose = () => {setDeletePostConfirm(false)};

    // function handleReadMore(e) {
    //     reactDom.findDOMNode(e.target).parentNode.classList.remove('read_less_active');
    // };
    
    // function handleReadLess(e) {
    //     reactDom.findDOMNode(e.target).parentNode.classList.add('read_less_active');
    // };

    const history = useHistory()

    const friendProfile = () => {
        history.push(`/dashboard/app/friend-profile/${item.user_id}`);
    };

    const taggedUserProfile = (taggedID) => {
        if(taggedID !== userData.id) {
            history.push(`/dashboard/app/friend-profile/${taggedID}`);
        } else {
            history.push(`/dashboard/app/profile`);
        }
    };

    const userProfile = () => {
        history.push(`/dashboard/app/profile`);
    };

    // function handleDeletePost(postId) {
    //     console.log(postId, "post deleted")
    //     setDeletePostConfirm(false)
    // };

    const handleDeletePost = (postId) => {
        setLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "data": {
                "confirm": "Y"
            },
            "query": {
                "id": postId
            }
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${servicePath}/post.delete`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.success === "Post deleted"){
                setDeletePostConfirm(false)
                setMessage("Post deleted successfully")
                setShowMessage(true)
                getSavedPost();
                setTimeout(() =>{
                    setLoading(false);
                }, 1000)
                setTimeout(() =>{
                    setShowMessage(false)
                }, 2000)
            }
        })
        .catch(error => console.log('error', error));
    };

    const handleRemoveSavePost = (postId) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
            "data": {
                "post_id": postId,
                "status": "unsave"
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${servicePath}/post.save`, requestOptions)
        .then(response => response.json())
        .then(result => {
            // console.log(result)
            if(result.success === "post unsaved successfully "){
                setMessage("Post removed from saved")
                setShowMessage(true)

                getSavedPost(currentPage);

                setTimeout(() =>{
                    setShowMessage(false)
                }, 2000)
            }
        })
        .catch(error => console.log('error', error));
    };

    const showPostDetails = (postId) => {
        history.push(`/dashboard/app/post-detail/${postId}`);
    };

    return (
        <>
            <div className="post-item post_cards">
                <div className="user-post-data pb-2">
                    <div className="d-flex justify-content-between">
                        {userData && item.user_id === userData.id ? (
                            <NavLink to="#" location={{}} onClick={() => userProfile()}  className="me-2">
                                <img
                                    className="rounded-circle avatar-50"
                                    src={item.details && item.details.photo ? item.details.photo : dummyUserImage}
                                    alt=""
                                    onError={(e) => {
                                        e.target.src = dummyUserImage
                                    }}
                                />
                            </NavLink>
                        ) : (
                            <NavLink to="#" location={{}} onClick={() => friendProfile()}  className="me-2">
                                <img
                                    className="rounded-circle avatar-50"
                                    src={item.details && item.details.photo ? item.details.photo : dummyUserImage}
                                    alt=""
                                    onError={(e) => {
                                        e.target.src = dummyUserImage
                                    }}
                                />
                            </NavLink>
                        )}

                        <div className="w-100">
                            <div className="d-flex justify-content-between align-items-start flex-nowrap h-100">
                                <div className='d-flex justify-content-center h-100 flex-column'>
                                    {item.tagged_users == null || item.tagged_users == "0" ? (
                                            <div className="h5 fw-bold mb-1 lh-1">{item.details.name}</div>
                                        ) : (
                                            <div className={`${item.tagged_users.length > 0 ? 'h5 mb-1 lh-1' : 'h5 mb-1 lh-1'}`}>
                                                <strong>{item.details && item.details.name + ' '} </strong>

                                                {item.tagged_users.length > 0 ? <> {' '} is with {' '}
                                                    <span>
                                                        <NavLink className="text-dark" to="#" location={{}} onClick={() => taggedUserProfile(item.tagged_users[0].id)}>
                                                            <strong>{item.tagged_users[0].name}</strong>
                                                        </NavLink>
                                                    </span> {' '}
                                                </> : ''}

                                                {item.tagged_users.length > 1 ? <>and
                                                    <span className='d-inline-block ms-1 tagged_user_drop'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                            as={CustomToggle}
                                                            id="post-option"
                                                            >
                                                                <strong className='text-dark'>
                                                                    {(item.tagged_users.length - 1) > 1 ? `${item.tagged_users.length - 1} others` : "1 other"}
                                                                </strong>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {item.tagged_users.map((item, index) => {
                                                                    return(
                                                                        <Dropdown.Item className={index === 0 ? 'd-none' : 'd-block px-2'} key={item.id} to="#" location={{}} onClick={() => taggedUserProfile(item.id)}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <img
                                                                                    className="rounded-circle avatar-30"
                                                                                    src={item.photo ? item.photo : dummyUserImage}
                                                                                    alt=""
                                                                                    onError={(e) => {
                                                                                        e.target.src = dummyUserImage
                                                                                    }}
                                                                                />
                                                                                <h6 className='mb-0 ms-1 fw-500'>
                                                                                    {item.name}
                                                                                </h6>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    )
                                                                })}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span>
                                                </> : ''}
                                            </div>
                                    )}
                                    <p className="mb-2 lh-1"><strong>{item.universe_id && item.universe_id.universeName}</strong>{item.sub_universe_id ? <strong>{', '} {item.sub_universe_id.universeName}</strong> : ''} {item.createdAt ? `(${item.createdAt})` : ''}</p>

                                    <div className="d-flex align-items-center flex-wrap badge_btns">
                                        {item && item.privacy && 
                                            <span className="btn btn-light custom_badge me-2">
                                                <i className="ri-settings-2-line h4"></i> {item.privacy}
                                            </span>
                                        }

                                        {item && item.multiverse_id && 
                                            <span className="btn btn-light custom_badge me-2">
                                                <i className="ri-settings-2-line h4"></i> {item.multiverse_id.multiverseName}
                                            </span>
                                        }

                                        {item && item.sub_multiverse_id && 
                                            <span className="btn btn-light custom_badge">
                                                <i className="ri-flag-line h4"></i> {item.sub_multiverse_id.multiverseName}
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="card-post-toolbar">
                                    <Dropdown>
                                        <Dropdown.Toggle className="bg-transparent border-0 p-0 lh-1">
                                            <i className="ri-more-fill"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className=" m-0 p-0">
                                            <Dropdown.Item
                                                className=" p-3"
                                                to="#"
                                                onClick={() => {showPostDetails(item.id)}}
                                            >
                                                <div className="d-flex align-items-top">
                                                    <i className="ri-profile-line h4"></i>
                                                    <div className="data ms-2">
                                                        <h6>View details</h6>
                                                        <p className="mb-0">
                                                            View post details
                                                        </p>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>

                                            {userData && item.isSaved === true && (
                                                <Dropdown.Item
                                                    className="p-3"
                                                    to="#"
                                                    onClick={() => {handleRemoveSavePost(item.id)}}
                                                >
                                                    <div className="d-flex align-items-top">
                                                    <i className="ri-save-line h4"></i>
                                                    <div className="data ms-2">
                                                        <h6>Unsave post</h6>
                                                        <p className="mb-0">
                                                            Remove this from your saved items
                                                        </p>
                                                    </div>
                                                    </div>
                                                </Dropdown.Item>
                                            )}

                                            {userData && item.user_id === userData.id && 
                                                <Dropdown.Item
                                                    className=" p-3"
                                                    to="#"
                                                    onClick={() => {setDeletePostConfirm(true)}}
                                                >
                                                    <div className="d-flex align-items-top">
                                                        <i className="ri-delete-bin-7-line h4"></i>
                                                        <div className="data ms-2">
                                                            <h6>Delete</h6>
                                                            <p className="mb-0">
                                                                Delete this post
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="">
                    <div className='text-black mb-3'>
                        {item.post_body && (
                            <Linkify>
                                {item.post_body}
                            </Linkify>
                        )}   
                    </div>
                    
                    {item.post_body && (item.post_body.match(/\bhttps?::\/\/\S+/gi) || item.post_body.match(/\bhttps?:\/\/\S+/gi)) && (
                        <LinkPreview data={item.post_body} />
                    )}
                </div>

                {item.post_type !== "text" && (
                    <div className="user-post mt-2">
                        {item.post_type == "image" && item.media_url != null && (
                            <img
                                className="img-fluid w-100"
                                src={item.media_url}
                                alt="post"
                                onError={(e) => {
                                    e.target.src = BrokenImg
                                }}
                            />
                        )}

                        {item.post_type == "video" && item.media_url != null && (
                            <div className="video_players">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={item.media_url}
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                        playsinline
                                    />
                                </div>
                            </div>
                        )}

                        {item.post_type == "audio" && item.media_url != null && (
                            <div className="d-flex">
                                <ReactAudioPlayer
                                    src={item.media_url}
                                    controls
                                    className="w-100"
                                />
                            </div>
                        )}
                    </div>
                )}

                {/* <div className="mt-2 text-muted">
                    <p className="text-muted">{item.post_body}</p>
                </div> */}
            </div>

            <Modal
                size="sm"
                className="fade"
                id="post-modal"
                dialogClassName="modal-dialog-centered"
                onHide={handleClose}
                show={deletePostConfirm}
            >
                <div className="text-center p-2">
                    <Modal.Body>
                        <h5>Are you sure you want to delete this post?</h5>
                    </Modal.Body>
                    <Modal.Footer className='px-0 pt-2 pb-3 d-flex justify-content-center border-0'>
                        <Button variant="primary" onClick={handleClose} className="me-2">
                            Cancel
                        </Button>
                        <Button variant="outline-danger" onClick={() => {handleDeletePost(item.id)}}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

export default SavedPostItem;